import { Tabs, useTranslation } from '@paykassma/pay-kit';
import { IWithdrawalListItem } from 'api/withdrawalGroup';
import RightModal from 'components/Modals/RightModal';
import React, { useEffect, useState } from 'react';

import CommonData from './CommonData';
import styles from './DetailedView.module.scss';
import { Postback } from './Postback';

interface IDetailedViewProps {
	readonly withdrawalData?: IWithdrawalListItem;
	readonly onClose: () => void;
	readonly refreshHandler: () => void;
}

const DetailedView: React.FC<IDetailedViewProps> = ({
	withdrawalData,
	onClose,
	refreshHandler,
}) => {
	const { t } = useTranslation();
	const tabs = [
		{
			label: t('Withdrawal info'),
			value: `/withdrawals/${withdrawalData?.id}/common`,
		},
	];

	const [activeTab, setActiveTab] = useState<string | number>(tabs[0].value);

	useEffect(() => {
		setActiveTab(() => {
			if (withdrawalData?.id) {
				return tabs[0].value;
			}

			return null;
		});
	}, [withdrawalData?.id]);

	if (withdrawalData && withdrawalData.postback_id !== null) {
		tabs.push({
			// label: "Постбeк",
			label: t('Postback'),
			value: `/withdrawals/${withdrawalData?.id}/postback`,
		});
	}

	return (
		<RightModal
			bodyClassName={styles.DetailedViewWrapper}
			onShow={() => null}
			isVisible={!!withdrawalData}
			onClose={onClose}
		>
			<Tabs
				value={activeTab}
				onChange={(value) => setActiveTab(value)}
				data-test-id={'details-tabs'}
				tabs={tabs}
			/>
			{withdrawalData && activeTab === tabs[0].value && (
				<CommonData id={withdrawalData.id} refreshHandler={refreshHandler} />
			)}
			{withdrawalData &&
				withdrawalData.postback_id !== null &&
				activeTab === tabs[1].value && (
					<Postback
						direction='outgoing'
						postback_id={withdrawalData.postback_id}
						postback_status={withdrawalData.postback_status}
					/>
				)}
		</RightModal>
	);
};

export default DetailedView;
