import { useTranslation } from 'pay-kit';
import RoutedTabs, { RoutedTab } from 'components/RoutedTabs';
import { Roles, useHasAccessHook } from 'contexts/AuthContext';
import { Outlet } from 'react-router-dom';
import PageTitle from 'src/components/PageTitle';

import styles from './styles.module.scss';

export default function TransactionsPage() {
	const { t } = useTranslation();
	const tabs: RoutedTab[] = [
		{
			label: t('Current'),
			value: '/transactions',
			visible: useHasAccessHook({ rule: Roles.TRANSACTION_LIST }),
		},
		{
			label: t('Possible'),
			value: '/transactions/possible',
			visible: useHasAccessHook({ rule: Roles.TRANSACTION_MANAGEMENT }),
		},
		{
			label: t('Archived'),
			value: '/transactions/archived',
			visible: useHasAccessHook({ rule: Roles.ARCHIVE_TRANSACTION_VIEW }),
		},
	];

	return (
		<div className={styles.panel}>
			<PageTitle title={t('Transactions')} />
			<RoutedTabs tabs={tabs} />
			<Outlet />
		</div>
	);
}
