import { Loader as Loading } from 'pay-kit';
import { FC, ReactElement } from 'react';

const Loader: FC<LoaderType> = ({ children, className, color, loading }) => {
	return loading ? (
		<div className={className}>
			<Loading color={color || `#697077`} />
		</div>
	) : (
		<>{children}</>
	);
};

export default Loader;

type LoaderType = {
	readonly children: ReactElement | null;
	readonly loading: unknown;
	readonly color?: string;
	readonly className?: string;
};
