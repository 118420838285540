import { useTranslation } from 'pay-kit';
import { useRef, useState } from 'react';
import { errorsMap } from 'utils/enums';

import styles from './DropZone.module.scss';
import FilePreview from './FilePreview';
import CloudIcon from './icons/cloudIcon.svg';
import TrashbinIcon from './icons/trashbin.svg';

interface IDropZoneProps {
	readonly onChange: (file?: File | string) => void;
	readonly setErrors?: (errors: readonly string[]) => void;
	readonly error?: string;
	readonly file?: File;
	readonly fileExtensionWhiteList: readonly `.${string}`[];
	readonly classname?: string;
}

const DropZone: React.FC<IDropZoneProps> = ({
	onChange,
	file,
	fileExtensionWhiteList,
	error,
	setErrors,
	classname,
}) => {
	const { t } = useTranslation();
	const [dragActive, setDragActive] = useState(false);
	const inputRef = useRef<HTMLInputElement>(null);

	const onAddFile = (file: File) => {
		onChange(undefined);

		const isWhiteListedByExtension =
			fileExtensionWhiteList.filter((ext) =>
				file.name.toLowerCase().endsWith(ext),
			).length > 0;

		if (!isWhiteListedByExtension && typeof setErrors === 'function') {
			// return setErrors([`${file.name} has wrong file extension`]);
			return setErrors([errorsMap.getWrongExtension(file.name)]);
		}

		onChange(file);
	};

	const handleDrag = function (e: any) {
		e.preventDefault();
		e.stopPropagation();

		if (e.type === 'dragenter' || e.type === 'dragover') {
			setDragActive(true);
		} else if (e.type === 'dragleave') {
			setDragActive(false);
		}
	};

	const handleDrop = function (e: any) {
		e.preventDefault();
		e.stopPropagation();
		setDragActive(false);

		if (e.dataTransfer.files && e.dataTransfer.files[0]) {
			onAddFile(e.dataTransfer.files[0]);
		}
	};

	const handleChange = function (e: any) {
		e.preventDefault();

		if (e.target.files && e.target.files[0]) {
			onAddFile(e.target.files[0]);
		}
	};

	const onButtonClick = () => {
		if (inputRef.current && inputRef.current.click !== undefined) {
			inputRef.current.click();
		}
	};

	const isFile = file instanceof File;

	if (isFile || typeof file === 'string') {
		return (
			<div className={classname}>
				<div className={styles.preview}>
					<FilePreview file={file} className={styles.image} />
					<button
						onClick={() => {
							onChange(undefined);
							setErrors && setErrors([]);
						}}
						data-test-id=''
					>
						<TrashbinIcon />
						{t('Delete')}
					</button>
				</div>
				{error && <div className={styles.errors}>{error}</div>}
			</div>
		);
	}

	return (
		<form
			className={styles.formFileUpload}
			onDragEnter={handleDrag}
			onSubmit={(e) => e.preventDefault()}
		>
			<label
				className={[
					styles.labelFileUpload,
					dragActive ? styles.dragActive : null,
				]
					.filter((c) => c !== null)
					.join(' ')}
			>
				<input
					ref={inputRef}
					type='file'
					className={styles.inputFileUpload}
					multiple={false}
					onChange={handleChange}
					data-test-id=''
					accept={fileExtensionWhiteList?.join(', ')}
				/>

				<div>
					<CloudIcon />
					<p>
						{t('Drag & Drop to Upload File')}
						{fileExtensionWhiteList
							? fileExtensionWhiteList.map((ext) => (
									<>
										<b key={ext}>{ext}</b>&nbsp;
									</>
								))
							: null}
					</p>
					<div>{t('or')}</div>

					<button
						className={styles.uploadButton}
						onClick={onButtonClick}
						data-test-id=''
					>
						{t('Browse File')}
					</button>
				</div>
			</label>

			{dragActive && (
				<div
					className={styles.dragFileElement}
					onDragEnter={handleDrag}
					onDragLeave={handleDrag}
					onDragOver={handleDrag}
					onDrop={handleDrop}
				/>
			)}
			{error && <div className={styles.errors}>{error}</div>}
		</form>
	);
};

export default DropZone;
