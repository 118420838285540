import { ScrollBox } from '@paykassma/pay-kit';
import CaretIcon from 'components/Icons/CaretIcon';
import LimitsDisplay from 'modules/Dashboard/components/WalletsTotalBalance/components/LimitsDisplay';
import useWalletTypeLimitsList from 'modules/Dashboard/hooks/useWalletTypeLimitsList';
import { Loader } from 'pay-kit';
import { FC, useState } from 'react';

import styles from './limits.module.scss';

const psItemStyle = {
	open: [styles.ps, styles.psActive].join(' '),
	close: styles.ps,
};

const caretStyle = {
	open: styles.caretUp,
	close: '',
};

const Limits: FC<Limits> = ({ options }) => {
	const { load, extendedList } = useWalletTypeLimitsList();
	const [styleKey, setStyleKey] = useState({} as StyleKeyType);

	const OnClick: OnClickType = (walletType) => {
		if (!styleKey[walletType] || styleKey[walletType] === 'close') {
			load(walletType);
		}

		setStyleKey((item) => ({
			...item,
			[walletType]: item[walletType] === 'open' ? 'close' : 'open',
		}));
	};

	const renderPS = () =>
		options?.map((ps, i) => {
			const isLoader =
				styleKey[ps.value] === 'open' &&
				extendedList[ps.value]?.state === 'pending' &&
				!extendedList[ps.value]?.list;

			const isLimitsDisplay =
				styleKey[ps.value] === 'open' && extendedList[ps.value]?.list;

			return (
				<>
					<div
						data-test-id='l1UZ5R2Ekjb4f_xpFyr4e'
						key={i}
						className={psItemStyle[styleKey[ps.value]] || psItemStyle.close}
						onClick={() => OnClick(ps.value)}
					>
						<CaretIcon
							className={caretStyle[styleKey[ps.value]] || caretStyle.close}
						/>
						{ps.label}
						{isLoader && (
							<div className={styles.loader}>
								<Loader color='#697077' />
							</div>
						)}
					</div>
					{isLimitsDisplay && (
						<LimitsDisplay list={extendedList[ps.value]?.list} />
					)}
				</>
			);
		});

	return (
		<div className={styles.limitsWrapper}>
			<ScrollBox scrollDirection='vertical' className={styles.limitsScrollBox}>
				{renderPS()}
			</ScrollBox>
		</div>
	);
};

export default Limits;

type Limits = {
	readonly options:
		| readonly {
				readonly label: string;
				readonly value: string;
		  }[]
		| undefined;
};

export type StyleKeyType = {
	readonly [key: string]: 'open' | 'close';
};

type OnClickType = (walletType: string) => void;
