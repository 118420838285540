import { AuthContext } from 'contexts/AuthContext/AuthContext';
import BalancePage from 'layout/pages/BalancePage';
import AccountsBalancesPage from 'layout/pages/BalancePage/AccountsBalancesPage';
import ErrorPage from 'layout/pages/ErrorPage';
import LogoutPage from 'layout/pages/LogoutPage';
import NotFoundPage from 'layout/pages/NotFoundPage';
import OperationHistoryPage from 'layout/pages/OperationHistoryPage';
// import ProxiesPage from "layout/pages/ProxiesPage";
import StockpilingsPage from 'layout/pages/StockpilingsPage';
import WalletsDebuggingPage from 'layout/pages/WalletsDebuggingPage';
import LogsListPage from 'layout/pages/WalletsDebuggingPage/LogsListPage';
import WithdrawalsLogsListPage from 'layout/pages/WalletsDebuggingPage/WithdrawalsLogsListPage';
// https://confluence.fixmost.com/pages/viewpage.action?pageId=111613841
// import WalletsPage from "layout/pages/WalletsPage";
// import ArchivedWalletsPage from "layout/pages/WalletsPage/ArchivedWalletsPage";
// import DisabledWalletsPage from "layout/pages/WalletsPage/DisabledWalletsPage";
// import StatisticWalletsPage from "layout/pages/WalletsPage/StatisticWallets";
// import WorkingWalletsPage from "layout/pages/WalletsPage/WorkingWalletsPage";
import WithdrawalsPage from 'layout/pages/WithdrawalsPage';
import AppSettingsPrivate from 'modules/AppSettings/AppSettingsPrivate';
import AppSettingsProfile from 'modules/AppSettings/AppSettingsProfile';
import AppSettingSystem from 'modules/AppSettings/AppSettingsSystem';
import Plugin from 'modules/AppSettings/Plugin';
import { useContext } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import AppSettingsPage from './layout/pages/AppSettingsPage';
import DashboardPage from './layout/pages/DashboardPage';
import LoginPage from './layout/pages/LoginPage';
import Main from './layout/pages/Main';
import PaymentSystemsPage from './layout/pages/PaymentSystemsPage';
import PaymentSystemsListPage from './layout/pages/PaymentSystemsPage/PaymentSystemsListPage';
import PluginPage from './layout/pages/PaymentSystemsPage/PluginPage';
import TariffsPage from './layout/pages/PaymentSystemsPage/TariffsPage';
import TranslationsPage from './layout/pages/PaymentSystemsPage/TranslationsPage';
import PostbacksPage from './layout/pages/PostbacksPage';
import AcceptedPostbacksPage from './layout/pages/PostbacksPage/AcceptedPostbacksPage';
import NotAcceptedPostbacks from './layout/pages/PostbacksPage/NotAcceptedPostbacks';
import ReportsPage from './layout/pages/ReportsPage';
import AutoReportsPage from './layout/pages/ReportsPage/AutoReportsPage';
import ReportsListPage from './layout/pages/ReportsPage/ReportsListPage';
import TransactionsPage from './layout/pages/TransactionsPage';
import ArchivedTransactionsPage from './layout/pages/TransactionsPage/ArchivedTransactionsPage';
import CurrentTransactionsPage from './layout/pages/TransactionsPage/CurrentTransactionsPage';
import PossibleTransactionsPage from './layout/pages/TransactionsPage/PossibleTransactionsPage';
import WalletsLoadPage from './layout/pages/WalletsLoadPage';

const ProtectedRoutes = () => {
	const authContext = useContext(AuthContext);

	if (!authContext.isAuth) {
		return <Navigate to='login' replace />;
	}

	return <Main />;
};

export default function Router() {
	return (
		<Routes>
			<Route path='login' element={<LoginPage />} />
			<Route path='/' element={<ProtectedRoutes />}>
				<Route index element={<DashboardPage />} />
				<Route path='stockpiling' element={<StockpilingsPage />} />
				<Route
					path='operation-history/:ps/:id/:date?/:time?'
					element={<OperationHistoryPage />}
				/>
				<Route path='balances' element={<BalancePage />}>
					<Route path='accounts' element={<AccountsBalancesPage />} />
				</Route>
				<Route path='settingsGroup' element={<AppSettingsPage />}>
					<Route path='profile' element={<AppSettingsProfile />} />
					<Route path='system' element={<AppSettingSystem />} />
					<Route path='private' element={<AppSettingsPrivate />} />
					<Route path='plugin' element={<Plugin />} />
				</Route>
				<Route path='payment-systems' element={<PaymentSystemsPage />}>
					<Route index element={<PaymentSystemsListPage />} />
					<Route path='tariffs' element={<TariffsPage />} />
					<Route path='translates' element={<TranslationsPage />} />
					<Route path='plugin' element={<PluginPage />} />
				</Route>
				<Route path='reports' element={<ReportsPage />}>
					<Route index element={<ReportsListPage />} />
					<Route path='autoreports' element={<AutoReportsPage />} />
				</Route>

				<Route path='postbacks' element={<PostbacksPage />}>
					<Route index element={<AcceptedPostbacksPage />} />
					<Route path='not-accepted' element={<NotAcceptedPostbacks />} />
				</Route>
				<Route path='transactions' element={<TransactionsPage />}>
					<Route index element={<CurrentTransactionsPage />} />
					<Route path='archived' element={<ArchivedTransactionsPage />} />
					<Route path='possible' element={<PossibleTransactionsPage />} />
				</Route>
				<Route path='withdrawals' element={<WithdrawalsPage />} />
				<Route path='wallets-load' element={<WalletsLoadPage />} />
				<Route path='error' element={<ErrorPage />} />
				<Route path='logout' element={<LogoutPage />} />
				{/* <Route path="proxies" element={<ProxiesPage />} /> */}
				<Route path='logs' element={<WalletsDebuggingPage />}>
					<Route path='' element={<LogsListPage />} />
					<Route
						path='withdrawals-logs'
						element={<WithdrawalsLogsListPage />}
					/>
				</Route>
				<Route path='*' element={<NotFoundPage />} />
				{/* https://confluence.fixmost.com/pages/viewpage.action?pageId=111613841 */}
				{/* <Route path="wallets" element={<WalletsPage />}>
					<Route index element={<WorkingWalletsPage />} />
					<Route path="disabled" element={<DisabledWalletsPage />} />
					<Route path="archived" element={<ArchivedWalletsPage />} />
					<Route path="statistic" element={<StatisticWalletsPage />} />
				</Route> */}
			</Route>
		</Routes>
	);
}
