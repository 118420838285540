import React, { useState } from 'react';

import AddNewModal, { AddNewModalPropsType } from './modals/AddNewModal';
import RemoveModal, { RemoveModalPropsType } from './modals/RemoveModal';
import RenameModal, { RenameModalPropsType } from './modals/RenameModal';

type IActionsContextValue = {
	readonly setAddNewModalData: (data: AddNewModalPropsType['data']) => void;
	readonly setRenameModalData: (data: RenameModalPropsType['data']) => void;
	readonly setRemoveModalData: (data: RemoveModalPropsType['data']) => void;
};

export const ActionsContext = React.createContext<IActionsContextValue>({
	setAddNewModalData: () => {},
	setRenameModalData: () => {},
	setRemoveModalData: () => {},
});

type ActionsProviderPropsType = {
	readonly children: React.ReactNode;
	readonly refreshHandler: () => void;
};

const ActionsProvider: React.FC<ActionsProviderPropsType> = ({
	children,
	refreshHandler,
}) => {
	const [AddNewModalData, setAddNewModalData] =
		useState<AddNewModalPropsType['data']>(undefined);
	const [removeModalData, setRemoveModalData] =
		useState<RemoveModalPropsType['data']>(undefined);
	const [renameModalData, setRenameModalData] =
		useState<RenameModalPropsType['data']>(undefined);

	return (
		<ActionsContext.Provider
			value={{
				setRemoveModalData: (data) => setRemoveModalData(data),
				setRenameModalData: (data) => setRenameModalData(data),
				setAddNewModalData: (data) => setAddNewModalData(data),
			}}
		>
			{children}
			<RemoveModal
				data={removeModalData}
				onClose={() => setRemoveModalData(undefined)}
				onSuccess={refreshHandler}
			/>
			<RenameModal
				data={renameModalData}
				onClose={() => setRenameModalData(undefined)}
				onSuccess={refreshHandler}
			/>
			<AddNewModal
				data={AddNewModalData}
				onClose={() => setAddNewModalData(undefined)}
				onSuccess={refreshHandler}
			/>
		</ActionsContext.Provider>
	);
};

export default ActionsProvider;
