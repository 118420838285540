import {
	FormTypesItemType,
	TranslationPackageType,
	UpdateTranslationPackageDataType,
} from 'api/translationsGroup';

type fieldType = {
	readonly name: string;
	readonly formTypes: readonly {
		readonly formType: FormTypesItemType;
		readonly items: readonly TranslationPackageType[];
	}[];
	readonly isDefault: boolean;
};

export const getPackageIds = (field: fieldType) => {
	// eslint-disable-next-line functional/prefer-readonly-type
	const packageIds: string[] = [];

	field.formTypes.forEach((ft) => {
		ft.items.forEach((items) => {
			packageIds.push(items.uuid);
		});
	});

	return packageIds;
};

export const getPackages = (field: fieldType) => {
	// eslint-disable-next-line functional/prefer-readonly-type, sonarjs/no-unused-collection
	const packages: (UpdateTranslationPackageDataType & {
		readonly uuid: string;
	})[] = [];

	field.formTypes.forEach((ft) => {
		ft.items.forEach((item) => {
			packages.push({
				uuid: item.uuid,
				is_manual: item.is_manual,
				name: item.name,
				packages_translations: item.packages_translations.map((pt) => ({
					field_uuid: pt.field_uuid,
					translations: pt.translations,
				})),
			});
		});
	});

	return packages;
};
