import Caret from 'assets/icons/caret.svg';
import { FC } from 'react';

import styles from './caretIcon.module.scss';

const CaretIcon: FC<CaretIconType> = ({ className }) => {
	return (
		<div className={[styles.commonCaret, className].join(' ')}>
			<Caret />
		</div>
	);
};

export default CaretIcon;

type CaretIconType = {
	readonly className?: string;
};
