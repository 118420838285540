import SettingsIcon from 'assets/icons/settings.svg';
import { CurrenciesContext } from 'contexts/CurrenciesContext';
import { WalletTypesContext } from 'contexts/WalletTypesContext';
import KassmaPaymentFrame from 'modules/AppSettings/components/KassmaPaymentFrame';
import LabelWithHint from 'modules/AppSettings/components/LabelWithHint';
import { LANG_OPTIONS, TIPS } from 'modules/AppSettings/Plugin/utils/constants';
import { t } from 'pay-kit';
import { PayKitForm } from 'pay-kit';
import { ReactElement, useContext, useState } from 'react';

import styles from './pluginInstallation.module.scss';

const PluginInstallation = () => {
	const walletTypesContextAPI = useContext(WalletTypesContext);
	const currenciesContextAPI = useContext(CurrenciesContext);
	const [generatedFields, setGeneratedFields] =
		useState<GeneratedFieldsType | null>(null);
	const CURRENCY_CODE_OPTIONS = currenciesContextAPI.list.map((currency) => ({
		label: currency.code,
		value: currency.code,
	}));

	const WALLET_TYPE_OPTIONS = walletTypesContextAPI.walletTypes.map(
		(wallet) => ({
			label: wallet.name,
			value: wallet.code,
		}),
	);

	const onGenerate: onSubmitHandler = (state) => {
		setGeneratedFields(state);
	};

	const SCHEMA = [
		{
			type: 'Group',
			render: (elements: readonly ReactElement[]) => (
				<>
					<div className={styles.generateFieldsValuesHeader}>
						{t(`Enter parameter values to generate your widget code.`)}
					</div>
					<div className={styles.fieldsGroup}>{elements}</div>
				</>
			),
			elements: [
				{
					name: `label`,
					type: `TextInput`,
					className: styles.inputField,
					label: <LabelWithHint tip={TIPS.requiredTipForLabel} text='label' />,
				},
				{
					name: `fixed_amount`,
					type: `TextInput`,
					className: styles.inputField,
					label: <LabelWithHint tip={TIPS.optionalTip} text='fixed_amount' />,
				},
				{
					name: `currency_code`,
					type: `Select`,
					options: CURRENCY_CODE_OPTIONS,
					placeholder: t(`Choose`),
					className: styles.selectField,
					isLoading: currenciesContextAPI.isLoading,
					label: <LabelWithHint tip={TIPS.requiredTip} text='currency_code' />,
				},
				{
					name: `payment_url_success_id`,
					type: `TextInput`,
					className: styles.inputField,
					label: (
						<LabelWithHint
							tip={TIPS.optionalTipBySettings}
							text='payment_url_success_id'
						/>
					),
				},
				{
					name: `lang`,
					type: `Select`,
					placeholder: t(`Choose`),
					options: LANG_OPTIONS,
					className: styles.selectField,
					label: <LabelWithHint tip={TIPS.optionalTip} text='lang' />,
				},
				{
					name: `payment_url_fail_id`,
					type: `TextInput`,
					className: styles.inputField,
					label: (
						<LabelWithHint
							tip={TIPS.optionalTipBySettings}
							text='payment_url_fail_id'
						/>
					),
				},
				{
					name: `wallet_type`,
					type: `Select`,
					placeholder: t(`Choose`),
					options: WALLET_TYPE_OPTIONS,
					className: styles.selectField,
					isLoading: walletTypesContextAPI.isLoading,
					label: <LabelWithHint tip={TIPS.optionalTip} text='wallet_type' />,
				},
				{
					name: `success_url`,
					type: `TextInput`,
					className: styles.inputField,
					label: (
						<LabelWithHint
							tip={TIPS.optionalTipBySettings}
							text='success_url'
						/>
					),
				},
				{
					name: `custom_transaction_id`,
					type: `TextInput`,
					className: styles.inputField,
					label: (
						<LabelWithHint
							tip={TIPS.optionalTip}
							text='custom_transaction_id'
						/>
					),
				},
				{
					name: `payment_url_pending_id`,
					type: `TextInput`,
					className: styles.inputField,
					label: (
						<LabelWithHint
							tip={TIPS.optionalTip}
							text='payment_url_pending_id'
						/>
					),
				},
				{
					name: `pending_url`,
					type: `TextInput`,
					className: styles.inputField,
					label: (
						<LabelWithHint
							tip={TIPS.optionalTipBySettings}
							text='pending_url'
						/>
					),
				},
				{
					name: `fail_url`,
					type: `TextInput`,
					className: styles.inputField,
					label: (
						<LabelWithHint tip={TIPS.optionalTipBySettings} text='fail_url' />
					),
				},
			],
		},
		{
			type: 'Group',
			render: (elements: readonly ReactElement[]) => (
				<div className={styles.submitButton}>{elements}</div>
			),
			elements: [
				{
					type: 'SubmitButton',
					label: (
						<div className={styles.submitButtonValue}>
							<SettingsIcon /> {t('Generate')}{' '}
						</div>
					),
					isLoading: false,
					onSubmit: onGenerate,
				},
			],
		},
	];

	return (
		<div className={styles.pluginInstallation}>
			<PayKitForm.Builder schema={SCHEMA} />

			<KassmaPaymentFrame generatedFields={generatedFields} />
		</div>
	);
};

export default PluginInstallation;

type onSubmitHandler = (state: GeneratedFieldsType) => void;

export type GeneratedFieldsType = {
	readonly label: string;
	readonly currency_code: string;
	readonly lang: string;
	readonly wallet_type: string;
	readonly custom_transaction_id: string;
	readonly fixed_amount: string;
	readonly payment_url_success_id: string;
	readonly payment_url_fail_id: string;
	readonly success_url: string;
	readonly payment_url_pending_id: string;
	readonly pending_url: string;
	readonly fail_url: string;
};
