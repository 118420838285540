import NotAcceptedPostbacks from 'modules/Postbacks/NotAcceptedPostbacks';
import { useEffect } from 'react';

export default function NotAcceptedPostbacksPage() {
	useEffect(() => {
		document.title = 'NotAcceptedPostbacks';
	}, []);

	return <NotAcceptedPostbacks />;
}
