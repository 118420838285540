import { Loader } from 'pay-kit';

import styles from './tabLoader.module.scss';

const TabLoader = () => {
	return (
		<div className={styles.loaderWrapper}>
			<Loader color='#A2A9B0' />
		</div>
	);
};

export default TabLoader;
