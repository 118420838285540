import { fetchOldApi } from './fetch';

const paymentSystemGroup = {
	updateSettings: (paymentSystem: any, data: any) =>
		fetchOldApi(
			`/payment-systems/update-settings/${paymentSystem}`,
			data,
			'POST',
		),
	applyCurrencies: (walletType: string, data: applyCurrenciesData) =>
		fetchOldApi(
			`/wallet-type-to-currency/?wallet_type=${walletType}`,
			data,
			'PUT',
		),
};

export interface applyCurrenciesData {
	readonly currencies: readonly string[];
	readonly items: readonly {
		readonly id: number;
		readonly is_applied: boolean;
	}[];
}

export default paymentSystemGroup;
