import API from 'api';
import { GetPluginSettingsType } from 'api/settingsGroup';
import { useCallback, useState } from 'react';
import { errorsMap } from 'utils/enums';

const useGetPluginSettings = () => {
	const [data, setData] = useState<GetPluginSettingsType[`params`] | null>(
		null,
	);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState<null | Error>(null);

	const load = useCallback(() => {
		setIsLoading(true);
		setError(null);
		setData(null);

		API.settings
			.getPluginSettings()
			.then((res) => {
				if (res.status === 'ok') {
					setData(res.params);
				} else {
					throw new Error(errorsMap.anyResponse);
				}

				return res;
			})
			.catch((err) => {
				setError(err);
			})
			.finally(() => setIsLoading(false));
	}, []);

	return {
		load,
		data,
		isLoading,
		error,
	};
};

export default useGetPluginSettings;
