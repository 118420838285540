import API from 'api';
import { TimezoneType } from 'api/settingsGroup';
import { t } from 'pay-kit';
import { useState } from 'react';
import { errorsMap } from 'utils/enums';

const useSetTimeZone = () => {
	const [error, setError] = useState<Error | null>(null);
	const [isLoading, setIsLoading] = useState(false);

	const update = (timeZone: TimezoneType[`timezone`]) => {
		setError(null);
		setIsLoading(true);

		return API.settings
			.setTimeZone(timeZone)
			.then((res) => {
				if (res.status === 'success') {
					window.pushAlert({
						type: `success`,
						description: t(`Successfully updated`),
					});

					return res;
				} else {
					throw new Error(errorsMap.anyResponse);
				}
			})
			.catch((err) => {
				console.log(err);
				setError(err);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	return {
		update,
		isLoading,
		error,
	};
};

export default useSetTimeZone;
