import { WalletTypeLimitsType } from 'api/walletTypesGroup';
import { convertIntoCurrency } from 'helpers/convertIntoCurrency';
import { useTranslation } from 'pay-kit';
import { Tooltip } from 'pay-kit';
import { FC } from 'react';

import styles from './limitsDisplay.module.scss';

const LimitsDisplay: FC<LimitsDisplayType> = ({ list, className }) => {
	const { t } = useTranslation();
	const renderLimits = () =>
		list?.map((limit, i) => {
			const minWithdrawal =
				limit.limits.withdrawal.min &&
				convertIntoCurrency({ amount: limit.limits.withdrawal.min });
			const maxWithdrawal =
				limit.limits.withdrawal.max &&
				convertIntoCurrency({ amount: limit.limits.withdrawal.max });
			const minDeposit =
				limit.limits.deposit.min &&
				convertIntoCurrency({ amount: limit.limits.deposit.min });
			const maxDeposit =
				limit.limits.deposit.max &&
				convertIntoCurrency({ amount: limit.limits.deposit.max });

			return (
				<div key={i}>
					<div className={styles.currency}>{limit.currency}</div>
					<div className={styles.withdrawalDepositLimits}>
						<div className={styles.limits}>
							<span className={styles.text}>{t('Withdrawal')}</span>
							<div>
								<div className={styles.limitsBlock}>
									<div className={styles.minMaxText}>{t(`Min.`)}</div>

									<Tooltip tip={minWithdrawal}>
										<div className={styles.limit}>{minWithdrawal}</div>
									</Tooltip>
								</div>
								<div className={styles.limitsBlock}>
									<div className={styles.minMaxText}>{t(`Max.`)}</div>

									<Tooltip tip={maxWithdrawal}>
										<div className={styles.limit}>{maxWithdrawal}</div>
									</Tooltip>
								</div>
							</div>
						</div>
						<div className={styles.limits}>
							<span className={styles.text}>{t(`Deposit`)}</span>
							<div>
								<div className={styles.limitsBlock}>
									<div className={styles.minMaxText}>{t(`Min.`)}</div>

									<Tooltip tip={minDeposit}>
										<div className={styles.limit}>{minDeposit}</div>
									</Tooltip>
								</div>
								<div className={styles.limitsBlock}>
									<div className={styles.minMaxText}>{t(`Max.`)}</div>

									<Tooltip tip={maxDeposit}>
										<div className={styles.limit}>{maxDeposit}</div>
									</Tooltip>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		});

	return (
		<div className={[styles.limitsDisplayWrapper, className].join(' ')}>
			{list?.length ? (
				renderLimits()
			) : (
				<div className={styles.noLimits}>There is no assigned limits!</div>
			)}
		</div>
	);
};

export default LimitsDisplay;

type LimitsDisplayType = {
	readonly list: readonly WalletTypeLimitsType[] | null;
	readonly className?: string;
};
