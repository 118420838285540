// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BalanceOperations__form-oHR{padding:32px;display:flex;flex-direction:column}.BalanceOperations__form-oHR .BalanceOperations__scrollBox-fnp{height:470px}.BalanceOperations__form-oHR .BalanceOperations__baseSettings-Gqx{display:grid;grid-template-columns:450px 450px;width:100%;column-gap:48px;row-gap:12px}.BalanceOperations__form-oHR .BalanceOperations__actions-VwG{background:#f2f3f5;display:flex;align-items:center;justify-content:flex-end;gap:48px;margin-top:auto}.BalanceOperations__form-oHR .BalanceOperations__actions-VwG>button:last-child{min-width:170px}`, "",{"version":3,"sources":["webpack://./src/modules/Reports/ReportList/components/ActionsProvider/modals/NewReport/forms/BalanceOperations/BalanceOperations.module.scss"],"names":[],"mappings":"AAAA,6BACC,YAAA,CACA,YAAA,CACA,qBAAA,CAEA,+DACC,YAAA,CAGD,kEACC,YAAA,CACA,iCAAA,CACA,UAAA,CACA,eAAA,CACA,YAAA,CAGD,6DACC,kBAAA,CACA,YAAA,CACA,kBAAA,CACA,wBAAA,CACA,QAAA,CACA,eAAA,CAYA,+EACC,eAAA","sourcesContent":[".form {\n\tpadding: 32px;\n\tdisplay: flex;\n\tflex-direction: column;\n\n\t.scrollBox {\n\t\theight: 470px;\n\t}\n\n\t.baseSettings {\n\t\tdisplay: grid;\n\t\tgrid-template-columns: 450px 450px;\n\t\twidth: 100%;\n\t\tcolumn-gap: 48px;\n\t\trow-gap: 12px;\n\t}\n\n\t.actions {\n\t\tbackground: #F2F3F5;\n\t\tdisplay: flex;\n\t\talign-items: center;\n\t\tjustify-content: flex-end;\n\t\tgap: 48px;\n\t\tmargin-top: auto;\n\n\t\t// .formatSwitcher {\n\t\t// \tdisplay: flex;\n\t\t// \talign-items: center;\n\t\t// \tgap: 16px;\n\n\t\t// \tbutton {\n\t\t// \t\tpadding: 0 16px;\n\t\t// \t}\n\t\t// }\n\n\t\t> button:last-child {\n\t\t\tmin-width: 170px;\n\t\t}\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": `BalanceOperations__form-oHR`,
	"scrollBox": `BalanceOperations__scrollBox-fnp`,
	"baseSettings": `BalanceOperations__baseSettings-Gqx`,
	"actions": `BalanceOperations__actions-VwG`
};
export default ___CSS_LOADER_EXPORT___;
