import { Button } from '@paykassma/pay-kit';
import CopyIcon from 'assets/icons/copy.svg';
import { getFields } from 'modules/AppSettings/components/KassmaPaymentFrame/helpers';
import { GeneratedFieldsType } from 'modules/AppSettings/Plugin/components/PluginInstallation';
import { useTranslation } from 'pay-kit';
import { FC, useRef } from 'react';
import copyToClipboard from 'utils/copyToClipboard';

import styles from './kassmaPaymentFrame.module.scss';

const KassmaPaymentFrame: FC<KassmaPaymentFrameType> = ({
	generatedFields,
}) => {
	const { t } = useTranslation();
	const codeWrapperRef = useRef<HTMLElement>(null);

	const getFrameField = getFields(generatedFields);

	const onSuccessCopy = () => {
		window.pushAlert({
			title: t(`Success`),
			description: t(`Copied`),
			type: 'success',
		});
	};

	const onErrorCopy = () => {
		window.pushAlert({
			title: t('Warning'),
			description: t(`Failed to copy`),
			type: 'warning',
		});
	};

	const onClick = () =>
		copyToClipboard(
			codeWrapperRef.current?.innerText || ``,
			onSuccessCopy,
			onErrorCopy,
		);

	return (
		<div className={styles.kassmaPaymentFrame}>
			<div className={styles.paymentFrameHeader}>
				{t(
					`This is a code for your widget. Paste it into the code of those pages on which you want to display it. You should fill parameters`,
				)}
			</div>

			<div className={styles.codeWrapper}>
				<code className={styles.codeContainer} ref={codeWrapperRef}>
					&lt;iframe id="kassma-plugin-frame" src="{window.config.pluginUrl}
					/?label=
					<span className={styles.code}>{getFrameField(`label`)}</span>
					&currency_code=
					<span className={styles.code}>{getFrameField(`currency_code`)}</span>
					&lang=
					<span className={styles.code}>{getFrameField(`lang`)}</span>
					&wallet_type=
					<span className={styles.code}>{getFrameField(`wallet_type`)}</span>
					&custom_transaction_id=
					<span className={styles.code}>
						{getFrameField(`custom_transaction_id`)}
					</span>
					&fixed_amount=
					<span className={styles.code}>{getFrameField(`fixed_amount`)}</span>
					&payment_url_success_id=
					<span className={styles.code}>
						{getFrameField(`payment_url_success_id`)}
					</span>
					&payment_url_fail_id=
					<span className={styles.code}>
						{getFrameField(`payment_url_fail_id`)}
					</span>
					&success_url=
					<span className={styles.code}>{getFrameField(`success_url`)}</span>
					&payment_url_pending_id=
					<span className={styles.code}>
						{getFrameField(`payment_url_pending_id`)}
					</span>
					&pending_url=
					<span className={styles.code}>{getFrameField(`pending_url`)}</span>
					&fail_url=
					<span className={styles.code}>{getFrameField(`fail_url`)}</span>
					" width="640" height="750" frameBorder="0"&gt;&lt;/iframe&gt;
					<br />
					&lt;script&gt;document.querySelector('#kassma-plugin-frame').src +=
					'&' + new Date().getTime();&lt;/script&gt;
				</code>
			</div>

			<Button
				data-test-id='2MNFjF8wNfH7jNERM79-X'
				onClick={onClick}
				classname={styles.copyButton}
				variant='secondary'
			>
				<CopyIcon /> {t(`Copy`)}
			</Button>
		</div>
	);
};

export default KassmaPaymentFrame;

type KassmaPaymentFrameType = {
	readonly generatedFields: GeneratedFieldsType | null;
};
