import { useTranslation } from '@paykassma/pay-kit';
import api, { ListReportReqData } from 'api/reportsGroup';
import { useState } from 'react';
import { errorsMap } from 'utils/enums';

export type CreateTransactionsListReportType = (args?: {
	readonly onSuccess?: (data: any) => void;
	readonly onError?: (err: Error) => void;
}) => {
	readonly create: (data: ListReportReqData) => void;
	readonly isLoading: boolean;
};

const CreateTransactionsListReport: CreateTransactionsListReportType = (
	props,
) => {
	const [isLoading, setLoading] = useState<boolean>(false);
	const { t } = useTranslation();

	const create = (data: ListReportReqData) => {
		setLoading(true);

		api
			.createTransactionsListReport(data)
			.then((res: any) => {
				if (res?.status === 'success' || res?.status === 'ok') {
					props?.onSuccess && props?.onSuccess(res);

					window.pushAlert({
						title: t('The report has been sent to the queue for processing'),
						type: 'success',
					});
				} else {
					// throw new Error("CreateTransactionsListReport – Unexpected response");
					throw new Error(errorsMap.anyResponse);
				}

				return res;
			})
			.catch((err) => {
				props?.onError && props?.onError(err);
				console.error(err);

				window.pushAlert({
					title: t('An error occurred while creating report'),
					type: 'error',
				});
			})
			.finally(() => setLoading(false));
	};

	return {
		create,
		isLoading,
	};
};

export default CreateTransactionsListReport;
