import { useTranslation } from 'pay-kit';
import Withdrawals from 'modules/Withdrawals';
import PageTitle from 'src/components/PageTitle';

import styles from './styles.module.scss';

export default function WithdrawalsPage() {
	const { t } = useTranslation();

	return (
		<div className={styles.panel}>
			<PageTitle title={t('Withdrawals')} />
			<Withdrawals />
		</div>
	);
}
