import { WalletTypesContext } from 'contexts/WalletTypesContext';
import React, { useState } from 'react';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import useUpdateSettings from './hooks/useUpdateSettings';
import CommonSettings from './modals/CommonSettings';
import CreateNew from './modals/CreateNew';
import CurrenciesSettings from './modals/CurrenciesSettings';
import EditingModal from './modals/EditingModal';
import LimitsSettings from './modals/LimitsSettings';
import RemoveConfirmation from './modals/RemoveConfirmation';
import ViewSettings from './modals/ViewSettings';
import {
	ActionsProviderPropsType,
	ActiveModalStateType,
	IActionsContextValue,
	ModalTypesENUM,
} from './types';

export const ActionsContext = React.createContext<IActionsContextValue>({
	updateSettingsAPI: {
		isLoading: false,
		update: () => {},
	},
	showTariffSettings: () => {},
	showCommonSettings: () => {},
	showCreateNewModal: () => {},
	showCurrenciesSettings: () => {},
	showEditingModal: () => {},
	showLimitsSettings: () => {},
	showRemoveConfirmation: () => {},
	showViewSettings: () => {},
});

const ActionsProvider = ({ children }: ActionsProviderPropsType) => {
	const walletTypesAPI = useContext(WalletTypesContext);
	const updateSettingsAPI = useUpdateSettings({
		onSuccess: () => walletTypesAPI.getWalletTypesList(),
	});
	const [activeModal, setActiveModal] = useState<ActiveModalStateType>(null);
	const navigate = useNavigate();
	const activeWalletType =
		activeModal?.psId !== undefined
			? walletTypesAPI.walletTypes.find((wt) => wt.code === activeModal?.psId)
			: undefined;

	return (
		<ActionsContext.Provider
			value={{
				updateSettingsAPI,
				showTariffSettings: (type) =>
					navigate(`/payment-systems/tariffs?walletType=${type}`),
				showCommonSettings: (type) =>
					setActiveModal({
						modalType: ModalTypesENUM.CommonSettings,
						psId: type,
					}),
				showCreateNewModal: () =>
					setActiveModal({ modalType: ModalTypesENUM.CreateNewModal }),
				showCurrenciesSettings: (type) =>
					setActiveModal({
						modalType: ModalTypesENUM.CurrenciesSettings,
						psId: type,
					}),
				showEditingModal: (type) =>
					setActiveModal({
						modalType: ModalTypesENUM.EditingModal,
						psId: type,
					}),
				showLimitsSettings: (type) =>
					setActiveModal({
						modalType: ModalTypesENUM.LimitsSettings,
						psId: type,
					}),
				showRemoveConfirmation: (type) =>
					setActiveModal({
						modalType: ModalTypesENUM.RemoveConfirmation,
						psId: type,
					}),
				showViewSettings: (type) =>
					setActiveModal({
						modalType: ModalTypesENUM.ViewSettings,
						psId: type,
					}),
			}}
		>
			{children}
			<CreateNew
				isOpen={activeModal?.modalType === ModalTypesENUM.CreateNewModal}
				onClose={() => setActiveModal(null)}
				onSuccess={() => {
					walletTypesAPI.getWalletTypesList();
					setActiveModal(null);
				}}
			/>
			<CommonSettings
				psId={activeModal?.psId}
				isOpen={activeModal?.modalType === ModalTypesENUM.CommonSettings}
				onClose={() => setActiveModal(null)}
				onSuccess={walletTypesAPI.getWalletTypesList}
				paymentType={activeWalletType?.payment_type}
			/>
			<CurrenciesSettings
				psId={activeModal?.psId}
				isOpen={activeModal?.modalType === ModalTypesENUM.CurrenciesSettings}
				onClose={() => setActiveModal(null)}
				onSuccess={() => {
					setActiveModal(null);
					walletTypesAPI.getWalletTypesList();
				}}
			/>
			<EditingModal
				psId={activeModal?.psId}
				isOpen={activeModal?.modalType === ModalTypesENUM.EditingModal}
				onClose={() => setActiveModal(null)}
				onSuccess={walletTypesAPI.getWalletTypesList}
			/>
			<LimitsSettings
				psId={activeModal?.psId}
				isOpen={activeModal?.modalType === ModalTypesENUM.LimitsSettings}
				onClose={() => setActiveModal(null)}
				onSuccess={walletTypesAPI.getWalletTypesList}
			/>
			<RemoveConfirmation
				psId={activeModal?.psId}
				isOpen={activeModal?.modalType === ModalTypesENUM.RemoveConfirmation}
				onClose={() => setActiveModal(null)}
				onSuccess={walletTypesAPI.getWalletTypesList}
			/>
			<ViewSettings
				psId={activeModal?.psId}
				isOpen={activeModal?.modalType === ModalTypesENUM.ViewSettings}
				onClose={() => setActiveModal(null)}
				onSuccess={walletTypesAPI.getWalletTypesList}
				paymentType={activeWalletType?.payment_type}
			/>
		</ActionsContext.Provider>
	);
};

export default ActionsProvider;
