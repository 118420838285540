import { Button, Loader, TextInput } from '@paykassma/pay-kit/lib';
import { PAYKASSMA_REDIRECT_URL } from 'api/fetch';
import PaykassmaIcon from 'components/Icons/PaykassmaIcon';
import { AuthContext } from 'contexts/AuthContext/AuthContext';
import ThumbnailIcon from 'modules/LoginForm/ThumbnailIcon';
import { useTranslation } from 'pay-kit';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useTextInput from 'utils/hooks/useTextInput';
import { validate } from 'utils/validate';
import { object, string } from 'yup';

import styles from './login.module.scss';

const LoginForm = () => {
	const [username, setUsername] = useTextInput('');
	const [password, setPassword] = useTextInput('');
	const { t } = useTranslation();

	const { login, isExchanging } = useContext(AuthContext);

	const [submitting, setSubmitting] = useState(false);

	const [errors, setErrors] = useState<any>({});
	const schema = object().shape({
		username: string().required(t('Required field')),
		password: string().required(t('Required field')),
	});

	const { isAuth } = useContext(AuthContext);

	const navigate = useNavigate();

	const successUrl = localStorage.getItem(PAYKASSMA_REDIRECT_URL);

	useEffect(() => {
		if (isAuth) {
			if (successUrl) {
				navigate(successUrl, { replace: true });
				localStorage.removeItem(PAYKASSMA_REDIRECT_URL);
			} else {
				navigate('/', { replace: true });
			}
		}
	}, [isAuth, successUrl]);

	const submit = (e?: any) => {
		e.preventDefault();

		const validationErrors = validate(schema, { username, password });

		setErrors(validationErrors);

		if (!Object.keys(validationErrors).length) {
			setSubmitting(true);

			login(username, password)
				.then((resp) => {
					if (resp.status === 'error') {
						setErrors({
							all: resp.error_message,
							password: ' ',
							username: ' ',
						});
					}
				})
				.finally(() => {
					setSubmitting(false);
				});
		}
	};

	if (isExchanging) {
		return <Loader />;
	}

	return (
		<form className={styles.LoginFormWrapper}>
			<div className={styles.loginFields}>
				<PaykassmaIcon className={styles.paykassma} />

				<TextInput
					className={styles.login}
					name='name'
					value={username}
					error={errors?.username}
					onChange={setUsername}
					placeholder='Login'
				/>

				<TextInput
					className={styles.password}
					name='password'
					value={password}
					error={errors?.password}
					onChange={setPassword}
					type='password'
					placeholder='Password'
				/>

				<Button
					classname={styles.loginButton}
					isLoading={submitting}
					data-test-id='EX7eRQYvtN3m1JGojFfM6'
					type='submit'
					onClick={submit}
					disabled={submitting}
				>
					{t('Sign in')}{' '}
				</Button>
			</div>

			<ThumbnailIcon className={styles.thumbnail} />
		</form>
	);
};

export default LoginForm;
