import { array, object, string } from 'yup';
import { t } from '@paykassma/pay-kit';

const validationSchema = array().of(
	object({
		language: string().required(t('Choose language')),
		text: string().required(t('Enter text')),
	}),
);

export const validate = (formData: any) => {
	try {
		validationSchema.validateSync(formData, { abortEarly: false });
	} catch (e: any) {
		const err: any = {};

		e.inner.forEach((error: any) => {
			if (error.path) {
				err[error.path] = error.message;
			}
		});

		return err;
	}

	return {};
};
