import { useTranslation } from 'pay-kit';
import api from 'api/translationsGroup';
import { Button, Modal } from 'pay-kit';
import { useState } from 'react';
import { errorsMap } from 'utils/enums';

import styles from './RemoveModal.module.scss';

export type RemoveModalPropsType = {
	readonly data?: {
		readonly translationType: 'hint' | 'package';
		readonly hints?: readonly string[];
		readonly packageIds?: readonly string[];
		readonly fieldName: string;
	};
	readonly onClose: () => void;
	readonly onSuccess: () => void;
};

const RemoveModal: React.FC<RemoveModalPropsType> = ({
	data,
	onClose,
	onSuccess,
}) => {
	const { t } = useTranslation();
	const [isLoading, setIsLoading] = useState(false);

	const submitHandler = () => {
		if (data?.translationType === 'package') {
			removePackage();
		}

		if (data?.translationType === 'hint') {
			removeHint();
		}
	};

	const removePackage = () => {
		if (data?.packageIds === undefined) {
			return;
		}

		setIsLoading(true);

		Promise.all(
			data.packageIds.map((packageId) => api.removePackages(packageId)),
		)
			.finally(() => setIsLoading(false))
			.then((results: any) => {
				results.forEach((res: any) => {
					if (res.status === 'fail') {
						typeof res.message === 'string' &&
							window.pushAlert({ type: 'error', title: res.message });

						throw new Error(res.message || errorsMap.anyResponse);
					}
				});

				onSuccess && onSuccess();
				onClose();

				window.pushAlert({
					type: 'success',
					title: t('Translate successfully deleted'),
				});

				return results;
			})
			.catch((error) => {
				console.error(error);
				onClose();

				window.pushAlert({
					type: 'error',
					title: t('Translate deleting error'),
				});
			});
	};

	const removeHint = () => {
		if (data?.hints === undefined) {
			return;
		}

		setIsLoading(true);

		Promise.all(data?.hints.map((h) => api.deleteHint(h)))
			.finally(() => setIsLoading(false))
			.then((results: any) => {
				results.forEach((res: any) => {
					if (res.status === 'fail') {
						typeof res.message === 'string' &&
							window.pushAlert({ type: 'error', title: res.message });

						throw new Error(res.message || errorsMap.anyResponse);
					}
				});

				onSuccess && onSuccess();
				onClose();

				window.pushAlert({
					type: 'success',
					title: t('Translate successfully deleted'),
				});

				return results;
			})
			.catch((error) => {
				console.error(error);
				onClose();

				window.pushAlert({
					type: 'error',
					title: t('Translate deleting error'),
				});
			});
	};

	return (
		<Modal
			className={styles.removeModal}
			isOpen={data !== undefined}
			title={t('Delete translation')}
			onClose={onClose}
		>
			<p>
				{t('Are you sure you want to delete the translation')}{' '}
				<b>{data?.fieldName}</b>?
			</p>
			<div className={styles.controls}>
				<Button
					data-test-id='cancel'
					onClick={onClose}
					variant='secondary'
					disabled={isLoading}
				>
					{t('Cancel')}
				</Button>
				<Button
					data-test-id='remove-submit'
					onClick={submitHandler}
					variant='delete'
					isLoading={isLoading}
					disabled={isLoading}
				>
					{t('Delete')}
				</Button>
			</div>
		</Modal>
	);
};

export default RemoveModal;
