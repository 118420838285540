import styles from 'assets/styles/main.module.scss';
import { Outlet } from 'react-router-dom';
import Sidebar from 'src/layout/components/Sidebar';

export default function Main() {
	return (
		<div className={styles.Main}>
			<Sidebar />
			<div className={styles.Container}>
				<Outlet />
			</div>
			<div className={styles.footer}></div>
		</div>
	);
}
