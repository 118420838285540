import { useOutsideClick } from 'pay-kit';
import React, { useRef, useState } from 'react';

import ChevronIcon from './chevron.svg';
import styles from './DropdownButton.module.scss';

interface IOption {
	readonly id: string | number;
	readonly onClick: (id: string | number) => void;
	readonly title: React.ReactNode;
	readonly dataTestId?: string;
}

interface IDropdownButtonProps {
	readonly options: readonly IOption[];
	readonly children: React.ReactNode;
	readonly postfixElement?: React.ReactNode;
	readonly className?: string;
	readonly dropdownClassName?: string;
	readonly dataTestId?: string;
}

const DropdownButton = ({
	options,
	children,
	postfixElement,
	className,
	dropdownClassName,
	dataTestId,
}: IDropdownButtonProps) => {
	const [isOpen, setIsOpen] = useState(false);

	const closeDropdown = () => setIsOpen(false);
	const dropdownButtonElement = useRef<HTMLDivElement>(null);

	useOutsideClick(dropdownButtonElement, closeDropdown as any);

	const classNames = [styles.DropdownButton, className];

	if (isOpen) {
		classNames.push(styles.isOpen);
	}

	return (
		<div
			data-test-id={dataTestId}
			ref={dropdownButtonElement}
			className={classNames.join(' ')}
			onClick={() => setIsOpen(true)}
		>
			<div className={styles.caption}>
				<span>{children}</span>
				<ChevronIcon />
			</div>

			<div className={[styles.dropdown, dropdownClassName].join(' ')}>
				{options.map((option) => (
					<button
						data-test-id={option.dataTestId}
						key={option.id}
						onClick={(e) => {
							e.stopPropagation();
							option.onClick(option.id);
							setIsOpen(false);
						}}
					>
						{option.title}
					</button>
				))}
			</div>

			{postfixElement}
		</div>
	);
};

export default DropdownButton;
