export const deepClone = (o: object): any => {
	if (o && typeof o === 'object') {
		if (Array.isArray(o)) {
			return o.map(deepClone);
		} else if (o.constructor === Object) {
			return Object.entries(o).reduce(
				(prev, [k, v]) => ({ ...prev, [k]: deepClone(v) }),
				{},
			);
		}

		return o;
	}

	return o;
};
