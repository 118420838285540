import React from 'react';

import styles from './Skeleton.module.scss';

type SkeletonPropsType = {
	readonly rowsCount: number;
	readonly hasSplit: boolean;
	readonly columns: readonly {
		readonly showOnlyIf: boolean;
		readonly title: string;
		readonly dataIndex: string;
		readonly key: string;
		readonly className: string;
		readonly render?: undefined;
	}[];
};

const Skeleton = ({ rowsCount, columns, hasSplit }: SkeletonPropsType) => {
	if (!rowsCount || !columns) {
		return null;
	}

	const rowsNumberArray = Array.from(Array(rowsCount).keys());

	return (
		<>
			{rowsNumberArray.map((rowNumber) => (
				<div
					className={[
						styles.SkeletonRow,
						hasSplit ? styles.splittedRow : undefined,
					].join(' ')}
					key={rowNumber}
				>
					{columns.map((column, n) => {
						const animationDelay = `${rowNumber * 150}ms`;

						return (
							<div
								key={column.key}
								className={[styles.col, column.className].join(' ')}
								style={{ animationDelay }}
							>
								<div className={styles.fakeContent} />
							</div>
						);
					})}
				</div>
			))}
		</>
	);
};

export default Skeleton;
