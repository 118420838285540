import { useTranslation } from 'pay-kit';
import LogsList from 'modules/WalletsDebugging/LogsList';
import { useEffect } from 'react';

const LogsListPage = () => {
	const { t } = useTranslation();

	useEffect(() => {
		document.title = t('Log list');
	}, []);

	return <LogsList />;
};

export default LogsListPage;
