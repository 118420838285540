import { StandardResponse } from 'api/types';
import { TokenData, UserData } from 'contexts/AuthContext/utils/types';
import {
	TOKEN_UPDATE_EVENT_NAME,
	getRefreshToken,
	getToken,
	removeRefreshToken,
	tokenUpdater,
} from 'helpers/tokenHelper';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import API from '../../api';

import { Roles } from './utils/enums';

type IAuthContext = {
	readonly token: string;
	readonly isAuth: boolean;
	readonly login: (
		login: string,
		password: string,
	) => Promise<StandardResponse<TokenData>>;
	readonly hasRole: (role: Roles) => boolean;
	readonly user: any;
	readonly logout: () => Promise<any>;
	readonly permissionsIsLoading: boolean;
	readonly isExchanging: boolean;
};

export type ColumnUpdateData = {
	readonly column: string;
	readonly name: string;
	readonly show: boolean;
};

const initialState: IAuthContext = {
	hasRole: () => true,
	user: {},
	logout: () => new Promise(() => {}),
	token: '',
	isAuth: false,
	login: () => {
		return new Promise(() => {});
	},
	isExchanging: false,
};

const authRoutes = ['/login'];

export const AuthContext = React.createContext<IAuthContext>(initialState);

AuthContext.displayName = 'AuthContext';

export default function AuthContextProvider(props: any) {
	const [token, setLocalToken] = useState<string>(getToken());
	const [roles, setRoles] = useState<readonly Roles[]>([]);
	const [user, setUser] = useState<Partial<UserData>>({});
	const [permissionsIsLoading, setPermissionsIsLoading] =
		useState<boolean>(true);
	const navigate = useNavigate();

	const location = useLocation();
	const saToken = new URLSearchParams(location.search).get('sa_token');

	const [isExchanging, setExchanging] = useState<boolean>(!!saToken);

	useEffect(() => {
		if (saToken) {
			setExchanging(true);

			API.auth
				.exchange(saToken)
				.then((resp) => {
					if (resp.status === 'success') {
						const { token, refresh_token } = resp.data;

						tokenUpdater(token, refresh_token);
					} else {
						window.pushAlert({
							description: resp.error_message
								? resp.error_message
								: JSON.stringify(resp.errors),
							type: 'error',
						});
					}
				})
				.catch((err) => {
					console.error(err);
				})
				.finally(() => {
					setExchanging(false);
					navigate('/', { replace: true });
				});
		}
	}, [saToken]);

	const isAuth = useMemo(() => !!token, [token]);

	document.addEventListener(TOKEN_UPDATE_EVENT_NAME, (e: any) => {
		const { detail: newToken } = e;

		setLocalToken(() => newToken);
	});

	function login(login: string, password: string) {
		return API.auth.login(login, password).then((resp) => {
			if (resp.status === 'success') {
				const { token: newToken, refresh_token } = resp.data;

				tokenUpdater(newToken, refresh_token);

				return resp;
			}

			return resp;
		});
	}

	const { pathname } = useLocation();

	useEffect(() => {
		if (!isAuth && !authRoutes.includes(pathname)) {
			navigate('/login', { replace: true });
		}
	}, [isAuth]);

	function loadPermissions() {
		API.auth
			.me()
			.then((resp) => {
				if (resp.status === 'success') {
					const { roles, ...userData } = resp.data;

					setUser(userData);
					setRoles(roles);
				}

				return resp;
			})
			.finally(() => setPermissionsIsLoading(false))
			.catch((err) => console.error(err));
	}

	async function logout() {
		const refreshToken = getRefreshToken();

		removeRefreshToken();

		if (refreshToken) {
			return API.auth.logout(refreshToken).finally(() => {
				tokenUpdater('', '');
				setUser({});
				setRoles([]);
			});
		}
	}

	useEffect(() => {
		if (token && !user.name) {
			loadPermissions();
		}
	}, [token, user]);

	const hasRole = (role: Roles): boolean => {
		return roles.includes(role);
	};

	const value = {
		isExchanging,
		hasRole,
		user,
		logout,
		token,
		isAuth,
		login,
		permissionsIsLoading,
	};

	return (
		<AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>
	);
}

export const useAuthContext = () => useContext(AuthContext);
