import ReportList from 'modules/Reports/ReportList';
import { useTranslation } from 'pay-kit';
import { useEffect } from 'react';

export default function ReportsListPage() {
	const { t } = useTranslation();

	useEffect(() => {
		document.title = t('Report list');
	}, []);

	return <ReportList />;
}
