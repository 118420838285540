import { useTranslation } from 'pay-kit';
import PageTitle from 'components/PageTitle';
import Stockpilings from 'modules/Stockpilings';
import { useEffect } from 'react';

const StockpilingsPage = () => {
	const { t } = useTranslation();

	useEffect(() => {
		document.title = t('Stockpilings');
	}, []);

	return (
		<>
			<PageTitle title={t('Stockpilings')} />
			<Stockpilings />
		</>
	);
};

export default StockpilingsPage;
