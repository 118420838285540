const ArchiveIcon = () => {
	return (
		<svg
			width='16'
			height='16'
			viewBox='0 0 16 16'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M7 7C6.72386 7 6.5 7.22386 6.5 7.5C6.5 7.77614 6.72386 8 7 8H9C9.27614 8 9.5 7.77614 9.5 7.5C9.5 7.22386 9.27614 7 9 7H7ZM2 5C2 5.55228 2.44772 6 3 6L3 12C3 13.1046 3.89543 14 5 14H11C12.1046 14 13 13.1046 13 12V6C13.5523 6 14 5.55228 14 5V3C14 2.44772 13.5523 2 13 2H3C2.44772 2 2 2.44772 2 3V5ZM13 3L13 5H3L3 3H13ZM4 6H12V12C12 12.5523 11.5523 13 11 13H5C4.44772 13 4 12.5523 4 12V6Z'
				fill='currentColor'
			/>
		</svg>
	);
};

export default ArchiveIcon;
