import { OperationHistoryTransactions } from 'api/balanceGroup';
import { useAccountsBalancesContext } from 'contexts/AccountsBalanceContext';
import { convertIntoCurrency } from 'helpers/convertIntoCurrency';
import { FC } from 'react';
import { useParams } from 'react-router-dom';

import styles from './withdrawalAmount.module.scss';

const WithdrawalAmount: FC<
	Pick<OperationHistoryTransactions, 'amount' | 'directing' | 'currency_code'>
> = ({ amount, directing }) => {
	const { filteredBalances } = useAccountsBalancesContext();
	const { id, ps } = useParams();
	const chars = { outgoing: '-', ingoing: '+' };
	const formattedAmount = convertIntoCurrency({ amount });

	const foundBalance = filteredBalances?.find(
		(balance) => balance?.payment_system === ps,
	);
	const balance = foundBalance?.balances?.find(
		(balance) => balance.id === parseInt(id || ''),
	);
	const currencyCode = balance?.currency_code || '';

	return (
		<div
			className={styles[directing]}
		>{`${chars[directing]} ${formattedAmount} ${currencyCode}`}</div>
	);
};

export default WithdrawalAmount;
