import { useTranslation } from 'pay-kit';
import { IPossibleTransaction } from 'api/transactionGroup';
import { Modal } from 'pay-kit';
import React from 'react';

import styles from './ScreenshotModal.module.scss';

interface IScreenshotModalProps {
	readonly onClose: () => void;
	readonly screenshot: IPossibleTransaction['screenshot'];
}

const ScreenshotModal: React.FC<IScreenshotModalProps> = ({
	onClose,
	screenshot,
}) => {
	const { t } = useTranslation();

	return (
		<Modal isOpen={!!screenshot} title={t('Transaction')} onClose={onClose}>
			{screenshot && (
				<div className={styles.ScreenshotModalBody}>
					<div className={styles.label}>{t('Screenshot')}</div>
					<img src={screenshot} alt='screenshot' />
				</div>
			)}
		</Modal>
	);
};

export default ScreenshotModal;
