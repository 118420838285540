/* eslint-disable react/prop-types */
import { TariffItemType } from 'api/tariffsGroup';
import { Button, ScrollBox, TextInput, useTranslation } from 'pay-kit';
import { useEffect, useState } from 'react';

import styles from './CurrencySelect.module.scss';

type CurrencySelectProps = {
	readonly tariffs: readonly TariffItemType[];
	readonly onSelect: (selectedTariff: TariffItemType['id']) => void;
	readonly selectedTariff?: TariffItemType['id'];
	readonly isLoading: boolean;
};

const CurrencySelect: React.FC<CurrencySelectProps> = ({
	tariffs,
	onSelect,
	selectedTariff,
	isLoading,
}) => {
	const { t } = useTranslation();
	const [currencyFilter, setCurrencyFilter] = useState('');
	const filteredTariffs = tariffs.filter((c) =>
		c.currency.includes(currencyFilter.toUpperCase()),
	);

	useEffect(() => {
		if (selectedTariff === undefined) {
			setCurrencyFilter('');
		}
	}, [selectedTariff]);

	if (!tariffs || tariffs.length === 0) {
		return (
			<span className={styles.notFoundMessage}>
				{t('No tariffs available')}
			</span>
		);
	}

	const listIsTooLong = tariffs.length > 11;

	const noCurrenciesPlaceholder =
		tariffs.length > 0 && currencyFilter ? (
			<span className={styles.notFoundMessage}>{t('Currency not found')}</span>
		) : null;

	const filteredCurrenciesList =
		filteredTariffs.length > 0
			? filteredTariffs.map((tariff) => (
					<Button
						key={tariff.id}
						onClick={() => onSelect(tariff.id)}
						variant={tariff.id === selectedTariff ? 'primary' : 'secondary'}
						data-test-id='currencySelectButton'
						disabled={isLoading}
					>
						{tariff.currency}
					</Button>
				))
			: noCurrenciesPlaceholder;

	if (!listIsTooLong) {
		return (
			<div className={styles.shortCurrencySelect}>{filteredCurrenciesList}</div>
		);
	}

	return (
		<div className={styles.currencySelect}>
			<TextInput
				value={currencyFilter}
				onChange={(e) => setCurrencyFilter(e.currentTarget.value)}
				placeholder={t('Currency search')}
				name='currencyFilter'
				data-test-id='currencyFilter'
			/>
			<ScrollBox scrollDirection='horizontal' className={styles.scrollBox}>
				{filteredCurrenciesList}
			</ScrollBox>
		</div>
	);
};

export default CurrencySelect;
