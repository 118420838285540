import { DashboardDataType } from 'api/dashboardGroup';
import { Chart } from 'chart.js';

import { COLORS } from './constants';

const getRandomRgb = () => {
	const num = Math.round(0xffffff * Math.random());
	const r = num >> 16;
	const g = (num >> 8) & 255;
	const b = num & 255;

	return `rgb(${r}, ${g}, ${b})`;
};

export const prepareChartData = (
	dataType: 'sum' | 'count',
	rawData?: DashboardDataType['data'],
) => {
	if (!rawData || !rawData[dataType]) {
		return undefined;
	}

	const data = rawData[dataType];

	if (!data) {
		return undefined;
	}

	const result: resultType = {
		labels: [],
		datasets: [],
	};

	/* eslint-disable @typescript-eslint/no-explicit-any */
	/* eslint-disable functional/prefer-readonly-type */
	type resultType = {
		readonly labels: string[];
		datasets: any[];
	};

	const tempDatasets = {} as any;

	data.forEach((item: Record<any, any>, i) => {
		const date = new Date(item.date);
		const fullDate = date.toLocaleDateString('ru-RU');
		const splittedDate = fullDate.split('.');

		result.labels.push(`${splittedDate[0]}.${splittedDate[1]}`);

		// init datasets
		const psList = Object.keys(item).filter((key) => key !== 'date');

		psList.forEach((psName, n) => {
			if (i === 0 || tempDatasets[psName] === undefined) {
				const color = n < COLORS.length ? COLORS[n] : getRandomRgb();

				tempDatasets[psName] = {
					label: psName,
					data: [],
					currencies: [],
					backgroundColor: color,
					borderColor: color,
					borderWidth: 1.5,
					pointBackgroundColor: '#ffffff',
				};
			}

			tempDatasets[psName].data.push(item[psName]?.value);

			item[psName]?.currencies &&
				tempDatasets[psName].currencies.push(item[psName]?.currencies);
		});
	});

	result.datasets = Object.values(tempDatasets);

	return result;
};

const getOrCreateTooltip = (chart: Chart) => {
	let tooltipEl = chart.canvas.parentNode?.querySelector('div');

	if (!tooltipEl) {
		tooltipEl = document.createElement('div');
		tooltipEl.style.width = '300px';
		tooltipEl.style.zIndex = `9999`;
		tooltipEl.style.background = 'rgba(255, 255, 255, 0.9)';
		tooltipEl.style.borderRadius = '12px';
		tooltipEl.style.fontSize = '14px';
		tooltipEl.style.color = '#21272A';
		tooltipEl.style.opacity = `1`;
		tooltipEl.style.pointerEvents = 'none';
		tooltipEl.style.position = 'absolute';
		tooltipEl.style.transform = 'translate(-50%, 0)';
		tooltipEl.style.transition = 'all .1s ease';
		tooltipEl.style.boxShadow = '0px 4px 10px 0px rgba(0, 0, 0, 0.15)';
		tooltipEl.style.lineHeight = '20px';

		const tooltipScrollContainer = document.createElement('div');
		const table = document.createElement('table');

		table.style.margin = '0';
		table.style.width = '100%';
		tooltipScrollContainer.appendChild(table);
		tooltipEl.appendChild(tooltipScrollContainer);
		chart.canvas.parentNode?.appendChild(tooltipEl);
	}

	return tooltipEl;
};

const createCurrencyElement = (currency: string) => {
	const unitsText = document.createTextNode(currency);
	const unitsEl = document.createElement('span');

	unitsEl.style.marginRight = '4px';
	unitsEl.style.color = '#697077';
	unitsEl.appendChild(unitsText);

	return unitsEl;
};

export const createExternalTooltipHandler =
	(psMap: Record<string, string> = {}, units = '') =>
	(context: { readonly chart: Chart; readonly tooltip: TooltipType }) => {
		const { chart, tooltip } = context;
		const tooltipEl = getOrCreateTooltip(chart);

		if (tooltip.opacity === 0) {
			tooltipEl.style.opacity = `0`;

			return;
		}

		if (tooltip.body) {
			const titleLines = tooltip.title || [];
			const tableHead = document.createElement('thead');

			titleLines.forEach((title: string) => {
				const tr = document.createElement('tr');

				tr.style.borderWidth = '0';

				const th = document.createElement('th');

				th.style.borderWidth = '0';
				th.style.color = '#697077';
				th.style.textAlign = 'left';
				th.style.fontWeight = 'normal';
				th.colSpan = 2;

				const text = document.createTextNode(title);

				th.appendChild(text);
				tr.appendChild(th);
				tableHead.appendChild(tr);
			});

			const bodyLines = tooltip.body
				.map((uselessArg: unknown, index: number) => {
					const detailedData = tooltip.dataPoints[index];
					const dataIndex = detailedData.dataIndex; // timeline index
					const rawValue = detailedData.raw;
					const psName = detailedData.dataset.label;
					const currencies = detailedData.dataset.currencies[dataIndex];
					const label = psMap[psName] || psName;
					const formattedValue = units ? formatMoney(rawValue) : `${rawValue}`;

					return { label, formattedValue, currencies, rawValue };
				})
				.sort((a, b) => {
					if (a.rawValue > b.rawValue) {
						return -1;
					}

					if (a.rawValue < b.rawValue) {
						return 1;
					}

					return 0;
				});

			const tableBody = document.createElement('tbody');

			bodyLines.forEach((item, i: number) => {
				const colors = tooltip.labelColors[i];
				const colorMark = document.createElement('span');

				colorMark.style.background = colors.borderColor;
				colorMark.style.borderWidth = '2px';
				colorMark.style.marginRight = '8px';
				colorMark.style.height = '10px';
				colorMark.style.width = '10px';
				colorMark.style.borderRadius = '2px';
				colorMark.style.display = 'inline-block';

				const tr = document.createElement('tr');

				tr.style.backgroundColor = 'inherit';
				tr.style.borderWidth = '0';

				const tdLabel = document.createElement('td');

				tdLabel.style.borderWidth = '0';
				tdLabel.style.verticalAlign = 'top';
				tdLabel.style.width = '100%';

				const tdValue = document.createElement('td');

				tdValue.style.borderWidth = '0';
				tdValue.style.textAlign = 'right';

				const labelText = document.createTextNode(item.label);
				const valueText = document.createTextNode(item.formattedValue);

				tdLabel.appendChild(colorMark);
				tdLabel.appendChild(labelText);

				const mainValueEl = document.createElement('div');
				const unitsEl = createCurrencyElement(units);

				mainValueEl.appendChild(unitsEl);
				mainValueEl.appendChild(valueText);
				mainValueEl.style.fontWeight = '600';
				tdValue.appendChild(mainValueEl);
				tdValue.style.textAlign = 'left';
				tdValue.style.marginBottom = '10px';

				const additionalCurrencies: unknown = item.currencies;

				// TODO: scrutinize thoroughly the case of `additionalCurrencies` being of type Object
				//  instead of string and apply more proper typing for this condition!
				if (additionalCurrencies instanceof Array) {
					Object.keys(additionalCurrencies).forEach((currency) => {
						const div = document.createElement('div');
						const currencyEl = createCurrencyElement(currency);
						const valueEl = document.createTextNode(
							formatMoney(additionalCurrencies[parseInt(currency)]),
						);

						div.appendChild(currencyEl);
						div.appendChild(valueEl);
						tdValue.append(div);
					});
				}

				tr.appendChild(tdLabel);
				tr.appendChild(tdValue);
				tableBody.appendChild(tr);
			});

			const tableRoot = tooltipEl.querySelector('table');

			if (tableRoot) {
				tableRoot.style.width = '100%';
			}

			// Remove old children
			while (tableRoot?.firstChild) {
				tableRoot.firstChild.remove();
			}

			// Add new children
			tableRoot?.appendChild(tableHead);
			tableRoot?.appendChild(tableBody);
		}

		const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

		// Display, position, and set styles for font
		tooltipEl.style.opacity = `1`;
		tooltipEl.style.left = positionX + tooltip.caretX + 'px';
		tooltipEl.style.top = positionY + tooltip.caretY + 6 + 'px';
		tooltipEl.style.font = tooltip.options.bodyFont.string;

		tooltipEl.style.padding =
			tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
	};

const formatMoney = new Intl.NumberFormat('en-US', {
	minimumFractionDigits: 2,
	maximumFractionDigits: 2,
}).format;

type TooltipType = {
	readonly body: readonly [
		{
			readonly lines: readonly string[];
		},
	];
	readonly caretX: number;
	readonly caretY: number;
	readonly dataPoints: readonly DataPoints[];
	readonly labelColors: readonly [
		{
			readonly borderColor: string;
		},
	];
	readonly opacity: number;
	readonly options: {
		readonly bodyFont: {
			readonly string: string;
		};
		readonly padding: string;
	};
	readonly title: readonly string[];
};

type DataPoints = {
	readonly dataIndex: number;
	readonly dataset: {
		readonly backgroundColor: string;
		readonly borderColor: string;
		readonly borderWidth: number;
		// TODO: In the inspect console there is no `[][]` but instead `[]` - look into und estimate the rationality of such a typing
		readonly currencies: readonly (readonly string[])[];
		readonly data: readonly number[];
		readonly label: string;
		readonly pointBackgroundColor: string;
	};
	readonly raw: number;
};
