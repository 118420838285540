const RecoverIcon = () => {
	return (
		<svg
			width='16'
			height='16'
			viewBox='0 0 16 16'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M3 8C3 5.23858 5.23858 3 8 3C9.63508 3 11.087 3.7844 12 4.99946L9.5 5C9.22386 5 9 5.22386 9 5.5C9 5.77614 9.22386 6 9.5 6H12.5C12.7761 6 13 5.77614 13 5.5V4.84295C13.0001 4.83864 13.0001 4.83434 13 4.83003V2.5C13 2.22386 12.7761 2 12.5 2C12.2239 2 12 2.22386 12 2.5V3.52786C10.9388 2.57815 9.53694 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14C11.3137 14 14 11.3137 14 8C14 7.72386 13.7761 7.5 13.5 7.5C13.2239 7.5 13 7.72386 13 8C13 10.7614 10.7614 13 8 13C5.23858 13 3 10.7614 3 8Z'
				fill='white'
			/>
		</svg>
	);
};

export default RecoverIcon;
