import { t } from '@paykassma/pay-kit';
import API from 'api';
import {
	ICreateManualRequestData,
	IPossibleTransaction,
} from 'api/transactionGroup';
import { useState } from 'react';
import { errorsMap } from 'utils/enums';

import { FormStateType } from '../index';

type ReturnType = {
	readonly create: (
		data: FormStateType,
		foundPossibleTransactions: readonly IPossibleTransaction[],
	) => void;
	readonly isLoading: boolean;
};

type HookType = (props: {
	readonly onSuccess: () => void;
	readonly onError: (
		errors: readonly {
			readonly target: string;
			readonly code: number;
			readonly message: string;
		}[],
	) => void;
}) => ReturnType;

const processPossibleTransactions = async (
	foundPossibleTransactions: readonly IPossibleTransaction[],
) => {
	// if possible tr. exists, remove it all
	if (foundPossibleTransactions.length > 0) {
		try {
			return await Promise.all(
				foundPossibleTransactions.map(({ primary_id }) =>
					API.transaction.removePossible({ primary_id }),
				),
			);
		} catch (err) {
			window.pushAlert({
				description: t('Could not remove existing possible transactions'),
				type: 'error',
			});
		}
	}
};

const useCreateManualTransaction: HookType = ({ onSuccess, onError }) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const create = async (
		data: FormStateType,
		foundPossibleTransactions: readonly IPossibleTransaction[],
	) => {
		setIsLoading(true);

		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const { currentStep, selectedPossibleTrId, ...formData } = data;

		await processPossibleTransactions(foundPossibleTransactions);

		return API.transaction
			.createManual(formData as ICreateManualRequestData)
			.then((res: any) => {
				if (res.status === 'success') {
					onSuccess();

					return res;
				} else {
					console.error(res);

					if (res.error_message) {
						window.pushAlert({
							description: res.error_message,
							type: 'error',
						});

						if (res.errors) {
							onError(res.errors);
						}
					}

					throw new Error(errorsMap.anyResponse);
				}
			})
			.finally(() => setIsLoading(false))
			.catch((err) => console.error(err));
	};

	return {
		isLoading,
		create,
	};
};

export default useCreateManualTransaction;
