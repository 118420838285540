import { useTranslation } from 'pay-kit';
import { OperationHistoryTransactions } from 'api/balanceGroup';
import PaymentSystemLogo from 'components/PaymentSystemLogo';
import { convertIntoCurrency } from 'helpers/convertIntoCurrency';
import WithdrawalAmount from 'modules/TransferHistory/components/OperationHistory/components/WithdrawalAmount';
import { getTitleForCancelRole } from 'modules/TransferHistory/components/OperationHistory/utils/getTitleForCancelRole';
import styles from 'modules/TransferHistory/components/OperationHistoryTab/operationHistory.module.scss';
import CreatedAt from 'modules/TransferHistory/components/WithdrawalsHistoryTabs/components/CreatedAt';
import { useOperationHistoryTableContext } from 'modules/TransferHistory/contexts/OperationHistoryTableContext';
import { Badge, Button } from 'pay-kit';

const useOperationHistoryTableColumns = () => {
	const { openCancelOperationModal } = useOperationHistoryTableContext();

	const { t } = useTranslation();

	return [
		{
			// title: "Дата • Время",
			title: `${t('Date')} • ${t('Time')}`,
			dataIndex: 'created_at',
			key: 'created_at',
			render: ({ created_at }: OperationHistoryTransactions) => (
				<CreatedAt created_at={created_at} />
			),
		},
		{
			// title: "Сумма списания/зачисления",
			title: t('Amount'),
			dataIndex: 'amount',
			key: 'amount',
			render: ({
				amount,
				directing,
				currency_code,
			}: OperationHistoryTransactions) => (
				<WithdrawalAmount
					amount={amount}
					currency_code={currency_code}
					directing={directing}
				/>
			),
		},
		{
			title: t('Commission'),
			dataIndex: 'total_commission',
			key: 'total_commission',
			render: ({
				total_commission,
				currency_code,
			}: OperationHistoryTransactions) => {
				const formattedAmount =
					total_commission && convertIntoCurrency({ amount: total_commission });

				return (
					<div>
						{formattedAmount} {currency_code}
					</div>
				);
			},
		},
		{
			// title: "Тип/Счёт",
			title: t('Type/Account'),
			dataIndex: 'wallet_type',
			key: 'wallet_type',
			render: ({
				wallet_type,
				external_address,
				operation_type,
			}: OperationHistoryTransactions) => {
				if (operation_type === 2) {
					return (
						<>
							{external_address ? (
								external_address
							) : (
								<PaymentSystemLogo id='' />
							)}
						</>
					);
				}

				return <PaymentSystemLogo id={wallet_type || ''} />;
			},
		},
		{
			title: t('Currency'),
			dataIndex: 'currency_code',
			key: 'currency_code',
		},
		{
			title: t('Exchange rate'),
			dataIndex: 'ex_rate',
			key: 'ex_rate',
			render: ({ ex_rate }: OperationHistoryTransactions) => {
				const formattedAmount = convertIntoCurrency({
					amount: ex_rate,
					maximumFractionDigits: 4,
					minimumFractionDigits: 4,
				});

				return <>{formattedAmount}</>;
			},
		},
		{
			// title: "Доп. Инфо",
			title: t('Additional info'),
			dataIndex: 'additionalInfo',
			key: 'additionalInfo',
			render: ({ cancellations_role }: OperationHistoryTransactions) => {
				return (
					<div className={styles.additionalInfo}>
						<Badge
							variant={
								getTitleForCancelRole(cancellations_role) === 'Отмененная'
									? 'red'
									: 'green'
							}
						>
							{getTitleForCancelRole(cancellations_role)}
						</Badge>
					</div>
				);
			},
		},
		{
			title: t('Actions'),
			dataIndex: 'actions',
			key: 'actions',
			render: ({
				cancellations_role,
				operation_type,
				id,
			}: OperationHistoryTransactions) => {
				const isCancelVisible =
					operation_type === 2 || cancellations_role !== null;

				return (
					!isCancelVisible && (
						<Button
							data-test-id='open-cancel-modal-btn'
							variant='delete'
							onClick={() => openCancelOperationModal(id)}
						>
							{/* Отменить */}
							{t('Cancel')}
						</Button>
					)
				);
			},
		},
	];
};

export default useOperationHistoryTableColumns;
