import { UpdateSettingsType } from 'api';
import { UPI_PAYMENT_METHOD_OPTIONS } from 'modules/AppSettings/AppSettingsSystem/utils/constants';
import { t } from 'pay-kit';
import { fieldErrorsMap } from 'utils/enums';

export const paymentMethodError = t(
	'Values are duplicated. Select different payment methods.',
);

export const urlPattern =
	/^https?:\/\/[-\w@:%.+~#=]{2,256}\.[a-z]{2,6}\b[-\w@:%+.~#?&/=]*$/;

export const isRequired = (value: number | string) =>
	!value ? fieldErrorsMap.isRequired : undefined;

export const isMinValueRequired = (value: number | string) =>
	parseInt(`${value}`) < 10 ? t(`Minimum allowed value: 10`) : undefined;

export const isUnique: IsUniqueType =
	(key, paymentMethod) => (value: string, state) => {
		return state[key].filter((qr) => qr.code === value).length > 1
			? `${paymentMethod}: ${paymentMethodError}`
			: undefined;
	};

export const isValidUrl: IsValidUrlType = (value) =>
	urlPattern.test(value) ? undefined : fieldErrorsMap.wrongLinkFormat;

export const getUniqUPIOptions: GetUniqUPIOptionsType =
	({ upiKey, isUPI_API }) =>
	(state, currentValue) => {
		const selectedOptions = state[upiKey].filter(
			(option) => option.code !== currentValue,
		);
		const defaultOptions = isUPI_API
			? UPI_PAYMENT_METHOD_OPTIONS.filter(({ api }) => api)
			: UPI_PAYMENT_METHOD_OPTIONS;

		return defaultOptions.filter((option) =>
			selectedOptions.every((element) => element.code !== option.value),
		);
	};

type UPIKeyType = 'upi_api_payment_methods' | 'upi_p2p_payment_methods';

type IsUniqueType = (
	key: UPIKeyType,
	paymentMethod: string,
) => (value: string, state: UpdateSettingsType) => string | undefined;

type IsValidUrlType = (value: string) => string | undefined;

type GetUniqUPIOptionsType = (params: {
	readonly upiKey: UPIKeyType;
	readonly isUPI_API?: boolean;
}) => (
	state: UpdateSettingsType,
	currentValue: string,
) => typeof UPI_PAYMENT_METHOD_OPTIONS;
