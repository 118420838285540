import API from 'api';
import { useState } from 'react';
import { errorsMap } from 'utils/enums';

export type LimitType = {
	readonly currency: string;
	readonly max: number;
	readonly min: number;
	readonly wallet_type: string;
};

const useWalletTypesLimits = () => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [list, setList] = useState<readonly LimitType[]>([]);

	const load = () => {
		setIsLoading(true);

		API.walletType
			.getIngoingLimits()
			.then((res: any) => {
				if (res?.status === 'success' && res?.data?.length > 0) {
					setList(res.data as readonly LimitType[]);
				} else {
					// throw new Error("Unexpecrted result in useWalletTypesLimits");
					throw new Error(errorsMap.anyResponse);
				}

				return res;
			})
			.finally(() => {
				setIsLoading(false);
			})
			.catch((err) => console.error(err));
	};

	return {
		load,
		isLoading,
		list,
	};
};

export default useWalletTypesLimits;
