export const deepClone = (obj: object): any => {
	if (obj && typeof obj === 'object') {
		if (Array.isArray(obj)) {
			return obj.map(deepClone);
		} else if (obj.constructor === Object) {
			return Object.entries(obj).reduce(
				(prev, [key, value]) => ({ ...prev, [key]: deepClone(value) }),
				{},
			);
		}

		return obj;
	}

	return obj;
};
