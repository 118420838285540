import { fetchOldApi } from 'api/fetch';

const dashboardGroup = {
	updateTransactionMetrics: (params: UpdateTransactionMetricsParamsType) =>
		fetchOldApi<LoadTransactionMetricsType>(
			'dashboard/get-data',
			params,
			'POST',
		),
	getTransactionsData: (params: ChartDataRequestParams) =>
		fetchOldApi<DashboardDataType>(
			`/dashboard/get-transactions-data`,
			params,
			'POST',
		),
	getWithdrawalsData: (params: ChartDataRequestParams) =>
		fetchOldApi<DashboardDataType>(
			`/dashboard/get-withdrawals-data`,
			params,
			'POST',
		),
};

type DateType = 'activation_date' | 'creation_date';

export type ChartDataRequestParams = {
	readonly date_type?: DateType;
	readonly currency_codes?: readonly string[];
	readonly show_all_active_transaction_data?: number;
	readonly display_in_currency?: string;
	readonly date_from?: string;
	readonly date_to?: string;
	readonly stockpiling_status: number;
	readonly payment_systems?: readonly string[];
	// readonly country_id?: unknown;
};

export type DashBoardMetricsType = {
	readonly date: string;
	readonly all: {
		readonly value: number;
		readonly currencies: readonly string[] | null;
	};
};

export type DashboardDataType = {
	readonly data: {
		readonly count: readonly DashBoardMetricsType[];
		readonly sum: readonly DashBoardMetricsType[];
	};
};

export type UpdateTransactionMetricsParamsType = {
	readonly date_from: string;
	readonly date_to: string;
	readonly date_type: DateType;
	readonly display_in_currency: string;
	readonly currency_codes?: readonly string[];
	readonly country_id?: unknown; // TODO: to find the necessity of this field
	readonly show_all_active_transaction_data: number;
	readonly stockpiling_status?: number;
};

export type LoadTransactionRecordType = Record<string, number>;

export type LoadTransactionMetricsType = {
	readonly data: {
		readonly activated_transactions_count: number;
		readonly activated_transactions_count_today: number;
		readonly activated_withdrawals_count: number;
		readonly activated_withdrawals_count_today: number;
		readonly active_proxies_count: number;
		readonly completed_transactions_count: number;
		readonly completed_transactions_count_today: number;
		readonly currency: {
			readonly symbol: string;
			readonly code: string;
		};
		readonly data: {
			readonly count: readonly {
				readonly date: string;
				readonly all: {
					readonly value: number;
				};
			}[];
			readonly sum: readonly {
				readonly date: string;
				readonly all: {
					readonly value: number;
					readonly currencies: unknown;
				};
			}[];
		};
		readonly payment_systems_count: number;
		readonly sum_of_activated_transactions: LoadTransactionRecordType;
		readonly sum_of_activated_transactions_today: LoadTransactionRecordType;
		readonly sum_of_completed_transactions: LoadTransactionRecordType;
		readonly sum_of_completed_transactions_commission: LoadTransactionRecordType;
		readonly sum_of_completed_transactions_commission_today: LoadTransactionRecordType;
		readonly sum_of_completed_transactions_today: LoadTransactionRecordType;
		readonly sum_of_settlement_commission: LoadTransactionRecordType;
		readonly sum_of_settlement_commission_today: LoadTransactionRecordType;
		readonly sum_of_transactions_commission: LoadTransactionRecordType;
		readonly sum_of_transactions_commission_today: LoadTransactionRecordType;
		readonly sum_of_withdrawals: LoadTransactionRecordType;
		readonly sum_of_withdrawals_commission: LoadTransactionRecordType;
		readonly sum_of_withdrawals_commission_today: LoadTransactionRecordType;
		readonly sum_of_withdrawals_for_today: LoadTransactionRecordType;
		readonly total_wallets_balance: LoadTransactionRecordType;
	};
};

export default dashboardGroup;
