import { WalletType } from 'api/walletTypesGroup';
import { Checkbox } from 'pay-kit';
import { Dispatch, FC, SetStateAction } from 'react';

import styles from './checkBoxesList.module.scss';

const CheckBoxesList: FC<CheckBoxesListType> = ({
	walletTypes,
	setCheckedPS,
	checkedPS,
}) => {
	const renderWallets = () => {
		const OnCheck: OnCheckType = (key, value) => {
			setCheckedPS((prevState) => ({
				...prevState,
				[key]: {
					isChecked: prevState[key] ? !prevState[key]?.isChecked : true,
					value,
				},
			}));
		};

		return walletTypes.map((wallet, i) => (
			<div key={i} className={styles.checkbox}>
				<Checkbox
					data-test-id='checkbox'
					id={`${i}`}
					label={wallet.name}
					onChange={() => OnCheck(i, wallet.code)}
					isChecked={checkedPS[i]?.isChecked}
				/>
			</div>
		));
	};

	return <>{renderWallets()}</>;
};

export default CheckBoxesList;

type CheckBoxesListType = {
	readonly walletTypes: readonly WalletType[];
	readonly setCheckedPS: Dispatch<SetStateAction<checkedPSType>>;
	readonly checkedPS: checkedPSType;
};

type checkedPSType = Partial<{
	readonly [key: number]: {
		readonly isChecked: boolean;
		readonly value: string;
	};
}>;

type OnCheckType = (key: number, value: string) => void;
