import { TrashIcon } from 'components/Icons/TrashIcon/TrashIcon';
import { Button } from 'pay-kit';
import { FC, ReactElement } from 'react';

import styles from './RemovableFields.module.scss';

const RemovableFields: FC<DynamicFieldsType> = ({
	classes,
	component,
	children,
	remove,
}) => {
	return (
		<div className={classes.fieldsWrapper}>
			{component} {children}
			<Button
				data-test-id='removeButton'
				onClick={remove}
				classname={classes.removeButton}
			>
				<TrashIcon activeClass={classes.deleteFieldIcon} />
			</Button>
		</div>
	);
};

export default RemovableFields;

type DynamicFieldsType = {
	readonly classes: {
		readonly fieldsWrapper: string;
		readonly removeButton: string;
		readonly deleteFieldIcon: string;
	};
	readonly component?: ReactElement;
	readonly children: readonly ReactElement[];
	readonly remove: <T>(e: T) => void;
};
