import { FC, ReactElement } from 'react';

import styles from './settingsBlockWrapper.module.scss';

const SettingsBlockWrapper: FC<SettingsBlockWrapperType> = ({
	children,
	header,
	classes,
	blockErrorFeedback,
}) => {
	return (
		<div className={classes.blockWrapper}>
			<div className={classes.header}>{header}</div>
			<div className={classes.fieldsWrapper}>{children}</div>
			{blockErrorFeedback}
		</div>
	);
};

export default SettingsBlockWrapper;

type SettingsBlockWrapperType = {
	readonly children: readonly ReactElement[];
	readonly header: string;
	readonly classes: {
		readonly blockWrapper?: string;
		readonly header?: string;
		readonly fieldsWrapper: string;
	};
	readonly blockErrorFeedback?: ReactElement;
};
