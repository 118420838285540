import { ValidationError } from 'yup';

export const validate = (schema: any, data: any): Record<string, string> => {
	let newErrors = {};

	try {
		schema.validateSync(data, { abortEarly: false });
	} catch (e: any) {
		const validationErrors: readonly ValidationError[] = e.inner;

		validationErrors.forEach((error) => {
			newErrors = { ...newErrors, [error.path || '']: error.message };
		});
	}

	return newErrors;
};
