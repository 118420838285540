import { log } from 'console';

import { OldResponse, Response, StandardResponse } from 'api/types';
import axios, {
	AxiosError,
	AxiosPromise,
	AxiosRequestConfig,
	Method,
} from 'axios';
import {
	RefreshData,
	RefreshRequestData,
} from 'contexts/AuthContext/utils/types';
import {
	PAYKASSMA_REFRESH_TOKEN_NAME,
	getToken,
	tokenUpdater,
} from 'helpers/tokenHelper';
import { PAYKASSMA_ERROR_MSG } from 'layout/pages/ErrorPage';
import { errorsMap } from 'utils/enums';
import { v4 as uuidv4 } from 'uuid';

export const PAYKASSMA_REDIRECT_URL = `PAYKASSMA_REDIRECT_URL`;

const getBaseApiConfig = <T>(
	url: string,
	params?: Record<string, any>,
	method: Method = 'GET',
	headers = {},
	config = {
		withToken: true,
	},
): AxiosRequestConfig<Response<T>> => {
	let authHeaders = {};

	if (config.withToken) {
		authHeaders = {
			Authorization: `Bearer ${getToken()}`,
		};
	}

	const _config: AxiosRequestConfig = {
		url: url,
		//@ts-ignore
		baseURL: window.config.apiUrl,
		method: method,
		headers: {
			...authHeaders,
			['x-current-language']: localStorage.getItem(`language`),
			...headers,
		},
		...config,
	};

	if (params) {
		if (method === 'GET') {
			_config.params = params;
		} else {
			_config.data = params;
		}
	}

	return _config;
};

/**
 * @param url
 * @param params
 * @param method
 * @param headers
 * @param config
 */
export function fetchOldApi<T>(
	url: string,
	params?: Record<string, any>,
	method: Method = 'GET',
	headers = {},
	config = {
		withToken: true,
	},
): Promise<OldResponse<T>> {
	const _config: AxiosRequestConfig<Response<T>> = getBaseApiConfig<T>(
		url,
		params,
		method,
		headers,
		config,
	);

	return errorHandlerMiddleware(_config, axios(_config)) as Promise<
		OldResponse<T>
	>;
}

/**
 * @param url
 * @param params
 * @param method
 * @param headers
 * @param config
 */
export function fetchApi<T>(
	url: string,
	params?: Record<string, any>,
	method: Method = 'GET',
	headers = {},
	config = {
		withToken: true,
	},
): Promise<StandardResponse<T>> {
	const _config: AxiosRequestConfig<Response<T>> = getBaseApiConfig(
		url,
		params,
		method,
		{
			...headers,
			'Request-Version': '1.000.0',
			'Request-Id': uuidv4(),
		},
		config,
	);

	return errorHandlerMiddleware(_config, axios(_config)) as Promise<
		StandardResponse<T>
	>;
}

const refreshMiddleware = (): Promise<any> => {
	const refresh_token =
		localStorage.getItem(PAYKASSMA_REFRESH_TOKEN_NAME) || ``;
	const config: AxiosRequestConfig<RefreshRequestData> = {
		url: 'auth/refresh',
		//@ts-ignore
		baseURL: window.config.apiUrl,
		method: 'POST',
		data: {
			refresh_token,
		},
		headers: {
			Authorization: `Bearer ${getToken()}`,
			'Request-Version': '1.000.0',
			'Request-Id': uuidv4(),
		},
	};

	const refreshRequest: AxiosPromise<StandardResponse<RefreshData>> =
		axios(config);

	const redirectToLogin = () => {
		localStorage.setItem(PAYKASSMA_REDIRECT_URL, window.location.pathname);
		tokenUpdater('', '');
		window.location.href = '/login';
	};

	return refreshRequest
		.then((resp) => {
			if (resp.data.status === 'success') {
				const { token, refresh_token } = resp.data.data;

				tokenUpdater(token, refresh_token);

				return token;
			} else {
				redirectToLogin();
			}
		})
		.catch((e) => {
			const { status, data } = e.response;
			const message = data.message;

			if (status === 404) {
				window.location.href = '/error';
			} else if (
				message.toString().includes('has expired') ||
				message.toString().includes('verified') ||
				message.toString().includes('blacklisted')
			) {
				redirectToLogin();
			}

			return e;
		});
};

let promisesToRefresh: Promise<any>[] = [];

const checkToken = async <T>(config: AxiosRequestConfig<Response<T>>) => {
	let token = config?.headers?.Authorization.toString().replace(`Bearer `, ``);
	const currToken = getToken();

	if (token === currToken) {
		token = await refreshMiddleware();
	} else {
		token = currToken;
	}

	const newConfig = {
		...config,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};

	return await errorHandlerMiddleware(newConfig, axios(newConfig));
};

const errorHandlerMiddleware = async <T>(
	config: AxiosRequestConfig<Response<T>>,
	promise: AxiosPromise<Response<T>>,
): Promise<Response<T>> => {
	const isOldResponse = (resp: Response<T>): resp is OldResponse<T> => {
		return (resp as StandardResponse<T>).code === undefined;
	};

	const refreshToken = () => {
		if (promisesToRefresh.length > 0) {
			return Promise.all(promisesToRefresh).then(() => {
				promisesToRefresh = [];

				return checkToken(config);
			});
		} else {
			const promise = checkToken(config);

			promisesToRefresh.push(promise);

			return promise;
		}
	};

	const blockAccess = () => {
		localStorage.setItem(PAYKASSMA_ERROR_MSG, errorsMap.noAccess);
		window.location.replace('/error');
	};

	try {
		const resp = await promise;
		const data = resp.data;

		if (
			!isOldResponse(data) &&
			data.status === 'error' &&
			data.code === 50003
		) {
			return refreshToken();
		}

		if (
			!isOldResponse(data) &&
			data.status === 'error' &&
			data.code === 90013
		) {
			// blockAccess()
		}
		//TODO: Проверить тип
		// const content: Response<T> = resp.data;

		if (!isOldResponse(data) && data.status === 'error') {
			console.log('fetch.ts: new format error');

			//TODO: Прикрутить обработку нового формата, когда появятся такие роуты
		}

		return resp.data;
		// @ts-ignore
	} catch (e: AxiosError<Response<T>>) {
		const status = e.response ? e.response.status : 'error';

		if (status === 401) {
			return refreshToken();
		} else if (status === 'error') {
			throw e.response?.data;
		} else if (status === 422) {
			return e.response?.data;
		} else if (status === 404) {
			window.pushAlert({
				title: errorsMap.any,
				type: 'error',
				// description: `Роут ${config.url} не найден`
				description: errorsMap.getNotFoundRoute(config.url),
			});
		} else if (status === 403) {
			blockAccess();
		} else if (status >= 500) {
			// localStorage.setItem(PAYKASSMA_ERROR_MSG, `${config.url} - ошибка ${status}`);
			localStorage.setItem(
				PAYKASSMA_ERROR_MSG,
				errorsMap.getStatusForEndpoint(config.url, status),
			);
			//window.location.replace("/error")
		}

		return e.response?.data;
	}
};
