// import { useDashboardContext } from "contexts/DashboardContext";
import Filters from 'modules/Dashboard/components/Filters';
import Metric from 'modules/Dashboard/components/WalletsTotalBalance/components/Metric';
import PaymentSystemsLimits from 'modules/Dashboard/components/WalletsTotalBalance/components/PaymentSystemsLimits';
// import WorkingWallets from "modules/Dashboard/components/WalletsTotalBalance/components/WorkingWallets";
// import { reduceWalletsSummary } from "modules/Dashboard/components/WalletsTotalBalance/helpers";
import { useTranslation } from 'pay-kit';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './walletsTotalBalance.module.scss';

const WalletsTotalBalance: FC<WalletsTotalBalanceType> = ({
	psCount,
	proxiesCount,
}) => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	// https://confluence.fixmost.com/pages/viewpage.action?pageId=111613841

	// const [allWalletsCount, setAllWalletsCount] = useState<number | null>(null);
	// const [workingWalletsCount, setWorkingWalletsCount] = useState<number | null>(null);
	// const [reducedWalletsSummary, setReducedWalletsSummary] = useState<reducedWalletsSummaryType | null>(null);
	// const [reducedWalletsSummaryKeyList, setReducedWalletsSummaryKeyList] =
	// 	useState<ReducedWalletsSummaryKeyListType | null>(null);

	// const { walletsSummary, isLoadWalletsSummaryLoading } = useDashboardContext();

	// useEffect(() => {
	// 	if (walletsSummary?.length) {
	// 		const allWallets = walletsSummary.reduce((acc, elem) => acc + elem.all, 0);
	// 		const workingWallets = walletsSummary.reduce((acc, elem) => acc + elem.worked, 0);
	// 		const reducedWalletsSummary = reduceWalletsSummary(walletsSummary);
	// 		const reducedWalletsSummaryKeyList = Object.keys(reducedWalletsSummary);

	// 		setAllWalletsCount(allWallets);
	// 		setWorkingWalletsCount(workingWallets);
	// 		setReducedWalletsSummary(reducedWalletsSummary);
	// 		setReducedWalletsSummaryKeyList(reducedWalletsSummaryKeyList);
	// 	}
	// }, [isLoadWalletsSummaryLoading]);

	return (
		<div className={styles.walletsTotalBalanceWrapper}>
			<div className={styles.totalBalanceContainer}>
				<div className={styles.metricsWrapper}>
					{(proxiesCount || proxiesCount === 0) && (
						<Metric
							title={t('Proxy')}
							count={proxiesCount}
							className={styles.metric}
							dataTestId='proxiesCount'
						/>
					)}
					{(psCount || psCount === 0) && (
						<Metric
							data-test-id='k_XowJnDm-hbOrWoqSmvh'
							onClick={() => navigate('/payment-systems')}
							title={t('PS')}
							count={psCount}
							className={styles.metric}
							dataTestId='psCount'
						/>
					)}
				</div>
				{/* 
				https://confluence.fixmost.com/pages/viewpage.action?pageId=111613841
				{reducedWalletsSummary && (
					<WorkingWallets
						reducedWalletsSummary={reducedWalletsSummary}
						reducedWalletsSummaryKeyList={reducedWalletsSummaryKeyList}
						allWalletsCount={allWalletsCount}
						workingWalletsCount={workingWalletsCount}
						isLoading={isLoadWalletsSummaryLoading}
					/>
				)} */}

				{/* Temporary disabled until PAY-13947 will be done */}
				<div style={{ display: 'none' }}>
					<PaymentSystemsLimits />
				</div>
			</div>

			<Filters />
		</div>
	);
};

export default WalletsTotalBalance;

type WalletsTotalBalanceType = {
	readonly psCount?: number;
	readonly proxiesCount?: number;
};

export type WalletsSummaryType = {
	readonly all: number;
	readonly worked: number;
	readonly wallet_off_limit: readonly { readonly [key: string]: number }[];
	readonly wallet_off_load: readonly { readonly [key: string]: number }[];
};

export type reducedWalletsSummaryType = {
	readonly [key: string]: WalletsSummaryType;
};

export type ReducedWalletsSummaryKeyListType = readonly string[];
