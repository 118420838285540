import { Loader, ScrollBox, useTranslation } from 'pay-kit';
import API from 'api';
import { postbackType } from 'api/postbackGroup';
import { ICurrentTransaction } from 'api/transactionGroup';
import TextWithCopyButton from 'components/TextWithCopyButton';
import React, { useEffect, useState } from 'react';
import { errorsMap } from 'utils/enums';

import PostbackStatus from '../../../../components/PostbackStatus';

import styles from './Postback.module.scss';
import StatusCode from './StatusCode';

interface IPostbackProps {
	readonly transactionData: ICurrentTransaction;
}

export const Postback: React.FC<IPostbackProps> = ({ transactionData }) => {
	const { t } = useTranslation();
	const [postbackData, setPostbackData] = useState<postbackType | null>(null);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const getPostbackData = () => {
		setIsLoading(true);

		API.postback
			.getOne({
				id: transactionData.postback_id as number,
				direction: transactionData.direction,
			})
			.then((res) => {
				if (res?.status === 'success') {
					setPostbackData(res?.data as postbackType);
					setIsLoading(false);

					return;
				} else {
					throw new Error(errorsMap.anyResponse);
				}
			})
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			.catch((err) => {
				setIsLoading(false);

				window.pushAlert({
					description: t('Failed to get postback data'),
					type: 'error',
				});
			});
	};

	useEffect(() => {
		if ([1, 2].includes(transactionData.postback_status)) {
			getPostbackData();
		}
	}, [transactionData.postback_id]);

	if (![1, 2].includes(transactionData.postback_status)) {
		return (
			<div className={styles.Postback}>
				<PostbackStatus status={transactionData.postback_status} showCaption />
			</div>
		);
	}

	if (isLoading) {
		return (
			<div className={styles.Postback}>
				<Loader />
			</div>
		);
	}

	const ROWS_MAP = [
		{
			id: 'id',
			title: 'ID',
			render: ({ id }: postbackType) => (
				<TextWithCopyButton textClassName={styles.longString} text={id} />
			),
		},
		{
			id: 'url',
			title: t('Url'),
			render: ({ url }: postbackType) => (
				<TextWithCopyButton textClassName={styles.longString} text={url} />
			),
		},
		{
			id: 'status_code',
			title: t('Status code'),
			render: ({ status_code }: postbackType) => (
				<StatusCode code={status_code} />
			),
		},
		{
			id: 'response',
			title: t('Response'),
			render: ({ response }: postbackType) => (
				<TextWithCopyButton textClassName={styles.longString} text={response} />
			),
		},
		{
			id: 'status',
			title: t('Status'),
			render: () => (
				<PostbackStatus status={transactionData.postback_status} showCaption />
			),
		},
		{ id: 'created_at', title: t('Creation date') },
		{
			id: 'request_body',
			title: t('Request body'),
			render: ({ request_body }: postbackType) => (
				<TextWithCopyButton
					className={styles.requestBody}
					textClassName={styles.requestBodyString}
					text={request_body}
				/>
			),
		},
	];

	if (postbackData === null) {
		return <>{t('No data')}</>;
	}

	return (
		<div className={styles.Postback}>
			<ScrollBox>
				{ROWS_MAP.map((row) => {
					const value = postbackData[row.id as keyof postbackType];

					return (
						<div key={`${row.id}_${row.title}`} className={styles.row}>
							<div className={styles.title}>{row.title}</div>
							<div className={styles.value}>
								{row.render ? row.render(postbackData) : `${value}`}
							</div>
						</div>
					);
				})}

				<ScrollBox className={styles.requestBodyFull}>
					{postbackData.request_body}
				</ScrollBox>
			</ScrollBox>
		</div>
	);
};
