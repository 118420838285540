import { useTranslation } from 'pay-kit';
import API from 'api';
import {
	GetOperationHistoryDataType,
	PSAndBalancesType,
	PSBalanceType,
} from 'api/balanceGroup';
import { SuccessStandardResponse } from 'api/types';
import { useAccountsBalancesContext } from 'contexts/AccountsBalanceContext';
import constructQueryStringParams from 'helpers/constructQueryStringParams';
import CancelOperation from 'modules/TransferHistory/components/CancelOperation';
import { FiltersType } from 'modules/TransferHistory/components/OperationHistory';
import { NO_ID } from 'modules/TransferHistory/components/PSSelect';
import { gettingCurrentCurrencyCode } from 'modules/TransferHistory/contexts/OperationHistoryContext/helpers';
import { Modal } from 'pay-kit';
import {
	Dispatch,
	ReactElement,
	SetStateAction,
	createContext,
	useCallback,
	useContext,
	useMemo,
	useState,
} from 'react';
import { useParams } from 'react-router-dom';
import useURLState from 'utils/hooks/useURLState';
import { fromLocaleDate } from 'utils/date';
import useURLFilters from 'utils/hooks/useURLFilters';

const OperationHistoryTableContext = createContext(
	{} as OperationHistoryTableContextType,
);
const OperationHistoryTableContextProvider: OperationHistoryTableContextProviderType =
	({ children }) => {
		const [error, setError] = useState<Error | null>(null);
		const [isGetOperationHistoryLoading, setGetOperationHistoryLoading] =
			useState(false);
		const [filter, setFilter] = useURLFilters({
			dateRangeFields: ['date'],
		});
		const { id, ps } = useParams();
		const [currentPage, setCurrentPage] = useState(1);
		const [limit, setLimit] = useURLState<number>('limit', 10);
		const [data, setData] = useState<GetOperationHistoryDataType | null>(null);
		const [paginationInfo, setPaginationInfo] = useState<
			SuccessStandardResponse<GetOperationHistoryDataType>[`paginate`] | null
		>(null);
		const [openCancelOperation, setOpenCancelOperation] = useState(false);
		const [transactionID, setTransactionID] = useState<string>('');
		const { filteredBalances } = useAccountsBalancesContext();

		const PSBalance = gettingCurrentCurrencyCode(id, ps, filteredBalances);

		const { t } = useTranslation();

		const getOperationHistory: getOperationHistoryType = useCallback(
			(id, data) => {
				setGetOperationHistoryLoading(true);
				setError(null);

				API.balances
					.getOperationHistory(id, data)
					.then((res) => {
						if (res.status === 'success') {
							setData(res.data);
							setPaginationInfo(res.paginate);
						} else {
							// const message = id === NO_ID ? `Не было предоставлено ID` : res.error_message;
							const message = id === NO_ID ? t('No ID provided') : res.message;

							window.pushAlert({
								type: `error`,
								title: t('Error'),
								description: message,
							});

							setData(null);
							setPaginationInfo(null);
						}
					})
					.catch((err) => {
						console.log(err);
						setError(err);
					})
					.finally(() => {
						setGetOperationHistoryLoading(false);
					});
			},
			[],
		);

		const OnGetOperationHistory: OnGetOperationHistoryHandler = useCallback(
			(resetCurrentPage) => {
				console.log(filter);

				const date_from = filter.date?.from; //? fromLocaleDate(filter.date?.from) : undefined;
				const date_to = filter.date?.to; //? fromLocaleDate(filter.date?.to) : undefined;
				const queryParams = constructQueryStringParams({
					date_from,
					date_to,
					operation_type: filter.operation_type,
					direction: filter.direction,
					offset: !resetCurrentPage ? (currentPage - 1) * limit : 0, // reset the previous offset to 0 when changing ps,
					limit,
				});

				getOperationHistory(id || '', queryParams);

				if (resetCurrentPage && typeof resetCurrentPage === 'function') {
					resetCurrentPage();
				}
			},
			[
				currentPage,
				filter.date?.from,
				filter.date?.to,
				filter.direction,
				filter.operation_type,
				getOperationHistory,
				id,
				limit,
			],
		);

		const openCancelOperationModal = useCallback((id: string) => {
			setOpenCancelOperation(true);

			if (id) {
				setTransactionID(id);
			}
		}, []);

		const onCancelOperation = () => {
			OnGetOperationHistory();
			setOpenCancelOperation(false);
		};

		const context = useMemo(
			() => ({
				data,
				PSBalance,
				filteredBalances,
				setPaginationInfo,
				paginationInfo,
				loadOperationHistory: getOperationHistory,
				onLoadOperationHistory: OnGetOperationHistory,
				openCancelOperationModal,
				setCurrentPage,
				currentPage,
				setFilter,
				filter,
				setLimit,
				limit,
				id,
				isGetOperationHistoryLoading,
				error,
			}),
			[
				data,
				PSBalance,
				paginationInfo,
				getOperationHistory,
				OnGetOperationHistory,
				openCancelOperationModal,
				currentPage,
				filter,
				setLimit,
				limit,
				isGetOperationHistoryLoading,
				error,
			],
		);

		return (
			<OperationHistoryTableContext.Provider value={context}>
				{children}
				<Modal
					title={t('Cancel operation')}
					onClose={() => setOpenCancelOperation((open) => !open)}
					isOpen={openCancelOperation}
				>
					<CancelOperation
						id={transactionID}
						closeModal={setOpenCancelOperation}
						onCancelOperation={onCancelOperation}
						isGetOperationHistoryLoading={isGetOperationHistoryLoading}
					/>
				</Modal>
			</OperationHistoryTableContext.Provider>
		);
	};

export default OperationHistoryTableContextProvider;

export const useOperationHistoryTableContext = () =>
	useContext(OperationHistoryTableContext);

type OperationHistoryTableContextProviderType = (params: {
	readonly children: ReactElement;
}) => ReactElement;

type OperationHistoryTableContextType = {
	readonly data: GetOperationHistoryDataType | null;
	readonly PSBalance?: PSBalanceType;
	readonly filteredBalances: readonly PSAndBalancesType[];
	readonly setPaginationInfo: Dispatch<
		SetStateAction<
			SuccessStandardResponse<GetOperationHistoryDataType>[`paginate`] | null
		>
	>;
	readonly paginationInfo:
		| SuccessStandardResponse<GetOperationHistoryDataType>[`paginate`]
		| null;
	readonly loadOperationHistory: getOperationHistoryType;
	readonly onLoadOperationHistory: OnGetOperationHistoryHandler;
	readonly openCancelOperationModal: (id: string) => void;
	readonly setCurrentPage: Dispatch<SetStateAction<number>>;
	readonly currentPage: number;
	readonly setFilter: Dispatch<FiltersType>;
	readonly filter: FiltersType;
	readonly setLimit: (limit: number) => void;
	readonly limit: number;
	readonly isGetOperationHistoryLoading: boolean;
	readonly error: Error | null;
};

type getOperationHistoryType = (id: string, data: string) => void;

type OnGetOperationHistoryHandler = (resetCurrentPage?: () => void) => void;
