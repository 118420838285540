import { useTranslation } from 'pay-kit';
import { getListFilterParamsType } from 'api/postbackGroup';
import { postbackType } from 'api/postbackGroup';
import Filter from 'components/Filter';
import { WalletTypesContext } from 'contexts/WalletTypesContext';
import { Table } from 'pay-kit';
import React, { useContext, useEffect, useState } from 'react';
import { fromLocaleDate, notLaterThanToday } from 'utils/date';
import { rejectSettlement } from 'utils/filterSettlement';
import useURLState from 'utils/hooks/useURLState';
import { Roles } from 'contexts/AuthContext';
import { useAuthContext } from 'contexts/AuthContext/AuthContext';
import useURLFilters from 'utils/hooks/useURLFilters';

import IconButton from '../../../components/IconButton';
import { failedTableColumns } from '../components/columns/Columns';
import ReloadButton from '../components/ReloadButton';
import usePostbacksListAPI from '../hooks/usePostbacksListAPI';
import PostbacksActionsProvider, {
	PostbacksActionsContext,
} from '../PostbacksActionsProvider';

import styles from './AcceptedPostbacks.module.scss';

const NotAcceptedPostbacks: React.FC = () => {
	const { t } = useTranslation();
	const [page, setCurrentPage] = useURLState<number>('page', 1);
	const [limit, setLimit] = useURLState<number>('limit', 10);
	const [filter, setFilter] = useURLFilters();
	const [selectedRowsIds, setSelectedRowsIds] = useState<
		readonly (string | number)[]
	>([]);
	const { postbacks, isLoading, error, paginationInfo, getPostbackList } =
		usePostbacksListAPI();

	const transformFilterValues = (values: getListFilterParamsType) => {
		const data = { ...values };
		const dateKeys: Array<keyof getListFilterParamsType> = [
			'date_from',
			'date_to',
		];

		dateKeys.forEach((key) => {
			if (key in data) {
				data[key] = fromLocaleDate(data[key]).split(' ')[0];
			}
		});

		return data;
	};

	const { hasRole } = useAuthContext();
	const resendPostbackAccess = hasRole(Roles.POSTBACK_SENDING);

	const loadPostbacks = () =>
		getPostbackList({
			accepted: 0,
			limit,
			page,
			...transformFilterValues(filter),
		});

	useEffect(loadPostbacks, [limit, page, filter]);
	useEffect(() => setSelectedRowsIds([]), [isLoading]);

	const walletTypesAPI = useContext(WalletTypesContext);

	return (
		<PostbacksActionsProvider postbacks={postbacks} onSuccess={loadPostbacks}>
			<Filter
				values={filter}
				onApply={(values) => {
					setCurrentPage(1);
					setFilter(values);
				}}
				classNames={{
					wrapper: styles.filter,
					inlineFilter: styles.inlineFilter,
				}}
				maxInlineFields={6}
				fields={[
					{
						name: 'wallet_type',
						placeholder: t('Wallet type'),
						component: 'select',
						options: rejectSettlement(walletTypesAPI.walletTypes).map(
							(type) => ({
								value: type.code,
								label: type.name,
							}),
						),
						isLoading: walletTypesAPI.isLoading,
					},
					{
						name: 'label',
						placeholder: t('Label'),
						component: 'textInput',
					},
					{
						name: 'merchant_order_id',
						placeholder: t('Order ID'),
						component: 'textInput',
					},
					{
						name: 'direction',
						options: [
							{ value: 'ingoing', label: t('Deposit') },
							{ value: 'outgoing', label: t('Withdrawal') },
						],
						placeholder: t('Type'),
						component: 'select',
					},
					{
						name: 'date_from',
						// placeholder: "Дата от",
						placeholder: t('Date from'),
						component: 'date',
						dateFormat: 'DD.MM.YYYY',
						blockPredicate: notLaterThanToday,
					},
					{
						name: 'date_to',
						// placeholder: "Дата до",
						placeholder: t('Date to'),
						component: 'date',
						dateFormat: 'DD.MM.YYYY',
						blockPredicate: notLaterThanToday,
					},
				]}
			/>

			<Table
				className={styles.NotAcceptedPostbacksTable}
				columns={failedTableColumns({ resendPostbackAccess })}
				data={postbacks}
				rowKey='id'
				message={error ? { text: `${error}`, type: 'error' } : undefined}
				isLoading={isLoading}
				skeleton={{ rowsCount: limit }}
				stickyHead
				paginator={{
					currentPage: paginationInfo?.current_page || 1,
					setCurrentPage,
					limit,
					setLimit,
					lastAvailablePage: paginationInfo?.last_available_page || 1,
					prefixElement: (
						<ReloadButton
							data-test-id='vPSPDYGhaw_BXKSVEgCUe'
							isLoading={isLoading}
							onClick={loadPostbacks}
						/>
					),
					className: styles.paginator,
					bottomPaginatorProps: {
						className: styles.bottomPaginator,
					},
				}}
				select={{
					multiselect: resendPostbackAccess,
					onRowsSelect: setSelectedRowsIds,
					selectedIds: selectedRowsIds,
					renderMultipleActions: resendPostbackAccess
						? (ids: readonly postbackType['id'][]) => (
								<PostbacksActionsContext.Consumer>
									{({ resetCountFailures, markAsSentIds }) => (
										<div className={styles.selectedRowsActions}>
											<IconButton
												data-test-id='2hvj5Jio38iMGQhUYRuNS'
												icon='resetCount'
												caption={t('Reset count')}
												onClick={() => resetCountFailures(ids)}
											/>
											<IconButton
												data-test-id='ZYQ5HlFXJ79TBnuO9FLRP'
												icon='accept'
												caption={t('Mark as sent')}
												onClick={() => markAsSentIds(ids)}
											/>
										</div>
									)}
								</PostbacksActionsContext.Consumer>
							)
						: () => null,
				}}
			/>
		</PostbacksActionsProvider>
	);
};

export default NotAcceptedPostbacks;
