import { ITransactionsFilterForm } from 'api/transactionGroup';
import { WalletListItem } from 'api/walletGroup';
import Filter from 'components/Filter';
import AuthContext from 'contexts/AuthContext';
import { Roles } from 'contexts/AuthContext/utils/enums';
import { WalletTypesContext } from 'contexts/WalletTypesContext';
import { useTranslation } from 'pay-kit';
import React, {
	Dispatch,
	SetStateAction,
	useContext,
	useEffect,
	useState,
} from 'react';
import { rejectSettlement } from 'utils/filterSettlement';
import { getUniqueWallets } from 'utils/getUniqueWallets';
import useGetWallets from 'utils/hooks/useGetWallets';

import styles from './CurrentTransactionsFilter.module.scss';
import { FilterErrors } from './utils';

interface CurrentTransactionsFilterProps {
	readonly value: ITransactionsFilterForm;
	readonly onApply: (value: ITransactionsFilterForm) => void;
	readonly errors: Record<string, string | null>;
	readonly setErrors: Dispatch<SetStateAction<FilterErrors>>;
}

interface WalletFetchParams {
	readonly page: number;
	readonly limit: number;
	readonly identifier?: string;
	readonly id?: number;
}

const CurrentTransactionsFilter: React.FC<CurrentTransactionsFilterProps> = ({
	value,
	onApply,
	errors,
	setErrors,
}) => {
	const { t } = useTranslation();
	const walletTypesAPI = useContext(WalletTypesContext);

	const walletsAPI = useGetWallets();

	const { hasRole } = useContext(AuthContext);

	const hasGetWalletsListPermission = hasRole(Roles.WALLET_LIST);

	const [accumulatedWallets, setAccumulatedWallets] = useState<
		readonly WalletListItem[]
	>([]);

	const walletId = value.wallet_id;

	const [fetchedWithId, setFetched] = useState(false);

	const [params, setParams] = useState<WalletFetchParams>({
		page: 1,
		limit: 10,
	});

	useEffect(() => {
		if (walletId && !fetchedWithId) {
			setParams((p) => ({
				...p,
				id: walletId,
			}));
		}
	}, [walletId, fetchedWithId]);

	const fetchWallets = () => {
		const res = walletsAPI.load({
			...params,
			removed: undefined,
			is_active: undefined,
		});

		if (res) {
			res.then((resp) => {
				if (resp.status === 'success') {
					setAccumulatedWallets((acc) =>
						getUniqueWallets([...acc, ...resp.data]),
					);
				}
			});
		}

		if (params.id) {
			setFetched(true);

			setParams((p) => {
				const newParams = { ...p };

				delete newParams.id;

				return newParams;
			});
		}
	};

	useEffect(() => {
		if (hasGetWalletsListPermission) {
			fetchWallets();
		}
	}, [params, hasGetWalletsListPermission, fetchedWithId]);

	return (
		<>
			<Filter
				values={value}
				onApply={onApply}
				classNames={{
					wrapper: styles.filter,
					inlineFilter: styles.inlineFilter,
				}}
				maxInlineFields={4}
				disabled={!!errors.transaction_id}
				fields={[
					{
						name: 'primary_id',
						placeholder: t('ID'),
						component: 'textInput',
					},
					{
						name: 'label',
						placeholder: t('Label'),
						component: 'textInput',
					},
					{
						name: 'transaction_id',
						placeholder: t('Transaction number'),
						component: 'textInput',
						error: errors.transaction_id,
						onChangeFilter: (value: string) => {
							if (errors.transaction_id) {
								if (value.length < 1 || value.length > 3) {
									setErrors({
										transaction_id: null,
									});
								}
							}
						},
					},
					{
						name: 'merchant_order_id',
						placeholder: t('Order ID'),
						component: 'textInput',
					},
					{
						name: 'exchanger_identifier',
						placeholder: t('Counterparty'),
						component: 'textInput',
					},
					{
						name: 'wallet_type',
						placeholder: t('Wallet type'),
						component: 'select',
						options: rejectSettlement(walletTypesAPI.walletTypes).map(
							(type) => ({
								value: type.code,
								label: type.name,
							}),
						),
						isLoading: walletTypesAPI.isLoading,
					},
					...(hasRole(Roles.WALLET_LIST)
						? [
								{
									name: 'wallet_hash_id',
									placeholder: t('Wallet'),
									component: 'select',
									options: accumulatedWallets.map((wallet) => ({
										value: wallet.hash_id,
										label: `${wallet.wallet_type} | ${wallet.identifier}`,
									})),
									isLoading: walletsAPI.isLoading,
									onMenuScrollToBottom: () => {
										if (
											walletsAPI.paginationInfo &&
											walletsAPI.paginationInfo?.total > 10
										) {
											setParams((p) => ({
												...p,
												page: p.page + 1,
											}));
										}
									},
									onInputChange: (value: any) => {
										if (!value) {
											setParams({
												page: 1,
												limit: 10,
											});
										} else {
											setAccumulatedWallets([]);

											setParams((p) => ({
												...p,
												page: 1,
												identifier: value,
											}));
										}
									},
								},
							]
						: ([] as readonly any[])), // TODO: find out how to replace the any type
					{
						name: 'direction',
						placeholder: t('Direction'),
						component: 'select',
						options: [
							{
								value: 'outgoing',
								// label: "Исходящие",
								label: t('Outgoing'),
							},
							{
								value: 'ingoing',
								// label: "Входящие",
								label: t('Ingoing'),
							},
						],
					},
					...(hasRole(Roles.DISPLAY_TRANSACTION_TYPE)
						? [
								{
									name: 'type',
									placeholder: t('Transaction kind'),
									component: 'select',
									options: [
										{
											value: 'real',
											label: t('Confirmed'),
										},
										{
											value: 'fake',
											label: t('Debug'),
										},
										{
											value: 'forced',
											label: t('Forced'),
										},
										{
											value: 'inner',
											label: t('Internal'),
										},
									],
								},
								{
									name: 'creation_type',
									placeholder: t('Transaction type'),
									component: 'select',
									options: [
										{
											value: 'auto',
											label: t('Auto'),
										},
										{
											value: 'manual',
											label: t('Manual'),
										},
									],
								},
							]
						: []),
					...(hasRole(Roles.TRANSACTION_PRIVATE_INFORMATION_VIEW)
						? [
								{
									name: 'has_label',
									placeholder: t('Label availability'),
									component: 'select',
									options: [
										{
											value: 'true',
											label: t('With label'),
										},
										{
											value: 'false',
											label: t('Empty'),
										},
									],
								},
							]
						: []),

					{
						name: 'date_from',
						// placeholder: "Дата создания от",
						placeholder: 'Date created from',
						component: 'date',
						dateFormat: 'DD.MM.YYYY',
						// dateTimeFormat: "DD.MM.YYYY HH:mm:ss",
						blockPredicate: (date) => date.getTime() >= new Date().getTime(),
						openOnTop: true,
						withTime: true,
					},
					{
						name: 'date_to',
						// placeholder: "Дата создания до",
						placeholder: 'Date created to',
						component: 'date',
						// dateTimeFormat: "DD.MM.YYYY HH:mm:ss",
						dateFormat: 'DD.MM.YYYY',
						blockPredicate: (date) => date.getTime() >= new Date().getTime(),
						withTime: true,
						openOnTop: true,
						isFinishDate: true,
					},
					{
						name: 'activated_date_from',
						// placeholder: "Дата активации от",
						placeholder: 'Date activated from',
						component: 'date',
						// dateTimeFormat: "YYYY-MM-DD HH:mm:ss",
						dateFormat: 'DD.MM.YYYY',
						blockPredicate: (date) => date.getTime() >= new Date().getTime(),
						openOnTop: true,
						withTime: true,
					},
					{
						name: 'activated_date_to',
						// placeholder: "Дата активации до",
						placeholder: 'Date activated to',
						component: 'date',
						// dateTimeFormat: "YYYY-MM-DD HH:mm:ss",
						dateFormat: 'DD.MM.YYYY',
						blockPredicate: (date) => date.getTime() >= new Date().getTime(),
						withTime: true,
						isFinishDate: true,
						openOnTop: true,
					},
					...(hasRole(Roles.TRANSACTION_PRIVATE_INFORMATION_VIEW)
						? [
								{
									name: 'status',
									placeholder: t('Status'),
									component: 'select',
									options: [
										{ label: t('All'), value: '' },
										// { label: "Неактивированные", value: "0" },
										{ label: t('Not activated'), value: '0' },
										{ label: t('Activated'), value: '1' },
										{ label: t('Pending'), value: '3' },
										{ label: t('Processing'), value: '4' },
										{ label: t('Crashed'), value: '5' },
										{ label: t('Canceled'), value: '6' },
										{ label: t('Rejected'), value: '7' },
										// { label: "С ошибкой", value: "8" },
										{ label: t('With error'), value: '8' },
									],
								},
							]
						: []),

					...(hasRole(Roles.TRANSACTION_SUSPECTS_TRANSACTIONS_VIEW)
						? [
								{
									name: 'is_fake',
									// placeholder: "Подлинность",
									placeholder: t('Originality'),
									component: 'select',
									options: [
										// { label: "Нормальная", value: "false" },
										{ label: t('Normal'), value: 'false' },
										// { label: "Фрод", value: "true" },
										{ label: t('Scam'), value: 'true' },
									],
								},
							]
						: []),
					{
						name: 'postback_status',
						// placeholder: "Постбeк",
						placeholder: t('Postback status'),
						component: 'select',
						hide: !hasRole(Roles.POSTBACKS),
						options: [
							{
								value: '3',
								label: t('Stockpiling not found'),
							},
							{
								value: '0',
								// label: "Идёт накопление",
								label: t('Stockpiling in progress'),
							},
							{
								value: '1',
								label: t('Sent'),
							},
							{
								value: '2',
								// label: "Ошибка отправки постбeка",
								label: t('Error sending postback'),
							},
						],
					},
				]}
			/>
		</>
	);
};

export default CurrentTransactionsFilter;
