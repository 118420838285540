export type RenderIfType = {
	readonly visible?: boolean;
	readonly children: React.ReactNode | readonly React.ReactNode[] | null;
};

const RenderIf: React.FC<RenderIfType> = ({ visible, children }) => {
	if (visible) {
		return children;
	}

	return null;
};

export default RenderIf;
