import api, { TranslationHintType } from 'api/translationsGroup';
import { useState } from 'react';
import { errorsMap } from 'utils/enums';

const useGetHints = () => {
	const [list, setList] = useState<readonly TranslationHintType[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const load = (wallet_type: string, isDefault: boolean) => {
		setIsLoading(true);

		api
			.getHints(wallet_type, isDefault)
			.then((res: any) => {
				if (res?.status === 'success' && !!res?.data) {
					setList(res.data as readonly TranslationHintType[]);

					return res;
				} else {
					// throw new Error(`Getting translation hints error`);
					throw new Error(errorsMap.anyResponse);
				}
			})
			.finally(() => setIsLoading(false))
			.catch((err) => {
				console.error(err);
			});
	};

	return {
		load,
		list,
		isLoading,
	};
};

export default useGetHints;
