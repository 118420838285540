import { useTranslation } from 'pay-kit';
import api, {
	TranslationHintType,
	UpdateTranslationPackageDataType,
} from 'api/translationsGroup';
import { Button, Modal, TextInput } from 'pay-kit';
import { useEffect, useState } from 'react';

import styles from './RenameModal.module.scss';

export type RenameModalPackagesData = UpdateTranslationPackageDataType & {
	readonly uuid: string;
};

export type RenameModalPropsType = {
	readonly data?: {
		readonly translationType: 'package' | 'hint';
		readonly fieldName: string;
		readonly usedNames: readonly string[];
		readonly packages?: readonly RenameModalPackagesData[];
		readonly formTypes?: readonly {
			readonly hint: TranslationHintType;
		}[];
	};
	readonly onClose: () => void;
	readonly onSuccess: () => void;
};

const RenameModal: React.FC<RenameModalPropsType> = ({
	data,
	onClose,
	onSuccess,
}) => {
	const { t } = useTranslation();
	const [newName, setNewName] = useState<string>('');
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const error = data?.usedNames.includes(newName)
		? t('Name already in use')
		: undefined;

	useEffect(() => {
		if (data === undefined) {
			setNewName('');
		}
	}, [data]);

	const submitHandler = () => {
		if (data?.translationType === 'hint') {
			renameHint();
		}

		if (data?.translationType === 'package') {
			renamePackage();
		}
	};

	const renameHint = () => {
		if (!data?.formTypes) {
			return;
		}

		setIsLoading(true);

		Promise.all(
			data.formTypes.map(({ hint }) =>
				api.updateHint(hint.uuid, {
					name: newName,
					is_manual: hint.is_manual,
					translations: hint.translations,
				}),
			),
		)
			.finally(() => setIsLoading(false))
			.then((result) => {
				onSuccess && onSuccess();
				onClose();

				window.pushAlert({
					type: 'success',
					title: t('Translate successfully renamed'),
				});

				return result;
			})
			.catch((error) => {
				console.error(error);
				window.pushAlert({ type: 'error', title: t('Translate rename error') });
			});
	};

	const renamePackage = () => {
		if (!data?.packages) {
			return;
		}

		setIsLoading(true);

		Promise.all(
			data.packages.map(({ uuid, is_manual, packages_translations }) =>
				api.updatePackages(uuid, {
					name: newName,
					is_manual,
					packages_translations,
				}),
			),
		)
			.finally(() => setIsLoading(false))
			.then((result) => {
				onSuccess && onSuccess();
				onClose();

				window.pushAlert({
					type: 'success',
					title: t('Translate successfully renamed'),
				});

				return result;
			})
			.catch((error) => {
				console.error(error);
				window.pushAlert({ type: 'error', title: t('Translate rename error') });
			});
	};

	return (
		<Modal
			className={styles.renameModal}
			isOpen={data !== undefined}
			title={t('Rename field {{name}}', { name: data?.fieldName })}
			onClose={onClose}
		>
			<p>
				{t('Rename field')} <b>{data?.fieldName}</b>
			</p>
			<TextInput
				placeholder={t('New name')}
				name='new-name'
				value={newName}
				onChange={(e) => setNewName(e.target.value)}
				data-test-id=''
				error={error}
			/>
			<div className={styles.controls}>
				<Button
					data-test-id='cancel'
					onClick={onClose}
					variant='secondary'
					disabled={isLoading}
				>
					{t('Cancel')}
				</Button>
				<Button
					data-test-id='rename-submit'
					onClick={submitHandler}
					disabled={error !== undefined || newName === '' || isLoading}
					isLoading={isLoading}
				>
					{t('Save')}
				</Button>
			</div>
		</Modal>
	);
};

export default RenameModal;
