import { ModalRowComponentType } from 'modules/TransferHistory/components/TransferringMoney';
import { useOperationHistoryContext } from 'modules/TransferHistory/contexts/OperationHistoryContext';
import { Select } from 'pay-kit';
import { FC, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import styles from './accountType.module.scss';

const AccountType: FC<Omit<ModalRowComponentType, 'changeField'>> = ({
	name,
	setForm,
	form,
	errors,
}) => {
	const { walletTypes, PSBalance } = useOperationHistoryContext();
	const { ps } = useParams();

	const formattedAccountsWalletTypes = walletTypes.map(
		({ name, code, supported_currencies, supported_wallet_currencies }) => ({
			label: name,
			value: code,
			supported_currencies,
			supported_wallet_currencies,
		}),
	);

	const supportedAccountsPS = formattedAccountsWalletTypes
		.filter((payment) =>
			payment.supported_currencies.includes(PSBalance?.currency_code || ''),
		)
		.map(({ label, value }) => ({ label, value }));

	const accountsSelectOptions = useMemo(() => {
		const source = PSBalance?.is_settlement
			? formattedAccountsWalletTypes
			: // TODO: A crutch for the logic reproduction of the old front
				[...supportedAccountsPS, { value: `paykassma`, label: `Paykassma` }];

		return source.filter((item) => item.value !== ps);
	}, [
		PSBalance?.is_settlement,
		supportedAccountsPS,
		formattedAccountsWalletTypes,
		ps,
	]);

	return (
		<Select
			name={name}
			className={styles.select}
			placeholder={''}
			options={accountsSelectOptions}
			onChange={(value) => {
				setForm((prevState) => ({ ...prevState, [name]: value }));
			}}
			value={form[name]}
			error={errors[name]}
		/>
	);
};

export default AccountType;
