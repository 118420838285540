import {
	CategoryScale,
	ChartData,
	Chart as ChartJS,
	LineElement,
	LinearScale,
	PointElement,
	Title,
	Tooltip,
} from 'chart.js';
import { FC } from 'react';
import { Line } from 'react-chartjs-2';

import { createExternalTooltipHandler } from '../utils';

ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
);

const LineChart: FC<LineChartType> = ({ data, unit, psMap }) => {
	const options = {
		plugins: {
			title: {
				display: false,
			},
			legend: {
				display: false,
			},
			tooltip: {
				enabled: false,
				external: createExternalTooltipHandler(psMap, unit),
			},
		},
		interaction: {
			intersect: false,
			axis: 'xy',
			mode: 'index',
		},
		tension: 0.4,
		responsive: true,
		maintainAspectRatio: false,
	} as never;

	return <Line options={options} data={data} height={380} />;
};

export default LineChart;

type LineChartType = {
	readonly data: ChartData<'line', readonly number[], string>;
	readonly unit?: string;
	readonly psMap: Record<string, string>;
};
