import { IStatusListItem, WithdrawalStatusENUM } from 'api/withdrawalGroup';
import { Tooltip, t } from 'pay-kit';
import React, { useContext } from 'react';

import { StatusesContext } from '../../../../contexts/statusesContext';

import styles from './WithdrawalStatus.module.scss';

interface IWithdrawalStatus {
	readonly status: WithdrawalStatusENUM;
	readonly dataTestId?: string;
}

const STATUS_STYLE_MAP = {
	[WithdrawalStatusENUM.NEW]: styles.NEW,
	[WithdrawalStatusENUM.COMPLETED]: styles.COMPLETED,
	[WithdrawalStatusENUM.CANCELLED]: styles.CANCELLED,
	[WithdrawalStatusENUM.PENDING]: styles.PENDING,
	[WithdrawalStatusENUM.TO_CHECK]: styles.TO_CHECK,
	[WithdrawalStatusENUM.REJECTED]: styles.REJECTED,
	[WithdrawalStatusENUM.ADDING_A_BENEFICIARY]: styles.ADDING_A_BENEFICIARY,
	[WithdrawalStatusENUM.ERROR]: styles.ERROR,
	[WithdrawalStatusENUM.APPROVING]: styles.APPROVING,
};

const buildStatusMap = (
	statuses: readonly IStatusListItem[],
	STATUS_STYLE_MAP: { readonly [key in WithdrawalStatusENUM]: string },
) => {
	const newObj: any = {};

	statuses.forEach(({ status, name }) => {
		newObj[status] = { label: name, className: STATUS_STYLE_MAP[status] };
	});

	return newObj;
};

const WithdrawalStatus: React.FC<IWithdrawalStatus> = ({
	status,
	dataTestId,
}) => {
	const statusesContext = useContext(StatusesContext);

	if (statusesContext.isLoading) {
		return null;
	}

	const statusMap = buildStatusMap(statusesContext.list, STATUS_STYLE_MAP);

	const statusObject = statusMap[status] || {
		label: `${t('Status is not defined')}: ${status}`,
		className: styles.notDefined,
	};
	const classNames = [styles.WithdrawalStatus, statusObject.className];

	return (
		// <Tooltip tip="Статус вывода" preferredSide="bottom">
		<Tooltip tip={t('Status')} preferredSide='bottom'>
			<div className={classNames.join(' ')} data-test-id={dataTestId}>
				{statusObject.label}
			</div>
		</Tooltip>
	);
};

export default WithdrawalStatus;
