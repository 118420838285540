import { Loader } from 'pay-kit';

import styles from './stockpilingModalLoader.module.scss';

const StockpilingModalLoader = () => {
	// TODO: Заменить стиль на токены, когда они будут доступны
	return (
		<div className={styles.loaderWrapper}>
			<Loader color={'#A2A9B0'} />
		</div>
	);
};

export default StockpilingModalLoader;
