import { ModalRowComponentType } from 'modules/TransferHistory/components/TransferringMoney';
import { useOperationHistoryContext } from 'modules/TransferHistory/contexts/OperationHistoryContext';
import { useOperationHistoryModalContext } from 'modules/TransferHistory/contexts/OperationHistoryModalContext';
import { Select } from 'pay-kit';
import { FC } from 'react';

import styles from './currency.module.scss';

const Currency: FC<Omit<ModalRowComponentType, 'changeField'>> = ({
	name,
	setForm,
	form,
}) => {
	const { PSBalance } = useOperationHistoryModalContext();
	const { walletTypes } = useOperationHistoryContext();

	const supportedCurrencies =
		walletTypes.find((w) => w.code === form.recipient_payment_system)
			?.supported_currencies || [];

	let supportedCurrenciesOptions =
		form.recipient_payment_system === 'paykassma'
			? [
					{
						label: 'USDT',
						value: 'USDT',
					},
				]
			: supportedCurrencies.map((currency) => ({
					label: currency,
					value: currency,
				}));

	supportedCurrenciesOptions = supportedCurrenciesOptions.length
		? supportedCurrenciesOptions
		: [
				{
					label: `${form[name]}`,
					value: `${form[name]}`,
				},
			];

	return (
		<Select
			name={name}
			options={supportedCurrenciesOptions}
			className={styles.currencySelect}
			onChange={(value) =>
				setForm((prevState) => ({ ...prevState, [name]: value }))
			}
			value={form[name]}
			isDisabled={!form.recipient_payment_system || !PSBalance?.is_settlement}
		/>
	);
};

export default Currency;
