import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

type FromUrlTransformFunc = (urlData: Record<string, string>) => any;
type ToUrlTransformFunc = (data: any) => Record<string, string>;

type TransformFuncs = {
	from?: FromUrlTransformFunc;
	to?: ToUrlTransformFunc;
};

type UseURLFiltersProps = TransformFuncs & {
	dateRangeFields?: string[];
	initialState?: Record<string, string>;
};

function fromUrlDateRangeTransformFixture(fieldName: string): TransformFuncs {
	const fromKey = `${fieldName}_from`;
	const toKey = `${fieldName}_to`;

	return {
		from: (urlData) => {
			const data = { ...urlData };

			if (fromKey in urlData) {
				data[fieldName] = {
					from: data[fromKey],
					to: data[toKey],
				};

				delete data[fromKey];
				delete data[toKey];
			}

			return data;
		},
		to: (formData) => {
			const data = { ...formData };

			if (fieldName in data) {
				data[fromKey] = data[fieldName].from;
				data[toKey] = data[fieldName].to;
				delete data[fieldName];
			}

			return data;
		},
	};
}

function useURLFilters(props: UseURLFiltersProps = {}) {
	const [searchParams, setSearchParams] = useSearchParams();
	const [changed, setChanged] = useState<boolean>(false);

	const transformFuncs: TransformFuncs = useMemo(() => {
		const transform = { ...props };

		if (props.dateRangeFields) {
			const dateRangeFuncs = props.dateRangeFields.map((field) =>
				fromUrlDateRangeTransformFixture(field),
			);

			transform.from = (urlData) => {
				let transformedData = { ...urlData };

				dateRangeFuncs.forEach((funcs) => {
					if (funcs.from) {
						transformedData = funcs.from(transformedData);
					}
				});

				if (props.from) {
					transformedData = props.from(transformedData);
				}

				return transformedData;
			};

			transform.to = (data) => {
				let transformedData = { ...data };

				dateRangeFuncs.forEach((funcs) => {
					if (funcs.to) {
						transformedData = funcs.to(transformedData);
					}
				});

				if (props.to) {
					transformedData = props.to(transformedData);
				}

				return transformedData;
			};
		}

		return {
			to: transform.to,
			from: transform.from,
		};
	}, []);

	const filters = useMemo(() => {
		let rawFilters = Object.fromEntries(searchParams.entries());

		if (props.initialState && !changed) {
			rawFilters = {
				...props.initialState,
				...rawFilters,
			};
		}

		if (transformFuncs.from) {
			rawFilters = transformFuncs.from(rawFilters);
		}

		return rawFilters;
	}, [searchParams, changed]);

	const setFilters = (values: Record<string, string | number>) => {
		setChanged(true);

		const transformValues = () => {
			let data: Record<string, string | string[]> = {
				// ...Object.fromEntries(prevFilters.entries()),
				...values,
			};

			if (transformFuncs.to) {
				data = transformFuncs.to(data);
			}

			return data;
		};

		setSearchParams(transformValues, {
			replace: true,
		});
	};

	useEffect(() => {
		if (!changed && props.initialState) {
			setFilters({
				...props.initialState,
				...filters,
			});
		}
	}, [changed]);

	return [filters, setFilters];
}

export default useURLFilters;
