import AuthContext from 'contexts/AuthContext';
import { Roles } from 'contexts/AuthContext/utils/enums';
import Dashboard from 'modules/Dashboard';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'pay-kit';

export default function DashboardPage() {
	const { t } = useTranslation();

	useEffect(() => {
		document.title = t('Dashboard');
	}, []);

	const { hasRole, isAuth, user } = useContext(AuthContext);
	const dashboardAccess = hasRole(Roles.DASHBOARD);
	const navigate = useNavigate();

	if (isAuth && user.name && !dashboardAccess) {
		navigate('/settingsGroup/profile');

		return null;
	}

	return <Dashboard />;
}
