import {
	ParserType,
	WalletState,
} from 'modules/Wallets/components/modals/ChangeWalletModal/utils/enums';
import { WalletTypes } from 'utils/enums';

import { fetchApi, fetchOldApi } from './fetch';

const walletsGroup = {
	getList: (filter: WalletsFilterForm) =>
		fetchApi<getWalletsListType>(
			'/wallets',
			{
				...filter,
			},
			'GET',
		),
	getOne: (id: WalletsFilterForm['id'] | string) =>
		fetchApi<getWalletsListType>(`/wallets/${id}`, {}, 'GET'),
	getPossibleWalletStatuses: (type: WalletStatusType) =>
		fetchOldApi<WalletStatusList>(`/wallet/admin-status`, { type }),
	getWallet: (hash_id: WalletTypeType[`hash_id`]) =>
		fetchApi<WalletItem>(`/wallets/${hash_id}`, {}, 'GET'),
	updateSetting: ({ hash_id, ...body }: UpdateSettingData) =>
		fetchApi(`/wallet/settings/${hash_id}`, { ...body }, 'POST'),
	disableWallet: (hash_id: WalletTypeType[`hash_id`]) =>
		fetchOldApi<void>(`/wallet/disable/${hash_id}`, {}, 'PUT'),
	archiveWallet: (hash_id: WalletTypeType[`hash_id`]) =>
		fetchOldApi<void>(`/wallet/remove/${hash_id}`, {}, 'DELETE'),
	activateWallet: (hash_id: WalletTypeType[`hash_id`]) =>
		fetchOldApi<void>(`/wallet/set-active/${hash_id}`, {}, 'POST'),
	recoverWallet: (
		hash_id: WalletTypeType[`hash_id`],
		parsing_start_at?: string,
	) =>
		fetchOldApi<void>(
			`/wallet/restore/${hash_id}`,
			parsing_start_at ? { parsing_start_at } : {},
			'POST',
		),
	setSmsCode: (params: SetSmsCodeParams) =>
		fetchOldApi(`/wallet/set-sms-code`, params, 'POST'),
	load: (params: LoadQueryParams) => fetchOldApi('/wallet/load', params, 'GET'),
	getItem: (hash_id: WalletTypeType[`hash_id`]) =>
		fetchOldApi(`/wallet/${hash_id}`),
	create: (form: any) => fetchApi('/wallet/create', form, 'POST'),
	updateWallet: (hash_id: number, form: any) =>
		fetchApi(`/wallet/update/${hash_id}`, form, 'POST'),
	getWallets: (params: boolean) =>
		fetchOldApi<GetWalletsType>(`wallet/list?${params}`),
	deleteWallet: (hash_id: WalletTypeType[`hash_id`]) =>
		fetchOldApi(`/wallet/${hash_id}`, {}, 'DELETE'),
};

export default walletsGroup;

//TODO: "Переделать после выпуска страницы 'Кошельки'"
type GetWalletsType = {
	readonly wallets: readonly WalletType[];
};

export type WalletTypeType = {
	readonly activity_log: {
		readonly data: {
			readonly updated_at: string | null;
		};
		readonly updated_at: string | null;
		readonly last_ingoing: string | null;
		readonly last_outgoing: string | null;
		readonly last_parsing: string | null;
	};
	readonly balance: number;
	readonly parser_type: ParserType;
	readonly comment: string | null;
	readonly created_at: string;
	readonly email_parser_proxy: readonly Proxy[];
	readonly priority: number;
	readonly vendor_code: string | null;
	readonly wallet_settings: Record<string, string>;
	readonly currency: string;
	readonly daily_transaction_limit: number | null;
	readonly hash_id: string;
	readonly identifier: string;
	readonly is_active: true;
	readonly proxies: readonly [];
	readonly removed: false;
	readonly sms_code: string | null;
	readonly sms_source: string | null;
	readonly states: readonly WalletState[];
	readonly wallet_type: WalletTypes;
	readonly wallet_off_limit: number | null;
	readonly wallet_off_load: number | null;
};

export type Proxy = {
	readonly country_code: string;
	readonly id: string;
	readonly ip: string;
	readonly port: number;
	readonly status: number;
	readonly type: string;
};

export type CommonWalletData = {
	readonly account_login: string | null;
	readonly balance: number;
	readonly comment: string | null;
	readonly wallet_type: WalletTypes;
	readonly updated_at: string;
	readonly id: number;
	readonly hash_id: string;
	readonly identifier: string;
	readonly currency: string;
	readonly proxies: readonly Proxy[];
	readonly error: string | null;
	readonly is_active: boolean;
	readonly priority: WalletPriority;
};

export type ExtraBalance = {
	readonly currency: string;
	readonly balance: string;
};

export type getWalletsListType = readonly WalletListItem[];

export type WalletListItem = CommonWalletData & {
	readonly wallet_type: WalletTypes;
	readonly account_number: string | null;
	readonly code: string;
	readonly requisites: readonly any[];
	readonly sms_code: any | null;
	readonly state: readonly WalletState[];
	readonly is_standard: boolean;
	readonly plugin_status?: boolean;
	readonly upi_addresses?: readonly string[];
	readonly extra_balances?: readonly ExtraBalance[];
	readonly email_username?: string;
	readonly username?: string;
	readonly removed_at: string | null;
	readonly states?: readonly WalletState[];
	readonly display_identifier?: string;
};

export enum WalletPriority {
	LOW = 0,
	BELOW_NORMAL = 25,
	NORMAL = 50,
	ABOVE_NORMAL = 75,
	HIGH = 100,
}

export type CommonWalletItem = CommonWalletData & {
	readonly activity_log: {
		readonly data: {
			readonly updated_at: string | null;
		};
		readonly updated_at: string | null;
		readonly last_ingoing: string | null;
		readonly last_outgoing: string | null;
	};
	readonly created_at: string;
	readonly email_parser_proxy: readonly Proxy[];
	readonly priority: WalletPriority;
	readonly vendor_code: string | null;
	readonly wallet_settings: Record<string, string>;
};

export type WalletItem = CommonWalletItem & any;

export type UpdateSettingData = {
	readonly hash_id: WalletTypeType[`hash_id`];
	readonly comment?: WalletItem['comment'] | null;
	readonly is_standard?: WalletListItem['is_standard'] | null;
	readonly plugin_status?: WalletListItem['plugin_status'] | null;
};

export type SetSmsCodeParams = {
	readonly identifier: string;
	readonly sms_code: string;
	readonly wallet_type: string;
};

export type LoadQueryParams = {
	readonly direction: 'outgoing' | 'ingoing';
	readonly load_object: 'payment_systems' | 'wallets';
	readonly wallet_type?: string;
	readonly load_type: 'transactions_count' | 'transactions_sum';
	readonly is_active?: boolean; // false = отключенные кошельки, true = рабочие кошельки
	readonly removed?: boolean; // true = удаленные кошельки
	readonly transaction_status?: 1 | 0; // Статус транзакций: 1: активированные
	// Дата создания транзакции
	readonly create_date_from?: string;
	readonly create_date_to?: string;
	// Дата активации транзакции
	readonly date_from?: string;
	readonly date_to?: string;
};

export type LoadQueryTransactionsSumListItem = {
	readonly key: string;
	readonly type: string;
	readonly transactions_usd_sum: string;
	readonly currency_transaction_sum: readonly [
		{
			readonly currency_code: string;
			readonly amount: string;
		},
	];
};

export type LoadQueryTransactionsCountListItem = {
	readonly hash_id: string;
	readonly identifier: string;
	readonly transactions_count: number;
	readonly type: string;
};

export type LoadQueryListItem =
	| LoadQueryTransactionsSumListItem
	| LoadQueryTransactionsCountListItem;

export type WalletsFilterForm = {
	readonly id?: number;
	readonly type?: string;
	readonly identifier?: string;
	readonly account_login?: string;
	readonly state?: string;
	readonly removed?: 0 | 1;
	readonly is_active?: 0 | 1;
	readonly limit?: number;
	readonly offset?: number;
};

export type WalletStatusType = 'worked' | 'disabled' | 'archived';

export type WalletStatus = {
	readonly id: number;
	readonly code: string;
	readonly text: string;
};

export type WalletStatusList = {
	readonly statuses: readonly WalletStatus[];
};

export type WalletType = {
	readonly account_login: string;
	readonly account_number: string;
	readonly balance: number;
	readonly code: string;
	readonly comment: string;
	readonly currency: string;
	readonly error: string;
	readonly id: number;
	readonly hash_id: string;
	readonly identifier: string;
	readonly is_active: boolean;
	readonly is_standard: boolean;
	readonly plugin_status: boolean;
	readonly proxies: readonly unknown[];
	readonly removed: boolean;
	readonly requisites: readonly unknown[];
	readonly sms_code: unknown;
	readonly state: readonly string[];
	readonly type: string;
	readonly updated_at: string;
	readonly status: number;
	readonly site_parser?: boolean;
	readonly email_parser?: boolean;
	readonly parser_type?: string | null;
};
