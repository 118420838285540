import { Button, Modal } from '@paykassma/pay-kit';
import {
	ICurrentTransaction,
	TransactionStatusEnum,
	TransactionTypeEnum,
} from 'api/transactionGroup';
import AuthContext, { Roles, useHasAccessHook } from 'contexts/AuthContext';
import { useTranslation } from 'pay-kit';
import { useContext, useMemo, useState } from 'react';

import { ActionsContext } from '../ActionsProvider';

import styles from './Actions.module.scss';
import ActivateIconSVG from './icons/activate.svg';
import ApproveIconSVG from './icons/approve.svg';
import ArchiveIconSVG from './icons/archive.svg';

const Actions = ({
	status,
	primary_id,
	type,
	direction,
	is_fake,
	id,
}: Pick<
	ICurrentTransaction,
	'status' | 'primary_id' | 'type' | 'direction' | 'is_fake' | 'id'
>) => {
	// Inner transaction: https://confluence.fixmost.com/pages/viewpage.action?pageId=55778445
	const isInnerTransaction =
		direction === 'outgoing' &&
		type === TransactionTypeEnum.APPROVED &&
		status === TransactionStatusEnum.NOT_ACTIVATED;
	const [isModalOpen, setIsModalOpen] = useState(false);
	const { hasRole } = useContext(AuthContext);
	const hasAccessToActivate = useHasAccessHook({
		rule: Roles.TRANSACTION_ACTIVATE,
	});
	const hasAccessToArchive = useHasAccessHook({
		rule: Roles.ARCHIVE_TRANSACTION_VIEW,
	});
	const hasAccessToApprove = useHasAccessHook({
		rule: Roles.TRANSACTION_SUSPECTS_APPROVE,
	});
	const handleOpenApproveModal = (flag: boolean) => () => setIsModalOpen(flag);

	const { t } = useTranslation();

	const canArchive = useMemo(() => {
		if (is_fake && status === TransactionStatusEnum.ACTIVATED) {
			return hasRole(Roles.ACTIVATED_FAKE_DELETE);
		}

		return hasAccessToArchive;
	}, [is_fake, status, hasAccessToArchive, hasRole]);

	return (
		<ActionsContext.Consumer>
			{({ archive, setAsFake, setAsNotFake, activate }) => (
				<div
					data-test-id='_zFvTsPIDN2-8vk3nqjA-'
					className={styles.actions}
					onClick={(e) => e.stopPropagation()}
				>
					<Modal
						isOpen={isModalOpen}
						onClose={handleOpenApproveModal(false)}
						title={t('Approve transaction')}
					>
						<p>
							{t('Are you sure you want to approve transaction')} {id}?
						</p>
						<div className={styles.wrapper}>
							<Button
								data-test-id='y03pQzUx9OCR2Rorixzlp'
								dataTestId='close'
								variant='secondary'
								onClick={handleOpenApproveModal(false)}
							>
								{t('Cancel')}{' '}
							</Button>
							{hasAccessToApprove && (
								<Button
									data-test-id='vWWHP87r7_kULkNi1H-0m'
									dataTestId='approve'
									onClick={() => setAsNotFake(primary_id)}
								>
									{/* Одобрить */}
									{t('Approve')}
								</Button>
							)}
						</div>
					</Modal>
					{status === TransactionStatusEnum.ACTIVATED &&
						!is_fake &&
						hasRole(Roles.SET_FAKE) && (
							<>
								<button
									data-test-id='setAsFakeButton'
									className={styles.setAsFakeButton}
									onClick={() => setAsFake(primary_id)}
								>
									{t('Fake')}
								</button>
							</>
						)}
					{is_fake && hasAccessToApprove && (
						<button
							data-test-id='openApproveModal'
							className={styles.setAsNotFakeButton}
							onClick={handleOpenApproveModal(true)}
						>
							<ApproveIconSVG />
							{t('Approve')}
						</button>
					)}
					{status === TransactionStatusEnum.NOT_ACTIVATED &&
						!isInnerTransaction &&
						!is_fake &&
						hasAccessToActivate && (
							<button
								data-test-id='activateButton'
								onClick={() => activate(primary_id)}
								className={styles.activateButton}
							>
								<ActivateIconSVG />
								{t('Activate')}
							</button>
						)}
					{canArchive && (
						<button
							data-test-id='archiveButton'
							className={styles.archiveButton}
							onClick={() => archive(primary_id)}
						>
							<ArchiveIconSVG />
							{t('Archive')}
						</button>
					)}
				</div>
			)}
		</ActionsContext.Consumer>
	);
};

export default Actions;
