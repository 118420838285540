import { useTranslation } from '@paykassma/pay-kit';
import api, { TariffItemType } from 'api/tariffsGroup';
import { useState } from 'react';
import { errorsMap } from 'utils/enums';

const useGetTariffs = () => {
	const [list, setList] = useState<readonly TariffItemType[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const { t } = useTranslation();

	const load = () => {
		setList([]);
		setIsLoading(true);

		api
			.getTariffs()
			.then((res: any) => {
				if (res?.status === 'ok' && Array.isArray(res?.items)) {
					setList(res.items as readonly TariffItemType[]);

					return res;
				} else {
					window.pushAlert({
						type: 'error',
						description: t('Loading tariff list error'),
					});

					// throw new Error(`Getting tariffs error`);
					throw new Error(errorsMap.anyResponse);
				}
			})
			.finally(() => setIsLoading(false))
			.catch((err) => {
				console.error(err);
			});
	};

	return {
		load,
		list,
		isLoading,
	};
};

export default useGetTariffs;
