import { useTranslation } from 'pay-kit';
import { PlusIcon } from 'components/Icons/PlusIcon/PlusIcon';
import { RefreshIcon } from 'components/Icons/RefreshIcon';
import { Roles, useHasAccessHook } from 'contexts/AuthContext';
import { WalletTypesContext } from 'contexts/WalletTypesContext';
import { Button, Table } from 'pay-kit';
import { useContext } from 'react';

import ActionsProvider, { ActionsContext } from './components/ActionsProvider';
import columns from './components/columns';
import styles from './PaymentsSystemsList.module.scss';

const PaymentSystemsList = () => {
	const walletTypesAPI = useContext(WalletTypesContext);
	const walletTypes = walletTypesAPI.walletTypes.filter(
		(wt) => !wt.is_settlement,
	);

	useHasAccessHook({ rule: Roles.MANAGE_PAYMENTS_CONSTRUCTOR, redirect: true });

	const { t } = useTranslation();
	const hasManualPSAccess = useHasAccessHook({
		rule: Roles.MANAGE_PAYMENTS_CONSTRUCTOR,
	});

	return (
		<ActionsProvider>
			<ActionsContext.Consumer>
				{({ showCreateNewModal }) => (
					<>
						<div className={styles.actions}>
							{hasManualPSAccess && (
								<Button data-test-id='' onClick={showCreateNewModal}>
									<PlusIcon />
									{t('Add manual PS')}
								</Button>
							)}

							<Button
								data-test-id=''
								type='button'
								variant='text-primary'
								onClick={() => walletTypesAPI.getWalletTypesList()}
							>
								<RefreshIcon />
								{t('Refresh')}
							</Button>
						</div>
						<Table
							className={styles.PaymentsSystemsListTable}
							columns={columns}
							data={walletTypes}
							rowKey='code'
							isLoading={walletTypesAPI.isLoading}
							skeleton={{ rowsCount: 100 }}
							stickyHead
						/>
					</>
				)}
			</ActionsContext.Consumer>
		</ActionsProvider>
	);
};

export default PaymentSystemsList;
