import { t } from '@paykassma/pay-kit';
import API from 'api';
import {
	IPossibleTransaction,
	IPossibleTransactionsFilterForm,
	ITransactionsPaginatorParams,
} from 'api/transactionGroup';
import { useState } from 'react';
import { errorsMap } from 'utils/enums';

const usePossibleTransactionsListAPI: usePossibleTransactionsListAPIType =
	() => {
		const [isLoading, setIsLoading] = useState<boolean>(false);
		const [transactions, setTransactions] = useState<
			readonly IPossibleTransaction[]
		>([]);
		const [error, setError] = useState<null | Error>(null);
		const [paginationInfo, setPaginationInfo] =
			useState<null | paginationInfoType>(null);

		const getTransactionsList = (
			params: IPossibleTransactionsFilterForm & ITransactionsPaginatorParams,
		) => {
			setIsLoading(true);
			setError(null);
			setTransactions([]);

			API.transaction
				.getPossibleList(params)
				.then((res: any) => {
					if (res?.status === 'success' && res?.data?.transactions?.data) {
						const { data, ...rest } = res.data.transactions;

						setTransactions(data);
						setPaginationInfo(rest);

						return;
					} else {
						// throw new Error("Invalid response");
						throw new Error(errorsMap.anyResponse);
					}
				})
				.finally(() => setIsLoading(false))
				.catch((err) => {
					// window.pushAlert({ title: "Возникла ошибка при получении возможных транзакций", type: "error" });
					window.pushAlert({
						title: t('An error occurred while getting possible transactions'),
						type: 'error',
					});

					setError(err);
				});
		};

		return {
			getTransactionsList,
			isLoading,
			transactions,
			error,
			paginationInfo,
		};
	};

export default usePossibleTransactionsListAPI;

export type usePossibleTransactionsListAPIType = () => {
	readonly getTransactionsList: (
		params: IPossibleTransactionsFilterForm & ITransactionsPaginatorParams,
	) => void;
	readonly isLoading: boolean;
	readonly transactions: readonly IPossibleTransaction[];
	readonly error: Error | null;
	readonly paginationInfo: paginationInfoType | null;
};

export type paginationInfoType = {
	readonly current_page: number;
	readonly from: number;
	readonly to: number;
	readonly per_page: number;
	readonly last_available_page: number;
};

export type getListSuccessResponseType = {
	readonly status: 'ok';
	readonly transactions: {
		readonly data: readonly IPossibleTransaction[];
	} & paginationInfoType;
};
