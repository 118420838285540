import { PayKitForm } from "@paykassma/pay-kit";
import type { TotalReportReqType } from "api/reportsGroup";
import { WalletTypesContext } from "contexts/WalletTypesContext";
import { useTranslation } from "pay-kit";
import { useContext } from "react";
import { fromLocaleDate, notLaterThanToday } from "utils/date";

import { ActionsContext } from "../../../../../ActionsProvider";
import styles from "./TotalReport.module.scss";

type FormState = {
	readonly currency_code: string;
	readonly utc_0: boolean;
	readonly period: Readonly<{
		readonly from: string;
		readonly to: string;
	}>;
	readonly file_format: "xlsx" | "csv";
};

type prepareFormDataType = (rawFormData: FormState) => TotalReportReqType;

const prepareFormData: prepareFormDataType = (rawFormData) =>
	({
		currency_code: rawFormData.currency_code,
		file_format: rawFormData.file_format,
		date_from: fromLocaleDate(rawFormData.period.from),
		date_to: fromLocaleDate(rawFormData.period.to),
		utc_0: rawFormData.utc_0 || false,
	} as TotalReportReqType);

export const TotalReport = () => {
	const { t } = useTranslation();
	const walletsContext = useContext(WalletTypesContext);
	const { createTotalReportAPI } = useContext(ActionsContext);

	const allCurrencies = walletsContext.walletTypes
		.map((c) => c.supported_currencies)
		.reduce((accum, current) => [...new Set([...accum, ...current])], []);

	const allCurrenciesOptions = allCurrencies.map((c) => ({ label: c, value: c }));

	const validatePeriod = (value: FormState["period"]) => {
		const dateFrom = value?.from;
		const dateTo = value?.to;

		if (!dateFrom || !dateTo) {
			return t("Choose period");
		} else {
			const getMonthAndYearString = (date: string) => {
				const splittedDate = date.split(" ")[0].split(".");
				return `${splittedDate[1]}/${splittedDate[2]}`;
			}

			if (getMonthAndYearString(dateFrom) !== getMonthAndYearString(dateTo)) {
				return t("The start and end dates of the period must be in the same month");
			}
		}

		return undefined;
	}

	const schema = [
		{
			type: "BaseSettingsSection",
			elements: [
				{
					name: "currency_code",
					label: t("Currency"),
					placeholder: "",
					type: "Select",
					options: () => [{ value: undefined, label: t("All") }, ...allCurrenciesOptions],
					isLoading: walletsContext.isLoading,
					// isRequired: true,
					// validation: (value: string, { currency_code }: FormState) =>
					// 	currency_code === undefined ? t("Choose currency") : undefined,
				},
				{
					name: "period",
					label: t("Period"),
					type: "DateRangePicker",
					fromPlaceholder: t("From"),
					toPlaceholder: t("To"),
					dateFormat: `DD.MM.YYYY`,
					blockPredicate: notLaterThanToday,
					withTime: true,
					isRequired: true,
					validation: validatePeriod,
					customStyles: () => ({
						top: "139px",
						right: "36px",
					}),
				},
				{ name: "utc_0", label: t("Timezone UTC+00:00"), type: "Toggler" },
			],
		},
		{
			type: "BottomSection",
			elements: [
				// {
				// 	name: "file_format",
				// 	label: t("Report format"),
				// 	type: "Switcher",
				// 	isRequired: true,
				// 	options: [
				// 		{ value: "xlsx", label: "xlsx" },
				// 		{ value: "csv", label: "csv" },
				// 	],
				// 	className: styles.formatSwitcher,
				// },
				{
					type: "SubmitButton",
					name: "submitButton",
					label: t("Create"),
					isLoading: createTotalReportAPI.isLoading,
					onSubmit: (formState: FormState) => {
						createTotalReportAPI.create(prepareFormData(formState));
					},
				},
			],
		},
	];

	const initialState: Partial<FormState> = {
		file_format: "xlsx",
		utc_0: true,
	};

	const customElements = {
		BaseSettingsSection: (props) => (
			<PayKitForm.Group {...props} render={(children) => <div className={styles.baseSettings}>{children}</div>} />
		),
		BottomSection: (props) => (
			<PayKitForm.Group {...props} render={(children) => <div className={styles.actions}>{children}</div>} />
		),
	};

	return (
		<div className={styles.form}>
			<PayKitForm.Builder schema={schema} customElements={customElements} initialState={initialState} />
		</div>
	);
};
