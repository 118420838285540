import { t } from 'pay-kit';

export const sliceWallets = <T>(
	list: ReadonlyArray<T>,
	showAll: boolean,
	range: number,
) => {
	const buttonText = showAll ? t('Hide') : t(`Show all`);

	if (showAll) {
		return { wallets: list, buttonText };
	}

	return { wallets: list ? list.slice(0, range) : [], buttonText };
};
