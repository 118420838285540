import API from 'api';
import { CurrencyType } from 'api/stockpilingsGroup';
import { useEffect, useState } from 'react';
import { errorsMap } from 'utils/enums';

const useCurrencyList = () => {
	const [list, setList] = useState<readonly CurrencyType[] | null>(null);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState<null | Error>(null);

	useEffect(() => {
		setIsLoading(true);
		setError(null);

		API.stockpiling
			.getCurrencyList()
			.then((res) => {
				if (res.status === 'success' && res?.data.currencies) {
					setList(res.data.currencies);
				} else {
					throw new Error(errorsMap.anyResponse);
				}
			})
			.catch((err) => {
				setError(err);
			})
			.finally(() => setIsLoading(false));
	}, []);

	return {
		list,
		isLoading,
		error,
	};
};

export default useCurrencyList;
