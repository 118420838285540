import API from 'api';
import { CreateImageParamsType } from 'api/walletTypesGroup';
import { useCallback, useState } from 'react';
import { errorsMap } from 'utils/enums';

const useCreateImage = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState<null | Error>(null);

	const create: CreateType = useCallback(
		(data: CreateImageParamsType, onSuccess, onError) => {
			setIsLoading(true);
			setError(null);

			API.walletType
				.createImage(data)
				.then((res) => {
					if (res.status === 'success') {
						onSuccess && onSuccess();
					} else {
						// throw new Error("Unexpected response in useGetPluginSettings");
						throw new Error(errorsMap.anyResponse);
					}

					return res;
				})
				.catch((err) => {
					setError(err);
				})
				.finally(() => setIsLoading(false));
		},
		[],
	);

	return {
		create,
		isLoading,
		error,
	};
};

export default useCreateImage;

type CreateType = (
	data: CreateImageParamsType,
	onSuccess?: () => void,
	onError?: () => void,
) => void;
