/* eslint-disable react/prop-types */
import { useOutsideClick } from '@paykassma/pay-kit';
import CancelSVG from 'assets/icons/cancelIcon.svg';
import ConfirmSVG from 'assets/icons/confirmIcon.svg';
import EditSVG from 'assets/icons/edit_24x24.svg';
import TrashSVG from 'assets/icons/trash.svg';
import { useRef, useState } from 'react';

import useAddCommissionHook from '../../../../hooks/useAddCommission';
import useEditCommissionHook from '../../../../hooks/useEditCommission';
import useRemoveCommissionHook from '../../../../hooks/useRemoveCommission';
import ConfirmationDialog from '../../../ConfirmationDialog';
import styles from '../PercentEditor.module.scss';

type PercentRowPropsType = {
	readonly children: React.ReactElement;
	readonly props: any;
	readonly tariffId: number;
	readonly onSuccess: () => void;
};

const PercentRow: React.FC<PercentRowPropsType> = ({
	children,
	props,
	tariffId,
	onSuccess,
}) => {
	const editCommissionAPI = useEditCommissionHook();
	const removeCommissionAPI = useRemoveCommissionHook();
	const addCommissionAPI = useAddCommissionHook();
	const commissionEl = useRef(null);
	const [editConfirmationIsVisible, setEditConfirmationVisibility] =
		useState<boolean>(false);
	const [removeConfirmationIsVisible, setRemoveConfirmationVisibility] =
		useState<boolean>(false);

	// reset edit mode on loosing focus
	useOutsideClick(commissionEl, (e) => {
		if (props.formDataAPI.formState?.rowPathInEditMode === props.path) {
			props.formDataAPI.setValue('rowPathInEditMode', undefined);
		}
	});

	const fieldInEditMode =
		props.formDataAPI.formState?.rowPathInEditMode === props.path;

	const value = props.formDataAPI.getValue(props.path);

	const hasErrors = Object.keys(props.formDataAPI.errors).length > 0;

	const submitHandler = () => {
		if (hasErrors) {
			props.formDataAPI.showAllErrors();
		} else {
			if (value.id === undefined) {
				addCommissionAPI.add(
					{ ...value, tariff_id: tariffId },
					{
						onSuccess: () => {
							onSuccess();
							props.formDataAPI.setValue('rowPathInEditMode', undefined);
						},
						onError: () => {
							setEditConfirmationVisibility(false);
							props.formDataAPI.resetState();
						},
					},
				);
			} else {
				editCommissionAPI.edit(
					value.id,
					{ ...value, tariff_id: tariffId },
					{
						onSuccess: () => {
							onSuccess();
							props.formDataAPI.setValue('rowPathInEditMode', undefined);
						},
						onError: () => {
							setEditConfirmationVisibility(false);
							props.formDataAPI.resetState();
						},
					},
				);
			}
		}
	};

	const removeHandler = (id?: number) => {
		if (id === undefined) {
			props.removeCurrentItem();
		} else {
			removeCommissionAPI.remove(id, {
				onSuccess: () => {
					props.removeCurrentItem();
					setRemoveConfirmationVisibility(false);
				},
				onError: () => setRemoveConfirmationVisibility(false),
			});
		}
	};

	return (
		<div className={styles.commissionRow} ref={commissionEl}>
			{children}
			<div className={styles.actions} data-disabled={false}>
				{fieldInEditMode ? (
					<>
						<button
							className={styles.confirm}
							data-test-id='confirmButton'
							onClick={() =>
								!hasErrors
									? setEditConfirmationVisibility(true)
									: props.formDataAPI.showAllErrors()
							}
						>
							<ConfirmSVG />
						</button>
						<button
							className={styles.cancel}
							onClick={() =>
								props.formDataAPI.setValue('rowPathInEditMode', undefined)
							}
							data-test-id='cancelButton'
						>
							<CancelSVG />
						</button>
					</>
				) : (
					<>
						<button
							data-test-id='editButton'
							onClick={() =>
								props.formDataAPI.setValue('rowPathInEditMode', props.path)
							}
						>
							<EditSVG />
						</button>
						<button
							data-test-id='deleteButton'
							onClick={() =>
								value.id === undefined
									? removeHandler(value.id)
									: setRemoveConfirmationVisibility(true)
							}
						>
							<TrashSVG />
						</button>
					</>
				)}
			</div>
			<ConfirmationDialog
				isVisible={editConfirmationIsVisible}
				type='edit'
				isLoading={editCommissionAPI.isLoading || addCommissionAPI.isLoading}
				onConfirm={submitHandler}
				onCancel={() => setEditConfirmationVisibility(false)}
			/>
			<ConfirmationDialog
				isVisible={removeConfirmationIsVisible}
				type='remove'
				isLoading={removeCommissionAPI.isLoading}
				onConfirm={() =>
					removeHandler(props.formDataAPI.getValue(`${props.path}.id`))
				}
				onCancel={() => setRemoveConfirmationVisibility(false)}
			/>
		</div>
	);
};

export default PercentRow;
