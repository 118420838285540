import Filter from 'components/Filter';
import { deepClone } from 'helpers/deepClone';
import ReloadButton from 'modules/Postbacks/components/ReloadButton';
import useOperationHistoryTableColumns from 'modules/TransferHistory/components/OperationHistory/components/Columns';
import { useOperationHistoryTableContext } from 'modules/TransferHistory/contexts/OperationHistoryTableContext';
import { useTranslation } from 'pay-kit';
import { Table } from 'pay-kit';
import { useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import styles from './operationHistory.module.scss';

const OperationHistoryTab = () => {
	const { ps, id } = useParams();
	const navigate = useNavigate();
	const operationHistoryTableColumns = useOperationHistoryTableColumns();
	const operationHistoryTableContextAPI = useOperationHistoryTableContext();

	const { t } = useTranslation();

	useEffect(() => {
		operationHistoryTableContextAPI.onLoadOperationHistory();
	}, [
		operationHistoryTableContextAPI.onLoadOperationHistory,
		operationHistoryTableContextAPI.filter,
		id,
		operationHistoryTableContextAPI.currentPage,
		operationHistoryTableContextAPI.limit,
	]);

	const onSetLimit: OnSetLimitType = (limit) => {
		operationHistoryTableContextAPI.setCurrentPage(1);
		operationHistoryTableContextAPI.setLimit(limit);
	};

	const psColumns = useMemo(() => deepClone(operationHistoryTableColumns), []);

	psColumns[1].title = (
		<span>
			{/* Сумма списания/зачисления • <br /> В валюте */}
			{t('Amount ')} • <br /> {t('Currency')}
		</span>
	);

	return (
		<div>
			<Filter
				classNames={{
					wrapper: styles.filterWrapper,
				}}
				values={operationHistoryTableContextAPI.filter}
				fields={[
					{
						name: 'direction',
						placeholder: t('Direction'),
						className: '',
						component: 'select',
						options: [
							{
								value: '',
								label: t('All'),
							},
							{
								value: `outgoing`,
								// label: `Исходящие`,
								label: t('Outgoing'),
							},
							{
								value: `ingoing`,
								// label: `Входящие`,
								label: t('Ingoing'),
							},
						],
					},
					{
						name: 'operation_type',
						placeholder: t('Operation type'),
						className: '',
						component: 'select',
						hide: !operationHistoryTableContextAPI.PSBalance?.is_settlement,
						options: [
							{
								value: '1',
								label: t('Account balance'),
							},
							{
								value: `2`,
								label: t('External account'),
							},
						],
					},
					{
						name: 'date',
						component: 'dateRange',
						// toPlaceholder: "Дата до",
						toPlaceholder: t('Date to'),
						// fromPlaceholder: "Дата от",
						fromPlaceholder: t('Date from'),
						dateFormat: 'DD.MM.YYYY',
						withTime: true,
						blockPredicate: (date) => date.getTime() >= new Date().getTime(),
					},
				]}
				onApply={(values) => {
					operationHistoryTableContextAPI.setFilter(values);
					navigate(`/operation-history/${ps}/${id}`, { replace: true }); // clearing the rout of the Accounts' Balances filters' params
				}}
			/>

			<Table
				className={styles.tableWrapper}
				// TODO: There is no unique field for rowKey
				rowKey={'id'}
				data={operationHistoryTableContextAPI.data?.transactions || []}
				columns={operationHistoryTableColumns}
				skeleton={{ rowsCount: operationHistoryTableContextAPI.limit }}
				paginator={
					operationHistoryTableContextAPI.paginationInfo
						? {
								currentPage: operationHistoryTableContextAPI.currentPage || 1,
								setCurrentPage: operationHistoryTableContextAPI.setCurrentPage,
								limit: operationHistoryTableContextAPI.limit,
								setLimit: onSetLimit,
								lastAvailablePage:
									Math.ceil(
										operationHistoryTableContextAPI.paginationInfo.total /
											operationHistoryTableContextAPI.limit,
									) || 1,
								prefixElement: (
									<ReloadButton
										data-test-id=''
										isLoading={
											operationHistoryTableContextAPI.isGetOperationHistoryLoading
										}
										onClick={
											operationHistoryTableContextAPI.onLoadOperationHistory
										}
									/>
								),
							}
						: undefined
				}
				isLoading={
					operationHistoryTableContextAPI.isGetOperationHistoryLoading ||
					!operationHistoryTableContextAPI.data?.transactions?.length
				}
			/>
		</div>
	);
};

export default OperationHistoryTab;

type OnSetLimitType = (limit: number) => void;
