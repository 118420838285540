import API from 'api';
import { ICurrentTransaction } from 'api/transactionGroup';
import { WalletItem, WalletTypeType } from 'api/walletGroup';
import Money from 'components/Money';
import PaymentSystemLogo from 'components/PaymentSystemLogo';
import TextWithCopyButton from 'components/TextWithCopyButton';
import { useTranslation } from 'pay-kit';
import { Button, Loader, ScrollBox, Tooltip } from 'pay-kit';
import React, { useEffect, useState } from 'react';
import { errorsMap } from 'utils/enums';

import ActivatePayTM from './ActivatePayTM';
import TransactionIcon from './icons/transaction.svg';
import styles from './Wallet.module.scss';

interface IWalletProps {
	readonly wallet_hash_id: ICurrentTransaction['wallet_hash_id'];
	readonly showAllTransactionsForWallet?: (
		wallet_hash_id: ICurrentTransaction['wallet_hash_id'],
	) => void;
}

export const Wallet: React.FC<IWalletProps> = ({
	wallet_hash_id,
	showAllTransactionsForWallet,
}) => {
	const { t } = useTranslation();
	const [walletData, setWalletData] = useState<WalletItem | {}>({});
	const [isLoading, setIsLoading] = useState<boolean>(false);

	useEffect(() => {
		if (wallet_hash_id) {
			getWalletData();
		}
	}, [wallet_hash_id]);

	const getWalletData = () => {
		setIsLoading(true);

		API.wallet
			.getWallet(wallet_hash_id)
			.then((res) => {
				if (res?.status === 'success') {
					setWalletData(res.data);
					setIsLoading(false);

					return;
				} else {
					throw new Error(errorsMap.anyResponse);
				}
			})
			.catch((err) => {
				setIsLoading(false);

				window.pushAlert({
					description: t('Failed to get wallet data'),
					type: 'error',
				});
			});
	};

	if (isLoading) {
		return (
			<div className={styles.Wallet}>
				<Loader />
			</div>
		);
	}

	if (!walletData || wallet_hash_id === null) {
		return <div className={styles.noWalletData}>{t('No wallet data')}</div>;
	}

	const ROWS_MAP = [
		{
			id: 'wallet_type',
			title: t('Wallet type'),
			render: ({ wallet_type }: WalletTypeType) => (
				<PaymentSystemLogo id={wallet_type} hideLabel={true} />
			),
		},
		{
			id: 'name',
			title: t('Name'),
			render: ({ wallet_type }: WalletTypeType) => (
				<PaymentSystemLogo id={wallet_type} hideLogo={true} />
			),
		},
		{
			id: 'id',
			title: t('Hash ID'),
			render: ({ hash_id }: WalletTypeType) => (
				<TextWithCopyButton
					className={styles.textWithCopyButton}
					text={hash_id}
					data-test-id='id'
				/>
			),
		},
		{
			id: 'identifier',
			title: t('Wallet number'),
			render: ({ identifier }: WalletTypeType) => (
				<TextWithCopyButton
					className={styles.textWithCopyButton}
					text={identifier}
					data-test-id='identifier'
				/>
			),
		},
		{
			id: 'balance',
			title: t('Balance'),
			render: ({ balance, currency }: WalletTypeType) => {
				if (balance !== null && currency && balance !== undefined) {
					return (
						<Money
							className={styles.balance}
							amount={balance}
							currencyCode={currency}
						/>
					);
				}

				return null;
			},
		},
		{
			id: 'activity_log',
			title: t('Update'),
			render: ({ activity_log }: WalletTypeType) => (
				<>
					<Tooltip
						preferredSide='bottom'
						tip={
							<>
								{activity_log?.last_ingoing && (
									<div>
										{t('Last money receiving')}: {activity_log?.last_ingoing}
									</div>
								)}
								{activity_log?.last_outgoing && (
									<div>
										{t('Last money withdrawal')}: {activity_log?.last_outgoing}
									</div>
								)}
								{activity_log?.last_parsing && (
									<div>
										{t('Last parsing')}: {activity_log?.last_parsing}
									</div>
								)}
								{activity_log?.data?.updated_at && (
									<div>
										{t('Last update')}: {activity_log?.data?.updated_at}
									</div>
								)}
							</>
						}
					>
						<div>{activity_log?.data?.updated_at}</div>
					</Tooltip>
				</>
			),
		},
		{
			id: 'comment',
			title: t('Comment'),
			render: ({ comment }: WalletTypeType) =>
				comment ? <>{comment}</> : <>&mdash;</>,
		},
	];

	return (
		<div className={styles.Wallet}>
			<ScrollBox>
				{ROWS_MAP.map((row) => {
					const value = walletData[row.id as keyof WalletTypeType];

					return (
						<div key={`${row.id}_${row.title}`} className={styles.row}>
							<div className={styles.title}>{row.title}</div>
							<div className={styles.value}>
								{row.render ? row.render(walletData) : `${value}`}
							</div>
						</div>
					);
				})}
			</ScrollBox>

			<div className={styles.actions}>
				{walletData.type === 'paytm' && (
					<ActivatePayTM identifier={walletData.identifier} />
				)}
				{showAllTransactionsForWallet && (
					<Button
						data-test-id='I50B-DmZilNtFL91rMhNp'
						onClick={() => showAllTransactionsForWallet(wallet_hash_id)}
						variant='primary'
					>
						<TransactionIcon />
						&nbsp; {t('All transactions for wallet')}
					</Button>
				)}
			</div>
		</div>
	);
};
