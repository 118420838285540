import { Tooltip } from 'pay-kit';

import QuestionIconSVG from './question_icon.svg';

type HelpTipPropsType = {
	readonly tip: React.ReactNode;
};

const HelpTip: React.FC<HelpTipPropsType> = ({ tip }) => {
	return (
		<Tooltip tip={tip} preferredSide='top'>
			<QuestionIconSVG />
		</Tooltip>
	);
};

export default HelpTip;
