import { useState } from 'react';
import { useTranslation } from '@paykassma/pay-kit';

import styles from './CurrenciesList.module.scss';
import IconSVG from './icon.svg';

interface ICurrenciesList {
	readonly list: readonly string[];
	readonly dataTestId?: string;
}

const CurrenciesList: React.FC<ICurrenciesList> = ({ list, dataTestId }) => {
	const [isExpanded, setExpanded] = useState<boolean>(false);
	const { t } = useTranslation();

	const listClassNames = [styles.currenciesList];

	if (isExpanded) {
		listClassNames.push(styles.expanded);
	}

	return (
		<div className={listClassNames.join(' ')}>
			<div className={styles.list} data-testid={dataTestId}>
				{list.join(', ')}
			</div>
			{list.length > 7 && (
				<button
					onClick={() => setExpanded((prevState) => !prevState)}
					className={styles.expand}
					data-test-id='expand-currencies-list'
				>
					<IconSVG />
					{isExpanded ? t('Collapse') : t('Show all')}
				</button>
			)}
		</div>
	);
};

export default CurrenciesList;
