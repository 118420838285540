import { useDashboardContext } from 'contexts/DashboardContext';
import Chart from 'modules/Dashboard/components/Chart';
import TransactionMetrics from 'modules/Dashboard/components/TransactionMetrics';
import { useTranslation } from 'pay-kit';
import { Tabs } from 'pay-kit';
import { useContext, useState } from 'react';
import AuthContext, { Roles } from 'contexts/AuthContext';

import styles from './dashboardTransactionsMetrics.module.scss';

const DashboardTransactionsMetrics = () => {
	const {
		sumOfActivatedTransactions,
		sumOfActivatedTransactionsToDay,
		activatedTransactionsCount,
		activatedTransactionsCountToDay,
		sumOfTransactionsCommission,
		sumOfTransactionsCommissionToDay,
		sumOfCompletedTransactions,
		sumOfCompletedTransactionsCommission,
		completedTransactionsCount,
		transactionRestMetrics,
	} = useDashboardContext();
	const [value, setValue] = useState<string | number>(1);
	const { t } = useTranslation();
	const { hasRole } = useContext(AuthContext);

	const startDate = transactionRestMetrics?.data.count[0].date.replace(
		/(\d+)-(\d+)-(\d+)/,
		'$3.$2.$1',
	);
	const endDate = transactionRestMetrics?.data.count[
		transactionRestMetrics?.data.count.length - 1
	].date.replace(/(\d+)-(\d+)-(\d+)/, '$3.$2.$1');
	const tabs = [
		{ value: 1, label: `${startDate} – ${endDate}` },
		{ value: 2, label: t(`Today`) },
	];

	const transactions =
		sumOfActivatedTransactions?.sum_of_activated_transactions || {};

	const transactionsWithdrawals =
		sumOfActivatedTransactions?.sum_of_withdrawals || {};

	const transactionsToDay =
		sumOfActivatedTransactionsToDay?.sum_of_activated_transactions_today || {};
	const transactionsWithdrawalsToDay =
		sumOfActivatedTransactionsToDay?.sum_of_withdrawals_for_today || {};

	const transactionsCommission =
		sumOfTransactionsCommission?.sum_of_transactions_commission || {};
	const withdrawalsCommission =
		sumOfTransactionsCommission?.sum_of_withdrawals_commission || {};
	const settlementCommission =
		sumOfTransactionsCommission?.sum_of_settlement_commission || {};

	const transactionsCommissionToDay =
		sumOfTransactionsCommissionToDay?.sum_of_transactions_commission_today ||
		{};
	const withdrawalsCommissionToDay =
		sumOfTransactionsCommissionToDay?.sum_of_withdrawals_commission_today || {};
	const settlementCommissionToDay =
		sumOfTransactionsCommissionToDay?.sum_of_settlement_commission_today || {};

	const completedTransactions =
		sumOfCompletedTransactions?.sum_of_completed_transactions || {};
	const completedTransactionsToday =
		sumOfCompletedTransactions?.sum_of_completed_transactions_today || {};

	const completedTransactionsCommission =
		sumOfCompletedTransactionsCommission?.sum_of_completed_transactions_commission ||
		{};
	const completedTransactionsCommissionToday =
		sumOfCompletedTransactionsCommission?.sum_of_completed_transactions_commission_today ||
		{};

	const transactionsMetrics: readonly MetricsSchemaType[] = [
		{
			header: t('Sum of activated transactions'),
			metrics: [
				{
					type: t(`Incoming`),
					currenciefy: true,
					amount:
						value === 1
							? Object.values(transactions)
							: Object.values(transactionsToDay),
					currency:
						value === 1
							? Object.keys(transactions)
							: Object.keys(transactionsToDay),
					className: styles.transactions,
					dataTestId: 'sum_of_activated_transactions',
				},
				{
					type: t(`Outgoing`),
					currenciefy: true,
					amount:
						value === 1
							? Object.values(transactionsWithdrawals)
							: Object.values(transactionsWithdrawalsToDay),
					currency:
						value === 1
							? Object.keys(transactionsWithdrawals)
							: Object.keys(transactionsWithdrawalsToDay),
					className: styles.transactionsWithdrawals,
					dataTestId: 'sum_of_withdrawals',
				},
			],
		},
		{
			header: t(`Incoming with completed stockpilings`),
			className: styles.completedTransactions,
			hide:
				!Object.entries(completedTransactions).length &&
				!Object.entries(completedTransactionsToday).length,
			metrics: [
				{
					currenciefy: true,
					amount:
						value === 1
							? Object.values(completedTransactions)
							: Object.values(completedTransactionsToday),
					currency:
						value === 1
							? Object.keys(completedTransactions)
							: Object.keys(completedTransactionsToday),
					dataTestId: 'sum_of_completed_transactions',
				},
			],
		},
	];

	const transactionsCommissionMetrics: readonly MetricsSchemaType[] = [
		{
			header: t('Sum of commission'),
			metrics: [
				{
					type: t(`Incoming`),
					currenciefy: true,
					amount:
						value === 1
							? Object.values(transactionsCommission)
							: Object.values(transactionsCommissionToDay),
					currency:
						value === 1
							? Object.keys(transactionsCommission)
							: Object.keys(transactionsCommissionToDay),
					className: styles.transactionsCommission,
					dataTestId: 'sum_of_transactions_commission',
				},
				{
					type: t(`Outgoing`),
					currenciefy: true,
					amount:
						value === 1
							? Object.values(withdrawalsCommission)
							: Object.values(withdrawalsCommissionToDay),
					currency:
						value === 1
							? Object.keys(withdrawalsCommission)
							: Object.keys(withdrawalsCommissionToDay),
					className: styles.withdrawalsCommission,
					dataTestId: 'sum_of_withdrawals_commission',
				},
				{
					type: t('Settlement'),
					currenciefy: true,
					amount:
						value === 1
							? Object.values(settlementCommission)
							: Object.values(settlementCommissionToDay),
					currency:
						value === 1
							? Object.keys(settlementCommission)
							: Object.keys(settlementCommissionToDay),
					dataTestId: 'sum_of_settlement_commission',
				},
			],
			hide: !hasRole(Roles.COMMISSION_VIEW),
		},
		{
			header: t(`Incoming with completed stockpilings`),
			className: styles.completedTransactions,
			hide:
				!completedTransactionsCommission.length &&
				!completedTransactionsCommissionToday.length,
			metrics: [
				{
					currenciefy: true,
					amount:
						value === 1
							? Object.values(completedTransactionsCommission)
							: Object.values(completedTransactionsCommissionToday),
					currency:
						value === 1
							? Object.keys(completedTransactionsCommission)
							: Object.keys(completedTransactionsCommissionToday),
					dataTestId: 'sum_of_completed_transactions_commission',
				},
			],
		},
	];

	const activatedTransactionsCountMetrics: readonly MetricsSchemaType[] = [
		{
			header: t('Number of activated transactions'),
			metrics: [
				{
					type: t(`Incoming`),
					amount:
						value === 1
							? activatedTransactionsCount?.activated_transactions_count
							: activatedTransactionsCountToDay?.activated_transactions_count_today,
					dataTestId: 'activated_transactions_count',
				},
				{
					type: t(`Outgoing`),
					amount:
						value === 1
							? activatedTransactionsCount?.activated_withdrawals_count
							: activatedTransactionsCountToDay?.activated_withdrawals_count_today,
					dataTestId: 'activated_withdrawals_count',
				},
			],
		},
		{
			header: t(`Incoming with completed stockpilings`),
			className: styles.completedTransactions,
			hide: !completedTransactionsCount,
			metrics: [
				{
					amount:
						value === 1
							? completedTransactionsCount?.completed_transactions_count
							: completedTransactionsCount?.completed_transactions_count_today,
					dataTestId: 'completed_transactions_count',
				},
			],
		},
	];

	return (
		<div>
			<div className={styles.dashboardTransactionsMetrics}>
				<Tabs
					tabs={tabs}
					onChange={setValue}
					value={value}
					data-test-id='period-tabs'
				/>

				<div className={styles.summaryMetrics}>
					<TransactionMetrics metrics={transactionsMetrics} />

					<TransactionMetrics metrics={transactionsCommissionMetrics} />

					<TransactionMetrics metrics={activatedTransactionsCountMetrics} />
				</div>
			</div>

			<Chart />
		</div>
	);
};

export default DashboardTransactionsMetrics;

type MetricsType = {
	readonly type?: string;
	readonly amount?: number | readonly number[];
	readonly currency?: readonly string[];
	readonly currenciefy?: boolean;
	readonly className?: string;
	readonly dataTestId?: string;
};

export type MetricsSchemaType = {
	readonly header: string;
	readonly metrics: readonly MetricsType[];
	readonly className?: string;
	readonly hide?: boolean;
};
