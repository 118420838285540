/* eslint-disable ternary/no-unreachable */
import { Modal } from '@paykassma/pay-kit';
import { useTranslation } from 'pay-kit';
import React, { useState } from 'react';
import { fromLocaleDate } from 'utils/date';
import { errorsMap } from 'utils/enums';

import API from '/api/reportsGroup';

import TransactionsList, {
	TransactionsListFilledFormStateType,
} from '../../components/forms/TransactionsList';
import TransactionsSum, {
	TransactionsSumFilledFormStateType,
} from '../../components/forms/TransactionsSum';

import styles from './NewTask.module.scss';

type NewTaskPropsType = {
	readonly closeHandler: () => void;
	readonly refreshList: () => void;
	readonly isOpen: boolean;
};

const NewTask: React.FC<NewTaskPropsType> = ({
	closeHandler,
	refreshList,
	isOpen,
}) => {
	const { t } = useTranslation();
	const [activeFormId, setActiveFormId] = useState('transactions_sum');
	const [isLoading, setIsLoading] = useState(false);

	const sendTransactionListRequest = (
		formData: TransactionsListFilledFormStateType,
	) => {
		setIsLoading(true);

		API.createAutoReport({
			report_type: 'transactions_list',
			filters: {
				wallet_type: formData.wallet_type,
				wallet_hash_id: formData.wallet_hash_id,
				direction: formData.direction,
				exchanger_identifier: formData.exchanger_identifier,
				label: formData.label,
				creation_type: formData.creation_type,
				originality: formData.originality,
				status: formData.status,
				type: formData.type,
				archiving_status: formData.archiving_status,
			},
			report_currency_code: formData.report_currency_code,
			fields: formData.fields,
			file_format: formData.file_format,
			utc_0: formData.utc_0,
			reporting_period: formData.reporting_period,
			emails: formData.emails.map(({ email }) => email),
			start: fromLocaleDate(formData.start),
		})
			.then((res) => {
				if (res.status === 'success') {
					closeHandler();
					refreshList();

					window.pushAlert({
						description: t(
							'The report auto-generation task has been successfully created',
						),
						type: 'success',
					});

					return res;
				} else {
					if (res.errors) {
						const plainErrors = Object.values(res.errors).join(', ');

						window.pushAlert({ description: plainErrors, type: 'error' });
					} else {
						throw new Error('Unexpected response in createAutoReport');
					}

					return res;
				}
			})
			.finally(() => {
				setIsLoading(false);
			})
			.catch((err) => {
				window.pushAlert({
					description: t(
						'An error occurred while creating a task for auto-generating a report',
					),
					type: 'error',
				});

				console.error(err);
			});
	};

	const sendTransactionSumRequest =
		(detailed: boolean) => (formData: TransactionsSumFilledFormStateType) => {
			setIsLoading(true);

			API.createAutoReport({
				report_type: detailed ? 'transactions_sum-detail' : 'transactions_sum',
				filters: {
					wallet_type: formData.wallet_type,
					wallet_hash_id: formData.wallet_hash_id,
					direction: formData.direction,
					exchanger_identifier: formData.exchanger_identifier,
					label: formData.label,
					creation_type: formData.creation_type,
					originality: formData.originality,
					status: formData.status,
					type: formData.type,
				},
				report_currency_code: formData.report_currency_code,
				fields: formData.commission
					? ['total_commission', 'deposit_commission', 'withdrawal_commission']
					: undefined,
				file_format: formData.file_format,
				utc_0: formData.utc_0,
				reporting_period: formData.reporting_period,
				emails: formData.emails.map(({ email }) => email),
				start: fromLocaleDate(formData.start),
			})
				.then((res) => {
					if (res.status === 'success') {
						closeHandler();
						refreshList();

						window.pushAlert({
							description: t(
								'The report auto-generation task has been successfully created',
							),
							type: 'success',
						});

					return res;
				} else {
					if (res.errors) {
						const plainErrors = Object.values(res.errors).join(", ");
						window.pushAlert({ description: plainErrors, type: "error" });
					} else {
						throw new Error(errorsMap.anyResponse);
					}

						return res;
					}
				})
				.finally(() => {
					setIsLoading(false);
				})
				.catch((err) => {
					window.pushAlert({
						description: t(
							'An error occurred while creating a task for auto-generating a report',
						),
						type: 'error',
					});

					console.error(err);
				});
		};

	const REPORT_TYPES = [
		{
			id: 'transactions_sum',
			title: t('Sum of transactions'),
			component: TransactionsSum,
			initialState: { file_format: 'xlsx', utc_0: true, emails: [] },
			request: sendTransactionSumRequest(false),
		},
		{
			id: 'transactions_list',
			title: t('Transaction list'),
			component: TransactionsList,
			request: sendTransactionListRequest,
			initialState: {
				file_format: 'xlsx',
				utc_0: true,
				emails: [],
				fields: [],
			},
		},
		{
			id: 'transactions_sum-detail',
			title: t('Sum of transactions with details by day'),
			component: TransactionsSum,
			initialState: { file_format: 'xlsx', utc_0: true, emails: [] },
			request: sendTransactionSumRequest(true),
		},
	];

	const ActiveForm = REPORT_TYPES.find((report) => report.id === activeFormId);

	return (
		<Modal
			isOpen={isOpen}
			title={t('Creating task for autogenerating')}
			onClose={closeHandler}
			className={styles.modal}
		>
			<div className={styles.newTaskWrapper}>
				<nav>
					{REPORT_TYPES.map((reportType) => {
						const isActive = activeFormId === reportType.id;

						return (
							<button
								className={isActive ? styles.activeButton : undefined}
								key={reportType.id}
								data-test-id={reportType.id}
								onClick={
									isLoading ? undefined : () => setActiveFormId(reportType.id)
								}
							>
								{reportType.title}
								{isActive && (
									<svg
										width='17'
										height='20'
										viewBox='0 0 17 20'
										fill='none'
										xmlns='http://www.w3.org/2000/svg'
									>
										<path
											d='M4.37114e-07 10L17 7.43094e-07L17 20L4.37114e-07 10Z'
											fill='#F2F3F5'
										/>
									</svg>
								)}
							</button>
						);
					})}
				</nav>
				<div className={styles.content}>
					{ActiveForm ? (
						<ActiveForm.component
							onSubmit={ActiveForm.request as any}
							isLoading={isLoading}
							initialState={ActiveForm.initialState as any}
						/>
					) : null}
				</div>
			</div>
		</Modal>
	);
};

export default NewTask;
