import { fetchApi } from './fetch';

const currencyGroup = {
	getList: () => fetchApi('/currency/list', undefined, 'GET'),
	updateToDefaultSorting: (params: UpdateToDefaultSortingType) =>
		fetchApi(
			`/currency/${params.currencyCode}`,
			{ is_default: params.is_default },
			'POST',
		),
};

export type UpdateToDefaultSortingType = {
	readonly currencyCode: string;
	readonly is_default: boolean;
};

export interface ICurrency {
	readonly id: number;
	readonly code: string;
	readonly symbol: string;
	readonly is_crypt: boolean;
	readonly rate: string;
	readonly symbol_position: 'left' | 'right';
	readonly payment_systems_user_sort_enabled: boolean;
	readonly name: string;
}

export default currencyGroup;
