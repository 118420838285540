import { useTranslation } from 'pay-kit';
import Filter from 'components/Filter';
import ReloadButton from 'modules/Postbacks/components/ReloadButton';
import { stockPilingsColumns } from 'modules/Stockpilings/components/Columns';
import StockPilingsListContextProvider, {
	useStockPilingsListContext,
} from 'modules/Stockpilings/contexts/StockPilingsListContext';
import { Table } from 'pay-kit';

import styles from './stockpilings.module.scss';

const Stockpilings = () => {
	const {
		stockPilings,
		isLoading,
		error,
		paginationInfo,
		loadStockPilings,
		limit,
		setLimit,
		filter,
		setFilter,
		setCurrentPage,
		page,
		formattedWalletsOption,
	} = useStockPilingsListContext();

	const { t } = useTranslation();

	return (
		<div className={styles.stockPilingWrapper}>
			<Filter
				values={filter}
				onApply={(values) => setFilter(values)}
				classNames={{ wrapper: styles.stockPilingsFilterWrapper }}
				fields={[
					{
						name: 'label',
						placeholder: t('Label'),
						component: 'textInput',
						className: styles.filtersField,
					},
					{
						name: 'payment_system',
						placeholder: t('Wallet type'),
						className: styles.filtersField,
						component: 'select',
						options: formattedWalletsOption,
					},
					{
						name: 'is_real',
						placeholder: t('Stockpiling type'),
						className: styles.filtersField,
						component: 'select',
						options: [
							{
								value: '',
								label: t('All'),
							},
							{
								value: '1',
								label: t('Real'),
							},
							{
								value: '0',
								// label: "Отладочное"
								label: t('Debug'),
							},
						],
					},
					{
						name: 'status',
						component: 'select',
						placeholder: t('Status'),
						className: styles.filtersField,
						options: [
							{
								value: '',
								label: t('All'),
							},
							{
								value: '0',
								label: t('Not completed'),
							},
							{
								value: '1',
								label: t('Completed'),
							},
						],
					},
					{
						name: 'date',
						component: 'dateRange',
						label: t('Date'),
						// toPlaceholder: "Дата до",
						toPlaceholder: t('Date to'),
						// fromPlaceholder: "Дата от",
						fromPlaceholder: t('Date from'),
						dateTimeFormat: 'DD.MM.YYYY',
						blockPredicate: (date) => date.getTime() >= new Date().getTime(),
						customStyles: () => ({
							right: 'unset',
							top: 'unset',
							transform: 'translateX(-200px)',
						}),
					},
				]}
			/>
			<div className={styles.stockpilingTableWrapper}>
				<Table
					className={styles.stockpilingTable}
					columns={stockPilingsColumns}
					data={stockPilings || []}
					rowKey='id'
					message={error ? { text: `${error}`, type: 'error' } : undefined}
					isLoading={isLoading}
					skeleton={{ rowsCount: limit }}
					paginator={{
						currentPage: paginationInfo?.current_page || page,
						setCurrentPage,
						limit,
						setLimit,
						lastAvailablePage: paginationInfo?.last_available_page || 1,
						prefixElement: (
							<ReloadButton
								data-test-id='PPy7FPwz5A06ApnarXH8_'
								isLoading={isLoading}
								onClick={loadStockPilings}
							/>
						),
						className: styles.paginator,
						bottomPaginatorProps: {
							className: styles.bottomPaginator,
						},
					}}
				/>
			</div>
		</div>
	);
};

export default () => (
	<StockPilingsListContextProvider>
		<Stockpilings />
	</StockPilingsListContextProvider>
);
