import { Tabs, t } from '@paykassma/pay-kit';
import { ICurrentTransaction } from 'api/transactionGroup';
import { ITransactionsFilterForm } from 'api/transactionGroup';
import RightModal from 'components/Modals/RightModal';
import AuthContext, { Roles } from 'contexts/AuthContext';
import React, { useContext, useMemo, useState } from 'react';

import styles from './DetailedView.module.scss';
import { CommonData, Postback, Wallet } from './Tabs';

interface IDetailedViewProps {
	readonly transactionData: ICurrentTransaction | null | undefined;
	readonly onClose: () => void;
	readonly onFilterApply?: (filterValues: ITransactionsFilterForm) => void;
}

const DetailedView: React.FC<IDetailedViewProps> = ({
	transactionData,
	onClose,
	onFilterApply,
}) => {
	const { hasRole } = useContext(AuthContext);

	const hasWalletAccess = hasRole(Roles.WALLET_LIST);

	const tabs = useMemo(() => {
		let result = [
			{
				value: 'common',
				label: t('Common info'),
			},
		];

		if (hasWalletAccess) {
			result.push({
				value: 'wallet',
				label: t('Wallet'),
			});
		}

		result = [
			...result,
			{
				value: 'postback',
				label: t('Postback'),
			},
		];

		return result;
	}, [hasWalletAccess]);

	const [activeTab, setActiveTab] = useState<string>('common');

	return (
		<RightModal
			bodyClassName={styles.DetailedViewWrapper}
			onShow={() => null}
			isVisible={transactionData !== null}
			onClose={onClose}
		>
			<Tabs
				tabs={tabs}
				value={activeTab}
				onChange={(value) => setActiveTab(value as string)}
				data-test-id='tabs'
			/>
			{transactionData && activeTab === 'common' && (
				<CommonData transactionData={transactionData} />
			)}
			{transactionData && activeTab === 'wallet' && (
				<Wallet
					wallet_hash_id={transactionData.wallet_hash_id}
					showAllTransactionsForWallet={(wallet_hash_id: string) =>
						onFilterApply && onFilterApply({ wallet_hash_id })
					}
				/>
			)}
			{transactionData && activeTab === 'postback' && (
				<Postback transactionData={transactionData} />
			)}
		</RightModal>
	);
};

export default DetailedView;
