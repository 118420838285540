import { useEffect, useRef, useState } from 'react';

export const useIntersectionObserver = <T>(params: {
	readonly list: readonly T[];
	readonly offset: number;
	readonly isObserverUnMounting?: boolean;
}) => {
	const [visible, setIsVisible] = useState(false);
	const [sliceEnd, setSliceEnd] = useState(0);
	const { offset = 10, isObserverUnMounting, list } = params;
	const targetRef = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		if (!isObserverUnMounting) {
			setSliceEnd(offset);
		}
	}, [list, isObserverUnMounting]);

	useEffect(() => {
		const options = {
			root: null,
			rootMargin: '0px',
			threshold: 0.5,
		};

		const observer = new IntersectionObserver((entries) => {
			const [entry] = entries;

			setIsVisible(entry.isIntersecting);
		}, options);

		if (targetRef.current) {
			observer.observe(targetRef.current as unknown as Element);
		}

		return () => {
			if (targetRef.current) {
				observer.unobserve(targetRef.current as unknown as Element);
			}
		};
	}, [targetRef.current, visible]);

	useEffect(() => {
		if (visible) {
			setSliceEnd((slicedEnd) => slicedEnd + offset);
		}
	}, [visible]);

	return { list: list ? list.slice(0, sliceEnd) : [], targetRef };
};
