import API from 'api';
import { getListFilterParamsType } from 'api/postbackGroup';
import {
	GetStockPilingsListParamsType,
	StockPilingsType,
	paginationInfoType,
} from 'api/stockpilingsGroup';
import { WalletTypesContext } from 'contexts/WalletTypesContext';
import {
	getFormattedFilterData,
	getFormattedWalletTypes,
} from 'modules/Stockpilings/contexts/StockPilingsListContext/helpers';
import { formStockPilingsList } from 'modules/Stockpilings/helpers';
import {
	Dispatch,
	ReactNode,
	createContext,
	useContext,
	useEffect,
	useState,
} from 'react';
import { errorsMap } from 'utils/enums';
import { rejectSettlement } from 'utils/filterSettlement';
import useURLFilters from 'utils/hooks/useURLFilters';
import useURLState from 'utils/hooks/useURLState';

export const StockPilingsListContext =
	createContext<StockPilingsListContextProps>(
		{} as StockPilingsListContextProps,
	);

const StockPilingsListContextProvider = ({
	children,
}: {
	readonly children: ReactNode;
}) => {
	const [page, setCurrentPage] = useURLState<number>('page', 1);
	const [limit, setLimit] = useURLState<number>('limit', 10);
	const [filter, setFilter] = useURLFilters({
		dateRangeFields: ['date'],
	});
	const [formattedWalletsOption, setFormattedWalletsOption] = useState<
		readonly FormattedWalletListType[]
	>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [stockPilings, setStockPilings] = useState<readonly StockPilingsType[]>(
		[],
	);
	const [error, setError] = useState<null | Error>(null);
	const [paginationInfo, setPaginationInfo] =
		useState<null | paginationInfoType>(null);
	const { walletTypes } = useContext(WalletTypesContext);

	const formattedFilterData = getFormattedFilterData(filter);
	const loadStockPilingsParams: GetStockPilingsListParamsType = {
		limit,
		page,
		...formattedFilterData,
	};

	useEffect(() => {
		const walletsOption = getFormattedWalletTypes(
			rejectSettlement(walletTypes),
		);

		setFormattedWalletsOption(walletsOption);
	}, [walletTypes.length]);

	const getCurrencyList = (stockPilingsList: readonly StockPilingsType[]) => {
		setIsLoading(true);
		setError(null);

		API.stockpiling
			.getCurrencyList()
			.then((res) => {
				if (res?.status === 'success' && res?.data.currencies) {
					const {
						data: { currencies },
					} = res;
					const stockPilings = formStockPilingsList(
						stockPilingsList,
						currencies,
					);

					setIsLoading(false);
					setStockPilings(stockPilings);
				}
			})
			.catch((err) => {
				setIsLoading(false);
				setError(err);
			});
	};

	const getStockPilingsList: GetStockPilingsListType = (params) => {
		setIsLoading(true);
		setError(null);
		setStockPilings([]);

		API.stockpiling
			.getStockPilingsList(params)
			.then((res) => {
				if (res?.status === 'ok' && res?.stockpiling_list?.data) {
					const { data, ...rest } = res.stockpiling_list;

					getCurrencyList(data);
					setPaginationInfo(rest);
				} else {
					// throw new Error("Invalid response");
					throw new Error(errorsMap.anyResponse);
				}
			})
			.catch((err) => {
				setIsLoading(false);
				setError(err);
			});
	};

	const loadStockPilings = () => getStockPilingsList(loadStockPilingsParams);

	useEffect(loadStockPilings, [limit, page, filter]);

	const data = {
		isLoading,
		stockPilings,
		error,
		paginationInfo,
		setCurrentPage,
		page,
		setLimit,
		limit,
		setFilter,
		filter,
		loadStockPilings,
		formattedWalletsOption,
	};

	return (
		<StockPilingsListContext.Provider value={data}>
			{children}
		</StockPilingsListContext.Provider>
	);
};

export default StockPilingsListContextProvider;

export const useStockPilingsListContext = () =>
	useContext(StockPilingsListContext);

export type FormattedWalletListType = {
	readonly value: string;
	readonly label: string;
};

type StockPilingsListContextProps = {
	readonly isLoading: boolean;
	readonly stockPilings: readonly StockPilingsType[] | null;
	readonly error: null | Error;
	readonly paginationInfo: null | paginationInfoType;
	readonly setCurrentPage: (value: number) => void;
	readonly page: number;
	readonly setLimit: (value: number) => void;
	readonly limit: number;
	readonly setFilter: Dispatch<getListFilterParamsType>;
	readonly filter: getListFilterParamsType;
	readonly loadStockPilings: () => void;
	readonly formattedWalletsOption: readonly FormattedWalletListType[];
};

export type GetStockPilingsListType = (
	params: GetStockPilingsListParamsType,
) => void;

export type SendPostbackType = (id: number) => void;
