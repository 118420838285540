const ArchiveIcon = () => {
	return (
		<svg
			width='16'
			height='16'
			viewBox='0 0 16 16'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M11.7707 4.22852C13.8533 6.31118 13.8533 9.68852 11.7707 11.7712C9.68802 13.8538 6.31068 13.8538 4.22802 11.7712C2.14535 9.68852 2.14535 6.31118 4.22802 4.22852'
				stroke='currentColor'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M7.99935 2.66699V8.00033'
				stroke='currentColor'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
};

export default ArchiveIcon;
