import AuthContext from 'contexts/AuthContext';
import { useContext, useEffect } from 'react';

export default function LogoutPage() {
	const { logout } = useContext(AuthContext);

	useEffect(() => {
		logout();
	}, []);

	return null;
}
