import { useTranslation } from 'pay-kit';
import Close from 'assets/icons/close.svg';
import CheckMark from 'assets/icons/simpleCheckMark.svg';
import { useOperationHistoryContext } from 'modules/TransferHistory/contexts/OperationHistoryContext';
import { FC } from 'react';

import styles from './actions.module.scss';

const Actions: FC<{ readonly status: number; readonly id: number }> = ({
	status,
	id,
}) => {
	const { t } = useTranslation();
	const { rejectApproveNewWithdrawal, isLoading } =
		useOperationHistoryContext();

	return (
		<>
			{status === 0 && (
				<div>
					<button
						data-test-id='vGy4Jxsy4f_WfpdTv9-EO'
						type='button'
						className={styles.accept}
						onClick={() => rejectApproveNewWithdrawal(id, 'approve')}
						disabled={isLoading}
					>
						<span className={styles.checkMark}>
							<CheckMark />
						</span>{' '}
						{t('Approve')}{' '}
					</button>
					<button
						type='button'
						data-test-id='vGy4Jxsy4f_WfpdTv9-EpO'
						className={styles.decline}
						onClick={() => rejectApproveNewWithdrawal(id, 'reject')}
					>
						<span className={styles.checkMark}>
							<Close />
						</span>{' '}
						{t('Reject')}{' '}
					</button>
				</div>
			)}
		</>
	);
};

export default Actions;
