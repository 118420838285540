import { useState } from 'react';
import { AutoReportListItemType } from 'api/reportsGroup';

import styles from './EmailsList.module.scss';
import IconSVG from './icon.svg';
import { useTranslation } from "@paykassma/pay-kit";

interface IEmailsList {
	readonly emails: AutoReportListItemType['emails'];
	readonly dataTestId?: string;
}

const EmailsList: React.FC<IEmailsList> = ({ emails, dataTestId }) => {
	const [isExpanded, setExpanded] = useState<boolean>(false);
	const { t } = useTranslation();

	const listClassNames = [styles.emailsList];

	if (isExpanded) {
		listClassNames.push(styles.expanded);
	}

	if (!Boolean(emails)) {
		return null;
	}

	return (
		<div className={listClassNames.join(' ')}>
			<div className={styles.list} data-test-id={dataTestId}>
				{emails.join(', ')}
			</div>
			{emails.length > 1 && (
				<button
					onClick={() => setExpanded((prevState) => !prevState)}
					className={styles.expand}
					data-test-id="expand-emails-list"
				>
					<IconSVG />
					{isExpanded ? t('Collapse') : t('Show all')}
				</button>
			)}
		</div>
	);
};

export default EmailsList;
