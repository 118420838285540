import AcceptedPostbacks from 'modules/Postbacks/AcceptedPostbacks';
import { useEffect } from 'react';

export default function AcceptedPostbacksPage() {
	useEffect(() => {
		document.title = 'Delivered postbacks';
	}, []);

	return <AcceptedPostbacks />;
}
