import { fetchOldApi } from 'api/fetch';

const walletsDebuggingGroup = {
	loadLogsCategoryList: () =>
		fetchOldApi<LoadLogsCategoryList>('/log/categories'),
	loadLogList: (params: string) =>
		fetchOldApi<LoadLogListType>(`log/list?${params}`),
	loadWithdrawalsLogList: (params: string) =>
		fetchOldApi<LoadWithdrawalsLogListType>(`log/withdrawals?${params}`),
};

export default walletsDebuggingGroup;

export type WithdrawalsLogType = {
	readonly created_at: string;
	readonly id: number;
	readonly message: string;
	readonly wallet_type: string;
	readonly withdrawal_id: number;
};

export type LoadWithdrawalsLogListType = {
	readonly data: {
		readonly data: readonly WithdrawalsLogType[];
		readonly current_page: number;
		readonly from: number;
		readonly last_available_page: number;
		readonly per_page: number;
		readonly to: number;
	};
};

export type LogsCategory = {
	readonly text: string;
	readonly value: string;
};

export type LoadLogsCategoryList = {
	readonly categories: readonly LogsCategory[];
};

export type LogListData = {
	readonly category: string;
	readonly created_at: string;
	readonly error_type: string;
	readonly id: number;
	readonly message: string;
	readonly wallet_hash_id: string;
	readonly wallet_identifier: string;
	readonly wallet_type: string;
};

export type LoadLogListType = {
	readonly logs: {
		readonly current_page: number;
		readonly data: readonly LogListData[];
		readonly from: number;
		readonly last_available_page: number;
		readonly per_page: number;
		readonly to: number;
	};
};
