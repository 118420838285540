/* eslint-disable promise/catch-or-return */
/* eslint-disable promise/always-return */
import Api from 'api';
import { paginationInfoType } from 'api/stockpilingsGroup';
import { PaginationData } from 'api/types';
import { WalletListItem, WalletType } from 'api/walletGroup';
import { Roles, useHasAccessHook } from 'contexts/AuthContext';
import { useEffect, useState } from 'react';
import { errorsMap } from 'utils/enums';

const useGetWallets = (initFetch = true) => {
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState<Error | null>(null);
	const [list, setList] = useState<readonly WalletListItem[] | null>([]);
	const [paginationInfo, setPaginationInfo] = useState<PaginationData | null>(
		null,
	);
	const hasAccessToFetchWallets = useHasAccessHook({ rule: Roles.WALLET_LIST });

	const fetchWallets = (params = {}) => {
		setIsLoading(true);
		setError(null);

		Api.wallet
			.getList(params)
			.then((res) => {
				if (res.status === 'success') {
					setList(res.data);
					setPaginationInfo(res.paginate);
				} else {
					// throw new Error("Unexpected response in getWallets!");
					throw new Error(errorsMap.anyResponse);
				}
			})
			.catch((error) => {
				console.log(error);
				setIsLoading(false);
				setError(error);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	useEffect(() => {
		if (!hasAccessToFetchWallets) {
			return;
		}

		if (initFetch) {
			fetchWallets();
		}
	}, [hasAccessToFetchWallets]);

	return {
		fetchWallets,
		list,
		paginationInfo,
		isLoading,
		error,
	};
};

export default useGetWallets;
