import { useTranslation } from 'pay-kit';
import PaymentsSystemsList from 'modules/PaymentSystems/PaymentSystemsList';
import { useEffect } from 'react';

export default function PaymentsSystemsListPage() {
	const { t } = useTranslation();

	useEffect(() => {
		document.title = t('Constructor');
	}, []);

	return <PaymentsSystemsList />;
}
