import {
	Dispatch,
	ReactNode,
	createContext,
	useContext,
	useState,
} from 'react';

const LogsListContext = createContext({} as LogsListContextType);
const LogsListContextProvider = ({
	children,
}: {
	readonly children: ReactNode;
}) => {
	const [walletHashId, setWalletHashId] = useState<string>();
	const [id, setID] = useState<number>();

	const contextValue = {
		setWalletHashId,
		walletHashId,
		setID,
		id,
	};

	return (
		<LogsListContext.Provider value={contextValue}>
			{children}
		</LogsListContext.Provider>
	);
};

export default LogsListContextProvider;

export const useLogsListContext = () => useContext(LogsListContext);

export type LogsListContextType = {
	readonly setWalletHashId: Dispatch<string | undefined>;
	readonly walletHashId?: string;
	readonly setID: Dispatch<number | undefined>;
	readonly id?: number;
};
