import API from 'api';
import {
	AccountsBalanceDateType,
	GetAccountsBalanceHistoryType,
} from 'api/balanceGroup';
import { Error } from 'api/types';
import { hideZeroBalances } from 'contexts/AccountsBalanceContext/helpers';
import {
	Dispatch,
	ReactNode,
	createContext,
	useContext,
	useMemo,
	useState,
} from 'react';

export const AccountsBalancesContext =
	createContext<AccountsBalancesContextType>({} as AccountsBalancesContextType);

const AccountsBalancesContextProvider = ({
	children,
}: {
	readonly children: ReactNode;
}) => {
	const [error, setError] = useState<Error | null>(null);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [accountsBalances, setAccountsBalances] = useState<
		GetAccountsBalanceHistoryType[`balances`]
	>([]);
	const [hideZeroBalance, setHideZeroBalance] = useState<boolean>(true);

	const filteredBalances = hideZeroBalances(accountsBalances, hideZeroBalance);

	const getAccountsBalanceHistory = () => {
		setIsLoading(true);
		setError(null);

		API.balances
			.getAccountsBalanceHistory()
			.then((res) => {
				if (res.status === 'ok') {
					setAccountsBalances(res.balances);
				}
			})
			.catch((err) => {
				console.log(err);
				setError(err);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const getAccountsBalanceHistoryByDate = (date: AccountsBalanceDateType) => {
		setIsLoading(true);
		setError(null);

		API.balances
			.getAccountsBalanceHistoryByDate(date)
			.then((res) => {
				if (res.status === 'ok') {
					setAccountsBalances(res.balances);
				}
			})
			.catch((err) => {
				console.log(err);
				setError(err);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const contextValue = {
		getAccountsBalanceHistory,
		getAccountsBalanceHistoryByDate,
		accountsBalances,
		filteredBalances,
		setHideZeroBalance,
		hideZeroBalance,
		isLoading,
		error,
	};

	return (
		<AccountsBalancesContext.Provider value={contextValue}>
			{children}
		</AccountsBalancesContext.Provider>
	);
};

export default AccountsBalancesContextProvider;

export const useAccountsBalancesContext = () =>
	useContext(AccountsBalancesContext);

type AccountsBalancesContextType = {
	readonly getAccountsBalanceHistory: () => void;
	readonly getAccountsBalanceHistoryByDate: (
		res: AccountsBalanceDateType,
	) => void;
	readonly filteredBalances: GetAccountsBalanceHistoryType[`balances`];
	readonly accountsBalances: GetAccountsBalanceHistoryType[`balances`];
	readonly setHideZeroBalance: Dispatch<boolean>;
	readonly hideZeroBalance: boolean;
	readonly isLoading: boolean;
	readonly error: Error | null;
};
