import { ModalRowComponentType } from 'modules/TransferHistory/components/TransferringMoney';
import { useOperationHistoryModalContext } from 'modules/TransferHistory/contexts/OperationHistoryModalContext';
import { testAgainstNonNumbers } from 'modules/TransferHistory/helpers';
import { TextInput } from 'pay-kit';
import { ChangeEvent, FC } from 'react';

import styles from './rate.module.scss';

const Rate: FC<Omit<ModalRowComponentType, 'changeField'>> = ({
	name,
	setForm,
	form,
	errors,
}) => {
	const { PSBalance, precision } = useOperationHistoryModalContext();

	const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
		const value = testAgainstNonNumbers(
			form[name] || '',
			e.target.value,
			precision,
			4,
		);

		setForm((prevState) => ({
			...prevState,
			[name]: value,
		}));
	};

	const isSettlement = form.recipient_payment_system === `paykassma`;

	return (
		<div className={styles.rateTextInput}>
			<TextInput
				name={name}
				className={styles.inputContainer}
				value={form[name] || ''}
				onChange={handleOnChange}
				isDisabled={
					!form.currency_code || (!PSBalance?.is_settlement && !isSettlement)
				}
				error={errors[name]}
			/>
		</div>
	);
};

export default Rate;
