export const stringifyErrorMessage: stringifyErrorMessageType = (error) => {
	if (typeof error === 'object') {
		let errorMessage = '';

		for (const message of Object.values(error)) {
			errorMessage += message + '\n';
		}

		return errorMessage;
	}

	return error;
};

type stringifyErrorMessageType = (error: string | object) => string;
