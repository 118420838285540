import API from 'api';
import { ICurrentTransaction } from 'api/transactionGroup';
import { useTranslation } from 'pay-kit';
import { Modal } from 'pay-kit';
import React, { useState } from 'react';
import { errorsMap } from 'utils/enums';

import ActivateConfirmationModal from './ActivateConfirmationModal';
import ConfirmationModal from './ConfirmationModal';

export const ActionsContext = React.createContext({
	activate: (primary_id: ICurrentTransaction['primary_id']) => {},
	archive: (primary_id: ICurrentTransaction['primary_id']) => {},
	setAsFake: (primary_id: ICurrentTransaction['primary_id']) => {},
	setAsNotFake: (primary_id: ICurrentTransaction['primary_id']) => {},
});

type ActionsProviderPropsType = {
	readonly children: React.ReactNode;
	readonly onSuccess: () => void;
};

const ActionsProvider = ({ children, onSuccess }: ActionsProviderPropsType) => {
	const [activateId, setActivateId] = useState<
		ICurrentTransaction['primary_id'] | undefined
	>();
	const [archiveId, setArchiveId] = useState<
		ICurrentTransaction['primary_id'] | undefined
	>();
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const { t } = useTranslation();

	const sendArchiveRequest = () => {
		if (archiveId === undefined) {
			return;
		}

		setIsLoading(true);

		API.transaction
			.archive({ primary_id: archiveId })
			.then((res) => {
				setIsLoading(false);

				if (res?.status === 'ok') {
					setIsLoading(false);
					onSuccess();

					window.pushAlert({
						description: t(`Transaction successfully archived`),
						type: 'success',
					});

					setArchiveId(undefined);

					return;
				} else {
					throw new Error(errorsMap.anyResponse);
				}
			})
			.catch((err) => {
				setIsLoading(false);

				window.pushAlert({
					description: t('Failed to archive transaction'),
					type: 'error',
				});
			});
	};

	const setAsFake = (primary_id: number, is_fake: boolean) => {
		API.transaction
			.setFakeStatus(primary_id, is_fake)
			.then((res) => {
				if (res.status === 'success') {
					onSuccess();

					window.pushAlert({
						description: is_fake
							? t('Transaction successfully marked as fraud')
							: t('Transaction successfully approved'),
						type: 'success',
					});

					return res;
				}

				throw new Error(errorsMap.anyResponse);
			})
			.catch(() => {
				window.pushAlert({
					description: t('Failed to set fake status'),
					type: 'error',
				});
			});
	};

	const sendActivateRequest = (formData: any) => {
		setIsLoading(true);

		API.transaction
			.activate({
				primary_id: activateId,
				...formData,
				merchant_order_id: formData.merchant_order_id || undefined,
			})
			.then((res: any) => {
				setIsLoading(false);

				if (res?.status === 'ok') {
					setIsLoading(false);
					onSuccess();

					window.pushAlert({
						description: t('Transaction successfully activated'),
						type: 'success',
					});

					setActivateId(undefined);

					return;
				} else {
					if (typeof res?.message === 'string') {
						window.pushAlert({ description: res?.message, type: 'error' });
					}

					throw new Error(errorsMap.anyResponse);
				}
			})
			.catch((err) => {
				console.error(err);
				setIsLoading(false);

				window.pushAlert({
					description: t('Failed to activate transaction'),
					type: 'error',
				});
			});
	};

	return (
		<ActionsContext.Provider
			value={{
				activate: setActivateId,
				archive: setArchiveId,
				setAsFake: (primary_id: number) => setAsFake(primary_id, true),
				setAsNotFake: (primary_id: number) => setAsFake(primary_id, false),
			}}
		>
			<Modal
				isOpen={archiveId !== undefined}
				title={t('Transaction archiving')}
				onClose={() => setArchiveId(undefined)}
			>
				<ConfirmationModal
					text={t(
						'Are you sure you want to archive transaction {{archiveId}}?',
						{ archiveId: archiveId as number },
					)}
					onConfirm={sendArchiveRequest}
					onClose={() => setArchiveId(undefined)}
					confirmText={t('Archive')}
					isLoading={isLoading}
				/>
			</Modal>
			<Modal
				isOpen={activateId !== undefined}
				title={t('Activate transaction')}
				onClose={() => setActivateId(undefined)}
			>
				<ActivateConfirmationModal
					onConfirm={sendActivateRequest}
					onClose={() => setActivateId(undefined)}
					confirmText={t('Activate')}
					isLoading={isLoading}
				/>
			</Modal>
			{children}
		</ActionsContext.Provider>
	);
};

export default ActionsProvider;
