import API from 'api';
import { SetSmsCodeParams, WalletTypeType } from 'api/walletGroup';
import Chat from 'assets/icons/chat.svg';
import {
	ParserType,
	WalletState,
} from 'modules/Wallets/components/modals/ChangeWalletModal/utils/enums';
import { Button, Modal, TextInput, useTranslation } from 'pay-kit';
import { FC, useState } from 'react';
import { errorsMap } from 'utils/enums';

import styles from './ActivatePayTM.module.scss';

const ActivatePayTM: FC<ActivatePayTMPropsType> = ({
	identifier,
	walletType,
	states,
	parserType,
	smsSource,
}) => {
	const { t } = useTranslation();
	const [sms_code, setSmsCode] = useState<SetSmsCodeParams['sms_code']>('');
	const [modalIsVisible, setModalVisibility] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const setSmsCodeRequest = () => {
		setIsLoading(true);

		API.wallet
			.setSmsCode({ sms_code, identifier, wallet_type: walletType })
			.then((res) => {
				if (res?.status === 'ok') {
					setIsLoading(false);

					window.pushAlert({
						description: t('Phone confirmed'),
						type: 'success',
					});

					setModalVisibility(false);

					return;
				} else {
					throw new Error('Unexpected response');
				}
			})
			.catch((err) => {
				setIsLoading(false);

				window.pushAlert({
					description: errorsMap.cantConfirmPhone,
					type: 'error',
				});
			});
	};

	const isEnterSMS =
		(parserType === ParserType.MOBILE_API ||
			states.includes(WalletState.PENDING_SMS_CONFIRMATION)) &&
		smsSource === `manual_input`;

	return (
		<>
			<Modal
				isOpen={modalIsVisible}
				title={t('Phone confirmation')}
				onClose={() => setModalVisibility(false)}
			>
				<form
					className={styles.form}
					onSubmit={(e) => {
						e.preventDefault();
						setSmsCodeRequest();
					}}
				>
					<div className={styles.inputRow}>
						<div className={styles.label}>{t('Enter code')}</div>
						<TextInput
							name='sms_code'
							placeholder={t('Code')}
							type='text'
							value={sms_code}
							onChange={(e) => setSmsCode(e.target.value)}
							data-test-id='ActivatePayTM_sms_code'
						/>
					</div>

					<div className={styles.actions}>
						<Button
							data-test-id='EI47Kf58khL2l41__2Xer'
							type='submit'
							isLoading={isLoading}
						>
							{t('Confirm')}
						</Button>
					</div>
				</form>
			</Modal>
			{isEnterSMS && (
				<Button
					data-test-id='N6frHHFgqLSISOtxoPbQN'
					classname={styles.enterSMS}
					onClick={() => setModalVisibility(true)}
					color='#E8882E'
				>
					<Chat /> {t('Enter SMS')}
				</Button>
			)}
		</>
	);
};

export default ActivatePayTM;

type ActivatePayTMPropsType = {
	readonly identifier: SetSmsCodeParams['identifier'];
	readonly walletType: SetSmsCodeParams['wallet_type'];
	readonly parserType: WalletTypeType['parser_type'];
	readonly states: WalletTypeType['states'];
	readonly smsSource: string | null;
};
