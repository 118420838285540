import { useTranslation } from 'pay-kit';
import RoutedTabs, { RoutedTab } from 'components/RoutedTabs';
import RoleContext, { Roles } from 'contexts/AuthContext';
import { useContext } from 'react';
import { Outlet } from 'react-router-dom';

const BalancePage = () => {
	const { hasRole } = useContext(RoleContext);
	const { t } = useTranslation();

	const tabs: RoutedTab[] = [
		{
			label: t('Accounts balance'),
			value: '/balances/accounts',
			visible:
				hasRole(Roles.ACCOUNTS_BALANCE) &&
				hasRole(Roles.MANAGE_AUTO_GENERATING_REPORTS),
		},
	];

	return (
		<>
			<RoutedTabs tabs={tabs} />
			<Outlet />
		</>
	);
};

export default BalancePage;
