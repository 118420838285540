import { ITransactionsFilterForm } from 'api/transactionGroup';
import { WalletListItem } from 'api/walletGroup';
import Filter from 'components/Filter';
import AuthContext from 'contexts/AuthContext';
import { Roles } from 'contexts/AuthContext/utils/enums';
import { WalletTypesContext } from 'contexts/WalletTypesContext';
import { useTranslation } from 'pay-kit';
import React, {
	Dispatch,
	SetStateAction,
	useContext,
	useEffect,
	useState,
} from 'react';
import { rejectSettlement } from 'utils/filterSettlement';
import { getUniqueWallets } from 'utils/getUniqueWallets';
import useGetWallets from 'utils/hooks/useGetWallets';
import { FilterErrors } from 'modules/Transactions/CurrentTransactions/Filter/utils';

import styles from './ArchivedTransactionsFilter.module.scss';

interface ArchivedTransactionsFilterProps {
	readonly value: ITransactionsFilterForm;
	readonly onApply: (value: ITransactionsFilterForm) => void;
	readonly errors: Record<string, string | null>;
	readonly setErrors: Dispatch<SetStateAction<FilterErrors>>;
}

interface WalletFetchParams {
	readonly page: number;
	readonly limit: number;
	readonly identifier?: string;
}

const ArchivedTransactionsFilter: React.FC<ArchivedTransactionsFilterProps> = ({
	value,
	onApply,
	errors,
	setErrors,
}) => {
	const { t } = useTranslation();
	const { walletTypes } = useContext(WalletTypesContext);

	const walletsAPI = useGetWallets();

	const { hasRole } = useContext(AuthContext);

	const hasGetWalletsListPermission = hasRole(Roles.WALLET_LIST);

	const [accumulatedWallets, setAccumulatedWallets] = useState<
		readonly WalletListItem[]
	>([]);

	const [params, setParams] = useState<WalletFetchParams>({
		page: 1,
		limit: 10,
	});

	const fetchWallets = () => {
		const res = walletsAPI.load({
			...params,
			removed: undefined,
			is_active: undefined,
		});

		if (res) {
			res.then((resp) => {
				if (resp.status === 'success') {
					setAccumulatedWallets((acc) =>
						getUniqueWallets([...acc, ...resp.data]),
					);
				}
			});
		}
	};

	useEffect(() => {
		if (hasGetWalletsListPermission) {
			fetchWallets();
		}
	}, [params, hasGetWalletsListPermission]);

	return (
		<Filter
			values={value}
			onApply={onApply}
			classNames={{
				wrapper: styles.filter,
				inlineFilter: styles.inlineFilter,
				additionalFiltersWrapper: styles.additionalFiltersWrapper,
			}}
			disabled={errors.transaction_id !== null}
			maxInlineFields={4}
			fields={[
				{
					name: 'transaction_id',
					placeholder: t('Transaction number'),
					component: 'textInput',
					error: errors.transaction_id,
					onChangeFilter: (value: string) => {
						if (errors.transaction_id) {
							if (value.length < 1 || value.length > 3) {
								setErrors({
									transaction_id: null,
								});
							}
						}
					},
				},
				{
					name: 'label',
					placeholder: t('Label'),
					component: 'textInput',
				},
				{
					name: 'merchant_order_id',
					placeholder: t('Order ID'),
					component: 'textInput',
				},
				{
					name: 'date',
					component: 'dateRange',
					label: t('Creation date'),
					// fromPlaceholder: "От",
					fromPlaceholder: t('From'),
					// toPlaceholder: "До",
					toPlaceholder: t('To'),
					dateFormat: 'DD.MM.YYYY',
					blockPredicate: (date) => date.getTime() >= new Date().getTime(),
					withTime: true,
				},
				{
					name: 'activated_date',
					component: 'dateRange',
					label: t('Activation date'),
					// fromPlaceholder: "От",
					fromPlaceholder: t('From'),
					// toPlaceholder: "До",
					toPlaceholder: t('To'),
					dateFormat: 'DD.MM.YYYY',
					blockPredicate: (date) => date.getTime() >= new Date().getTime(),
					withTime: true,
				},
				{
					name: 'primary_id',
					placeholder: t('ID'),
					component: 'textInput',
				},
				{
					name: 'exchanger_identifier',
					placeholder: t('Counterparty'),
					component: 'textInput',
				},
				{
					name: 'wallet_type',
					placeholder: t('Wallet type'),
					component: 'checkboxGroup',
					options: rejectSettlement(walletTypes).map((type) => ({
						value: type.code,
						label: type.name,
					})),
				},
				{
					name: 'wallet_hash_id',
					placeholder: t('Wallet'),
					component: 'select',
					options: accumulatedWallets.map((wallet) => ({
						value: `${wallet.hash_id}`,
						label: `${wallet.wallet_type} | ${wallet.identifier}`,
					})),
					isLoading: walletsAPI.isLoading,
					onMenuScrollToBottom: () => {
						if (
							walletsAPI.paginationInfo &&
							walletsAPI.paginationInfo.total > 10
						) {
							setParams((p) => ({
								...p,
								page: p.page + 1,
							}));
						}
					},
					onInputChange: (value) => {
						if (!value) {
							setParams({
								page: 1,
								limit: 10,
							});
						} else {
							setAccumulatedWallets([]);

							setParams((p) => ({
								...p,
								page: 1,
								identifier: value,
							}));
						}
					},
				},
				{
					name: 'direction',
					placeholder: t('Direction'),
					component: 'select',
					options: [
						{
							value: 'outgoing',
							// label: "Исходящие",
							label: t('Outgoing'),
						},
						{
							value: 'ingoing',
							// label: "Входящие",
							label: t('Incoming'),
						},
					],
				},
				{
					name: 'type',
					placeholder: t('Transaction kind'),
					component: 'select',
					options: [
						{
							value: 'real',
							label: t('Confirmed'),
						},
						{
							value: 'fake',
							label: t('Debug'),
						},
						{
							value: 'forced',
							label: t('Forced'),
						},
						{
							value: 'inner',
							label: t('Internal'),
						},
					],
				},
				{
					name: 'status',
					placeholder: t('Status'),
					component: 'select',
					options: [
						{ label: t('All'), value: '' },
						// { label: "Неактивированные", value: "0" },
						{ label: t('Not activated'), value: '0' },
						{ label: t('Activated'), value: '1' },
						{ label: t('Pending'), value: '3' },
						{ label: t('Processing'), value: '4' },
						{ label: t('Crashed'), value: '5' },
						{ label: t('Canceled'), value: '6' },
						{ label: t('Rejected'), value: '7' },
						// { label: "С ошибкой", value: "8" },
						{ label: t('With Error'), value: '8' },
					],
				},
				{
					name: 'postback_status',
					// placeholder: "Постбeк",
					placeholder: t('Postback status'),
					component: 'select',
					hide: !hasRole(Roles.POSTBACKS),
					options: [
						{
							value: '3',
							label: t('Stockpiling not found'),
						},
						{
							value: '0',
							// label: "Идёт накопление",
							label: t('Stockpiling in progress'),
						},
						{
							value: '1',
							label: t('Sent'),
						},
						{
							value: '2',
							// label: "Ошибка отправки постбeка",
							label: t('Error sending postback'),
						},
					],
				},
				{
					name: 'is_fake',
					// placeholder: "Подлинность",
					placeholder: t('Fake'),
					component: 'select',
					options: [
						// { label: "Нормальная", value: "false" },
						{ label: t('Normal'), value: 'false' },
						// { label: "Фрод", value: "true" },
						{ label: t('Fake'), value: 'true' },
					],
				},
			]}
		/>
	);
};

export default ArchivedTransactionsFilter;
