import { convertIntoCurrency } from 'helpers/convertIntoCurrency';
import { MetricsSchemaType } from 'modules/Dashboard/components/DashboardTransactionsMetrics';
import React from 'react';
import { FC } from 'react';

import styles from './transactionMetrics.module.scss';

const TransactionMetrics: FC<TransactionMetricsType> = ({ metrics }) => {
	const renderMetrics = () => {
		return metrics?.map((elem) => {
			return (
				!elem.hide && (
					<React.Fragment key={elem.header}>
						<div className={[styles.summaryHeader, elem.className].join(' ')}>
							{elem.header}
						</div>
						<div className={styles.metrics}>
							{elem.metrics.map((elem, i) => {
								const amountArr: readonly number[] =
									elem.amount === undefined
										? []
										: elem.currenciefy
											? (elem.amount as readonly number[])
											: [elem.amount as number];

								return (
									<div key={i}>
										<span className={styles.amountType}>{elem.type}</span>
										{amountArr.map((amount, idx) => {
											const amountText = elem.currenciefy
												? convertIntoCurrency({ amount })
												: amount;

											return (
												<div
													key={idx}
													className={[styles.amount, elem.className].join(' ')}
												>
													<span
														className={styles.amount}
														data-test-id={elem.dataTestId}
													>
														{amountText}
													</span>
													{elem.currency && (
														<span
															className={styles.currency}
															data-test-id='currency'
														>
															{elem.currency[idx]}
														</span>
													)}
												</div>
											);
										})}
									</div>
								);
							})}
						</div>
					</React.Fragment>
				)
			);
		});
	};

	return <div className={styles.metricsWrapper}>{renderMetrics()}</div>;
};

export default TransactionMetrics;

type TransactionMetricsType = {
	readonly metrics: readonly MetricsSchemaType[];
};
