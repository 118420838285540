import { useTranslation } from '@paykassma/pay-kit';
import api, { TariffType } from 'api/tariffsGroup';
import { useState } from 'react';
import { errorsMap } from 'utils/enums';

const useGetTariff = () => {
	const [tariff, setTariff] = useState<TariffType | undefined>(undefined);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const { t } = useTranslation();

	const load = (id: number) => {
		setTariff(undefined);
		setIsLoading(true);

		api
			.getTariff(id)
			.then((res: any) => {
				if (res?.status === 'ok' && res?.item) {
					setTariff(res.item as TariffType);

					return res;
				} else {
					window.pushAlert({
						type: 'error',
						description: t('Loading tariff error'),
					});

					// throw new Error(`Getting tariff error`);
					throw new Error(errorsMap.anyResponse);
				}
			})
			.finally(() => setIsLoading(false))
			.catch((err) => {
				console.error(err);
			});
	};

	return {
		load,
		tariff,
		isLoading,
	};
};

export default useGetTariff;
