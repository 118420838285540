import ToggleButton from 'components/Icons/Caret';
import { FC, useEffect, useLayoutEffect, useRef, useState } from 'react';

import styles from './dynamicMultilineText.module.scss';

const textStyles = {
	fullText: `${styles.dynamicMultilineTextContainer} ${styles.displayAll}`,
	cutText: styles.dynamicMultilineTextContainer,
};

const DynamicMultilineText: FC<DynamicMultilineTextType> = ({
	text,
	className,
	toggleButtonValue,
	dataTestId,
}) => {
	const [showAll, setShowAll] = useState(false);
	const [textStyle, setTextStyle] = useState(textStyles.fullText);
	const [isLonger, setIsLonger] = useState(false);
	const ref = useRef<HTMLParagraphElement>(null);

	useLayoutEffect(() => {
		const elem = ref.current && getComputedStyle(ref.current);
		const lineHeight =
			(elem && elem.getPropertyValue('line-height').replace('px', '')) || '';
		const height =
			(elem && elem.getPropertyValue('height').replace('px', '')) || '';
		const isLonger = +height > +lineHeight * 3;

		if (isLonger) {
			setTextStyle(textStyles.cutText);
		} else {
			setTextStyle(textStyles.fullText);
		}

		setIsLonger(isLonger);
	}, []);

	useEffect(() => {
		if (showAll) {
			setTextStyle(textStyles.fullText);
		} else {
			setTextStyle(textStyles.cutText);
		}
	}, [showAll]);

	return (
		<div className={[styles.dynamicMultilineTextWrapper, className].join(' ')}>
			<p className={textStyle} ref={ref} data-test-id={dataTestId}>
				{text}
			</p>

			{isLonger && (
				<ToggleButton
					setShowAll={setShowAll}
					showAll={showAll}
					customClass={styles.caretWrapper}
					value={toggleButtonValue}
					dataTestId='expandMessageButton'
				/>
			)}
		</div>
	);
};

export default DynamicMultilineText;

type DynamicMultilineTextType = {
	readonly text: string;
	readonly toggleButtonValue?: {
		readonly hideText?: string;
		readonly openText?: string;
	};
	readonly className?: string;
	readonly dataTestId?: string;
};
