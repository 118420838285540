import { UpdatePluginSettingsType } from 'api/settingsGroup';
import Save from 'assets/icons/save.svg';
import CustomCssFile from 'modules/AppSettings/Plugin/components/CustomCssFile';
import useUpdatePluginSettings from 'modules/AppSettings/Plugin/hooks/useUpdatePluginSettings';
import { t, useTranslation } from 'pay-kit';
import { Button, Select } from 'pay-kit';
import { FC, useState } from 'react';

import styles from './pluginTheme.module.scss';

const options = [
	{
		value: `light`,
		label: t('Light'),
	},
	{
		value: `dark`,
		label: t('Dark'),
	},
	{
		value: `white-green`,
		label: t(`White and green`),
	},
	{
		value: `custom`,
		label: t(`Custom`),
	},
];

const PluginTheme: FC<PluginThemeType> = ({
	theme,
	isPluginSettingsLoading,
}) => {
	const { t } = useTranslation();
	const updatePluginSettingsAPI = useUpdatePluginSettings();
	const [option, setOption] = useState<
		UpdatePluginSettingsType[`payment_plugin_color_scheme`] | undefined
	>(undefined);

	function onSelect<T>(option: T) {
		setOption(
			option as UpdatePluginSettingsType[`payment_plugin_color_scheme`],
		);
	}

	const onSave = () => {
		if (option) {
			updatePluginSettingsAPI.update({ payment_plugin_color_scheme: option });
		}
	};

	const selectedValue = option || theme;

	return (
		<div className={styles.pluginThemeWrapper}>
			<div className={styles.selectWrapper}>
				<div className={styles.selectContainer}>
					<div className={styles.label}>{t('Theme')}</div>
					<Select
						name='type'
						placeholder={t(`Choose`)}
						className={styles.select}
						options={options}
						onChange={onSelect}
						value={selectedValue}
						isLoading={isPluginSettingsLoading}
					/>
				</div>

				<Button
					data-test-id='Ky0ZJhUJ6wyzEkkvCEucB'
					onClick={onSave}
					classname={styles.saveButton}
				>
					<Save /> {t('Save')}{' '}
				</Button>
			</div>

			{selectedValue === 'custom' && <CustomCssFile />}
		</div>
	);
};

export default PluginTheme;

type PluginThemeType = {
	readonly theme?: UpdatePluginSettingsType[`payment_plugin_color_scheme`];
	readonly isPluginSettingsLoading: boolean;
};
