import React from 'react';

import styles from './Date.module.scss';

type DatePropsType = {
	readonly date: string;
	readonly dataTestId?: string;
};

const Date: React.FC<DatePropsType> = ({ date, dataTestId }) => {
	return (
		<div className={styles.Date} data-test-id={dataTestId}>
			{date}
		</div>
	);
};

export default Date;
