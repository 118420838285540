import API from 'api';
import { useTranslation } from 'pay-kit';
import { Modal } from 'pay-kit';
import React, { useEffect, useState } from 'react';
import { errorsMap } from 'utils/enums';

import {
	isAlphabeticValue,
	isLengthTooLong,
	isSpecialPs,
	prepareRequestData,
	validate,
} from './utils';
import WithdrawalForm from './WithdrawalForm';

interface ICreateNewModal {
	readonly isOpen: boolean;
	readonly onClose: () => void;
	readonly onSuccess?: () => void;
}

export interface IFormData {
	readonly payment_system?: string;
	readonly label?: string;
	readonly amount?: number;
	readonly currency_code?: string;
	readonly account_number?: string;
	readonly withdrawal_id?: string;
	readonly account_name?: string;
	readonly account_email?: string;
	readonly payments_method?: string;
	readonly payments_provider?: string;
	readonly bank_code?: string;
	readonly branch_code?: string;
	readonly bank_code_in_payments_system?: string;
	readonly comment?: string;
	readonly iban?: string;
}

const INITIAL_FORM_DATA = {};

export type PaymentSystemType = {
	readonly is_manual: boolean;
	readonly text: string;
	readonly value: string;
};

const CreateNewModal: React.FC<ICreateNewModal> = ({
	isOpen,
	onClose,
	onSuccess,
}) => {
	const { t } = useTranslation();
	const [formData, setFormData] = useState<IFormData>(INITIAL_FORM_DATA);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [formErrors, setFormErrors] = useState<readonly string[]>([]);
	const [touchedFields, setTouchedFields] = useState<
		readonly (keyof IFormData | '*')[]
	>([]);
	const [supportedCurrencyList, setSupportedCurrencyList] = useState<
		readonly string[]
	>([]);
	const [supportedCurrencyListIsLoading, setSupportedCurrencyListIsLoading] =
		useState<boolean>(false);

	const paymentSystemIsChoosed = formData.payment_system !== undefined;

	const fieldsErrors = validate(formData);
	const isNotValid = Object.keys(fieldsErrors).length > 0;

	const fieldChangeHandler = (
		name: keyof IFormData,
		value: unknown,
		silent?: boolean,
	) => {
		const undefinedIfEmptyString = (val?: unknown) =>
			val === '' ? undefined : val;

		if (isSpecialPs(formData.payment_system)) {
			// Блокируем ввод, кроме чисел
			if (name === 'account_number' && value && isAlphabeticValue(value)) {
				return;
			}
		}

		if (
			(formData.payment_system === 'nagad' ||
				formData.payment_system === 'bkash') &&
			name === 'account_number' &&
			isLengthTooLong(11, value as string)
		) {
			return;
		}

		if (
			formData.payment_system === 'rocket' &&
			isLengthTooLong(12, value as string)
		) {
			return;
		}

		setFormData((prevState) => {
			return { ...prevState, [name]: undefinedIfEmptyString(value) };
		});

		setFormErrors([]);

		!silent &&
			setTouchedFields((prevState) => [...new Set([...prevState, name])]);
	};

	useEffect(() => {
		if (!isOpen) {
			setFormData(INITIAL_FORM_DATA);
			setFormErrors([]);
			setTouchedFields([]);
		}
	}, [isOpen]);

	useEffect(() => {
		// Это все дело нужно для того, чтобы запретить пользователю удалять предзаполненные данные в форме.
		// +91 для paytm и 01 для nagad, bKash и rocket
		// TODO Если есть желание сделать проще и короче - welcome
		if (isSpecialPs(formData.payment_system)) {
			if (formData.account_number === '0' || formData.account_number === '1') {
				setFormData((data) => ({ ...data, account_number: '01' }));
			}
		}

		if (formData.payment_system === 'paytm') {
			if (
				formData.account_number === '91' ||
				formData.account_number === '+9' ||
				formData.account_number === '+1'
			) {
				setFormData((data) => ({ ...data, account_number: '+91' }));
			}
		}
	}, [formData.account_number, formData.payment_system]);

	const getCurrencyList = () => {
		if (formData.payment_system === undefined) {
			return;
		}

		fieldChangeHandler('currency_code', undefined, true);
		setSupportedCurrencyListIsLoading(true);

		API.walletType
			.getSupportedCurrency(formData.payment_system)
			.then((res: any) => {
				setSupportedCurrencyListIsLoading(false);

				if (res?.status === 'ok' && !!res?.currencies) {
					setSupportedCurrencyList(res.currencies);

					if (res.currencies.length === 1) {
						fieldChangeHandler('currency_code', res.currencies[0], true);
					}

					return res;
				} else {
					// throw new Error(`Getting supported currency list for payment system ${formData.payment_system} erorr`);
					throw new Error(errorsMap.getCurrencyError(formData.payment_system));
				}
			})
			.catch((err) => {
				setSupportedCurrencyListIsLoading(false);
				console.error(err);
			});
	};

	useEffect(() => {
		if (paymentSystemIsChoosed) {
			setFormData(({ payment_system }) => ({ payment_system }));
			setTouchedFields([]);
			getCurrencyList();

			if (formData.payment_system === 'paytm') {
				setFormData((data) => ({ ...data, account_number: '+91' }));
			}

			if (isSpecialPs(formData.payment_system)) {
				setFormData((data) => ({ ...data, account_number: '01' }));
			}
		}
	}, [formData.payment_system]);

	const submitHandler = () => {
		if (isNotValid) {
			setTouchedFields(['*']);

			return;
		}

		setFormErrors([]);
		setIsLoading(true);

		API.withdrawal
			.create(prepareRequestData(formData))
			.then((res) => {
				if (res.status === 'ok') {
					window.pushAlert({
						// description: `Заявка на вывод создана`,
						description: t('Withdrawal created'),
						type: 'success',
					});

					setIsLoading(false);
					setFormData(INITIAL_FORM_DATA);
					onSuccess && onSuccess();
				} else {
					if (
						typeof res.message === 'object' &&
						Object.keys(res.message).length > 0
					) {
						const _formErrors: any = [];

						Object.keys(res.message).forEach((key: string) =>
							_formErrors.push(res.message[key] || ''),
						);

						setFormErrors(_formErrors);
					}

					if (typeof res.message === 'string') {
						setFormErrors([res.message]);
					}

					// throw new Error("An error has occurred while trying to create new withdrawal");
					throw new Error(errorsMap.anyCreate);
				}

				return res;
			})
			.catch((err) => {
				window.pushAlert({
					// description: `Произошла ошибка при попытке создать заявку на вывод`,
					description: t('Failed to create withdrawal'),
					type: 'error',
				});

				console.error(err);
				setIsLoading(false);
			});
	};

	return (
		<Modal isOpen={isOpen} title={t('Withdrawal')} onClose={onClose}>
			<WithdrawalForm
				{...{
					formData,
					fieldChangeHandler,
					supportedCurrencyList,
					supportedCurrencyListIsLoading,
					submitHandler,
					paymentSystemIsChoosed,
					isLoading,
					formErrors,
					fieldsErrors,
					touchedFields,
				}}
			/>
		</Modal>
	);
};

export default CreateNewModal;
