import Api from 'api';
import { LoadLogsCategoryList } from 'api/walletsDebuggingGroup';
import { useEffect, useState } from 'react';
import { errorsMap } from 'utils/enums';

const useLoadLogsCategoryList = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState<Error | null>(null);
	const [list, setList] = useState<LoadLogsCategoryList['categories'] | null>(
		null,
	);

	useEffect(() => {
		setIsLoading(true);
		setError(null);

		Api.walletsDebugging
			.loadLogsCategoryList()
			.then((res) => {
				if (res.status === 'ok') {
					setList(res.categories);
				} else {
					// throw new Error("Unexpected response in loadLogsCategoryList!");
					throw new Error(errorsMap.anyResponse);
				}
			})
			.catch((error) => {
				console.log(error);
				setError(error);
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, []);

	return {
		list,
		isLoading,
		error,
	};
};

export default useLoadLogsCategoryList;
