import { IWithdrawalsFilterForm } from 'api/withdrawalGroup';
import PlusIcon from 'assets/icons/plus.svg';
import DropdownButton from 'components/DropdownButton';
import { Roles, useHasAccessHook } from 'contexts/AuthContext';
import WalletTypesContextProvider from 'contexts/WalletTypesContext';
import { useTranslation } from 'pay-kit';
import { Button, Table } from 'pay-kit';
import React, { useEffect, useState } from 'react';
import useURLState from 'utils/hooks/useURLState';
import useURLFilters from 'utils/hooks/useURLFilters';

import ReloadButton from '../Postbacks/components/ReloadButton';

import ActionsProvider, { ActionsContext } from './components/ActionsProvider';
import columns, { SubRow } from './components/columns';
import Filter from './components/WithdrawalsFilter';
import StatusesContextProvider from './contexts/statusesContext';
import useWithdrawalsListAPI from './hooks/useWithdrawalsListAPI';
import styles from './Withdrawals.module.scss';

const Withdrawals: React.FC = () => {
	const { t } = useTranslation();
	const [page, setCurrentPage] = useURLState<number>('page', 1);
	const [limit, setLimit] = useURLState<number>('limit', 20);
	const [filter, setFilter] = useURLFilters({
		dateRangeFields: ['date'],
	});

	const { withdrawals, isLoading, error, paginationInfo, getWithdrawalsList } =
		useWithdrawalsListAPI();
	const loadWithdrawals = () => {
		getWithdrawalsList({ limit, page, ...filter });
	};
	const hasAccessToCreateNew = useHasAccessHook({
		rule: Roles.WITHDRAWALS_CREATE,
	});
	const hasAccessToManage = useHasAccessHook({
		rule: Roles.WITHDRAWALS_MANAGE,
	});

	useEffect(loadWithdrawals, [limit, page, filter]);

	return (
		<WalletTypesContextProvider>
			<StatusesContextProvider>
				<ActionsProvider
					withdrawals={withdrawals}
					refreshHandler={loadWithdrawals}
				>
					<ActionsContext.Consumer>
						{({ createNew, importReport, downloadReport, showDetailsFor }) => (
							<>
								<div className={styles.actions}>
									{hasAccessToCreateNew && (
										<Button onClick={() => createNew()} data-test-id=''>
											<PlusIcon />
											&nbsp;&nbsp;{t('Add withdrawal request')}{' '}
										</Button>
									)}
									{hasAccessToManage && (
										<DropdownButton
											options={[
												{
													id: 'import-report',
													title: t('Upload'),
													onClick: importReport,
												},
												{
													id: 'download-report',
													title: t('Download'),
													onClick: downloadReport,
												},
											]}
											data-test-id='ManualWithdrawalsButton'
										>
											{t('Manual withdrawals')}{' '}
										</DropdownButton>
									)}
								</div>
								<Filter
									value={filter}
									onApply={(value) => {
										setCurrentPage(1);
										setFilter(value);
									}}
								/>
								<Table
									className={styles.WithdrawalsTable}
									columns={columns}
									data={withdrawals}
									stickyHead
									rowKey='id'
									message={
										error ? { text: `${error}`, type: 'error' } : undefined
									}
									isLoading={isLoading}
									skeleton={{ rowsCount: limit }}
									onRowClick={(id: string | number) =>
										showDetailsFor(id as number)
									}
									subRowRender={SubRow}
									paginator={
										paginationInfo
											? {
													currentPage: paginationInfo?.current_page || 1,
													setCurrentPage: (page: any) =>
														setCurrentPage(page as number),
													limit,
													setLimit,
													lastAvailablePage:
														paginationInfo?.last_available_page || 1,
													prefixElement: (
														<ReloadButton
															data-test-id=''
															isLoading={isLoading}
															onClick={loadWithdrawals}
														/>
													),
													className: styles.paginator,
													bottomPaginatorProps: {
														className: styles.bottomPaginator,
													},
												}
											: undefined
									}
								/>
							</>
						)}
					</ActionsContext.Consumer>
				</ActionsProvider>
			</StatusesContextProvider>
		</WalletTypesContextProvider>
	);
};

export default Withdrawals;
