import { LogListData } from 'api/walletsDebuggingGroup';
import DynamicMultilineText from 'components/DynamicMultilineText';
import PaymentSystemLogo from 'components/PaymentSystemLogo';
import WalletIdentifier from 'modules/WalletsDebugging/LogsList/components/WalletIdentifier';
import { t } from 'pay-kit';
import { toLocaleDate } from 'utils/date';

import styles from './logsListColumns.module.scss';

const WalletType = {
	title: t('Wallet type'),
	dataIndex: 'wallet_type',
	key: 'wallet_type',
	render: ({ wallet_type }: LogListData) => (
		<PaymentSystemLogo id={wallet_type} dataTestId='wallet_type' />
	),
};

const WalletID = {
	title: t('Wallet number'),
	dataIndex: 'wallet_identifier',
	key: 'wallet_identifier',
	render: ({ wallet_identifier, wallet_hash_id, id }: LogListData) => {
		return (
			<WalletIdentifier
				wallet_identifier={wallet_identifier}
				wallet_hash_id={wallet_hash_id}
				id={id}
				dataTestId='wallet_identifier'
			/>
		);
	},
};

const ErrorType = {
	title: t('Error type'),
	dataIndex: 'error_type',
	key: 'error_type',
	render: ({ error_type }: LogListData) => (
		<span className={styles.errorType} data-test-id='error_type'>
			{error_type}
		</span>
	),
};

const Category = {
	title: t('Category'),
	dataIndex: 'category',
	key: 'category',
	dataTestId: 'category',
};

const Message = {
	title: t('Message'),
	dataIndex: 'message',
	key: 'message',
	render: ({ message }: LogListData) => (
		<DynamicMultilineText
			text={message}
			className={styles.messageWrapper}
			toggleButtonValue={{ hideText: t('Hide') }}
			dataTestId='message'
		/>
	),
};

const Date = {
	title: t('Creation date'),
	dataIndex: 'created_at',
	key: 'created_at',
	render: ({ created_at }: LogListData) => {
		const [date, time] = toLocaleDate(created_at).split(' ');

		return (
			<div data-test-id='created_at'>
				<div>{date}</div>
				<div className={styles.creationTime}>{time}</div>
			</div>
		);
	},
};

export const LogsListColumns = [
	WalletType,
	WalletID,
	ErrorType,
	Category,
	Message,
	Date,
];
