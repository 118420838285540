import Api from 'api';
import { LoadLogListType } from 'api/walletsDebuggingGroup';
import { useState } from 'react';
import { errorsMap } from 'utils/enums';

const useLoadLogList = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState<Error | null>(null);
	const [data, setData] = useState<LoadLogListType['logs'] | null>(null);

	const load = (params: string) => {
		setError(null);
		setIsLoading(true);

		Api.walletsDebugging
			.loadLogList(params)
			.then((res) => {
				if (res.status === 'ok') {
					setData(res.logs);
				} else {
					// throw new Error("Unexpected response in loadLogList!");
					throw new Error(errorsMap.anyResponse);
				}
			})
			.catch((err) => {
				console.log(err);
				setError(err);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	return {
		load,
		data,
		isLoading,
		error,
	};
};

export default useLoadLogList;
