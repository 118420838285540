import api, { ILimitsItem } from 'api/walletTypesGroup';
import { useState } from 'react';
import { errorsMap } from 'utils/enums';

const useSetLimitsHook: UseSetLimitsHookType = ({ onSuccess, onFail }) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const post = (psId: string, data: readonly ILimitsItem[]) => {
		setIsLoading(true);

		api
			.setLimits(psId, data)
			.then((res: any) => {
				if (res.status === 'ok') {
					onSuccess(res);
				} else {
					// throw new Error("Unexpected response received in setLimits");
					throw new Error(errorsMap.anyResponse);
				}

				return res;
			})
			.finally(() => setIsLoading(false))
			.catch(onFail);
	};

	return {
		post,
		isLoading,
	};
};

type UseSetLimitsHookType = (callbacks: {
	readonly onSuccess: (res: any) => void;
	readonly onFail: (err: Error) => void;
}) => {
	readonly post: (psId: string, data: readonly ILimitsItem[]) => void;
	readonly isLoading: boolean;
};

export default useSetLimitsHook;
