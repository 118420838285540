import API from 'api';
import { UpdatePrivateSettingsType } from 'api/settingsGroup';
import { useState } from 'react';
import { errorsMap } from 'utils/enums';

const useGetPrivateSettings = () => {
	const [error, setError] = useState<Error | null>(null);
	const [isLoading, setIsLoading] = useState(false);
	const [data, setData] = useState<UpdatePrivateSettingsType | null>(null);

	const load = () => {
		setError(null);
		setIsLoading(true);
		setData(null);

		API.settings
			.getPrivateSettings()
			.then((resp) => {
				if (resp.status === 'success') {
					setData(resp.data);
				} else {
					throw new Error(errorsMap.anyResponse);
				}
			})
			.catch((err) => {
				console.log(err);
				setError(err);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	return {
		load,
		data,
		isLoading,
		error,
	};
};

export default useGetPrivateSettings;
