import { useTranslation } from 'pay-kit';
import PaymentSystemLogo from 'components/PaymentSystemLogo';
import { Roles, useHasAccessHook } from 'contexts/AuthContext';
import { WalletTypesContext } from 'contexts/WalletTypesContext';
import { Select } from 'pay-kit';
import { useContext, useEffect, useState } from 'react';

import CommissionsEditor from './components/CommissionsEditor';
import CurrencySelect from './components/CurrencySelect';
import SkeletonLoader from './components/SkeletonLoader';
import useGetTariff from './hooks/useGetTariff';
import useGetTariffs from './hooks/useGetTariffs';
import styles from './Tariffs.module.scss';

const Tariffs = () => {
	useHasAccessHook({ rule: Roles.MANAGE_PAYMENTS_CONSTRUCTOR, redirect: true });

	const { t } = useTranslation();
	const walletTypesAPI = useContext(WalletTypesContext);
	const useGetTariffsAPI = useGetTariffs();
	const useGetTariffAPI = useGetTariff();

	useEffect(() => {
		walletTypesAPI.getWalletTypesList();
		useGetTariffsAPI.load();
	}, []);

	const [selectedWalletTypeId, setSelectedWalletTypeId] = useState<
		string | undefined
	>(undefined);
	const [selectedTariffId, setSelectedTariffId] = useState<number | undefined>(
		undefined,
	);

	const selectedWalletTypeTariffs =
		useGetTariffsAPI.list.filter(
			(tariff) => tariff.wallet_type === selectedWalletTypeId,
		) || [];

	useEffect(() => {
		if (walletTypesAPI.walletTypes.length > 0) {
			if (selectedWalletTypeId === undefined) {
				setSelectedWalletTypeId(
					walletTypesAPI.walletTypes[0].code || undefined,
				);
			} else if (selectedTariffId === undefined) {
				setSelectedTariffId(selectedWalletTypeTariffs[0]?.id || undefined);
			} else {
				useGetTariffAPI.load(selectedTariffId);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [walletTypesAPI.walletTypes, selectedWalletTypeId, selectedTariffId]);

	const paymentSystemOptions = walletTypesAPI.walletTypes
		.filter((wt) => !wt.is_settlement)
		.map((item) => ({
			value: item.code,
			label: (
				<div className={styles.paymentSystemOptionLabel}>
					<PaymentSystemLogo
						classNames={{ logo: styles.logo }}
						id={item.code}
						hideLabel
					/>
					{item.name}
				</div>
			),
		}));

	return (
		<>
			<div className={styles.filter}>
				<Select
					className={styles.select}
					name={'payment-system-select'}
					options={paymentSystemOptions}
					onChange={(v) => {
						setSelectedTariffId(undefined);
						setSelectedWalletTypeId(v as string);
					}}
					value={selectedWalletTypeId}
					data-test-id='payment-system-select'
					isLoading={walletTypesAPI.isLoading}
					isDisabled={walletTypesAPI.isLoading || useGetTariffAPI.isLoading}
					noError
				/>
				{!walletTypesAPI.isLoading && (
					<CurrencySelect
						tariffs={selectedWalletTypeTariffs}
						onSelect={setSelectedTariffId}
						selectedTariff={selectedTariffId}
						isLoading={useGetTariffAPI.isLoading}
					/>
				)}
			</div>
			{/* TODO: Uncomment when backend will be done */}
			{/* <Switcher
				className={styles.switcher}
				value={"transactions"}
				onSwitch={(v) => console.log(v)}
				options={[
					{ label: "Транзакции клиентов", value: "clients_transactions" },
					{ label: "Операции с балансом счета", value: "account_balance" },
				]}
				data-test-id="Tabs"
			/> */}
			{useGetTariffAPI.isLoading ? (
				<div className={styles.commissionsWrapper}>
					<SkeletonLoader />
				</div>
			) : (
				useGetTariffAPI.tariff?.id &&
				selectedTariffId && (
					<div
						className={styles.commissionsWrapper}
						key={useGetTariffAPI.tariff?.id}
					>
						<CommissionsEditor
							tariffId={useGetTariffAPI.tariff.id as number}
							title={t('Incoming transaction commission')}
							onSuccess={() => useGetTariffAPI.load(selectedTariffId as number)}
							commissions={useGetTariffAPI.tariff?.commissions.filter(
								(c) => c.direction === 'ingoing',
							)}
							direction='ingoing'
							isLoading={useGetTariffAPI.isLoading}
						/>
						<CommissionsEditor
							tariffId={useGetTariffAPI.tariff.id as number}
							title={t('Outgoing transaction commission')}
							onSuccess={() => useGetTariffAPI.load(selectedTariffId as number)}
							commissions={useGetTariffAPI.tariff?.commissions.filter(
								(c) => c.direction === 'outgoing',
							)}
							direction='outgoing'
							isLoading={useGetTariffAPI.isLoading}
						/>
					</div>
				)
			)}
		</>
	);
};

export default Tariffs;
