import { Tooltip } from 'pay-kit';
import { FC } from 'react';

import styles from './labelWithHint.module.scss';

const LabelWithHint: FC<LabelWithHintType> = ({ className, text, tip }) => {
	return (
		<div className={[styles.labelWithHint, className].join(' ')}>
			{text}
			<Tooltip tip={tip} preferredSide='bottom'>
				<div className={styles.questionMark}>
					<svg
						viewBox='0 0 12 13'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
					>
						<path
							d='M6 0.995117C9.31371 0.995117 12 3.68141 12 6.99512C12 10.3088 9.31371 12.9951 6 12.9951C2.68629 12.9951 0 10.3088 0 6.99512C0 3.68141 2.68629 0.995117 6 0.995117ZM6 1.99512C3.23858 1.99512 1 4.23369 1 6.99512C1 9.75654 3.23858 11.9951 6 11.9951C8.76142 11.9951 11 9.75654 11 6.99512C11 4.23369 8.76142 1.99512 6 1.99512ZM6 9.49512C6.41421 9.49512 6.75 9.8309 6.75 10.2451C6.75 10.6593 6.41421 10.9951 6 10.9951C5.58579 10.9951 5.25 10.6593 5.25 10.2451C5.25 9.8309 5.58579 9.49512 6 9.49512ZM6 3.49512C7.10457 3.49512 8 4.39055 8 5.49512C8 6.22565 7.78822 6.63481 7.24605 7.20302L6.98196 7.47257C6.60451 7.86614 6.5 8.07822 6.5 8.49512C6.5 8.77126 6.27614 8.99512 6 8.99512C5.72386 8.99512 5.5 8.77126 5.5 8.49512C5.5 7.76459 5.71178 7.35543 6.25395 6.78721L6.51804 6.51766C6.89549 6.1241 7 5.91202 7 5.49512C7 4.94283 6.55228 4.49512 6 4.49512C5.44772 4.49512 5 4.94283 5 5.49512C5 5.77126 4.77614 5.99512 4.5 5.99512C4.22386 5.99512 4 5.77126 4 5.49512C4 4.39055 4.89543 3.49512 6 3.49512Z'
							fill='currentColor'
						/>
					</svg>
				</div>
			</Tooltip>
		</div>
	);
};

export default LabelWithHint;

type LabelWithHintType = {
	readonly className?: string;
	readonly text: string;
	readonly tip: string;
};
