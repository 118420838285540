import { fetchOldApi } from 'api/fetch';
import { ProxyCommonType } from 'modules/Proxies/components/ProxyModalRows';

const proxiesGroup = {
	getProxies: (params: GetProxiesFilter) =>
		fetchOldApi<GetProxiesType>('/proxy/list', params),
	initCheckingMultiAddingProxies: (
		params: readonly InitCheckingMultiAddingProxiesType[],
	) => fetchOldApi<void>('proxy/init-checking-all', params, 'POST'),
	checkAllMultiAddingProxyStatuses: () =>
		fetchOldApi<CheckAllMultiAddingProxyStatusesType>(
			'proxy/get-checking-all-statuses',
		),
	initCheckingMultiAddingProxy: (params: InitCheckingMultiAddingProxiesType) =>
		fetchOldApi<void>('proxy/init-checking', params, 'POST'),
	addProxies: (params: AddProxiesType) =>
		fetchOldApi<void>('proxy/multi-adding', params, 'POST'),
	createProxy: (params: Partial<ProxyCommonType>) =>
		fetchOldApi<void>('proxy/create', params, 'POST'),
	initCheckAll: () => fetchOldApi<void>('proxy/initcheckall'),
	getChecked: () => fetchOldApi<GetCheckedType>('proxy/getchecked'),
	checkProxy: (id: number) =>
		fetchOldApi<void>(`proxy/check/${id}`, undefined, 'POST'),
	deleteProxy: (params: DeleteProxyType) =>
		fetchOldApi<void>('proxy', params, 'DELETE'),
	loadProxyItem: (uuid: string) =>
		fetchOldApi<LoadProxyItemType>(`proxy/${uuid}`),
	updateProxy: (params: UpdateProxyType, uuid: string) =>
		fetchOldApi<void>(`proxy/update/${uuid}`, params, 'POST'),
};

export default proxiesGroup;

export type ProxyItemWalletsType = {
	readonly id: number;
	readonly identifier: string;
	readonly parser_type?: string | null;
};

export type GetProxiesFilter = {
	readonly ip?: string;
	readonly port?: number;
	readonly username?: string;
	readonly status?: 0 | 1;
	readonly type?: string;
	readonly country_code?: string;
	readonly wallet_type?: string;
	readonly wallet_identifier?: string;
};

export type LoadProxyItemType = {
	readonly proxy: {
		readonly country_code: string;
		readonly created_at: string;
		readonly flag: number;
		readonly id: number;
		readonly ip: string;
		readonly is_checking: number;
		readonly password: string;
		readonly port: number;
		readonly status: 0 | 1;
		readonly type: readonly ('socks4' | 'socks5' | 'https' | 'http')[];
		readonly updated_at: string;
		readonly username: string;
		readonly wallets: readonly ProxyItemWalletsType[];
	};
};

export type UpdateProxyWalletsType = {
	readonly id: number | string;
	readonly identifier?: string;
	readonly parser_type?: string | null;
};

export type UpdateProxyType = {
	readonly country_code: string;
	readonly ip: string;
	readonly password: string;
	readonly port: number;
	readonly type: readonly ('socks4' | 'socks5' | 'https' | 'http')[];
	readonly username: string;
	readonly wallets: readonly UpdateProxyWalletsType[];
};

export type DeleteProxyType = { readonly proxies: readonly string[] };

export type GetCheckedType = {
	readonly checked: boolean;
	readonly proxies: readonly ProxiesType[];
};

export type AddProxiesType = {
	readonly proxies: readonly CheckAllMultiAddingProxyType[];
	readonly update_existed: boolean;
};

export type CheckAllMultiAddingProxyStatusesType = {
	readonly verified: number | string;
	readonly proxies: readonly CheckAllMultiAddingProxyType[];
};

export type CheckAllMultiAddingProxyType = {
	readonly country_code: string;
	readonly in_system: boolean;
	readonly ip_host: string;
	readonly password: string;
	readonly port: string;
	readonly status: 0 | 1;
	readonly type: string;
	readonly username: string;
};

export type InitCheckingMultiAddingProxiesType = {
	readonly country_code: string;
	readonly ip_host: string;
	readonly password: string;
	readonly port: string;
	readonly type: string;
	readonly username: string;
};

export type ProxiesType = {
	readonly country_code: string;
	readonly created_at: string;
	readonly id: number;
	readonly ip: string;
	readonly uuid: string;
	readonly is_checking: number;
	readonly last_checked_at: string;
	readonly password: string;
	readonly port: number;
	readonly status: 0 | 1;
	readonly type: string;
	readonly updated_at: string;
	readonly username: string;
	readonly wallets: readonly number[];
};

export type GetProxiesType = {
	readonly proxies: readonly ProxiesType[];
};
