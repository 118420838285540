import { t } from 'pay-kit';
import { number, object, string } from 'yup';

const validationSchema = object().shape({
	// amount: number().typeError('Введите число').required("Обязательное поле"),
	amount: number().typeError(t('Input number')).required(t('Required field')),
	// currency: string().required("Выберите валюту"),
	currency: string().required(t('Choose currency')),
});

export const validate = (formData: any) => {
	try {
		validationSchema.validateSync(formData, { abortEarly: false });
	} catch (e: any) {
		const err: any = {};

		e.inner.forEach((error: any) => {
			if (error.path) {
				err[error.path] = error.message;
			}
		});

		return err;
	}

	return {};
};
