import { useTranslation } from 'pay-kit';
import { Button, Modal } from '@paykassma/pay-kit';
import React from 'react';

import styles from './DeleteReports.module.scss';

type DeleteReportsModalPropsType = {
	readonly fileNames?: readonly string[];
	readonly onClose: () => void;
	readonly onSuccess: () => void;
};

const DeleteReportsModal: React.FC<DeleteReportsModalPropsType> = ({
	fileNames,
	onClose,
	onSuccess,
}) => {
	const { t } = useTranslation();

	return (
		<Modal
			isOpen={fileNames !== undefined}
			title={t('Reports deletion')}
			onClose={onClose}
			className={styles.modal}
		>
			<div className={styles.text}>
				{t('Are you sure you want to delete selected reports?')}
			</div>
			<div className={styles.actions}>
				<Button onClick={onClose} variant='secondary' data-test-id='cancel'>
					{t('Cancel')}
				</Button>
				<Button onClick={onSuccess} variant='delete' data-test-id='delete'>
					{t('Delete')}
				</Button>
			</div>
		</Modal>
	);
};

export default DeleteReportsModal;
