import { useTranslation } from '@paykassma/pay-kit';
import api, { UpdateSettingsDataType } from 'api/walletTypesGroup';
import { useState } from 'react';
import { errorsMap } from 'utils/enums';

export type UpdateSettingsHookType = (args?: {
	readonly onSuccess?: (data: any) => void;
	readonly onError?: (err: Error) => void;
}) => {
	readonly update: (data: UpdateSettingsDataType) => void;
	readonly isLoading: boolean;
};

const UpdateSettingsHook: UpdateSettingsHookType = (props) => {
	const [isLoading, setLoading] = useState<boolean>(false);
	const { t } = useTranslation();

	const update = (data: UpdateSettingsDataType) => {
		setLoading(true);

		api
			.updateSettings(prepareData(data))
			.then((res: any) => {
				if (res?.status === 'success' && res?.message === '') {
					props?.onSuccess && props?.onSuccess(res);

					window.pushAlert({
						title: t('Settings saved'),
						type: 'success',
					});
				} else {
					// throw new Error("UpdateSettings – Unexpected response");
					throw new Error(errorsMap.anyResponse);
				}

				return res;
			})
			.finally(() => {
				setLoading(false);
			})
			.catch((err) => {
				props?.onError && props?.onError(err);
				console.error(err);

				window.pushAlert({
					title: errorsMap.cantUpdate,
					type: 'error',
				});
			});
	};

	return {
		update,
		isLoading,
	};
};

export default UpdateSettingsHook;

const prepareData = (data: UpdateSettingsDataType) => {
	const dataClone = JSON.parse(JSON.stringify(data));

	Object.keys(dataClone).forEach((key: string) => {
		if (dataClone[key] === null) {
			delete dataClone[key];
		}
	});

	return dataClone;
};
