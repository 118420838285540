import { fetchApi } from 'api/fetch';

const commissionGroup = {
	getSettlementCommission: (
		params: Partial<GetSettlementCommissionParamsType>,
	) =>
		fetchApi<GetSettlementCommissionType>(
			`commission/settlement_commission/${params.id}?amount=${params.amount}`,
		),
};

export default commissionGroup;

export type GetSettlementCommissionParamsType = {
	readonly id: string;
	readonly amount: string;
};

export type GetSettlementCommissionType = {
	readonly percent_commission: string;
	readonly total_commission: string;
};
