import { Button, Table } from '@paykassma/pay-kit';
import { useTranslation } from 'pay-kit';
import { useEffect, useState } from 'react';

import PlusIcon from '/assets/icons/plus.svg';
import RefreshIcon from '/assets/icons/refresh.svg';
import TrashIcon from '/assets/icons/trash_16x16.svg';

import ActionsProvider, { ActionsContext } from './ActionsProvider';
import styles from './AutoReports.module.scss';
import columns from './components/columns';
import useGetAutoReports from './hooks/useGetAutoReports';

const AutoReports = () => {
	const getAutoReportsAPI = useGetAutoReports();
	const [selectedIds, setSelectedIds] = useState<readonly (string | number)[]>(
		[],
	);
	const { t } = useTranslation();

	const getAutoReports = () => {
		getAutoReportsAPI.load();
	};

	useEffect(getAutoReports, []);

	return (
		<ActionsProvider refreshList={getAutoReports}>
			<ActionsContext.Consumer>
				{({ remove, create }) => (
					<>
						<div className={styles.topActions}>
							<Button data-test-id='create-new' onClick={() => create(true)}>
								<PlusIcon />
								&nbsp;&nbsp;{t('Create task')}
							</Button>
							<Button
								data-test-id='refresh'
								onClick={getAutoReports}
								variant='text-primary'
							>
								<RefreshIcon />
								&nbsp;&nbsp;{t('Refresh')}
							</Button>
						</div>

						<Table
							rowKey='id'
							data={getAutoReportsAPI.list}
							columns={columns(t)}
							isLoading={getAutoReportsAPI.isLoading}
							select={{
								selectedIds,
								multiselect: true,
								onRowsSelect: (ids: readonly (string | number)[]) =>
									setSelectedIds(ids),
								renderMultipleActions: (ids) => (
									<button
										onClick={() => remove(ids)}
										className={styles.removeSelectedItemsButton}
										data-test-id='removeSelectedItemsButton'
									>
										<TrashIcon />
										&nbsp;&nbsp;{t('Delete')}
									</button>
								),
							}}
						/>
					</>
				)}
			</ActionsContext.Consumer>
		</ActionsProvider>
	);
};

export default AutoReports;
