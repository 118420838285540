import API from 'api';
import { useTranslation } from 'pay-kit';
import {
	Alert,
	Button,
	Modal,
	Select,
	TextInput,
	Textarea,
	Toggler,
} from 'pay-kit';
import React, { useEffect, useState } from 'react';
import { errorsMap } from 'utils/enums';

import styles from './ApproveModal.module.scss';

type senderWalletType = {
	readonly hash_id: string;
	readonly identifier: string;
};

interface IApproveModal {
	readonly id: number | undefined;
	readonly onClose: () => void;
	readonly refreshHandler?: () => void;
}

const ApproveModal: React.FC<IApproveModal> = ({
	id,
	onClose,
	refreshHandler,
}) => {
	const { t } = useTranslation();
	const [comment, setComment] = useState<string>('');
	const [wallets, setWallets] = useState<readonly senderWalletType[]>([]);
	const [walletsListIsLoading, setWalletsListIsLoading] =
		useState<boolean>(false);
	const [wallet_sender, setWalletSender] = useState<string | undefined>(
		undefined,
	);
	const [isManualInput, setManualInput] = useState<boolean>(false);
	// const walletSenderError = (wallet_sender !== undefined && wallet_sender.length > 20) ? "Не более 20 символов" : undefined;
	const walletSenderError =
		wallet_sender !== undefined && wallet_sender.length > 20
			? t('Not more than 20 characters')
			: undefined;
	const canSubmit =
		comment !== '' &&
		wallet_sender !== undefined &&
		wallet_sender !== '' &&
		walletSenderError === undefined;

	useEffect(() => {
		if (id !== undefined) {
			getWalletsList(id);
		} else {
			setWallets([]);
			setWalletSender(undefined);
			setComment('');
			setManualInput(false);
		}

		return () => setWallets([]);
	}, [id]);

	const getWalletsList = (id: number) => {
		setWalletsListIsLoading(true);

		API.withdrawal
			.getWalletsSender(id)
			.then((res: any) => {
				setWalletsListIsLoading(false);

				if (res?.data && res?.data?.wallets) {
					setWallets(res.data.wallets);

					return res;
				} else {
					window.pushAlert({
						// description: `Произошла ошибка при попытке получить список кошельков для вывода`,
						description: t('Failed to get list of wallets for withdrawal'),
						type: 'error',
					});

					// throw new Error("Invalid response format – getWalletsSender");
					throw new Error(errorsMap.anyResponse);
				}
			})
			.catch((err) => {
				setWalletsListIsLoading(false);
				console.error(err);
			});
	};

	const sendApproveRequest = () => {
		if (id === undefined || !canSubmit) {
			return;
		}

		API.withdrawal
			.approve(id, comment, wallet_sender)
			.then((res: any) => {
				if (res.status === 'ok') {
					window.pushAlert({
						// description: `Вывод подтверждён`,
						description: t('Withdrawal approved'),
						type: 'success',
					});

					onClose();
					refreshHandler && refreshHandler();

					return res;
				} else {
					window.pushAlert({
						// description: `Произошла ошибка при попытке подтвердить вывод`,
						description: t('Failed to approve withdrawal'),
						type: 'error',
					});

					// throw new Error("Invalid response in withdrawal approve");
					throw new Error(errorsMap.anyResponse);
				}
			})
			.catch((err) => {
				console.error(err);
			});
	};

	return (
		<Modal
			isOpen={id !== undefined}
			title={t('Withdrawal approval')}
			onClose={onClose}
		>
			<div className={styles.form}>
				<Toggler
					label={t('Manual fill')}
					value={isManualInput}
					onChange={(bool) => {
						if (!bool) {
							setWalletSender(undefined);
						}

						setManualInput(bool);
					}}
					data-test-id=''
				/>

				{isManualInput ? (
					<TextInput
						// label="Номер кошелька для вывода *"
						label={t('Wallet number')}
						value={wallet_sender === undefined ? '' : wallet_sender.toString()}
						onChange={(e) => setWalletSender(e.target.value)}
						name='wallet_sender'
						data-test-id=''
						error={walletSenderError}
					/>
				) : (
					<Select
						value={wallet_sender}
						name='wallet_sender'
						// label="Номер кошелька для вывода *"
						label={t('Wallet number')}
						options={wallets.map((wallet) => ({
							value: wallet.identifier,
							label: `${wallet.identifier}`,
						}))}
						isLoading={walletsListIsLoading}
						onChange={(v) => setWalletSender(v as string)}
						placeholder=''
						data-test-id=''
						noError
					/>
				)}

				<Textarea
					label={t('Comment')}
					rows={2}
					data-test-id='comment'
					value={comment}
					onChange={(v) => setComment(v.target.value as string)}
				/>

				<Alert
					variant='info'
					// title={"* Если необходимого кошелька нет в списке, выберите вариант «Ввод вручную» и заполните номер кошелька вручную."}
					title={t(
						'* If the wallet is not in the list, select the manual fill option and enter the wallet number manually.',
					)}
				/>

				<div className={styles.actions}>
					<Button
						disabled={!canSubmit}
						data-test-id=''
						onClick={sendApproveRequest}
					>
						{t('Confirm')}
					</Button>
				</div>
			</div>
		</Modal>
	);
};

export default ApproveModal;
