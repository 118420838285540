import API from 'api';
import { ICurrency } from 'api/currencyGroup';
import React, { useContext, useEffect, useState } from 'react';
import { errorsMap } from 'utils/enums';

import AuthContext from './AuthContext';

export const CurrenciesContext = React.createContext({
	list: [] as readonly ICurrency[],
	isLoading: false,
});

type CurrenciesContextProviderPropsType = {
	readonly children: React.ReactNode;
};

const CurrenciesContextProvider = ({
	children,
}: CurrenciesContextProviderPropsType) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [list, setList] = useState<readonly ICurrency[]>([]);

	const { isAuth } = useContext(AuthContext);

	const getCurrenciesList = () => {
		setIsLoading(true);
		setList([]);

		API.currency
			.getList()
			.then((res: any) => {
				setIsLoading(false);

				if (res?.status === 'success' && res?.data?.currencies) {
					setList(res?.data?.currencies);
				} else {
					throw new Error('Invalid response');
				}

				return res;
			})
			.catch((err) => {
				setIsLoading(false);

				// window.pushAlert({ type: "error", description: "getCurrenciesList error" });
				window.pushAlert({
					type: 'error',
					description: errorsMap.cantGetCurrenciesData,
				});
			});
	};

	useEffect(() => {
		if (isAuth) {
			getCurrenciesList();
		}
	}, [isAuth]);

	return (
		<CurrenciesContext.Provider
			value={{
				isLoading,
				list,
			}}
		>
			{children}
		</CurrenciesContext.Provider>
	);
};

export default CurrenciesContextProvider;

export type getListSuccessResponseType = {
	readonly status: 'ok';
	readonly currencies: readonly ICurrency[];
};
