// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TransactionsList__form-wrm{padding:32px}.TransactionsList__form-wrm .TransactionsList__scrollBox-gvo{height:470px}.TransactionsList__form-wrm .TransactionsList__baseSettings-BYN{display:grid;grid-template-columns:450px 450px;width:100%;column-gap:48px;row-gap:12px}.TransactionsList__form-wrm .TransactionsList__actions-NHc{position:sticky;right:0;bottom:0;left:0;background:#f2f3f5;padding-bottom:32px;display:flex;align-items:center;justify-content:flex-end;margin-top:40px;gap:48px}.TransactionsList__form-wrm .TransactionsList__actions-NHc>button:last-child{min-width:170px}`, "",{"version":3,"sources":["webpack://./src/modules/Reports/ReportList/components/ActionsProvider/modals/NewReport/forms/TransactionsList/TransactionsList.module.scss"],"names":[],"mappings":"AAAA,4BACI,YAAA,CAEA,6DACI,YAAA,CAGJ,gEACI,YAAA,CACA,iCAAA,CACA,UAAA,CACA,eAAA,CACA,YAAA,CAGJ,2DACI,eAAA,CACA,OAAA,CACA,QAAA,CACA,MAAA,CACA,kBAAA,CACA,mBAAA,CACA,YAAA,CACA,kBAAA,CACA,wBAAA,CACA,eAAA,CACA,QAAA,CAYA,6EACI,eAAA","sourcesContent":[".form {\n    padding: 32px;\n\n    .scrollBox {\n        height: 470px;\n    }\n\n    .baseSettings {\n        display: grid;\n        grid-template-columns: 450px 450px;\n        width: 100%;\n        column-gap: 48px;\n        row-gap: 12px;\n    }\n\n    .actions {\n        position: sticky;\n        right: 0;\n        bottom: 0;\n        left: 0;\n        background: #F2F3F5;\n        padding-bottom: 32px;\n        display: flex;\n        align-items: center;\n        justify-content: flex-end;\n        margin-top: 40px;\n        gap: 48px;\n\n        // .formatSwitcher {\n        //     display: flex;\n        //     align-items: center;\n        //     gap: 16px;\n\n        //     button {\n        //         padding: 0 16px;\n        //     }\n        // }\n\n        > button:last-child {\n            min-width: 170px;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": `TransactionsList__form-wrm`,
	"scrollBox": `TransactionsList__scrollBox-gvo`,
	"baseSettings": `TransactionsList__baseSettings-BYN`,
	"actions": `TransactionsList__actions-NHc`
};
export default ___CSS_LOADER_EXPORT___;
