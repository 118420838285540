const DisableIcon = () => {
	return (
		<svg
			width='16'
			height='16'
			viewBox='0 0 16 16'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M3.33398 13.3334C7.23923 9.42813 9.42874 7.23862 13.334 3.33337'
				stroke='currentColor'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M12.395 4.69328C13.1882 5.74762 13.576 7.052 13.4876 8.36847C13.3992 9.68494 12.8405 10.9258 11.9134 11.8646C10.9863 12.8034 9.75261 13.3777 8.43736 13.4826C7.1221 13.5875 5.81295 13.2161 4.74873 12.4361'
				stroke='currentColor'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M3.09667 10.4914C2.55935 9.43397 2.3783 8.23113 2.58052 7.06234C2.78274 5.89356 3.35739 4.82146 4.21875 4.00598'
				stroke='currentColor'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M8.08677 5.421C8.08677 2.0868 8.08683 5.66755 8.08683 2.33334'
				stroke='currentColor'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
};

export default DisableIcon;
