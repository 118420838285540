import { Table } from '@paykassma/pay-kit';
import {
	IArchivedTransaction,
	ITransactionsFilterForm,
} from 'api/transactionGroup';
import AuthContext, { Roles } from 'contexts/AuthContext';
import React, { useContext, useEffect, useState } from 'react';
import { fromLocaleDate } from 'utils/date';
import useURLState from 'utils/hooks/useURLState';
import useURLFilters from 'utils/hooks/useURLFilters';

import ReloadButton from '../../Postbacks/components/ReloadButton';
import {
	archivedTransactionsTableColumns,
	archivedTransactionsTableSubRow,
} from '../components/columns';
import useArchivedTransactionsListAPI from '../hooks/useArchivedTransactionsListAPI';
import {
	FilterErrors,
	isFilterInvalid,
	validateFilters,
} from '../CurrentTransactions/Filter/utils';

import styles from './ArchivedTransactions.module.scss';
import DetailedView from './DetailedView';
import ArchivedTransactionsFilter from './Filter';

const ArchivedTransactions: React.FC = () => {
	const [page, setCurrentPage] = useURLState<number>('page', 1);
	const [limit, setLimit] = useURLState<number>('limit', 10);
	const [filter, setFilter] = useURLFilters({
		dateRangeFields: ['date', 'activated_date'],
	});
	// Валидация фильтров
	const [filterErrors, setFilterErrors] = useState<FilterErrors>({
		transaction_id: null,
	});
	const {
		transactions,
		isLoading,
		error,
		paginationInfo,
		getTransactionsList,
	} = useArchivedTransactionsListAPI();
	const [detailedTransactionViewId, setDetailedTransactionViewId] = useState<
		number | string | null
	>(null);
	const { hasRole } = useContext(AuthContext);
	const commissionAccess = hasRole(Roles.COMMISSION_VIEW);

	const loadTransactions = () =>
		getTransactionsList({ limit, page, ...transformFilterData(filter) });

	useEffect(loadTransactions, [limit, page, filter]);

	const detailedTransactionViewData = detailedTransactionViewId
		? transactions.find(
				(transaction) => transaction.primary_id === detailedTransactionViewId,
			)
		: null;

	return (
		<>
			<DetailedView
				transactionData={detailedTransactionViewData as IArchivedTransaction}
				onClose={() => setDetailedTransactionViewId(null)}
			/>
			<ArchivedTransactionsFilter
				value={filter}
				onApply={(value) => {
					const errors = validateFilters(value as FilterErrors);

					setFilterErrors(errors);

					if (isFilterInvalid(errors)) {
						return;
					}

					setCurrentPage(1);
					setFilter(value);
				}}
				errors={filterErrors}
				setErrors={setFilterErrors}
			/>
			<Table
				className={styles.ArchivedTransactionsTable}
				columns={archivedTransactionsTableColumns(commissionAccess)}
				data={transactions}
				rowKey='primary_id'
				message={error ? { text: `${error}`, type: 'error' } : undefined}
				isLoading={isLoading}
				skeleton={{ rowsCount: limit }}
				onRowClick={setDetailedTransactionViewId}
				stickyHead
				subRowRender={archivedTransactionsTableSubRow}
				paginator={
					paginationInfo
						? {
								currentPage: paginationInfo?.current_page || 1,
								setCurrentPage: (page) => setCurrentPage(page as number),
								limit,
								setLimit,
								lastAvailablePage: paginationInfo?.last_available_page || 1,
								prefixElement: (
									<ReloadButton
										data-test-id='SFAzTrprG5NJEzs8dуу2uRHM'
										isLoading={isLoading}
										onClick={loadTransactions}
									/>
								),
								className: styles.paginator,
								bottomPaginatorProps: {
									className: styles.bottomPaginator,
								},
							}
						: undefined
				}
			/>
		</>
	);
};

const transformFilterData = (data: any) => {
	const dataClone = JSON.parse(JSON.stringify(data));

	if (
		dataClone.date !== undefined &&
		dataClone.date.from !== undefined &&
		dataClone.date.to !== undefined
	) {
		dataClone.date_from = fromLocaleDate(dataClone.date.from);
		dataClone.date_to = fromLocaleDate(dataClone.date.to);
		delete dataClone.date;
	}

	if (
		dataClone.activated_date !== undefined &&
		dataClone.activated_date.from !== undefined &&
		dataClone.activated_date.to !== undefined
	) {
		dataClone.activated_date_from = fromLocaleDate(
			dataClone.activated_date.from,
		);

		dataClone.activated_date_to = fromLocaleDate(dataClone.activated_date.to);
		delete dataClone.activated_date;
	}

	return dataClone as ITransactionsFilterForm;
};

export default ArchivedTransactions;
