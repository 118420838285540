import React from 'react';

import styles from './Skeleton.module.scss';

type SkeletonPropsType = {
	readonly rows: number;
};

const Skeleton: React.FC<SkeletonPropsType> = ({ rows }) => {
	if (!rows) {
		return null;
	}

	const rowsNumberArray = Array.from(Array(rows).keys());

	return (
		<>
			{rowsNumberArray.map((rowNumber) => (
				<div className={styles.row} key={rowNumber} />
			))}
		</>
	);
};

export default Skeleton;
