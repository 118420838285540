import { useTranslation } from 'pay-kit';
import { Roles, useHasAccessHook } from 'contexts/AuthContext';
import ArchivedTransactions from 'modules/Transactions/ArchivedTransactions';
import { useEffect } from 'react';

export default function ArchivedTransactionsPage() {
	const { t } = useTranslation();

	useEffect(() => {
		document.title = t('Archived transactions');
	}, []);

	useHasAccessHook({ rule: Roles.ARCHIVE_TRANSACTION_VIEW, redirect: true });

	return <ArchivedTransactions />;
}
