import { useTranslation } from 'pay-kit';
import { MouseEventHandler } from 'react';
import { errorsMap } from 'utils/enums';

import styles from './CopyTextButton.module.scss';

type CopyTextButtonPropsType = {
	readonly text: string | number;
	readonly onSuccess?: (text: string) => void;
	readonly onFail?: (err: Error) => void;
};

const CopyTextButton = ({
	text,
	onSuccess,
	onFail,
}: CopyTextButtonPropsType) => {
	const { t } = useTranslation();

	const copyToClipboard: MouseEventHandler = (e) => {
		e.stopPropagation();

		const safeLengthString =
			`${text}`.length > 100 ? `${text}`.substring(0, 150) + '...' : text;

		navigator.clipboard
			.writeText(text.toString())
			.then((res) => {
				if (onSuccess) {
					onSuccess(text.toString());
				} else {
					window.pushAlert({
						description: t(`Copied: ${safeLengthString}`, { safeLengthString }),
						type: 'success',
					});
				}

				return res;
			})
			.catch((err) => {
				if (onFail) {
					onFail(err);
				} else {
					window.pushAlert({ description: errorsMap.cantCopy, type: 'error' });
				}
			});
	};

	return (
		<button
			data-test-id='6gKfMOI4Uw122S1bcQivR'
			className={styles.CopyTextButton}
			onClick={copyToClipboard}
		>
			<svg
				width='20'
				height='20'
				viewBox='0 0 20 20'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
			>
				<path
					d='M8 2C6.89543 2 6 2.89543 6 4V14C6 15.1046 6.89543 16 8 16H14C15.1046 16 16 15.1046 16 14V4C16 2.89543 15.1046 2 14 2H8ZM7 4C7 3.44772 7.44772 3 8 3H14C14.5523 3 15 3.44772 15 4V14C15 14.5523 14.5523 15 14 15H8C7.44772 15 7 14.5523 7 14V4ZM4 6.00001C4 5.25973 4.4022 4.61339 5 4.26758V14.5C5 15.8807 6.11929 17 7.5 17H13.7324C13.3866 17.5978 12.7403 18 12 18H7.5C5.567 18 4 16.433 4 14.5V6.00001Z'
					fill='currentColor'
				/>
			</svg>
		</button>
	);
};

export default CopyTextButton;
