import { PSAndBalancesType } from 'api/balanceGroup';

export const hideZeroBalances = (
	balances: readonly PSAndBalancesType[],
	hideZeroBalance: boolean,
) => {
	if (!hideZeroBalance || !balances.length) {
		return balances;
	}

	const mappedBalances = balances.map(({ balances, ...rest }) => {
		const ParticularPSBalances = balances
			.map((balanceData) => {
				if (parseInt(`${balanceData.amount}`) === 0) {
					return;
				}

				return balanceData;
			})
			.filter((amount) => amount);

		if (ParticularPSBalances.length) {
			return { ...rest, balances: ParticularPSBalances };
		}
	});

	return mappedBalances.filter(
		(balances) => balances?.balances.length,
	) as readonly PSAndBalancesType[];
};
