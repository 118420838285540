/* eslint-disable ternary/no-unreachable */
import { Button, PayKitForm } from "@paykassma/pay-kit";
import PlusIcon from "assets/icons/plus.svg";
import TrashIcon from "assets/icons/trash.svg";
import { WalletTypesContext } from "contexts/WalletTypesContext";
import AdditionalSettingsSection from "modules/Reports/ReportList/components/ActionsProvider/modals/NewReport/components/AdditionalSettingsSection";
import { useTranslation } from "pay-kit";
import React, { useContext } from "react";
import { isValidEmail } from "utils/isValidEmail";

import { ActiveWalletsContext } from '../../../ActionsProvider/ActiveWalletsContext';

import styles from './TransactionsList.module.scss';

type TransactionsSumProps = {
	readonly onSubmit: (data: TransactionsListFilledFormStateType) => void;
	readonly isLoading: boolean;
	readonly initialState: FormStateType;
	readonly editMode?: boolean;
};

const TransactionsList: React.FC<TransactionsSumProps> = ({
	onSubmit,
	isLoading,
	initialState,
	editMode,
}) => {
	const { t } = useTranslation();
	const walletTypesContext = useContext(WalletTypesContext);
	const activeWallets = useContext(ActiveWalletsContext);

	// wallet types
	const walletTypesOptions = [
		{ label: t('All') },
		...walletTypesContext.walletTypes.map(({ name, code }) => ({
			label: name,
			value: code,
		})),
	];

	// wallets (filtering by wallet type)
	const getWalletsOptions = (wallet_type: string) => [
		{ label: t('All') },
		...activeWallets.list
			.filter((w) => (!wallet_type ? false : w.wallet_type === wallet_type))
			.map((w) => ({ label: `${w.identifier}`, value: w.hash_id })),
	];

	// currencies
	const getCurrenciesOfCurrentWalletType = (wallet_type: undefined | string) =>
		walletTypesContext.walletTypes
			.find(({ code }) => wallet_type === code)
			?.supported_currencies.map((c) => ({ label: c, value: c })) || [];

	const allCurrencies = walletTypesContext.walletTypes
		.map((c) => c.supported_currencies)
		.reduce((accum, current) => [...new Set([...accum, ...current])], []);

	const allCurrenciesOptions = allCurrencies.map((c) => ({
		label: c,
		value: c,
	}));

	const FORM_SCHEMA: any = [
		{
			type: 'Group',
			elements: [
				{
					type: 'BaseSettingsSection',
					elements: [
						{
							name: 'wallet_type',
							label: t('Wallet type'),
							type: 'Select',
							options: walletTypesOptions,
							isLoading: walletTypesContext.isLoading,
						},
						{
							name: 'direction',
							label: t('Direction'),
							type: 'Select',
							options: [
								{ label: t('All') },
								{ value: 'outgoing', label: t('Outgoing') },
								{ value: 'ingoing', label: t('Ingoing') },
							],
						},
						{
							name: 'wallet_hash_id',
							label: t('Wallet'),
							type: 'Select',
							isLoading: activeWallets.isLoading,
							disabled: ({ wallet_type }: FormStateType) => !wallet_type,
							options: ({ wallet_type }: FormStateType) =>
								getWalletsOptions(wallet_type as string),
						},
						{
							name: 'type',
							label: t('Transaction kind'),
							type: 'MultiSelect',
							placeholder: t('All'),
							options: [
								{ label: t('All') },
								{ label: t('Confirmed'), value: 0 },
								{ label: t('Forced'), value: 2 },
								{ label: t('Debug'), value: 1 },
							],
						},
						{
							name: 'report_currency_code',
							label: t('Currency'),
							placeholder: '',
							type: 'Select',
							options: ({ wallet_type }: FormStateType) =>
								wallet_type
									? getCurrenciesOfCurrentWalletType(wallet_type)
									: allCurrenciesOptions,
							isLoading: walletTypesContext.isLoading,
							isRequired: true,
							validation: (
								value: string,
								{ report_currency_code }: FormStateType,
							) =>
								report_currency_code === undefined
									? t('Choose currency')
									: undefined,
						},
						{
							name: 'creation_type',
							label: t('Transaction type'),
							type: 'Select',
							options: [
								{ label: t('All') },
								{ label: t('Auto'), value: 'auto' },
								{ label: t('Manual'), value: 'manual' },
							],
						},
						{
							name: 'archiving_status',
							label: t('Transaction archiving status'),
							type: 'Select',
							options: [
								{ label: t('All') },
								{ label: t('Archived only'), value: 'onlyArchived' },
								{ label: t('Not archived only'), value: 'withoutArchived' },
							],
						},
						{
							name: 'status',
							label: t('Transaction status'),
							type: 'Select',
							options: [
								{ label: t('All') },
								{ label: t('Activated'), value: 1 },
								{ label: t('Not activated'), value: 0 },
							],
						},
						{
							name: 'originality',
							label: t('Originality'),
							type: 'Select',
							options: [
								{ label: t('All') },
								{ label: t('Normal'), value: true },
								{ label: t('Scam'), value: false },
							],
						},
						{ name: 'label', label: t('Label'), type: 'TextInput' },
						{
							name: 'exchanger_identifier',
							label: t('Counterparty'),
							type: 'TextInput',
						},
						{ name: 'utc_0', label: t('Timezone UTC+00:00'), type: 'Toggler' },
						{
							type: 'PeriodSettings',
							elements: [
								{
									name: 'start',
									label: t('Start'),
									type: 'DatePicker',
									isRequired: true,
									validation: (value: string) =>
										!value ? t('Field is required') : undefined,
									dateFormat: `DD.MM.YYYY`,
									withTime: true,
									customStyles: () => ({
										right: `unset`,
										transform: `translateX(170px)`,
									}),
								},
								{
									name: 'reporting_period',
									label: t('Reporting period'),
									validation: (value: string) =>
										!value ? t('Field is required') : undefined,
									type: 'Select',
									options: [
										{ label: t('Day'), value: 'day' },
										{ label: t('Week'), value: 'week' },
										{ label: t('Mounth'), value: 'month' },
										{ label: t('Quarter'), value: 'quarter' },
										{ label: t('Year'), value: 'year' },
									],
									isRequired: true,
								},
							],
						},
						{
							type: 'Repeat',
							name: 'emails',
							validation: (emails) =>
								!emails || emails.length < 1
									? t('Please specify at least one recipient')
									: undefined,
							render: (
								children: readonly React.ReactElement[],
								{ insertItem, error }: any,
							) => (
								<div className={styles.recepient}>
									<h3>
										{t('Recipient')}
										<Button
											variant='text-primary'
											onClick={() => insertItem()}
											data-test-id='add-new-recepient'
										>
											<PlusIcon />
											{t('Add email')}
										</Button>
									</h3>
									<div className={styles.recepientsList}>{children}</div>
									{error && <div className={styles.error}>{error}</div>}
								</div>
							),
							elements: [
								{
									type: 'Group',
									render: (children, { removeCurrentItem }) => (
										<div
											style={{
												display: 'flex',
												gap: '8px',
											}}
										>
											{children}
											<button
												data-test-id='removeAddress'
												onClick={removeCurrentItem}
											>
												<TrashIcon />
											</button>
										</div>
									),
									elements: [
										{
											type: 'Group',
											render: (children) => (
												<div
													style={{
														flexGrow: '1',
													}}
												>
													{children}
												</div>
											),
											elements: [
												{
													name: 'email',
													label: 'Email',
													placeholder: 'Email',
													type: 'TextInput',
													isRequired: true,
													validation: [
														(value: string) =>
															!value ? t('Field is required') : undefined,
														(email: string) =>
															!isValidEmail(email)
																? t('Invalid email')
																: undefined,
													],
												},
											],
										},
									],
								},
							],
						},
					],
				},
				{
					type: 'AdditionalSettingsSection',
					elements: [
						{
							type: 'CheckboxGroup',
							name: 'fields',
							options: [
								{
									value: 'wallet_identifier',
									label: t('Wallet number'),
								},
								{
									value: 'transaction_number',
									label: t('Transaction number'),
								},
								{
									value: 'vendor_code',
									label: t('Wallet provider code'),
								},
								{
									value: 'merchant_order_id',
									label: t('Order ID'),
								},
								{
									value: 'exchanger_identifier',
									label: t('Counterparty'),
								},
								{
									value: 'total_commission',
									label: t('Commission'),
								},
								{
									value: 'date',
									label: t('Date'),
								},
								{
									value: 'activated_date',
									label: t('Activation date'),
								},
								{
									value: 'label',
									label: t('Label'),
								},
								{
									value: 'status',
									label: t('Status'),
								},
								{
									value: 'type',
									label: t('Transaction kind'),
								},
								{
									value: 'creation_type',
									label: t('Transaction type'),
								},
								{
									value: 'archiving_date',
									label: t('Archiving date'),
								},
								{
									value: 'originality',
									label: t('Originality'),
								},
							],
						},
					],
				},
			],
			// render: (children) => (
			// 	<ScrollBox scrollDirection="vertical" style={{ maxHeight: 550 }}>
			// 		{children}
			// 	</ScrollBox>
			// ),
		},
		{
			type: 'BottomSection',
			elements: [
				{
					name: "file_format",
					label: t("Report format"),
					type: "Switcher",
					options: [
						{ value: "xlsx", label: "xlsx" },
						{ value: "csv", label: "csv" },
					],
					className: styles.formatSwitcher,
				},
				{
					type: 'SubmitButton',
					name: 'submitButton',
					label: editMode ? t('Save') : t('Create'),
					isLoading,
					onSubmit,
				},
			],
		},
	];

	const customElements = {
		BaseSettingsSection: (props: any) => (
			<PayKitForm.Group
				{...props}
				render={(children) => (
					<div className={styles.baseSettings}>{children}</div>
				)}
			/>
		),
		AdditionalSettingsSection,
		PeriodSettings: (props: any) => (
			<PayKitForm.Group
				{...props}
				render={(children) => (
					<div className={styles.periodSettings}>
						<h3>{t('Period')}</h3>
						{children}
					</div>
				)}
			/>
		),
		BottomSection: (props: any) => (
			<PayKitForm.Group
				{...props}
				render={(children) => <div className={styles.actions}>{children}</div>}
			/>
		),
	};

	return (
		<PayKitForm.Builder<FormStateType>
			schema={FORM_SCHEMA}
			initialState={initialState}
			customElements={customElements}
		/>
	);
};

export default TransactionsList;

type FormStateType = {
	readonly wallet_type?: string; // {t("Wallet type")}	readonly direction?: "outgoing" | "ingoing"; // Направление
	readonly wallet_hash_id?: string; // {t("Wallet")}(shows only when wallet type choosen)
	readonly type?: readonly ('0' | '1' | '2')[]; // Вид транзакций
	readonly report_currency_code?: string; // {t("Currency")}*
	readonly creation_type?: 'auto' | 'manual'; // Тип транзакции
	readonly originality?: boolean;
	readonly start?: string; // {t("Start")}*
	readonly status?: 1 | 0; // 0 :{ text: {t("All")}, value: null}1:{ text: "Активированные", value: 1}2:{ text: "Неактивированные", value: 0}
	readonly label?: string; // {t("Label")}	readonly emails: readonly { readonly email: string }[]; // {t("Recipient")}	readonly exchanger_identifier?: string; // {t("Counterparty")}	readonly utc_0: boolean; // {t("Timezone")}UTC+00:00
	readonly reporting_period?: 'day' | 'week' | 'month' | 'quarter' | 'year';
	readonly file_format: 'xlsx' | 'csv'; // Формат отчета csv | xlsx
	readonly archiving_status?: 'onlyArchived' | 'withoutArchived'; //Статус архивации транзакции
	readonly fields?: readonly (
		| 'wallet_identifier'
		| 'transaction_number'
		| 'vendor_code'
		| 'merchant_order_id'
		| 'exchanger_identifier'
		| 'total_commission'
		| 'date'
		| 'activated_date'
		| 'label'
		| 'status'
		| 'type'
		| 'creation_type'
		| 'archiving_date'
		| 'originality'
	)[];
};

export type TransactionsListFilledFormStateType = FormStateType & {
	readonly reporting_period: string;
	readonly start: string;
};
