import Thumbnail from 'assets/icons/thumbnail.svg';
import { FC } from 'react';

import styles from './thumbnail.module.scss';

const ThumbnailIcon: FC<ThumbnailType> = ({ className }) => {
	return (
		<div className={[styles.thumbnailWrapper, className].join(' ')}>
			<div className={styles.thumbnail}>
				<Thumbnail />
			</div>
		</div>
	);
};

export default ThumbnailIcon;

type ThumbnailType = {
	readonly className?: string;
};
