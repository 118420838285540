import API from 'api';
import {
	GetSettlementCommissionParamsType,
	GetSettlementCommissionType,
} from 'api/commissionGroup';
import { useState } from 'react';

const useGetSettlementCommission = () => {
	const [error, setError] = useState<Error | null>(null);
	const [isLoading, setIsLoading] = useState(false);
	const [data, setData] = useState<GetSettlementCommissionType | null>(null);

	const load = async (params: Partial<GetSettlementCommissionParamsType>) => {
		setError(null);
		setIsLoading(true);

		try {
			const res = await API.commission.getSettlementCommission(params);

			if (res.status === 'success') {
				setData(res.data);
			} else {
				// Handle cases where res.status is not 'success' but not an error
				setError(new Error(res.message || 'Unknown error occurred'));
			}
		} catch (e) {
			console.error(e);
			setError(e instanceof Error ? e : new Error('An unknown error occurred'));
		} finally {
			setIsLoading(false);
		}
	};

	return {
		load,
		data,
		isLoading,
		error,
	};
};

export default useGetSettlementCommission;
