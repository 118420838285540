import React from 'react';

import styles from './StatusCode.module.scss';

type StatusCodePropsType = {
	readonly code: number;
};

const StatusCode: React.FC<StatusCodePropsType> = ({ code }) => {
	switch (code) {
		case 200:
		case 201:
			return <span className={styles.success}>{code}</span>;
		default:
			return <span className={styles.error}>{code}</span>;
	}
};

export default StatusCode;
