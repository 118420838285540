import { useTranslation } from 'pay-kit';
import { Button } from '@paykassma/pay-kit';
/* eslint-disable react/prop-types */
import { TariffType } from 'api/tariffsGroup';
import { useEffect, useState } from 'react';

import { prepareInitialState } from '../PercentEditor/utils';

import CommissionEditor from './CommissionEditor';
import styles from './FixEditor.module.scss';

type FixEditorProps = {
	readonly onSuccess: () => void;
	readonly commissions: TariffType['commissions'];
	readonly direction: 'ingoing' | 'outgoing';
	readonly tariffId: number;
};

const FixEditor: React.FC<FixEditorProps> = ({
	commissions,
	onSuccess,
	direction,
	tariffId,
}) => {
	const { t } = useTranslation();
	const [editableCommissions, setEditableCommissions] = useState(
		prepareInitialState(commissions),
	);

	useEffect(() => {
		setEditableCommissions(commissions);
	}, [commissions]);

	const resetState = () => setEditableCommissions(commissions);

	const addNewCommission = () => {
		setEditableCommissions((prevState) => {
			return [
				...prevState,
				{
					tariff_id: tariffId,
					id: undefined,
					type: 'fix',
					direction,
					commission: '',
					min_amount: '0',
					max_amount: null,
				},
			];
		});
	};

	return (
		<div className={styles.wrapper}>
			<h4>{t('Fix')}</h4>
			{editableCommissions.map((commission, index) => (
				<CommissionEditor
					key={index}
					index={index}
					commission={commission}
					resetState={resetState}
					tariffId={tariffId}
					canRemove={editableCommissions.length > 1}
					onSuccess={onSuccess}
				/>
			))}

			{editableCommissions.length === 0 && (
				<Button
					data-test-id='addNewCommissionButton'
					onClick={addNewCommission}
					variant='text-primary'
				>
					+ {t('Add')}
				</Button>
			)}
		</div>
	);
};

export default FixEditor;
