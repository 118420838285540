import { IPossibleTransactionsFilterForm } from 'api/transactionGroup';
import Filter from 'components/Filter';
import { WalletTypesContext } from 'contexts/WalletTypesContext';
import { useTranslation } from 'pay-kit';
import React, { Dispatch, SetStateAction, useContext } from 'react';
import { rejectSettlement } from 'utils/filterSettlement';
import { FilterErrors } from 'modules/Transactions/CurrentTransactions/Filter/utils';

import styles from './PossibleTransactionsFilter.module.scss';

interface PossibleTransactionsFilterProps {
	readonly value: IPossibleTransactionsFilterForm;
	readonly onApply: (value: IPossibleTransactionsFilterForm) => void;
	readonly errors: Record<string, string | null>;
	readonly setErrors: Dispatch<SetStateAction<FilterErrors>>;
}

const PossibleTransactionsFilter: React.FC<PossibleTransactionsFilterProps> = ({
	value,
	onApply,
	errors,
	setErrors,
}) => {
	const { t } = useTranslation();
	const walletTypesAPI = useContext(WalletTypesContext);

	return (
		<Filter
			values={value}
			onApply={onApply}
			classNames={{ wrapper: styles.filter, inlineFilter: styles.inlineFilter }}
			maxInlineFields={5}
			disabled={!!errors.transaction_id}
			fields={[
				{
					name: 'primary_id',
					placeholder: t('ID'),
					component: 'textInput',
				},
				{
					name: 'transaction_id',
					placeholder: t('Transaction number'),
					component: 'textInput',
					error: errors.transaction_id,
					onChangeFilter: (value: string) => {
						if (errors.transaction_id) {
							if (value.length < 1 || value.length > 3) {
								setErrors({
									transaction_id: null,
								});
							}
						}
					},
				},
				{
					name: 'wallet_type',
					placeholder: t('Wallet type'),
					component: 'select',
					options: rejectSettlement(walletTypesAPI.walletTypes).map((type) => ({
						value: type.code,
						label: type.name,
					})),
					isLoading: walletTypesAPI.isLoading,
				},
				{
					name: 'status',
					placeholder: t('Status'),
					component: 'select',
					options: [
						{ label: t('All'), value: '' },
						{ label: t('New'), value: '0' },
						{ label: t('Processed'), value: '1' },
					],
				},
				{
					name: 'label',
					// placeholder: "Лейбл/Номер заказа",
					placeholder: t('Label/Order ID'),
					component: 'textInput',
				},
				{
					name: 'date_from',
					// placeholder: "Дата создания от",
					placeholder: t('Date created from'),
					component: 'date',
					dateFormat: 'DD.MM.YYYY',
					blockPredicate: (date) => date.getTime() >= new Date().getTime(),
					withTime: true,
					withSeconds: false,
					openOnTop: true,
				},
				{
					name: 'date_to',
					// placeholder: "Дата создания до",
					placeholder: t('Date created to'),
					component: 'date',
					dateFormat: 'DD.MM.YYYY',
					blockPredicate: (date) => date.getTime() >= new Date().getTime(),
					withTime: true,
					isFinishDate: true,
					withSeconds: false,
					openOnTop: true,
				},
			]}
		/>
	);
};

export default PossibleTransactionsFilter;
