import { Roles, useHasAccessHook } from 'contexts/AuthContext';
import { useAuthContext } from 'contexts/AuthContext/AuthContext';
import Section from 'modules/AppSettings/components/Section';
import PluginInstallation from 'modules/AppSettings/Plugin/components/PluginInstallation';
import PluginTheme from 'modules/AppSettings/Plugin/components/PluginTheme';
import PSDisplayOrder from 'modules/AppSettings/Plugin/components/PSDisplayOrder';
import PSDisplayOrderActionsProvider from 'modules/AppSettings/Plugin/components/PSDisplayOrder/context';
import PSHints from 'modules/AppSettings/Plugin/components/PSHints';
import PSHintsActionsProvider from 'modules/AppSettings/Plugin/components/PSHints/context';
import useGetPluginSettings from 'modules/AppSettings/Plugin/hooks/useGetPluginSettings';
import { useTranslation } from 'pay-kit';
import { ScrollBox } from 'pay-kit';
import { useEffect } from 'react';

import styles from './pluginInstallation.module.scss';

const Plugin = () => {
	const getPluginSettingsAPI = useGetPluginSettings();
	const { hasRole } = useAuthContext();
	const { t } = useTranslation();
	const hasPluginSettingsRole = hasRole(Roles.PLUGIN_SETTINGS);
	const hasPluginSettingsClientRole = hasRole(Roles.PLUGIN_SETTINGS);

	useHasAccessHook({ rule: Roles.PLUGIN_SETTINGS, redirect: true });

	useEffect(() => {
		if (hasPluginSettingsRole) {
			getPluginSettingsAPI.load();
		}
	}, [getPluginSettingsAPI.load]);

	return (
		<div className={styles.container}>
			{hasPluginSettingsClientRole && (
				<Section
					header={t('Plugin installation')}
					className={styles.pluginSection}
				>
					<PluginInstallation />
				</Section>
			)}

			{hasPluginSettingsClientRole && (
				<Section header={t(`Plugin theme`)}>
					<PluginTheme
						theme={getPluginSettingsAPI.data?.payment_plugin_color_scheme}
						isPluginSettingsLoading={getPluginSettingsAPI.isLoading}
					/>
				</Section>
			)}

			{(hasPluginSettingsRole || hasPluginSettingsClientRole) && (
				<>
					{hasRole(Roles.MEDIA_SETTINGS) && (
						<Section
							header={t(`Upload supporting media`)}
							className={styles.psHintsWrapper}
						>
							<PSHintsActionsProvider>
								<ScrollBox
									className={styles.psHintsScroll}
									scrollDirection='vertical'
								>
									<PSHints />
								</ScrollBox>
							</PSHintsActionsProvider>
						</Section>
					)}
				</>
			)}
		</div>
	);
};

export default Plugin;
