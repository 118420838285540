import PageTitle from 'components/PageTitle';
import RoutedTabs, { RoutedTab } from 'components/RoutedTabs';
import { useTranslation } from 'pay-kit';
import { Outlet } from 'react-router-dom';

const WalletsDebuggingPage = () => {
	const { t } = useTranslation();

	const tabs: readonly RoutedTab[] = [
		{ value: '/logs', label: t('Log list') },
		{ value: '/logs/withdrawals-logs', label: t('Withdrawals logs') },
	];

	return (
		<>
			<PageTitle title={t('Debugging wallets')} />
			<RoutedTabs tabs={tabs} />
			<Outlet />
		</>
	);
};

export default WalletsDebuggingPage;
