import CopyTextButton from 'components/CopyTextButton';
import { Tooltip } from 'pay-kit';
import React from 'react';

import styles from './TextWithCopyButton.module.scss';

interface ITextWithCopyButtonProps {
	readonly text: string | number | null | undefined;
	readonly className?: string;
	readonly textClassName?: string;
	readonly hideCopyButton?: boolean;
	readonly dataTestId?: string;
}

const TextWithCopyButton: React.FC<ITextWithCopyButtonProps> = ({
	text,
	hideCopyButton,
	className,
	textClassName,
	dataTestId,
}) => {
	if (!text && text !== 0) {
		return null;
	}

	const classNames = [styles.TextWithCopyButton, className];

	return (
		<div className={classNames.join(' ')}>
			<Tooltip tip={text} preferredSide={'bottom'}>
				<div
					className={[styles.textWrapper, textClassName].join(' ')}
					data-test-id={dataTestId}
				>
					<span className={styles.text}>{text}</span>
				</div>
			</Tooltip>
			{!hideCopyButton && <CopyTextButton text={text} />}
		</div>
	);
};

export default TextWithCopyButton;
