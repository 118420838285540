export default function WarningIcon() {
	return (
		<svg
			width='33'
			height='32'
			viewBox='0 0 33 32'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M14.8647 1.4421C16.4231 0.539744 18.3686 1.06407 19.3246 2.60194L19.4487 2.81789L31.0777 24.7665C31.3547 25.2893 31.5 25.877 31.5 26.4746C31.5 28.3405 30.1134 29.8678 28.3586 29.9919L28.1277 30H4.87289C4.30139 30 3.73926 29.8482 3.23929 29.5587C1.68079 28.6566 1.07372 26.6545 1.81345 24.9914L1.92271 24.7669L13.5485 2.81825C13.8551 2.23952 14.3112 1.76263 14.8647 1.4421ZM16.5013 22.155C15.6747 22.155 15.0046 22.8556 15.0046 23.7198C15.0046 24.584 15.6747 25.2845 16.5013 25.2845C17.328 25.2845 17.9981 24.584 17.9981 23.7198C17.9981 22.8556 17.328 22.155 16.5013 22.155ZM16.4981 9.61454C15.7295 9.61483 15.0962 10.2199 15.0099 10.9992L14.9999 11.1819L15.0026 19.0175L15.0127 19.2002C15.0996 19.9794 15.7333 20.584 16.5019 20.5838C17.2705 20.5835 17.9038 19.9784 17.9901 19.1991L18.0001 19.0164L17.9974 11.1808L17.9873 10.9981C17.9004 10.2189 17.2667 9.61425 16.4981 9.61454Z'
				fill='#DC3545'
			/>
		</svg>
	);
}
