import { IPossibleTransaction } from 'api/transactionGroup';
import RightModal from 'components/Modals/RightModal';
import React from 'react';
import { useTranslation } from '@paykassma/pay-kit';

import CommonData from './CommonData';
import styles from './DetailedView.module.scss';

interface IDetailedViewProps {
	readonly transactionData?: IPossibleTransaction;
	readonly onClose: () => void;
}

const DetailedView: React.FC<IDetailedViewProps> = ({
	transactionData,
	onClose,
}) => {
	const { t } = useTranslation();

	return (
		<RightModal
			bodyClassName={styles.DetailedViewWrapper}
			onShow={() => null}
			isVisible={!!transactionData}
			onClose={onClose}
			// heading="Общая информация"
			heading={t('Common data')}
		>
			{transactionData && <CommonData transactionData={transactionData} />}
		</RightModal>
	);
};

export default DetailedView;
