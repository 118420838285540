import { useTranslation } from 'pay-kit';
import { Tooltip } from 'pay-kit';
import React from 'react';

import Status0SVG from './icons/status_0.svg';
import Status1SVG from './icons/status_1.svg';
import Status2SVG from './icons/status_2.svg';
import Status3SVG from './icons/status_3.svg';
import styles from './PostbackStatus.module.scss';

interface IPostbackStatusType {
	readonly status: 0 | 1 | 2 | 3;
	readonly showCaption?: boolean;
	readonly dataTestId?: string;
}

const PostbackStatus: React.FC<IPostbackStatusType> = ({
	status,
	showCaption,
	dataTestId,
}) => {
	const { t } = useTranslation();

	if (status === 0) {
		return (
			// <Tooltip tip="Статус постбека: Идёт накопление" preferredSide="bottom">
			<Tooltip tip={t('Postback status: In progress')} preferredSide='bottom'>
				<div className={styles.PostbackStatus} data-test-id={dataTestId}>
					<Status0SVG />
					{/* {showCaption && <span>Идёт накопление</span>} */}
					{showCaption && <span>{t('In progress')}</span>}
				</div>
			</Tooltip>
		);
	}

	if (status === 1) {
		return (
			// <Tooltip tip="Статус постбека: Отправлен" preferredSide="bottom">
			<Tooltip tip={t('Postback status: Sent')} preferredSide='bottom'>
				<div className={styles.PostbackStatus} data-test-id={dataTestId}>
					<Status1SVG />
					{showCaption && <span>{t('Sent')}</span>}
				</div>
			</Tooltip>
		);
	}

	if (status === 2) {
		return (
			// <Tooltip tip="Статус постбека: Ошибка отправки" preferredSide="bottom">
			<Tooltip tip={t('Postback status: Error')} preferredSide='bottom'>
				<div className={styles.PostbackStatus} data-test-id={dataTestId}>
					<Status2SVG />
					{/* {showCaption && <span>Ошибка отправки</span>} */}
					{showCaption && <span>{t('Sent error')}</span>}
				</div>
			</Tooltip>
		);
	}

	if (status === 3) {
		return (
			// <Tooltip tip="Статус постбека: Накопление не найдено" preferredSide="bottom">
			<Tooltip
				tip={t('Postback status: Stockpiling not found')}
				preferredSide='bottom'
			>
				<div className={styles.PostbackStatus} data-test-id={dataTestId}>
					<Status3SVG />
					{showCaption && <span>{t('Stockpiling not found')}</span>}
				</div>
			</Tooltip>
		);
	}

	return null;
};

export default PostbackStatus;
