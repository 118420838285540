import { useTranslation } from 'pay-kit';
/* eslint-disable test-selectors/button */
import CopyTextButton from 'components/CopyTextButton';
import { Button, ScrollBox } from 'pay-kit';

import styles from './DetailedRequest.module.scss';

type DetailedRequestPropsType = {
	readonly request?: string;
	readonly onClose: () => void;
};

const DetailedRequest = ({ request, onClose }: DetailedRequestPropsType) => {
	const { t } = useTranslation();

	return (
		<div className={styles.DetailedRequest}>
			<div className={styles.subheader}>
				<h3>{t('Request body')}</h3>
				<CopyTextButton
					text={request || ''}
					// onSuccess={() => window.pushAlert({ description: `Тело запроса cкопировано`, type: "success" })}
					onSuccess={() =>
						window.pushAlert({
							description: t('Request body copied'),
							type: 'success',
						})
					}
				/>
			</div>
			<ScrollBox className={styles.scrollBox}>{request}</ScrollBox>
			<Button
				data-test-id='close-modal'
				onClick={onClose}
				variant='primary'
				classname={styles.closeButton}
			>
				{/* Закрыть */}
				{t('Close')}
			</Button>
		</div>
	);
};

export default DetailedRequest;
