import { useEffect, useState } from 'react';

type FilePreviewPropsType = {
	readonly file?: File;
	readonly className?: string;
};

const FilePreview: React.FC<FilePreviewPropsType> = ({ file, className }) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [url, setUrl] = useState<string | undefined | null>(
		typeof file === 'string' ? file : undefined,
	);

	useEffect(() => {
		const isFile = file instanceof File;

		if (isFile && !url) {
			setIsLoading(true);

			const fr = new FileReader();

			fr.readAsArrayBuffer(file as File);

			fr.onload = () => {
				const url = URL.createObjectURL(new Blob([fr.result as BlobPart]));

				setIsLoading(false);
				setUrl(url);
			};
		}
	}, [file, url]);

	if (!url || isLoading) {
		return null;
	}

	return (
		<div className={className} style={{ backgroundImage: `url('${url}')` }} />
	);
};

export default FilePreview;
