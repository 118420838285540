export const formatDate = (date: Date) => date.toISOString().split('T')[0];

export const subtractDays = (date: Date, days: number) =>
	new Date(date.setTime(date.getTime() - days * 24 * 60 * 60 * 1000));

export const notLaterThanToday = (date: Date) =>
	date.getTime() >= new Date().getTime();

export const toLocaleDate = (
	rawDate: Date | string | null | undefined,
): string => {
	if (!rawDate) {
		return '';
	}

	let date = rawDate;

	if (typeof rawDate == 'string') {
		date = new Date(rawDate);
	}

	return date.toLocaleString('ru-RU').replace(',', '');
};

export const fromLocaleDate = (
	rawDate: string | null | undefined,
): string | undefined => {
	if (!rawDate) {
		return undefined;
	}

	const [datePart, timePart] = rawDate.split(' ');

	const [dayPart, monthPart, yearPart] = datePart.split('.');

	let result = `${yearPart}-${monthPart}-${dayPart}`;

	if (timePart) {
		result += ` ${timePart}`;
	}

	return result;
};

/**
 * Returns date X days ago
 * @param {number} days Days to subtract
 * @param {boolean} endOfDay if true time will be "23:59:59" else "00:00:00"
 * @return {string} Will return a string like "2028-11-01 00:00:00"
 */
export const getOffsetDateFromToday = (days = 0, endOfDay: boolean) => {
	const offsetMs = 24 * 60 * 60 * 1000 * days;
	const date = new Date();
	date.setTime(date.getTime() - offsetMs);
	const year = date.getUTCFullYear();
	const month = date.getUTCMonth() + 1;
	const day = date.getUTCDate();
	const zeroPrefixFormat = (n: number) => (`${n}`.length < 2 ? `0${n}` : n);
	const formattedMonth = zeroPrefixFormat(month);
	const formattedDay = zeroPrefixFormat(day);
	const time = endOfDay ? "23:59:59" : "00:00:00";

	return `${year}-${formattedMonth}-${formattedDay} ${time}`;
};

export const isLongerThan = (days: number) => (date1?: string, date2?: string) => {
	if (!date1 || !date2) {
		return undefined;
	}

	const d1 = new Date(date1).getTime();
	const d2 = new Date(date2).getTime();
	const diffMs = d2 - d1;
	const MS_PER_DAY = 1000 * 60 * 60 * 24;
	return days < Math.round(diffMs / MS_PER_DAY);
};
