import API from 'api';
import { t } from 'pay-kit';
import {
	ICurrentTransaction,
	ITransactionsFilterForm,
	ITransactionsPaginatorParams,
} from 'api/transactionGroup';
import { useState } from 'react';
import { errorsMap } from 'utils/enums';
import { Mutable } from 'utils/types';

const useTransactionsListAPI: useTransactionsListAPIType = () => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [transactions, setTransactions] = useState<
		readonly ICurrentTransaction[]
	>([]);
	const [error, setError] = useState<null | Error>(null);
	const [paginationInfo, setPaginationInfo] =
		useState<null | paginationInfoType>(null);

	const getTransactionsList = (
		params: Mutable<ITransactionsFilterForm> & ITransactionsPaginatorParams,
	) => {
		setIsLoading(true);
		setError(null);
		setTransactions([]);

		params.merchant_order_id = params.merchant_order_id
			? [params.merchant_order_id as string]
			: undefined;

		API.transaction
			.getArchivedList(params)
			.then((res: any) => {
				if (res?.status === 'success' && res?.data?.transactions?.data) {
					const { data, ...rest } = res.data.transactions;

					setTransactions(data);
					setPaginationInfo(rest);

					return res;
				} else {
					// throw new Error("Invalid response");
					throw new Error(errorsMap.anyResponse);
				}
			})
			.finally(() => setIsLoading(false))
			.catch((err) => {
				// window.pushAlert({ title: "Возникла ошибка при получении архивных транзакций", type: "error" });
				window.pushAlert({
					title: t('An error occurred while getting archived transactions'),
					type: 'error',
				});

				setError(err);
			});
	};

	return {
		getTransactionsList,
		isLoading,
		transactions,
		error,
		paginationInfo,
	};
};

export default useTransactionsListAPI;

export type useTransactionsListAPIType = () => {
	readonly getTransactionsList: any;
	readonly isLoading: boolean;
	readonly transactions: readonly ICurrentTransaction[];
	readonly error: Error | null;
	readonly paginationInfo: paginationInfoType | null;
};

export type paginationInfoType = {
	readonly current_page: number;
	readonly from: number;
	readonly to: number;
	readonly per_page: number;
	readonly last_available_page: number;
};

export type getListSuccessResponseType = {
	readonly status: 'ok';
	readonly transactions: {
		readonly data: readonly ICurrentTransaction[];
	} & paginationInfoType;
};
