import { fetchApi, fetchOldApi } from './fetch';

const settingsGroup = {
	getPluginSettings: () =>
		fetchOldApi<GetPluginSettingsType>(`settings/plugin`),
	updatePluginSettings: (data: UpdatePluginSettingsType) =>
		fetchOldApi(`settings/plugin`, data, `POST`),
	getSettingsParams: () => fetchApi<UpdateSettingsType>(`settings/params`),
	setTimeZone: (data: TimezoneType[`timezone`]) =>
		fetchApi(`settings/timezone`, { timezone: data }, `POST`),
	updateSettings: (data: UpdateSettingsType | PickedUpdateSettingsType) =>
		fetchApi(`settings/params`, data, `POST`),
	getTimezone: () => fetchApi<TimezoneType>(`settings/timezone`),
	getTimezoneList: () => fetchOldApi<AvailableTimezonesType>(`timezones/list`),
	getPrivateSettings: () =>
		fetchApi<UpdatePrivateSettingsType>(`settings/private-params`),
	updatePrivateSettings: (data: UpdatePrivateSettingsType) =>
		fetchApi<void>(`settings/private-params`, data, `POST`),
	getPaymentOfCurrencies: () =>
		fetchOldApi<GetPaymentOfCurrenciesType>(
			`settings/default-payment-of-currencies`,
		),
	getPaymentsByCurrency: (currencyCode: string) =>
		fetchOldApi<GetPaymentsByCurrencyType>(
			`settings/payments-for-currency?currency_code=${currencyCode}`,
		),
	updateCustomStyle: (data: UpdateCustomStyleParamsType) =>
		fetchOldApi(`settings/set-custom-css`, data, 'POST', {
			'Content-Type': `multipart/form-data`,
		}),
};

export type UpdateCustomStyleParamsType = {
	readonly file: File;
};

export type PaymentType = {
	readonly id: number;
	readonly type: string;
};

export type GetPaymentsByCurrencyType = {
	readonly applied_payment_systems: readonly PaymentType[];
	readonly default_payment_systems: readonly PaymentType[];
};

export type CurrencyListWithDefaultSortingType = {
	readonly code: string;
	readonly is_default: boolean;
	readonly payments: readonly PaymentType[];
};

export type GetPaymentOfCurrenciesType = {
	readonly currency_list_with_default_sorting: readonly CurrencyListWithDefaultSortingType[];
};

export type UpdatePluginSettingsType = {
	readonly payment_plugin_color_scheme:
		| 'light'
		| 'dark'
		| 'white-green'
		| 'custom';
};

export type CurrencyListWithCustomSorting = {
	readonly code: string;
	readonly is_default: boolean;
	readonly payments: readonly { readonly id: number; readonly type: string }[];
};

export type GetPluginSettingsType = {
	readonly params: {
		readonly currency_list_with_custom_sorting: readonly CurrencyListWithCustomSorting[];
		readonly custom_css: string;
		readonly custom_css_url: string;
		readonly payment_plugin_color_scheme: UpdatePluginSettingsType[`payment_plugin_color_scheme`];
	};
};

export type AvailableTimezonesType = {
	readonly available_timezones: readonly {
		readonly name: string;
		readonly value: string;
	}[];
};

export type TimezoneType = {
	readonly timezone: string;
};

export type UpdatePasswordProps = {
	readonly old_password: string;
	readonly new_password: string;
};

export type RedirectUrlType = { readonly id: string; readonly url: string };

export type UpdatePrivateSettingsType = {
	readonly postback_access_key: string;
	readonly postback_private_access_key: string;
	readonly postback_url: string;
	readonly withdrawal_postback_url: string;
	readonly delete_withdrawals_after_days: string;
};

export type UpdateSettingsType = {
	readonly upi_api_payment_methods: readonly {
		readonly order: number;
		readonly code: string;
	}[];
	readonly upi_p2p_payment_methods: readonly {
		readonly order: number;
		readonly code: string;
	}[];
	readonly timezone: string;
	readonly del_possible_trans_after_days: string;
	readonly failed_urls: readonly RedirectUrlType[];
	readonly succeeded_urls: readonly RedirectUrlType[];
	readonly pending_urls: readonly RedirectUrlType[];
	readonly transaction_reactivation_timer: string;
	readonly transaction_recheck_timer: string;
	readonly use_sms_box: boolean;
	readonly use_upi_qrcode: boolean;
	readonly use_url_from_settings: boolean;
	readonly display_report_problem: boolean;
	readonly restrict_withdrawal_requests: boolean;
};

export type PickedUpdateSettingsType = Pick<
	UpdateSettingsType,
	`succeeded_urls` | `failed_urls`
>;

export default settingsGroup;
