import { useTranslation } from '@paykassma/pay-kit';

import BigLogo from './img/bigLogo.svg';
import PointerArrow from './img/pointer.svg';
import styles from './Placeholder.module.scss';

const Placeholder = () => {
	const { t } = useTranslation();

	return (
		<div className={styles.placeholder}>
			<BigLogo />
			<div className={styles.subtitle}>Plugin Paykassma</div>
			<div className={styles.callToAction}>
				{t('Choose parameters')}
				<PointerArrow />
			</div>
		</div>
	);
};

export default Placeholder;
