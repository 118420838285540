import CssIcon from 'assets/icons/css.svg';
import useCreateCustomStyle from 'modules/AppSettings/Plugin/components/CustomCssFile/hooks/useCreateCustomStyle';
import { t, useTranslation } from 'pay-kit';
import { Button } from 'pay-kit';
import { ChangeEventHandler, useState } from 'react';

import styles from './customCssFile.module.scss';

// const error = `Недопустимый формат файла. Файл должен быть следующих форматов: css`;
const error = t(
	`Invalid file format. The file must be in the following formats: {{formats}}`,
	{ formats: `css` },
);

const CustomCssFile = () => {
	const [customCssFileName, setCustomCssFileName] = useState(`custom.css`);
	const [errorMessage, setErrorMessage] = useState(``);
	const [file, setFile] = useState<File | null>(null);
	const { t } = useTranslation();

	const createCustomStyleAPI = useCreateCustomStyle();

	const onUpload: ChangeEventHandler<HTMLInputElement> = (e) => {
		const file = e.target.files ? e.target.files[0] : null;
		const reader = new FileReader();

		if (file) {
			reader.readAsDataURL(file);
		}

		const isCss = file?.type.endsWith(`css`);

		reader.onload = () => {
			if (file && isCss) {
				setCustomCssFileName(file.name);
				setErrorMessage(``);
				setFile(file);
			} else if (!isCss) {
				setFile(null);
				setErrorMessage(error);
			}
		};
	};

	const onFileSave = () => {
		const onSuccess = () =>
			window.pushAlert({
				title: t('Success'),
				description: t(`Styles for the plugin have been successfully added`),
				type: 'success',
			});

		if (file) {
			createCustomStyleAPI.update({ file }, onSuccess);
		}
	};

	return (
		<div className={styles.uploadCustomCssFileWrapper}>
			<div className={styles.uploadCustomCssFile}>
				<div className={styles.label}>{t('File')}</div>

				<div className={styles.customCss}>
					<div className={styles.cssIcon}>
						<CssIcon />
					</div>

					{customCssFileName}

					<label className={styles.uploadCssFile}>
						{t('Upload file')}
						<input
							className={styles.hiddenFileInput}
							type='file'
							onChange={onUpload}
						/>
					</label>

					{errorMessage && (
						<div className={styles.errorMessage}>{errorMessage}</div>
					)}
				</div>
			</div>

			{file && (
				<Button
					data-test-id='uo9Yw78e-U8-5qNOdJI-x'
					onClick={onFileSave}
					classname={styles.saveFileButton}
					isLoading={createCustomStyleAPI.isLoading}
				>
					{t('Save file')}{' '}
				</Button>
			)}
		</div>
	);
};

export default CustomCssFile;
