import api from 'api/walletTypesGroup';
import { IConstructorSettingsSuccessResponse } from 'api/walletTypesGroup';
import { useState } from 'react';
import { errorsMap } from 'utils/enums';

type useConstructorSettingsHookType = () => {
	readonly load: (walletType: string) => void;
	readonly data?: IConstructorSettingsSuccessResponse;
	readonly isLoading: boolean;
};

const useConstructorSettingsHook: useConstructorSettingsHookType = () => {
	const [data, setData] = useState<
		IConstructorSettingsSuccessResponse | undefined
	>();
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const load = (walletType: string) => {
		setIsLoading(true);
		setData(undefined);

		api
			.getConstructorSettings(walletType)
			.then((res: any) => {
				if (res?.status === 'success' && res?.data) {
					setData(res);

					return res;
				} else {
					// throw new Error(`Getting Constructor Settings for ${walletType} error`);
					throw new Error(errorsMap.getConstructor(walletType));
				}
			})
			.finally(() => setIsLoading(false))
			.catch((err) => {
				console.error(err);
			});
	};

	return {
		load,
		data,
		isLoading,
	};
};

export default useConstructorSettingsHook;
