import {
	IGetTargetsResponse,
	ITargetsItem,
	WalletType,
} from 'api/walletTypesGroup';
import TrashIcon from 'assets/icons/trash.svg';
import PaymentSystemLogo from 'components/PaymentSystemLogo';
import { useTranslation } from 'pay-kit';
import {
	Button,
	Loader,
	Modal,
	MultiSelect,
	ScrollBox,
	Select,
	TextInput,
	Toggler,
} from 'pay-kit';
import { useEffect, useState } from 'react';
import { deepClone } from 'utils/deepClone';
import { errorsMap } from 'utils/enums';

import DropZone from '../../../../../../../components/DropZone';

import useGetTargetsHook from './useGetTargetsHook';
import useGetTranslationsHook from './useGetTranslationsHook';
import useSetTargetsHook from './useSetTargetsHook';
import {
	formValidationSchema,
	getErrorsForTouchedFields,
	validateForm,
} from './utils';
import styles from './ViewSettings.module.scss';

export interface IViewSettingsProps {
	readonly psId?: string;
	readonly isOpen: boolean;
	readonly onClose: () => void;
	readonly onSuccess: () => void;
	readonly paymentType?: WalletType['payment_type'];
}

// eslint-disable-next-line functional/prefer-readonly-type
type Mutable<Type> = {
	-readonly [Key in keyof Type]: Type[Key];
};

const DRAFT_TARGETING_PREFIX = 'draft_targeting_';

const ViewSettings: React.FC<IViewSettingsProps> = ({
	isOpen,
	onClose,
	psId,
	onSuccess,
	paymentType,
}) => {
	const { t } = useTranslation();
	const [formData, setFormData] = useState<IGetTargetsResponse>();
	const [touchedFields, setTouchedFields] = useState<readonly string[] | '*'>(
		[],
	);
	const [validationErrors, setValidationErrors] = useState<null | {
		readonly [key: string]: string;
	}>(null);
	const visibleErrors = getErrorsForTouchedFields(
		validationErrors,
		touchedFields,
	);
	const showPaymentConfirmation = paymentType !== 'API';

	const getTranslationsHook = useGetTranslationsHook();
	const getTargetsHook = useGetTargetsHook();
	const setTargetsHook = useSetTargetsHook({
		onSuccess: () => {
			onSuccess && onSuccess();
			onClose();

			window.pushAlert({
				type: 'success',
				description: t('Settings successfully updated'),
			});
		},
		onFail: () => {
			window.pushAlert({ type: 'error', description: errorsMap.cantUpdate });
		},
	});

	useEffect(() => {
		if (!isOpen || psId === undefined) {
			// clear form
			setTouchedFields([]);
			setFormData(undefined);

			return;
		}

		getTranslationsHook.load(psId);
		getTargetsHook.load(psId);
	}, [psId, isOpen]);

	useEffect(() => {
		if (getTargetsHook.data !== undefined) {
			setFormData(getTargetsHook.data);
		}
	}, [getTargetsHook.data]);

	useEffect(() => {
		(async () => {
			const errors = await validateForm(
				formData as any,
				formValidationSchema({ showPaymentConfirmation }),
			);

			setValidationErrors(errors as any);
		})();
	}, [formData]);

	if (getTranslationsHook.isLoading || getTargetsHook.isLoading) {
		return (
			<Modal
				className={styles.modal}
				isOpen={isOpen}
				title={t('View Type')}
				onClose={onClose}
			>
				<Loader />
			</Modal>
		);
	}

	const addTargeting = () => {
		setFormData((prevState) => {
			const stateClone: any = deepClone(prevState as IGetTargetsResponse);
			const id = DRAFT_TARGETING_PREFIX + Date.now();

			stateClone.targets.push({
				id,
				title: '',
				hint_payment: '',
				hint_requisites: '',
				field_payment: '',
				field_requisites: '',
				is_enabled: '',
				logo: null,
				currencies: [],
				show_limits: false,
			});

			return stateClone;
		});
	};

	const removeTargeting = (id: number | string) => {
		setFormData((prevState: IGetTargetsResponse | undefined) => {
			if (!prevState?.targets) {
				return;
			}

			const stateClone: Mutable<IGetTargetsResponse> = deepClone(prevState);

			stateClone.targets = stateClone.targets.filter((t) => t.id !== id);

			if (id.toString().startsWith(DRAFT_TARGETING_PREFIX)) {
				return stateClone;
			}

			if (!stateClone.deleted) {
				stateClone.deleted = [id as string];
			} else if (
				Array.isArray(stateClone.deleted) &&
				stateClone.deleted.length > 0
			) {
				stateClone.deleted = [...stateClone.deleted, id];
			}

			return stateClone;
		});
	};

	const defaultFieldsChangeHandler =
		(name: string) => (value: string | number | boolean) => {
			setFormData((prevState) => {
				const stateClone: any = deepClone(prevState as IGetTargetsResponse);

				stateClone.default[name] = value;

				return stateClone;
			});
		};

	const targetFieldsChangeHandler =
		(targetId: string | number, index: number) =>
		(name: string) =>
		(value: string | number | boolean | File) =>
			setFormData((prevState) => {
				const stateClone: any = deepClone(prevState as IGetTargetsResponse);

				stateClone.targets = stateClone.targets.map(
					(t: Mutable<ITargetsItem>) => {
						if (t.id === targetId) {
							// @ts-ignore
							t[name] = value;
						}

						setTouchedFields((prevState: readonly string[] | '*') => [
							...new Set([...prevState, `targets[${index}].${name}`]),
						]);

						return t;
					},
				);

				return stateClone;
			});

	const submitHandler = () => {
		if (validationErrors !== null && Object.keys(validationErrors).length > 0) {
			setTouchedFields('*');

			return;
		}

		const postData = {
			is_default_enabled: formData?.default.is_enabled ? 1 : 0,
			show_limits: formData?.default.show_limits,
			targets: formData?.targets.map((t) => ({
				id: t.id.toString().startsWith(DRAFT_TARGETING_PREFIX)
					? undefined
					: t.id,
				logo: t.logo instanceof File ? t.logo : undefined,
				title: t.title,
				currencies: t.currencies,
				hint_requisites: t.hint_requisites,
				field_requisites: t.field_requisites,
				show_limits: t.show_limits || false,
				hint_payment: t.hint_payment ? t.hint_payment : undefined,
				field_payment: t.field_payment ? t.field_payment : undefined,
			})),
			deleted: formData?.deleted,
		};

		setTargetsHook.post(psId as string, postData as any);
	};

	const requisiteHintsOptions =
		getTranslationsHook.data?.hints
			.filter((h) => h.form_type === 'requisite')
			.map((hint) => ({ label: hint.name, value: hint.id })) || [];
	const paymentHintsOptions =
		getTranslationsHook.data?.hints
			.filter((h) => h.form_type === 'payment')
			.map((hint) => ({ label: hint.name, value: hint.id })) || [];
	const requisiteFieldsOptions =
		getTranslationsHook.data?.fields
			.filter((h) => h.form_type === 'requisite')
			.map((hint) => ({ label: hint.name, value: hint.id })) || [];
	const paymentFieldsOptions =
		getTranslationsHook.data?.fields
			.filter((h) => h.form_type === 'payment')
			.map((hint) => ({ label: hint.name, value: hint.id })) || [];

	return (
		<Modal
			className={styles.modal}
			isOpen={isOpen}
			title={t('View Type')}
			onClose={onClose}
		>
			<ScrollBox className={styles.scrollBox}>
				<h4>{t('General')}</h4>
				<FieldWrapper
					label={t('Logo')}
					value={<PaymentSystemLogo id={psId as string} hideLabel />}
				/>
				<FieldWrapper label={t('Name')} value={formData?.default.title} />
				<FieldWrapper
					label={t('Currencies')}
					value={formData?.default.currencies.map((c) => c.currency).join(', ')}
				/>
				<FieldWrapper
					label={t('Display view in plugin')}
					value={
						<Toggler
							value={formData?.default.is_enabled}
							onChange={defaultFieldsChangeHandler('is_enabled')}
							data-test-id=''
						/>
					}
				/>

				<h4>{t('Payment making form')}</h4>
				<FieldWrapper
					label={t('Hint')}
					value={
						getTranslationsHook.data?.hints.find(
							(h) => h.id === formData?.default.hint_requisit,
						)?.name
					}
				/>
				<FieldWrapper
					label={t('Limit hint')}
					value={
						<Toggler
							value={formData?.default.show_limits}
							onChange={defaultFieldsChangeHandler('show_limits')}
							data-test-id=''
						/>
					}
				/>
				<FieldWrapper
					label={t('Fields')}
					value={
						getTranslationsHook.data?.fields.find(
							(h) => h.id === formData?.default.field_requisit,
						)?.name
					}
				/>
				{showPaymentConfirmation && (
					<>
						<h4>{t('Payment confirmation form')}</h4>
						<FieldWrapper
							label={t('Hint')}
							value={
								getTranslationsHook.data?.hints.find(
									(h) => h.id === formData?.default.hint_payment,
								)?.name
							}
						/>
						<FieldWrapper
							label={t('Fields')}
							value={
								getTranslationsHook.data?.fields.find(
									(h) => h.id === formData?.default.field_payment,
								)?.name
							}
						/>
					</>
				)}

				{formData?.targets.map((target, index) => {
					const fieldsChangeHandler = targetFieldsChangeHandler(
						target.id,
						index,
					);

					return (
						<div className={styles.targeting} key={target.id}>
							<div className={styles.header}>
								{t('Targeting')}{' '}
								<button
									className={styles.removeButton}
									onClick={() => removeTargeting(target.id)}
									data-test-id=''
								>
									<TrashIcon />
								</button>
							</div>
							<FieldWrapper
								label={t('Logo')}
								value={
									<DropZone
										fileExtensionWhiteList={['.png', '.jpeg', '.jpg', '.svg']}
										file={target.logo}
										onChange={fieldsChangeHandler('logo') as any}
										data-test-id=''
										setErrors={(err) => console.error(err)}
										error={visibleErrors?.[`targets[${index}].logo`]}
									/>
								}
							/>
							<h4>{t('General')}</h4>
							<FieldWrapper
								label={t('Name')}
								value={
									<TextInput
										name={'title'}
										value={target.title as string}
										onChange={(e) =>
											fieldsChangeHandler('title')(e.target.value.toString())
										}
										data-test-id={''}
										className={styles.textInput}
										error={visibleErrors?.[`targets[${index}].title`]}
									/>
								}
							/>
							<FieldWrapper
								label={t('Currencies')}
								value={
									<MultiSelect
										options={
											formData?.default.currencies.map((c) => ({
												value: c.currency,
												label: c.currency,
											})) || []
										}
										value={target.currencies}
										name='currencies'
										onChange={fieldsChangeHandler('currencies') as any}
										data-test-id=''
										className={styles.select}
										placeholder=''
										error={visibleErrors?.[`targets[${index}].currencies`]}
									/>
								}
							/>
							<h4>{t('Payment making form')}</h4>
							<FieldWrapper
								label={t('Hint')}
								value={
									<Select
										name='hint_requisites'
										value={target.hint_requisites}
										onChange={fieldsChangeHandler('hint_requisites') as any}
										options={requisiteHintsOptions}
										data-test-id=''
										className={styles.select}
										placeholder=''
										error={visibleErrors?.[`targets[${index}].hint_requisites`]}
									/>
								}
							/>
							<FieldWrapper
								label={t('Limit hint')}
								value={
									<Toggler
										value={target.show_limits}
										onChange={fieldsChangeHandler('show_limits')}
										data-test-id=''
									/>
								}
							/>
							<FieldWrapper
								label={t('Fields')}
								value={
									<Select
										name='field_requisites'
										value={target.field_requisites}
										onChange={fieldsChangeHandler('field_requisites') as any}
										options={requisiteFieldsOptions}
										data-test-id=''
										className={styles.select}
										placeholder=''
										error={
											visibleErrors?.[`targets[${index}].field_requisites`]
										}
									/>
								}
							/>

							{showPaymentConfirmation && (
								<>
									<h4>{t('Payment confirmation form')}</h4>
									<FieldWrapper
										label={t('Hint')}
										value={
											<Select
												name='hint_payment'
												value={target.hint_payment}
												onChange={fieldsChangeHandler('hint_payment') as any}
												options={paymentHintsOptions}
												data-test-id=''
												className={styles.select}
												placeholder=''
												error={
													visibleErrors?.[`targets[${index}].hint_payment`]
												}
											/>
										}
									/>
									<FieldWrapper
										label={t('Fields')}
										value={
											<Select
												name='field_payment'
												value={target.field_payment}
												onChange={fieldsChangeHandler('field_payment') as any}
												options={paymentFieldsOptions}
												data-test-id=''
												className={styles.select}
												placeholder=''
												error={
													visibleErrors?.[`targets[${index}].field_payment`]
												}
											/>
										}
									/>
								</>
							)}
						</div>
					);
				})}
			</ScrollBox>

			<div className={styles.actions}>
				<Button variant='text-primary' data-test-id='' onClick={addTargeting}>
					+ {t('Add targeting')}
				</Button>
				<Button
					isLoading={setTargetsHook.isLoading}
					onClick={submitHandler}
					data-test-id=''
				>
					{t('Save')}
				</Button>
			</div>
		</Modal>
	);
};

const FieldWrapper: React.FC<{
	readonly label: string;
	readonly value: React.ReactNode;
}> = ({ label, value }) => (
	<div className={styles.fieldWrapper}>
		<div className={styles.label}>{label}</div>
		{value}
	</div>
);

export default ViewSettings;
