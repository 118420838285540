import { fetchOldApi } from './fetch';

export interface ICountry {
	readonly code: string;
	readonly id: number;
	readonly title: string;
}

const countryGroup = {
	// method is in use only in proxies
	getList: () => fetchOldApi<GetCountriesListType>('/countries/list'),
};

export default countryGroup;

export type CountryType = {
	readonly code: string;
	readonly id: number;
	readonly title: string;
};

export type GetCountriesListType = {
	readonly countries: readonly CountryType[];
};
