import Plus from 'assets/icons/plus.svg';
import PaymentSystemLogo from 'components/PaymentSystemLogo';
import { WalletTypesContext } from 'contexts/WalletTypesContext';
import Loader from 'modules/AppSettings/Plugin/components/PSHints/components/Loader';
import { useHintContext } from 'modules/AppSettings/Plugin/components/PSHints/context';
import VisibleIcon from 'modules/Wallets/WorkingWallets/components/Togglers/VisibilityToggler/icons/VisibleIcon';
import { useContext, useState } from 'react';

import styles from './psHints.module.scss';

const PSHints = () => {
	const [activeHint, setActiveHint] = useState(``);
	const hintContextAPI = useHintContext();
	const walletTypesAPI = useContext(WalletTypesContext);
	const availableHints =
		hintContextAPI?.images &&
		Object.entries(hintContextAPI?.images).map((hint) => hint);

	const walletTypesHints =
		walletTypesAPI.walletTypes.length !== 0 &&
		availableHints &&
		walletTypesAPI.walletTypes.map((wallet) => {
			const availableHint = availableHints.find(
				([wallType]) => wallType === wallet.code,
			);

			return availableHint
				? {
						name: wallet.name,
						code: wallet.code,
						hint: availableHint[1],
					}
				: {
						name: wallet.name,
						code: wallet.code,
						hint: null,
					};
		});

	const renderHints = () => {
		return (
			walletTypesHints &&
			walletTypesHints.map((wallet) => (
				<div
					data-test-id='bjdaEtMeRL3_sRSm85JSB'
					key={wallet.code}
					className={
						activeHint === wallet.code
							? [styles.hint, styles.activeHint].join(' ')
							: styles.hint
					}
					onClick={() => {
						setActiveHint(wallet.code);

						hintContextAPI?.uploadFile({
							name: wallet.name,
							code: wallet.code,
							hint: wallet.hint,
						});
					}}
				>
					<PaymentSystemLogo
						id={wallet.code}
						classNames={{ wrapper: styles.psLogoWrapper }}
					/>
					{wallet.hint ? (
						<VisibleIcon />
					) : (
						<div className={styles.plusIcon}>
							<Plus />
						</div>
					)}
				</div>
			))
		);
	};

	const psHintsStyle = hintContextAPI?.isFileUploading
		? [styles.psHints, styles.pulsating].join(' ')
		: styles.psHints;

	return (
		<Loader loading={!walletTypesHints} className={styles.hintsLoader}>
			<div className={psHintsStyle}>{renderHints()}</div>
		</Loader>
	);
};

export default PSHints;
