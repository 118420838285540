import { Button } from '@paykassma/pay-kit';
import { IWithdrawalListItem } from 'api/withdrawalGroup';
import {
	WithdrawalStatusENUM,
	WithdrawalType as WithdrawalTypeENUM,
} from 'api/withdrawalGroup';
import ApproveIcon from 'assets/icons/approve_16x16.svg';
import CloseIcon from 'assets/icons/close.svg';
import Money from 'components/Money';
import PaymentSystemLogo from 'components/PaymentSystemLogo';
import TextWithCopyButton from 'components/TextWithCopyButton';
import AuthContext, { Roles } from 'contexts/AuthContext';
import { t, useTranslation } from 'pay-kit';
import { useContext } from 'react';

import { ActionsContext } from '../ActionsProvider';

import styles from './Columns.module.scss';
import ColumnTitleSeparator from './components/ColumnTitleSeparator';
import CreationType from './components/CreationType';
import PostbackStatus from './components/PostbackStatus';
import TransactionType from './components/TransactionType';
import WithdrawalStatus from './components/WithdrawalStatus';
import WithdrawalType from './components/WithdrawalType';

const columns = [
	{
		title: t('Wallet type'),
		dataIndex: 'wallet_type',
		key: 'wallet_type',
		render: ({ wallet_type }: IWithdrawalListItem) => (
			<PaymentSystemLogo id={wallet_type} dataTestId='wallet_type' />
		),
	},
	{
		title: 'ID',
		dataIndex: 'id',
		key: 'id',
		dataTestId: 'id',
	},
	{
		title: t('Request date'),
		dataIndex: 'created_at',
		key: 'created_at',
		dataTestId: 'created_at',
		render: ({ created_at }: IWithdrawalListItem) => {
			const splittedDate = created_at.split(' ');
			const createdAtDate = splittedDate[0];
			const createdAtTime = splittedDate[1];

			return (
				<>
					<div className={styles.createdAtDate}>{createdAtDate}</div>
					<div className={styles.createdAtTime}>{createdAtTime}</div>
				</>
			);
		},
	},
	{
		title: t('Amount'),
		dataIndex: 'amount',
		key: 'amount',
		render: ({ amount, currency_code }: IWithdrawalListItem) => (
			<Money
				className={styles.amount}
				amount={amount}
				currencyCode={currency_code}
				dataTestId='amount'
			/>
		),
	},
	{
		// title: "ID вывода",
		title: t('Withdrawal ID'),
		dataIndex: 'withdrawal_id',
		key: 'withdrawal_id',
		render: ({ withdrawal_id }: IWithdrawalListItem) => (
			<TextWithCopyButton
				text={withdrawal_id}
				textClassName={styles.withdrawalId}
				dataTestId='withdrawal_id'
			/>
		),
	},
	{
		title: t('Wallet sender'),
		dataIndex: 'wallet_sender',
		key: 'wallet_sender',
		render: ({ wallet_sender_identifier }: IWithdrawalListItem) => (
			<div className={styles.walletSender} data-test-id='wallet_sender'>
				{wallet_sender_identifier}
			</div>
		),
	},
	{
		title: t('Wallet recipient'),
		dataIndex: 'wallet_recipient',
		key: 'wallet_recipient',
		dataTestId: 'wallet_recipient',
	},
	{
		title: t('Label'),
		dataIndex: 'label',
		key: 'label',
		render: ({ label }: IWithdrawalListItem) => (
			<TextWithCopyButton
				text={label}
				textClassName={styles.label}
				dataTestId='label'
			/>
		),
	},
	{
		title: t('Comment'),
		dataIndex: 'comment',
		key: 'comment',
		render: ({ comment }: IWithdrawalListItem) => (
			<div className={styles.comment} data-test-id='comment'>
				{comment}
			</div>
		),
	},
];

export const SubRow = ({
	id,
	status,
	is_test,
	is_manually,
	postback_status,
	type,
}: IWithdrawalListItem) => {
	const { t } = useTranslation();
	const { hasRole } = useContext(AuthContext);

	const manageAccess = hasRole(Roles.WITHDRAWALS_MANAGE);
	const viewTypesAccess = hasRole(Roles.DISPLAY_TRANSACTION_TYPE);

	return (
		<div className={styles.subRow}>
			{is_test !== undefined && is_test !== null && (
				<>
					<WithdrawalType isTest={is_test} dataTestId='is_test' />
					<ColumnTitleSeparator />
				</>
			)}

			{is_manually !== undefined && is_manually !== null && (
				<>
					<CreationType is_manually={is_manually} dataTestId='is_manually' />
					<ColumnTitleSeparator />
				</>
			)}

			{postback_status !== null && postback_status !== undefined && (
				<>
					<PostbackStatus
						status={postback_status}
						dataTestId='postback_status'
					/>
					<ColumnTitleSeparator />
				</>
			)}

			{viewTypesAccess && type !== undefined && type !== null && (
				<>
					<TransactionType
						isAuto={type === WithdrawalTypeENUM.AUTO}
						dataTestId='type'
					/>
					<ColumnTitleSeparator />
				</>
			)}

			{status !== undefined && status !== null && (
				<WithdrawalStatus status={status} dataTestId='status' />
			)}

			<ActionsContext.Consumer>
				{({ approve, reject }) => (
					<div className={styles.actions}>
						{[WithdrawalStatusENUM.NEW, WithdrawalStatusENUM.ERROR].includes(
							status,
						) &&
							manageAccess && (
								<Button
									onClick={(e) => {
										approve(id);
										e.stopPropagation();
									}}
									isSmall
									type='button'
									color='#24A148'
									data-test-id='approve/reject'
								>
									<ApproveIcon />
									{status === WithdrawalStatusENUM.ERROR
										? t('Retry')
										: t('Approve')}
								</Button>
							)}

						{[WithdrawalStatusENUM.NEW, WithdrawalStatusENUM.ERROR].includes(
							status,
						) &&
							manageAccess && (
								<Button
									onClick={(e) => {
										reject(id);
										e.stopPropagation();
									}}
									isSmall
									type='button'
									variant='delete'
									data-test-id='reject'
								>
									<CloseIcon />
									{t('Reject')}
								</Button>
							)}
					</div>
				)}
			</ActionsContext.Consumer>
		</div>
	);
};

export default columns;
