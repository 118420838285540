import { WalletListItem } from 'api/walletGroup';

export const getUniqueWallets = (wallets: readonly WalletListItem[]) => {
	const uniqueIds: readonly WalletListItem[`hash_id`][] = [
		...new Set(wallets.map((w) => w.hash_id)),
	];

	return uniqueIds.map((hash_id: string) => {
		return wallets.find((w) => w.hash_id === hash_id);
	});
};
