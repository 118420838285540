import { PSAndBalancesType } from 'api/balanceGroup';

export const gettingCurrentCurrencyCode = (
	currencyID = '',
	ps = '',
	walletTypes: readonly PSAndBalancesType[],
) => {
	const walletType = walletTypes.find(
		({ payment_system }) => payment_system === ps,
	);

	return walletType?.balances?.find(({ id }) => id === parseInt(currencyID));
};
