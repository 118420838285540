import {
	LoadQueryListItem,
	LoadQueryParams,
	LoadQueryTransactionsCountListItem,
	LoadQueryTransactionsSumListItem,
} from 'api/walletGroup';
import { numberWithSpaces } from 'components/Money/utils';
import PaymentSystemLogo from 'components/PaymentSystemLogo';
import { t, useTranslation } from 'pay-kit';
import { useEffect, useRef, useState } from 'react';

import { ActionsContext } from '../ActionsProvider';

import Skeleton from './Skeleton';
import styles from './WalletsLoadTable.module.scss';

interface WalletsLoadTableProps {
	readonly data: readonly LoadQueryListItem[];
	readonly filterState: LoadQueryParams;
	readonly isLoading: boolean;
}

const WalletsLoadTable: React.FC<WalletsLoadTableProps> = ({
	data,
	filterState,
	isLoading,
}) => {
	const { t } = useTranslation();
	const [sticked, setSticked] = useState<boolean>(false);
	const tableRef = useRef<HTMLTableElement>(null);

	useEffect(() => {
		const scrollListener = () => {
			if (tableRef.current) {
				const top = tableRef.current?.getBoundingClientRect().top;

				if (top <= 1) {
					setSticked(true);
				} else {
					setSticked(false);
				}
			}
		};

		window.document.addEventListener('scroll', scrollListener);

		return () => window.document.removeEventListener('scroll', scrollListener);
	}, []);

	if (data.length < 1 && !isLoading) {
		return (
			<div className={styles.emptyListPlaceholder}>{t('List is empty')}</div>
		);
	}

	const columns = [
		{
			title: t('Wallet type'),
			dataIndex: 'type',
			key: 'type',
			render: ({ type }: LoadQueryTransactionsSumListItem) => (
				<PaymentSystemLogo id={type} dataTestId='type' />
			),
			className: styles.walletTypeCol,
		},
		{
			showOnlyIf: filterState.load_object === 'wallets',
			title: t('Wallet number'),
			dataIndex: 'identifier',
			key: 'identifier',
			className: styles.walletIdentifierCol,
			render: ({ identifier, hash_id }: LoadQueryTransactionsCountListItem) => (
				<ActionsContext.Consumer>
					{({ showWalletDetails }) => (
						<button
							onClick={() => showWalletDetails(hash_id)}
							data-test-id='identifier'
						>
							<div className={styles.text}>{identifier}</div>
						</button>
					)}
				</ActionsContext.Consumer>
			),
		},
		{
			showOnlyIf: filterState.load_type === 'transactions_sum',
			title: `${t('Amount')} ${filterState.direction === 'ingoing' ? t('ingoing') : t('outgoing')} ${t(
				'transactions',
			)}`,
			dataIndex: 'currency_transaction_sum',
			key: 'currency_transaction_sum',
			className: styles.currencyTransactionSumCol,
			render: ({
				currency_transaction_sum,
			}: LoadQueryTransactionsSumListItem) =>
				currency_transaction_sum &&
				currency_transaction_sum.map(({ currency_code, amount }) => (
					<div key={currency_code} data-test-id='currency_transaction_sum'>
						{currency_code}:&nbsp;
						<span className={styles.accentTextColor}>
							{numberWithSpaces(amount)}
						</span>
					</div>
				)),
		},
		{
			showOnlyIf: filterState.load_type === 'transactions_sum',
			// title: `Сумма ${filterState.direction === "ingoing" ? "входящих" : "исходящих"} транзакций (USD)`,
			title: `${t('Amount')} ${filterState.direction === 'ingoing' ? t('ingoing') : t('outgoing')} ${t(
				'transactions',
			)} (USD)`,
			dataIndex: 'transactions_usd_sum',
			key: 'transactions_usd_sum',
			render: ({ transactions_usd_sum }: LoadQueryTransactionsSumListItem) =>
				transactions_usd_sum ? (
					<span
						className={styles.accentTextColor}
						data-test-id='transactions_usd_sum'
					>
						{numberWithSpaces(transactions_usd_sum)}
					</span>
				) : null,
		},
		{
			showOnlyIf: filterState.load_type === 'transactions_count',
			// title: `Количество ${filterState.direction === "ingoing" ? "входящих" : "исходящих"} транзакций`,
			title: `${t('Number of')} ${filterState.direction === 'ingoing' ? t('ingoing') : t('outgoing')} ${t(
				'transactions',
			)}`,
			dataIndex: 'transactions_count',
			key: 'transactions_count',
			render: ({ transactions_count }: LoadQueryTransactionsCountListItem) => {
				// check float
				return (
					<span data-test-id='transactions_count'>
						{+transactions_count % 1 !== 0
							? Math.floor(transactions_count)
							: transactions_count}
					</span>
				);
			},
		},
	];

	const preparedColumns = columns.filter(
		(col) => col.showOnlyIf === undefined || col.showOnlyIf === true,
	);
	const hasSplit =
		filterState.load_type === 'transactions_count' &&
		filterState.load_object === 'payment_systems';
	const tableClassName = [
		styles.table,
		hasSplit ? styles.twoColumnTable : undefined,
		sticked ? styles.stickedHead : undefined,
	]
		.filter((cls) => cls !== undefined)
		.join(' ');
	const columnsSchemaArray = hasSplit === true ? [1, 2] : [1];

	const renderSkeleton = () => (
		<Skeleton
			rowsCount={20}
			columns={preparedColumns as any}
			hasSplit={hasSplit}
		/>
	);

	return (
		<div className={tableClassName} ref={tableRef}>
			{columnsSchemaArray.map((colKey) => (
				<div className={[styles.row, styles.header].join(' ')} key={colKey}>
					{preparedColumns.map((col) => (
						<div
							className={[styles.col, col.className].join(' ')}
							key={col.key}
						>
							{col.title}
						</div>
					))}
				</div>
			))}

			{isLoading
				? renderSkeleton()
				: data.map((item: any) => (
						<div className={styles.row} key={item.hash_id || item.key || item.type}>
							{preparedColumns.map((col) => (
								<div
									key={col.key}
									className={[styles.col, col.className].join(' ')}
									data-test-id={col.dataTestId}
								>
									{col.render ? col.render(item) : item[col.dataIndex]}
								</div>
							))}
						</div>
					))}
		</div>
	);
};

export default WalletsLoadTable;

interface ISkeletonLoaderProps {
	readonly fakeRows: number;
}

export const SkeletonLoader: React.FC<ISkeletonLoaderProps> = ({
	fakeRows,
}) => {
	return (
		<div>
			{t('Loading')} {fakeRows}
		</div>
	);
};
