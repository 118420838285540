import { t } from 'pay-kit';
import { OperationHistoryTransactions } from 'api/balanceGroup';

export const getTitleForCancelRole = (
	cancellationsRole: OperationHistoryTransactions['cancellations_role'],
) => {
	if (cancellationsRole === 0) {
		return t('Cancelled');
	}

	// null - допустимое значение. !cancellationsRole - для возможного undefined.
	if (cancellationsRole === null || !cancellationsRole) {
		return null;
	}

	if (cancellationsRole === 1) {
		return t('Compensating');
	}
};
