import API from 'api';
import { LoadQueryListItem, LoadQueryParams } from 'api/walletGroup';
import { useState } from 'react';
import { errorsMap } from 'utils/enums';

const useWalletsLoadListAPI: useWalletsLoadListAPIType = () => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [list, setList] = useState<readonly LoadQueryListItem[] | null>(null);
	const [error, setError] = useState<null | Error>(null);

	const getList = (params: LoadQueryParams) => {
		setIsLoading(true);
		setError(null);
		setList([]);

		API.wallet
			.load(params)
			.then((res: any) => {
				setIsLoading(false);

				if (res?.status === 'ok' && res?.result) {
					setList(res.result);

					return res;
				} else {
					// throw new Error("Invalid response");
					throw new Error(errorsMap.anyResponse);
				}
			})
			.catch((err) => {
				setIsLoading(false);
				setError(err);
			});
	};

	return {
		getList,
		isLoading,
		list,
		error,
	};
};

export default useWalletsLoadListAPI;

export type useWalletsLoadListAPIType = () => {
	readonly getList: (params: LoadQueryParams) => void;
	readonly isLoading: boolean;
	readonly list: readonly LoadQueryListItem[] | null;
	readonly error: Error | null;
};
