import RecoverIcon from 'modules/Wallets/ArchivedWallets/components/Actions/icons/RecoverIcon';
import ActivateIcon from 'modules/Wallets/DisabledWallets/components/Actions/icons/ActivateIcon';
import ArchiveIcon from 'modules/Wallets/DisabledWallets/components/Actions/icons/ArchiveIcon';
import DisableIcon from 'modules/Wallets/WorkingWallets/components/Actions/icons/DisableIcon';
import { Tooltip } from 'pay-kit';
import React from 'react';

import styles from './IconButton.module.scss';

type IconButtonPropsType = {
	readonly icon:
		| 'details'
		| 'resend'
		| 'accept'
		| 'resetCount'
		| 'disable'
		| 'recover'
		| 'archive'
		| 'activate';
	readonly onClick: () => void;
	readonly tip?: string;
	readonly caption?: string;
	readonly dataTestId?: string;
};

const IconButton: React.FC<IconButtonPropsType> = ({
	icon,
	onClick,
	tip,
	caption,
	dataTestId,
}) => {
	const _icon = iconsMap[icon] || null;
	const className = [styles.IconButton];

	if (caption) {
		className.push(styles.textButton);
	}

	const button = (
		<button
			data-test-id={dataTestId}
			onClick={onClick}
			className={className.join(' ')}
		>
			{_icon} {caption && <span>{caption}</span>}
		</button>
	);

	if (tip) {
		return (
			<Tooltip tip={tip} preferredSide='bottom'>
				{button}
			</Tooltip>
		);
	}

	return button;
};

export default IconButton;

const DocumentIcon = (
	<svg
		width='24'
		height='24'
		viewBox='0 0 24 24'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M7 21H17C18.6569 21 20 19.6569 20 18V8.37167C20 7.57602 19.6839 6.81296 19.1213 6.25035L16.7497 3.87868C16.187 3.31607 15.424 3 14.6283 3H7C5.34315 3 4 4.34315 4 6V18C4 19.6569 5.34315 21 7 21Z'
			stroke='currentColor'
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M16 16H8'
			stroke='currentColor'
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M11 12H8'
			stroke='currentColor'
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M20 8.5H16.5C15.3954 8.5 14.5 7.60457 14.5 6.5V3'
			stroke='currentColor'
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
);

const AccceptIcon = (
	<svg
		width='24'
		height='24'
		viewBox='0 0 24 24'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			d='M19.5605 7.44403L12.0045 15L8.22754 11.222'
			stroke='currentColor'
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M21 12C21 16.971 16.971 21 12 21C7.029 21 3 16.971 3 12C3 7.029 7.029 3 12 3C13.453 3 14.821 3.353 16.036 3.964'
			stroke='currentColor'
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
);

const SendIcon = (
	<svg
		width='24'
		height='24'
		viewBox='0 0 24 24'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			d='M5.3788 13.4556L2.80858 16.0259'
			stroke='currentColor'
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M10.5768 18.5126L7.9488 21.1663'
			stroke='currentColor'
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M8.01975 15.9552L4.09374 19.8812'
			stroke='currentColor'
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M12.4864 12.7369L14.1608 16.6426C14.6072 17.6826 16.1115 17.5882 16.4227 16.4997L19.6124 5.33544C19.7842 4.73339 19.228 4.17726 18.626 4.34903L7.46167 7.53871C6.37322 7.84994 6.27798 9.35506 7.31881 9.80065L11.2245 11.475C11.7908 11.7165 12.2432 12.1689 12.4864 12.7369Z'
			stroke='currentColor'
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
);

const ResetCount = (
	<svg
		width='24'
		height='24'
		viewBox='0 0 24 24'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			d='M2.9961 12C2.99024 16.54 6.36543 20.3742 10.8696 20.9441C15.3737 21.5141 19.5975 18.6414 20.7227 14.243C21.8479 9.84464 19.5223 5.29659 15.2978 3.63362C11.0733 1.97065 6.27156 3.71305 4.09657 7.69819'
			stroke='currentColor'
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M10.709 10.7505L11.9585 9.49902V14.5011'
			stroke='currentColor'
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M10.6709 14.6721H13.33'
			stroke='currentColor'
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M3.57227 4.79999V7.69419H6.46647'
			stroke='currentColor'
			strokeWidth='0.988692'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
);

const iconsMap = {
	disable: <DisableIcon />,
	details: DocumentIcon,
	resend: SendIcon,
	accept: AccceptIcon,
	resetCount: ResetCount,
	recover: <RecoverIcon />,
	archive: <ArchiveIcon />,
	activate: <ActivateIcon />,
};
