export const formatDate: FormatDateType = (date) =>
	date.replace(/(\d+)\.(\d+)\.(\d+)/, `$1-$2-$3`);

export const getCurrencyAndAmount = <T extends object | undefined>(
	metrics: T,
) => (metrics ? Object.entries(metrics)[0] : []);

const options = {
	year: 'numeric',
	month: '2-digit',
	day: '2-digit',
} as const;

const dateFormats = {
	D: `$2`,
	M: `$1`,
	Y: `$3`,
};

export const getDateRange: GetDateRangeType = ({
	range,
	delimiter = '.',
	format,
}) => {
	const date = new Date();

	const fromCapturingGroup = format?.dateFrom
		.split('/')
		.reduce((total, current) => {
			const init = total ? total + '-' : total;

			return init + `${dateFormats[current as keyof typeof dateFormats]}`;
		}, '');

	const toCapturingGroup = format?.dateTo
		.split('/')
		.reduce((total, current) => {
			const init = total ? total + '-' : total;

			return init + `${dateFormats[current as keyof typeof dateFormats]}`;
		}, '');

	let dateTo = date
		.toLocaleDateString('en-US', options)
		.replace(/\//g, delimiter);

	const newDate = new Date(date.setDate(date.getDate() - range));
	let dateFrom = newDate
		.toLocaleDateString('en-US', options)
		.replace(/\//g, delimiter);

	const datePattern = new RegExp(
		`^(\\d+)${delimiter}(\\d+)${delimiter}(\\d+)`,
		'g',
	);

	dateFrom = fromCapturingGroup
		? dateFrom.replace(datePattern, fromCapturingGroup)
		: dateFrom;

	dateTo = toCapturingGroup
		? dateTo.replace(datePattern, toCapturingGroup)
		: dateTo;

	return { dateFrom, dateTo };
};

export const getInitDateRange = () => {
	const dayInMillis = 1000 * 60 * 60 * 24;

	const dateFrom = new Date();

	dateFrom.setHours(0);
	dateFrom.setMinutes(0);
	dateFrom.setSeconds(0);
	dateFrom.setTime(dateFrom.getTime() - dayInMillis * 6);

	const dateTo = new Date();

	dateTo.setHours(23);
	dateTo.setMinutes(59);
	dateTo.setSeconds(59);

	const format = (date: Date) => {
		const year = date.getFullYear();
		const month = (date.getMonth() + 1).toString().padStart(2, '0');
		const day = date.getDate().toString().padStart(2, '0');

		const timeMethods: readonly (keyof Date)[] = [
			'getHours',
			'getMinutes',
			'getSeconds',
		];
		//@ts-ignore
		const timeTokens: readonly string[] = timeMethods.map((method) =>
			date[method]().toString().padStart(2, '0'),
		);

		const time = timeTokens.reduce((acc, token) => `${acc}:${token}`);

		const hours = date.getHours().toString().padStart(2, '0');

		return `${year}-${month}-${day} ${time}`;
	};

	return {
		dateFrom: format(dateFrom),
		dateTo: format(dateTo),
	};
};

export const getDashboardFiltersInitPrams = () => {
	const { dateFrom, dateTo } = getInitDateRange();

	return {
		date_from: dateFrom,
		date_to: dateTo,
		date_type: 'activation_date',
		display_in_currency: 'USD',
		show_all_active_transaction_data: 1,
	} as const;
};

type FormatDateType = (date: string) => string;

type FormatType = `Y/M/D` | `Y/D/M` | `M/Y/D` | `M/D/Y` | `D/Y/M` | `D/M/Y`;

type GetDateRangeType = (params: {
	readonly range: number;
	readonly delimiter?: string;
	readonly format?: {
		readonly dateFrom: FormatType;
		readonly dateTo: FormatType;
	};
}) => {
	readonly dateFrom: string;
	readonly dateTo: string;
};
