import { Roles } from 'contexts/AuthContext/utils/enums';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import RoleContext from 'src/contexts/AuthContext';

type HasAccessHookPropsType = {
	readonly rule: Roles;
	readonly redirect?: boolean;
	readonly redirectURL?: string;
};

type HasAccessHookType = (props: HasAccessHookPropsType) => boolean;

export const useHasAccessHook: HasAccessHookType = ({
	rule,
	redirect = false,
	redirectURL = '/error',
}) => {
	const { hasRole, permissionsIsLoading } = useContext(RoleContext);
	const navigate = useNavigate();

	if (permissionsIsLoading) {
		return false;
	}

	if (hasRole(rule)) {
		return true;
	}

	if (redirect) {
		console.warn(`has no rule ${rule}`);
		navigate(redirectURL, { replace: true });
	}

	return false;
};
