import { PayKitForm } from '@paykassma/pay-kit';
import { TariffType } from 'api/tariffsGroup';
import { useTranslation } from 'pay-kit';

import { getCustomElements } from './customElements';
import schema from './formSchema';
import styles from './PercentEditor.module.scss';
import { prepareInitialState } from './utils';

export type FormStateType = {
	readonly commissions: TariffType['commissions'];
	readonly rowPathInEditMode?: string;
};

type PercentEditorProps = {
	readonly commissions: TariffType['commissions'];
	readonly onSuccess: () => void;
	readonly direction: 'ingoing' | 'outgoing';
	readonly tariffId: number;
	readonly type: 'settlement' | 'percent';
};

const PercentEditor: React.FC<PercentEditorProps> = ({
	commissions,
	onSuccess,
	direction,
	tariffId,
	type,
}) => {
	const { t } = useTranslation();

	return (
		<div className={styles.wrapper}>
			{type === 'percent' && <h4>{t('Percent of transaction sum')}</h4>}
			{type === 'settlement' && <h4>{t('Settlement')}</h4>}

			<PayKitForm.Builder<FormStateType>
				schema={schema}
				initialState={{
					commissions: prepareInitialState(commissions),
					rowPathInEditMode: undefined,
				}}
				customElements={getCustomElements({
					onSuccess,
					direction,
					tariffId,
					type,
				})}
			/>
		</div>
	);
};

export default PercentEditor;
