import API from 'api';
import { PSBalanceType, TransferBalanceDataType } from 'api/balanceGroup';
import { CurrencyListType } from 'api/stockpilingsGroup';
import { Error, OldResponse } from 'api/types';
import { useOperationHistoryContext } from 'modules/TransferHistory/contexts/OperationHistoryContext';
import {
	Dispatch,
	ReactNode,
	createContext,
	useContext,
	useEffect,
	useState,
} from 'react';

export const OperationHistoryModalContext =
	createContext<OperationHistoryModalContextType>(
		{} as OperationHistoryModalContextType,
	);

const OperationHistoryModalContextProvider = ({
	children,
}: {
	readonly children: ReactNode;
}) => {
	const [error, setError] = useState<Error | null>(null);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [accountTypeSelectValue, setAccountTypeSelectValue] = useState('');
	const [currencySelectValue, setCurrencySelectValue] = useState('');
	const [rateInputValue, setRateInputValue] = useState('');
	const [precision, setPrecision] = useState(0);
	const [currencies, setCurrencies] = useState<
		CurrencyListType['currencies'] | null
	>(null);
	const { PSBalance } = useOperationHistoryContext();

	const initForm = {
		id: '',
		recipient_payment_system: '',
		currency_code: '',
		ex_rate: '',
		commission_percent: '',
		writeoff_amount: '',
		isWriteOffAmountTouched: false,
		crediting: '',
		isCreditingTouched: false,
	};

	useEffect(() => {
		setIsLoading(true);
		setError(null);

		API.stockpiling
			.getCurrencyList()
			.then((res) => {
				if (res.status === 'success') {
					const isCrypt = res.data.currencies.find(
						(currency) => currency.code === PSBalance?.currency_code,
					);
					const precision = isCrypt?.is_crypt ? 8 : 4;

					setCurrencies(res.data.currencies);
					setPrecision(precision);
				}
			})
			.catch((err) => {
				console.log(err);
				setError(err);
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, [PSBalance?.currency_code]);

	const transferBalance = (params: Partial<TransferBalanceDataType>) => {
		return API.balances.transferBalance(params);
	};

	const contextValue = {
		transferBalance,
		setAccountTypeSelectValue,
		accountTypeSelectValue,
		setCurrencySelectValue,
		currencySelectValue,
		setRateInputValue,
		rateInputValue,
		precision,
		PSBalance,
		currencies,
		initForm,
		error,
		isLoading,
	};

	return (
		<OperationHistoryModalContext.Provider value={contextValue}>
			{children}
		</OperationHistoryModalContext.Provider>
	);
};

export default OperationHistoryModalContextProvider;

export const useOperationHistoryModalContext = () =>
	useContext(OperationHistoryModalContext);

export type OperationHistoryModalContextType = {
	readonly transferBalance: (
		params: Partial<TransferBalanceDataType>,
	) => Promise<OldResponse<unknown>>;
	readonly setAccountTypeSelectValue: Dispatch<string>;
	readonly accountTypeSelectValue: string;
	readonly setCurrencySelectValue: Dispatch<string>;
	readonly currencySelectValue: string;
	readonly setRateInputValue: Dispatch<string>;
	readonly rateInputValue: string;
	readonly precision: number;
	readonly currencies: CurrencyListType['currencies'] | null;
	readonly PSBalance: PSBalanceType | undefined;
	readonly initForm: {
		readonly id: string;
		readonly recipient_payment_system: string;
		readonly currency_code: string;
		readonly ex_rate: string;
		readonly commission_percent: string;
		readonly writeoff_amount: string;
		readonly isWriteOffAmountTouched: boolean;
		readonly crediting: string;
		readonly isCreditingTouched: boolean;
	};
	readonly isLoading: boolean;
	readonly error: Error | null;
};
