import { t } from '@paykassma/pay-kit';
import API from 'api';
import { IWithdrawalDetails } from 'api/withdrawalGroup';
import { useState } from 'react';

const useWithdrawalAPI: IUseWithdrawalAPI = () => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [withdrawal, setWithdrawal] = useState<IWithdrawalDetails | null>(null);
	const [error, setError] = useState<null | Error>(null);

	const getWithdrawal = (id: number) => {
		setIsLoading(true);
		setError(null);
		setWithdrawal(null);

		API.withdrawal
			.getOne(id)
			.then((res: any) => {
				setIsLoading(false);

				if (res?.status === 'success' && res?.data) {
					setWithdrawal(res.data);

					return res;
				} else {
					throw new Error(t('Invalid response'));
				}
			})
			.catch((err) => {
				setIsLoading(false);
				setError(err);
			});
	};

	return {
		getWithdrawal,
		isLoading,
		withdrawal,
		error,
	};
};

export default useWithdrawalAPI;

export type IUseWithdrawalAPI = () => {
	readonly getWithdrawal: (id: number) => void;
	readonly isLoading: boolean;
	readonly withdrawal: IWithdrawalDetails | null;
	readonly error: Error | null;
};

export type getListSuccessResponseType = {
	readonly status: 'ok';
	readonly withdrawal: IWithdrawalDetails;
};
