import { BodyEndPortal, useOutsideClick } from 'pay-kit';
import React, { useRef } from 'react';

import ChevronIcon from './icons/chevron.svg';
import CircleCloseIcon from './icons/circle-close.svg';
import styles from './RightModal.module.scss';

type RightModalPropsType = {
	readonly children: React.ReactNode;
	readonly isVisible: boolean;
	readonly onClose: () => void;
	readonly onShow: () => void;
	readonly heading?: string;
	readonly bodyClassName?: string;
};

const RightModal: React.FC<RightModalPropsType> = ({
	children,
	bodyClassName,
	isVisible,
	onClose,
	onShow,
	heading,
}) => {
	const classNames = [styles.rightModal, bodyClassName];
	const modalElement = useRef<HTMLDivElement>(null);

	useOutsideClick(modalElement, (e) => {
		// 1st check case click when modal is opened
		const openedModals = document.querySelectorAll(
			'[class^="Modal"][role="dialog"][data-is-open="true"]',
		);

		// 2nd check case click on modal
		const clickOnModal =
			e?.path?.find((el: HTMLDivElement) => el.role === 'dialog') !== undefined;

		// in case click on DatePicker's popup calendar
		// const clickOnDatePickerCalendar = e.target?.className?.indexOf && e.target.className.indexOf("DatePicker__content") === 0;
		// const clickOnDatePickerCalendar = e.target?.className && e.target.className.includes("DatePicker__content");
		const clickOnDatePickerCalendar =
			e.target instanceof Element &&
			e.target.className &&
			typeof e.target.className.includes === 'function' &&
			(['DatePicker', 'DateRangePicker', 'TimePicker'].some((cl) =>
				e.target.className.includes(cl),
			) ||
				e.target.getAttribute('data-test-id')?.includes('TimePicker'));

		if (openedModals.length > 0 || clickOnModal || clickOnDatePickerCalendar) {
			return;
		}

		onClose();
	});

	if (isVisible) {
		classNames.push(styles.isVisible);
	}

	return (
		<BodyEndPortal>
			<div className={classNames.join(' ')} ref={modalElement}>
				{heading && <div className={styles.heading}>{heading}</div>}

				{children}

				<button
					data-test-id='hideModal'
					className={styles.hideModal}
					onClick={isVisible ? onClose : onShow}
				>
					<ChevronIcon />
				</button>

				<button
					data-test-id='circleClose'
					className={styles.circleClose}
					onClick={onClose}
				>
					<CircleCloseIcon />
				</button>
			</div>
		</BodyEndPortal>
	);
};

export default RightModal;
