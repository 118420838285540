export const COLORS = [
	'#0072C3',
	'#B78686',
	'#FFBC92',
	'#C28098',
	'#3300C3',
	'#C191F1',
	'#72BE68',
	'#178206',
	'#79553D',
	'#A50B70',
	'#69B4FF',
	'#F984E5',
	'#8500C3',
	'#4AC300',
	'#2A8D9C',
	'#FF7D7D',
	'#EBE100',
	'#A7FC00',
	'#F64A46',
	'#C38C00',
	'#C38C00',
	'#F83232',
	'#757982',
	'#B4BAC8',
	'#C3003B',
	'#0047AB',
	'#004953',
	'#E7C697',
	'#79443B',
	'#FFD700',
	'#F67B00',
	'#FF00FF',
	'#98FF98',
	'#048071',
	'#49EFDB',
	'#C14804',
	'#34C5F2',
	'#CBCBCB',
	'#0057FF',
	'#00FFD1',
	'#9A9D00',
	'#C5D98C',
	'#87A4B9',
	'#B57F7F',
	'#B287B2',
	'#304565',
	'#55780C',
	'#EFD4B4',
];
