// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TotalReport__form-GkR{padding:32px;display:flex;flex-direction:column}.TotalReport__form-GkR .TotalReport__scrollBox-YVZ{height:470px}.TotalReport__form-GkR .TotalReport__baseSettings-za1{display:grid;grid-template-columns:450px 450px;width:100%;column-gap:48px;row-gap:12px}.TotalReport__form-GkR .TotalReport__baseSettings-za1>*:last-child{grid-column:2}.TotalReport__form-GkR .TotalReport__actions-uqw{background:#f2f3f5;display:flex;align-items:center;justify-content:flex-end;gap:48px;margin-top:auto}.TotalReport__form-GkR .TotalReport__actions-uqw>button:last-child{min-width:170px}`, "",{"version":3,"sources":["webpack://./src/modules/Reports/ReportList/components/ActionsProvider/modals/NewReport/forms/TotalReport/TotalReport.module.scss"],"names":[],"mappings":"AAAA,uBACI,YAAA,CACA,YAAA,CACA,qBAAA,CAEA,mDACI,YAAA,CAGJ,sDACI,YAAA,CACA,iCAAA,CACA,UAAA,CACA,eAAA,CACA,YAAA,CAEA,mEACI,aAAA,CAIR,iDACI,kBAAA,CACA,YAAA,CACA,kBAAA,CACA,wBAAA,CACA,QAAA,CACA,eAAA,CAYA,mEACI,eAAA","sourcesContent":[".form {\n    padding: 32px;\n    display: flex;\n    flex-direction: column;\n\n    .scrollBox {\n        height: 470px;\n    }\n\n    .baseSettings {\n        display: grid;\n        grid-template-columns: 450px 450px;\n        width: 100%;\n        column-gap: 48px;\n        row-gap: 12px;\n\n        &>*:last-child {\n            grid-column: 2;\n        }\n    }\n\n    .actions {\n        background: #F2F3F5;\n        display: flex;\n        align-items: center;\n        justify-content: flex-end;\n        gap: 48px;\n        margin-top: auto;\n\n        // .formatSwitcher {\n        //     display: flex;\n        //     align-items: center;\n        //     gap: 16px;\n\n        //     button {\n        //         padding: 0 16px;\n        //     }\n        // }\n\n        >button:last-child {\n            min-width: 170px;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": `TotalReport__form-GkR`,
	"scrollBox": `TotalReport__scrollBox-YVZ`,
	"baseSettings": `TotalReport__baseSettings-za1`,
	"actions": `TotalReport__actions-uqw`
};
export default ___CSS_LOADER_EXPORT___;
