import { useTranslation } from 'pay-kit';
import { Button } from '@paykassma/pay-kit';
import { useNavigate } from 'react-router-dom';

import styles from './NotFoundPage.module.scss';
import WarningIcon from './icons/WarningIcon';

export default function NotFoundPage() {
	const navigate = useNavigate();
	const { t } = useTranslation();

	const handleClick = () => {
		navigate(-1);
	};

	return (
		<div className={styles.pageContainer}>
			<div className={styles.iconWrapper}>
				<WarningIcon />
				<h1 className={styles.errorCode}>404</h1>
			</div>
			<h2 className={styles.pageTitle}>{t('Something went wrong...')}</h2>
			<div className={styles.pageDescription}>
				{t("We're sorry, but the page was not found.")}
			</div>

			<Button variant='link-primary' onClick={handleClick}>
				{t('Back')}
			</Button>
		</div>
	);
}
