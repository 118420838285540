/* eslint-disable react-hooks/exhaustive-deps */
import { PayKitForm, Tooltip } from '@paykassma/pay-kit';
import { Roles, useHasAccessHook } from 'contexts/AuthContext';
import { useAuthContext } from 'contexts/AuthContext/AuthContext';
import { CurrenciesContext } from 'contexts/CurrenciesContext';
import { WalletTypesContext } from 'contexts/WalletTypesContext';
import { Loader, useTranslation } from 'pay-kit';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import copyToClipboard from 'utils/copyToClipboard';
import { rejectSettlement } from 'utils/filterSettlement';
import useGetWallets from 'utils/hooks/useGetWallets';

import LinkIcon from './icons/link.svg';
import RefreshIcon from './icons/refresh.svg';
import ResetIcon from './icons/reset.svg';
import Placeholder from './Placeholder';
import styles from './Plugin.module.scss';

const PLUGIN_LOCAL_STORAGE_KEY = 'pluginConstructor';

const getRestoredState = () => {
	try {
		const jsonValue = localStorage.getItem(PLUGIN_LOCAL_STORAGE_KEY);

		return jsonValue ? JSON.parse(jsonValue) : undefined;
	} catch (err) {
		console.error(err);
		localStorage.removeItem(PLUGIN_LOCAL_STORAGE_KEY);

		return undefined;
	}
};

type FormStateType = {
	readonly label?: string;
	readonly currency_code?: readonly string[];
	readonly lang?: string;
	readonly wallet_type?: string;
	readonly level?: string;
	readonly custom_transaction_id?: string;
	readonly fixed_amount?: string;
	readonly payment_url_success_id?: string;
	readonly payment_url_fail_id?: string;
	readonly success_url?: string;
	readonly fail_url?: string;
};

const Plugin = () => {
	useHasAccessHook({ rule: Roles.MANAGE_PAYMENTS_CONSTRUCTOR, redirect: true });

	const { t } = useTranslation();

	const [appliedState, setAppliedState] = useState<FormStateType | undefined>(
		getRestoredState(),
	);
	const [applied, setApplied] = useState<boolean>(false);

	const currencies = useContext(CurrenciesContext);
	const walletTypesAPI = useContext(WalletTypesContext);
	const walletsAPI = useGetWallets();
	const iframeElement = useRef(null);

	const { hasRole } = useAuthContext();

	const formDataAPI = PayKitForm.useFormDataAPI<FormStateType>({});

	const applyAndSaveState = (state?: FormStateType) => {
		setAppliedState(state);

		if (state) {
			const json = JSON.stringify(state);

			localStorage.setItem(PLUGIN_LOCAL_STORAGE_KEY, json);
		} else {
			localStorage.removeItem(PLUGIN_LOCAL_STORAGE_KEY);
		}
	};

	useEffect(() => {
		if (hasRole(Roles.WALLET_LIST)) {
			walletsAPI.load();
		}
	}, []);

	useEffect(() => {
		if (
			!currencies.isLoading &&
			!walletTypesAPI.isLoading &&
			!walletsAPI.isLoading &&
			!applied
		) {
			const restoredState = getRestoredState();

			if (restoredState) {
				Object.keys(restoredState).forEach((key) =>
					formDataAPI.setValue(key, restoredState[key]),
				);
			}

			setApplied(true);
		}
	}, [
		currencies.isLoading,
		walletTypesAPI.isLoading,
		walletsAPI.isLoading,
		applied,
	]);

	const customElements = useMemo(
		() => ({
			ActionsSection: (props: any) => (
				<PayKitForm.Group
					{...props}
					render={(children) => (
						<div className={styles.actions}>{children}</div>
					)}
				/>
			),
			IconButtonWithTooltip: ({ label, icon, ...rest }: any) => (
				<Tooltip tip={label} preferredSide='top'>
					<button
						className={styles.iconButton}
						data-test-id={label}
						title={label}
						onClick={() => rest.onClick(rest.formDataAPI)}
						disabled={rest.disabled}
					>
						{icon}
					</button>
				</Tooltip>
			),
		}),
		[],
	);

	const langs = [
		'en',
		'vi',
		'ms',
		'id',
		'uz',
		'es',
		'pt',
		'tr',
		'ar',
		'fa',
		'ur',
		'bn',
		'ne',
		'ru',
		'hi',
		'my',
		'si',
		'pa',
		'sw',
		'km',
	];

	const iframeURL = constructIframeURL(appliedState);

	const schema = [
		{
			type: 'ActionsSection',
			elements: [
				{
					type: 'SubmitButton',
					label: t('Apply'),
					onSubmit: (data: FormStateType) => {
						applyAndSaveState(data);
					},
				},
				{
					type: 'IconButtonWithTooltip',
					icon: <ResetIcon />,
					variant: 'secondary',
					label: t('Reset all params'),
					disabled: (state: FormStateType) => {
						return Object.keys(state).length === 0;
					},
					onClick: (formState: FormStateType) => {
						// debugger;
						formDataAPI.resetState();
						applyAndSaveState(undefined);
					},
				},
				{
					type: 'IconButtonWithTooltip',
					icon: <RefreshIcon />,
					variant: 'secondary',
					label: t('Refresh'),
					disabled: !iframeURL,
					onClick: () => {
						if (iframeElement?.current?.src) {
							iframeElement.current.src = iframeURL;
						}
					},
				},
				{
					type: 'IconButtonWithTooltip',
					icon: <LinkIcon />,
					label: t('Copy link'),
					disabled: !iframeURL,
					variant: 'secondary',
					onClick: () => {
						if (iframeURL) {
							copyToClipboard(iframeURL, () =>
								window.pushAlert({
									description: t('Link copied'),
									type: 'success',
								}),
							);
						}
					},
				},
			],
		},
		{
			name: 'label',
			label: 'label',
			type: 'TextInput',
			isRequired: true,
			validation: (value: string) =>
				!value ? t('Field is required') : undefined,
		},
		{
			name: 'currency_code',
			label: 'currency_code',
			type: 'MultiSelect',
			isRequired: true,
			validation: (value: string) =>
				!value || value.length < 1 ? t('Choose currency') : undefined,
			options: currencies.list.map((c) => ({ label: c.code, value: c.code })),
			isLoading: currencies.isLoading,
		},
		{
			name: 'lang',
			label: 'lang',
			type: 'Select',
			options: langs.map((lang) => ({ label: lang, value: lang })),
		},
		{
			name: 'wallet_type',
			label: 'wallet_type',
			type: 'Select',
			options: [
				{ label: t('All'), value: '' },
				...rejectSettlement(walletTypesAPI.walletTypes).map((wt) => ({
					label: wt.code,
					value: wt.code,
				})),
			],
			isLoading: walletTypesAPI.isLoading,
		},
		{
			name: 'w_hash_id',
			label: 'w_hash_id',
			type: 'Select',
			options: (state: FormStateType) => {
				return walletsAPI.list
					.filter((item) => item.wallet_type === state.wallet_type)
					.map((wallet) => ({
						label: `${wallet.hash_id} | ${wallet.identifier} `,
						value: wallet.hash_id,
					}));
			},
			existsIf: ({ wallet_type }: FormStateType) =>
				!!wallet_type && hasRole(Roles.WALLET_LIST),
			isLoading: walletsAPI.isLoading,
		},
		{
			name: 'level',
			label: 'level',
			type: 'TextInput',
		},
		{
			name: 'custom_transaction_id',
			label: 'custom_transaction_id',
			type: 'TextInput',
		},
		{
			name: 'fixed_amount',
			label: 'fixed_amount',
			type: 'TextInput',
		},
		{
			name: 'payment_url_success_id',
			label: 'payment_url_success_id',
			type: 'TextInput',
		},
		{
			name: 'payment_url_fail_id',
			label: 'payment_url_fail_id',
			type: 'TextInput',
		},
		{
			name: 'success_url',
			label: 'success_url',
			type: 'TextInput',
		},
		{
			name: 'fail_url',
			label: 'fail_url',
			type: 'TextInput',
		},
	] as any;

	return (
		<div className={styles.wrapper}>
			{appliedState && !applied ? (
				<Loader />
			) : (
				<div className={styles.settings}>
					<PayKitForm.Builder<FormStateType>
						formDataAPI={formDataAPI}
						schema={schema}
						customElements={customElements}
					/>
				</div>
			)}
			{iframeURL ? (
				<iframe
					className={styles.iframe}
					height='726'
					width='617'
					src={iframeURL}
					ref={iframeElement}
				/>
			) : (
				<Placeholder />
			)}
		</div>
	);
};

export default Plugin;

const constructIframeURL = (appliedState?: FormStateType) => {
	if (!appliedState) {
		return undefined;
	}

	const queryParams = new URLSearchParams(
		appliedState as Record<string, string>,
	);

	return `${window.config.pluginUrl}/?${queryParams.toString()}`;
};
