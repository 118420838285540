import CurrentTransactions from 'modules/Transactions/CurrentTransactions';
import { useTranslation } from 'pay-kit';
import { useEffect } from 'react';

export default function CurrentTransactionsPage() {
	const { t } = useTranslation();

	useEffect(() => {
		document.title = t('Current transactions');
	}, []);

	return <CurrentTransactions />;
}
