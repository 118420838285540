import {
	FormTypesItemType,
	TranslationPackageType,
} from 'api/translationsGroup';
import RightChevron from 'assets/icons/right_chevron.svg';
import Spoiler from 'components/Spoiler';
import React from 'react';

import { ActionsContext } from '../../components/ActionsProvider';
import EditTranslationPopup from '../../components/EditTranslationPopup';

import styles from './NavigationTree.module.scss';
import { getPackageIds, getPackages } from './utils';

type NavigationTreePropsType = {
	readonly data: readonly {
		readonly name: string;
		readonly formTypes: readonly {
			readonly formType: FormTypesItemType;
			readonly items: readonly TranslationPackageType[];
		}[];
		readonly isDefault: boolean;
	}[];
	readonly selectedField?: {
		readonly packageId: string;
		readonly fieldId: string;
	};
	readonly setSelectedField: (selectedField: {
		readonly packageId: string;
		readonly fieldId: string;
	}) => void;
	readonly usedNames: readonly string[];
};

const NavigationTree: React.FC<NavigationTreePropsType> = ({
	data,
	selectedField,
	setSelectedField,
	usedNames,
}) => (
	<div className={styles.navigationTree}>
		{data.map((field) => (
			<Spoiler
				key={field.name}
				className={styles.spoiler}
				expandedClassName={styles.expanded}
				title={
					<>
						{!field.isDefault && (
							<ActionsContext.Consumer>
								{({ setRemoveModalData, setRenameModalData }) => (
									<EditTranslationPopup
										onRename={() =>
											setRenameModalData({
												fieldName: field.name,
												usedNames,
												packages: getPackages(field),
												translationType: 'package',
											})
										}
										onRemove={() =>
											setRemoveModalData({
												fieldName: field.name,
												packageIds: getPackageIds(field),
												translationType: 'package',
											})
										}
									/>
								)}
							</ActionsContext.Consumer>
						)}
						<div className={styles.translationName} title={field.name}>
							{field.name}
						</div>
					</>
				}
			>
				{field.formTypes.map((formType) => (
					<React.Fragment key={formType.formType.title}>
						<h4>{formType.formType.title}</h4>
						{formType.items.map((pkg) =>
							pkg.packages_translations.map((translation) => {
								const isActive =
									selectedField?.fieldId === translation.field &&
									selectedField?.packageId === pkg.uuid;

								return (
									<button
										className={isActive ? styles.activeButton : undefined}
										key={translation.field_uuid}
										onClick={() =>
											setSelectedField({
												packageId: pkg.uuid,
												fieldId: translation.field,
											})
										}
										data-test-id='package-button'
									>
										{translation.name}
										<RightChevron />
									</button>
								);
							}),
						)}
					</React.Fragment>
				))}
			</Spoiler>
		))}
	</div>
);

export default NavigationTree;
