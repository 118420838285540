import { convertIntoCurrency } from 'helpers/convertIntoCurrency';
import { useOperationHistoryContext } from 'modules/TransferHistory/contexts/OperationHistoryContext';
import { FC } from 'react';
import { useTranslation } from '@paykassma/pay-kit';

import styles from './amount.module.scss';

const Amount: FC<{ readonly amount: number }> = ({ amount }) => {
	const { PSBalance } = useOperationHistoryContext();

	const { t } = useTranslation();

	return (
		<div className={styles.amount}>
			{convertIntoCurrency({ amount })} {t('CU')}
		</div>
	);
};

export default Amount;
