import api from 'api/translationsGroup';
import { useState } from 'react';
import { errorsMap } from 'utils/enums';

const useGetLanguages = () => {
	const [list, setList] = useState<readonly string[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const load = () => {
		setIsLoading(true);

		api
			.getLanguages()
			.then((res: any) => {
				if (res?.status === 'success' && !!res?.data) {
					setList(res.data as readonly string[]);

					return res;
				} else {
					// throw new Error(`Getting translation languages error`);
					throw new Error(errorsMap.anyResponse);
				}
			})
			.finally(() => setIsLoading(false))
			.catch((err) => {
				console.error(err);
			});
	};

	return {
		load,
		list,
		isLoading,
	};
};

export default useGetLanguages;
