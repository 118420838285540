import api, { IGetTargetsResponse } from 'api/walletTypesGroup';
import { useState } from 'react';
import { errorsMap } from 'utils/enums';

const useGetTargetsHook = () => {
	const [data, setData] = useState<IGetTargetsResponse | undefined>(undefined);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const load = (psId: string) => {
		setIsLoading(true);

		api
			.getTargets(psId)
			.then((res: any) => {
				if (res.status === 'ok') {
					setData(res as IGetTargetsResponse);
				} else {
					// throw new Error("Unexpected response received in getTargets");
					throw new Error(errorsMap.anyResponse);
				}

				return res;
			})
			.finally(() => setIsLoading(false))
			.catch((err) => {
				console.error(err);
				window.pushAlert({ type: 'error', title: err.toString() });
			});
	};

	return {
		data,
		load,
		isLoading,
	};
};

export default useGetTargetsHook;
