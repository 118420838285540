import { GetAutoReportResponseData } from '/api/reportsGroup';

import { TransactionsListFilledFormStateType } from '../../components/forms/TransactionsList';
import { TransactionsSumFilledFormStateType } from '../../components/forms/TransactionsSum';

import { fromLocaleDate, toLocaleDate } from 'utils/date';

export type TaskType =
	| 'transactions_sum'
	| 'transactions_list'
	| 'transactions_sum-detail';

export const prepareReceivedData = (
	data: GetAutoReportResponseData['data'],
) => {
	const prepareDate = (date: string) => {
		const parts = date.split(' ').slice(0, 2);
		const joined = parts.join(' ');

		return joined;
	};

	const newObj: any = {
		emails: data.emails.map((email) => ({ email })),
		utc_0: data.timezone,
		file_format: data.file_format,
		wallet_type: data.filters.wallet_type,
		direction: data.filters.direction,
		wallet_id: data.filters.wallet_id,
		type: data.filters.type,
		report_currency_code: data.currency_code,
		creation_type: data.filters.creation_type,
		originality: data.filters.originality,
		status: data.filters.status,
		label: data.filters.label,
		exchanger_identifier: data.filters.exchanger_identifier,
		reporting_period: data.reporting_period,
		archiving_status: data.filters.archiving_status,
	};

	let fields = [];

	if (Array.isArray(data.fields)) {
		fields = data.fields;
	} else if (data.fields instanceof Object) {
		fields = Object.values(data.fields);
	}

	if (
		['transactions_sum-detail', 'transactions_sum'].includes(data.report_type)
	) {
		newObj.commission =
			fields.filter((field: string) =>
				[
					'total_commission',
					'deposit_commission',
					'withdrawal_commission',
				].includes(field),
			).length === 3;
	}

	newObj.fields = fields;
	newObj.start = prepareDate(data.start);

	return newObj;
};

export const preparePostData = (
	report_type: TaskType,
	formData: (
		| TransactionsListFilledFormStateType
		| TransactionsSumFilledFormStateType
	) & {
		readonly archiving_status?: 'onlyArchived' | 'withoutArchived';
		readonly commission?: boolean;
		readonly fields: readonly string[];
	},
) => {
	const obj: any = {
		report_type,
		filters: {
			wallet_type: formData.wallet_type,
			wallet_hash_id: formData.wallet_hash_id,
			direction: formData.direction,
			exchanger_identifier: formData.exchanger_identifier,
			label: formData.label,
			creation_type: formData.creation_type,
			originality: formData.originality,
			status: formData.status,
			type: formData.type,
			archiving_status: formData.archiving_status,
		},
		fields: [],
		report_currency_code: formData.report_currency_code,
		file_format: formData.file_format,
		utc_0: formData.utc_0,
		reporting_period: formData.reporting_period,
		emails: formData.emails.map(({ email }) => email),
		start: formData.start,
	};

	switch (report_type) {
		case 'transactions_list':
			obj.fields = formData.fields;

			break;
		case 'transactions_sum':
		case 'transactions_sum-detail':
			if (formData.commission) {
				obj.fields = [
					...new Set([
						...formData.fields,
						'total_commission',
						'deposit_commission',
						'withdrawal_commission',
					]),
				];
			} else {
				obj.fields = formData.fields.filter(
					(field: string) =>
						![
							'total_commission',
							'deposit_commission',
							'withdrawal_commission',
						].includes(field),
				);
			}

			break;
	}

	return obj;
};
