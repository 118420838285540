import API from 'api';
import { postbackType } from 'api/postbackGroup';
import { useState } from 'react';
import { errorsMap } from 'utils/enums';
import { t } from 'pay-kit';

type useGetPostbackDataType = () => {
	readonly isLoading: boolean;
	readonly postbackData?: postbackType;
	readonly load: (
		id: postbackType['id'],
		direction: postbackType['direction'],
	) => void;
};

const useGetPostbackData: useGetPostbackDataType = () => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [postbackData, setPostbackData] = useState<postbackType | undefined>(
		undefined,
	);

	const load = (
		id: postbackType['id'],
		direction: postbackType['direction'],
	) => {
		setIsLoading(true);

		API.postback
			.getOne({ id, direction })
			.then((res) => {
				if (res?.status === 'success') {
					setPostbackData(res?.data as postbackType);
					setIsLoading(false);

					return;
				} else {
					// throw new Error("Unexpected response");
					throw new Error(errorsMap.anyResponse);
				}
			})
			.catch(() => {
				setIsLoading(false);

				// window.pushAlert({ description: "Не удалось получить данные о постбeке", type: "error" });
				window.pushAlert({
					description: t('Failed to get postback'),
					type: 'error',
				});
			});
	};

	return {
		isLoading,
		postbackData,
		load,
	};
};

export default useGetPostbackData;
