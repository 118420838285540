import CopyTextButton from 'components/CopyTextButton';
import { FC, ReactNode } from 'react';
import { t } from '@paykassma/pay-kit';

import styles from './textWithCopyButton.module.scss';

type PostbackRowType = {
	readonly rowValue?: number | string | ReactNode;
	readonly copiedTextValue?: number | string;
	readonly copy?: boolean;
	readonly copiedTitle?: string;
};

const TextWithCopyButton: FC<PostbackRowType> = ({
	rowValue = '',
	copiedTextValue,
	copy = false,
	copiedTitle,
}) => {
	const handleOnSuccess = () =>
		window.pushAlert({
			description: `${copiedTitle} ${t('copied')}!`,
			type: 'success',
		});

	return (
		<div className={styles.row}>
			<div className={styles.rowValue}>{rowValue}</div>
			{copy && (
				<CopyTextButton
					text={copiedTextValue || ''}
					onSuccess={handleOnSuccess}
				/>
			)}
		</div>
	);
};

export default TextWithCopyButton;
