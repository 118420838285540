import { Button, Table } from '@paykassma/pay-kit';
import { ReportStatus, ReportsListItemType } from 'api/reportsGroup';
import DownloadIcon16x16 from 'assets/icons/download_16x16.svg';
import PlusIcon from 'assets/icons/plus.svg';
import TrashIcon16x16 from 'assets/icons/trash_16x16.svg';
import { useTranslation } from 'pay-kit';

import ActionsProvider, { ActionsContext } from './components/ActionsProvider';
import { reportColumns } from './components/columns';
import styles from './ReportList.module.scss';

const ReportList = () => {
	const { t } = useTranslation();

	return (
		<ActionsProvider>
			<ActionsContext.Consumer>
				{({
					getReportsAPI,
					selectedRows,
					setSelectedRows,
					downloadReports,
					deleteReports,
					createNewReport,
				}) => (
					<>
						<div className={styles.topActions}>
							<Button data-test-id='new-report' onClick={createNewReport}>
								<PlusIcon />
								&nbsp;&nbsp;
								{t('Create report')}
							</Button>
							<Button
								data-test-id='refresh'
								variant='text-primary'
								onClick={getReportsAPI.load}
							>
								{t('Refresh')}
							</Button>
						</div>
						<Table
							isLoading={getReportsAPI.isLoading}
							rowKey='file_path'
							data={getReportsAPI.list || []}
							className={styles.table}
							skeleton={{ rowsCount: 20 }}
							select={{
								multiselect: true,
								onRowsSelect: (ids) => {
									const withCorrectStatus = getReportsAPI.list.filter(
										(item) => item.status === ReportStatus.SUCCESS,
									);
									const inIds = withCorrectStatus.filter((item) =>
										ids.includes(item.file_path),
									);

									setSelectedRows(inIds.map((item) => item.file_path));
								},
								selectedIds: selectedRows,
								renderMultipleActions: (file_paths) => (
									<div className={styles.selectedRowsActions}>
										<button
											data-test-id='download'
											onClick={() => downloadReports(file_paths)}
										>
											<DownloadIcon16x16 /> {t('Download')}
										</button>
										<button
											data-test-id='delete'
											className={styles.deleteReports}
											onClick={() => {
												const fileNames = getReportsAPI.list
													.filter((report: ReportsListItemType) =>
														file_paths.includes(report.file_path),
													)
													.map(
														(report: ReportsListItemType) => report.file_name,
													);

												deleteReports(fileNames);
											}}
										>
											<TrashIcon16x16 /> {t('Delete')}
										</button>
									</div>
								),
							}}
							columns={reportColumns({ downloadReports, deleteReports })}
						/>
					</>
				)}
			</ActionsContext.Consumer>
		</ActionsProvider>
	);
};

export default ReportList;
