import { PossibleTransactionStatusType } from 'api/transactionGroup';
import { Tooltip, t } from 'pay-kit';
import React from 'react';

import styles from './PossibleTransactionStatus.module.scss';

interface IPossibleTransactionStatus {
	readonly status: PossibleTransactionStatusType;
	readonly dataTestId?: string;
}

const PossibleTransactionStatus: React.FC<IPossibleTransactionStatus> = ({
	status,
	dataTestId,
}) => {
	const StatusMap = {
		[PossibleTransactionStatusType.NEW]: {
			// label: "Новая",
			label: t('New'),
			className: styles.NEW,
		},
		[PossibleTransactionStatusType.PROCESSED]: {
			// label: "Обработана",
			label: t('Processed'),
			className: styles.PROCESSED,
		},
	};

	// const statusObject = StatusMap[status] || { label: "Неопределенный статус", className: styles.notDefined };
	const statusObject = StatusMap[status] || {
		label: t('Not defined status'),
		className: styles.notDefined,
	};
	const classNames = [styles.TransactionStatus, statusObject.className];

	return (
		// <Tooltip tip="Статус транзакции" preferredSide="bottom">
		<Tooltip tip={t('Status')} preferredSide='bottom'>
			<div className={classNames.join(' ')} data-test-id={dataTestId}>
				{statusObject.label}
			</div>
		</Tooltip>
	);
};

export default PossibleTransactionStatus;
