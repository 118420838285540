import { ScrollBox, useTranslation } from 'pay-kit';
import { IArchivedTransaction } from 'api/transactionGroup';
import Money from 'components/Money';
import PaymentSystemLogo from 'components/PaymentSystemLogo';
import TextWithCopyButton from 'components/TextWithCopyButton';
import React from 'react';

import CreationType from '../../../components/CreationType';
import PostbackStatus from '../../../components/PostbackStatus';
import TransactionStatus from '../../../components/TransactionStatus';
import TransactionType from '../../../components/TransactionType';

import styles from './CommonData.module.scss';

interface ICommonDataProps {
	readonly transactionData: IArchivedTransaction;
}

const CommonData: React.FC<ICommonDataProps> = ({ transactionData }) => {
	const { t } = useTranslation();
	const ROWS_MAP = [
		{
			id: 'wallet_type',
			title: t('Wallet type'),
			render: ({ wallet_type }: IArchivedTransaction) => (
				<PaymentSystemLogo id={wallet_type} hideLabel={true} />
			),
		},
		{
			id: 'wallet_type',
			title: t('Name'),
			render: ({ wallet_type }: IArchivedTransaction) => (
				<PaymentSystemLogo id={wallet_type} hideLogo={true} />
			),
		},
		{
			id: 'id',
			title: t('ID'),
			render: ({ primary_id }: IArchivedTransaction) => (
				<TextWithCopyButton text={primary_id} />
			),
		},
		{
			id: 'wallet_identifier',
			title: t('Wallet number'),
			render: ({ wallet_identifier }: IArchivedTransaction) => (
				<TextWithCopyButton text={wallet_identifier} />
			),
		},
		{
			id: 'amount',
			title: t('Amount'),
			render: ({ amount, direction, currency_code }: IArchivedTransaction) => (
				<Money
					amount={amount}
					direction={direction}
					currencyCode={currency_code}
				/>
			),
		},
		{
			id: 'total_commission',
			title: t('Commission'),
			render: ({ total_commission, currency_code }: IArchivedTransaction) => (
				<Money amount={total_commission} currencyCode={currency_code} />
			),
		},
		{
			id: 'created_at',
			title: t('Creation date'),
		},
		{
			id: 'activated_at',
			title: t('Activation date'),
		},
		{
			id: 'merchant_order_id',
			title: t('Order ID'),
			render: ({ merchant_order_id }: IArchivedTransaction) => (
				<TextWithCopyButton text={merchant_order_id} />
			),
		},
		{
			id: 'label',
			title: t('Label'),
			render: ({ label }: IArchivedTransaction) => (
				<TextWithCopyButton text={label} />
			),
		},
		{
			id: 'id',
			title: t('Transaction number'),
			render: ({ id }: IArchivedTransaction) => (
				<TextWithCopyButton text={id} />
			),
		},
		{
			id: 'exchanger_identifier',
			title: t('Counterparty'),
			render: ({ exchanger_identifier }: IArchivedTransaction) => (
				<TextWithCopyButton text={exchanger_identifier} />
			),
		},
		{
			id: 'status',
			title: t('Status'),
			render: ({ status }: IArchivedTransaction) => (
				<TransactionStatus status={status} />
			),
		},
		{
			id: 'postback_status',
			title: t('Postback'),
			render: ({ postback_status }: IArchivedTransaction) => (
				<PostbackStatus status={postback_status} />
			),
		},
		{
			id: 'type',
			title: t('Transaction kind'),
			render: ({ type }: IArchivedTransaction) => (
				<TransactionType type={type} />
			),
		},
		{
			id: 'creation_type',
			title: t('Transaction type'),
			render: ({ creation_type }: IArchivedTransaction) => (
				<CreationType type={creation_type} />
			),
		},
	];

	return (
		<div className={styles.CommonData}>
			<ScrollBox>
				{ROWS_MAP.map((row) => {
					const value = transactionData[row.id as keyof IArchivedTransaction];
					const renderedValue = row.render
						? row.render(transactionData)
						: value;

					return (
						<div key={`${row.id}_${row.title}`} className={styles.row}>
							<div className={styles.title}>{row.title}</div>
							<div className={styles.value}>{renderedValue}</div>
						</div>
					);
				})}
			</ScrollBox>
		</div>
	);
};

export default CommonData;
