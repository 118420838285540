import { useTranslation } from 'pay-kit';
import { Button, Modal } from 'pay-kit';

import styles from './RemoveConfirmation.module.scss';

export interface IRemoveConfirmationProps {
	readonly psId?: string;
	readonly isOpen: boolean;
	readonly onClose: () => void;
	readonly onSuccess: () => void;
}

const RemoveConfirmation: React.FC<IRemoveConfirmationProps> = ({
	isOpen,
	onClose,
	psId,
	onSuccess,
}) => {
	const { t } = useTranslation();

	return (
		<Modal
			isOpen={isOpen}
			title={t('Payment system deleting')}
			onClose={onClose}
		>
			<p>
				{t(`Are you sure you want to delete payment systems {{psId}}?`, {
					psId,
				})}
			</p>
			<div className={styles.actions}>
				<Button
					onClick={() => {}}
					data-test-id='cancel'
					type='button'
					variant='secondary'
				>
					{t('Cancel')}
				</Button>
				<Button
					onClick={() => {}}
					data-test-id='confirm'
					type='button'
					variant='delete'
				>
					{t('Delete')}
				</Button>
			</div>
		</Modal>
	);
};

export default RemoveConfirmation;
