import { useState } from 'react';

type UseFormStateHookPropsType<StateType> = {
	readonly initialState: StateType;
};

type UseFormStateHookReturnType<StateType> = {
	readonly formState: StateType;
	readonly setFieldState: (name: string, value: any) => void;
	readonly resetState: () => void;
	readonly mergeFormState: typeof useState<StateType>;
};

function useFormStateHook<StateType>({
	initialState,
}: UseFormStateHookPropsType<StateType>) {
	const [formState, setState] = useState<StateType>(initialState);

	const setFieldState = (name: string, value: any) => {
		setState((prevState) => ({ ...prevState, [name]: value }));
	};

	const resetState = () => setState(initialState);

	const mergeFormState = (changesObj: Partial<StateType>) => {
		setState((prevState: StateType) => ({ ...prevState, ...changesObj }));
	};

	return {
		formState,
		setFieldState,
		resetState,
		mergeFormState,
	} as UseFormStateHookReturnType<StateType>;
}

export default useFormStateHook;
