import { AccountsBalanceDateType, PSBalanceType } from 'api/balanceGroup';
import { convertIntoCurrency } from 'helpers/convertIntoCurrency';
import { Tooltip } from 'pay-kit';
import { Link } from 'react-router-dom';

import styles from './balancesLinks.module.scss';

export type BalancesListType = Partial<Pick<PSBalanceType, 'id'>> &
	Omit<PSBalanceType, 'is_settlement' | 'id'>;

const BalancesLinks = ({
	balances,
	onClick,
	payment_system,
	amountClass,
	dataTestId,
	filters,
}: {
	readonly balances: readonly BalancesListType[];
	readonly filters: AccountsBalanceDateType;
	readonly payment_system: string;
	readonly onClick?: () => void;
	readonly amountClass?: string;
	readonly dataTestId?: string;
}) => {
	const renderList = (): readonly JSX.Element[] => {
		return balances.map(({ currency_code, amount, id }, i) => {
			const formattedAmount = convertIntoCurrency({ amount });
			const handleOnClick = () => onClick && onClick();

			const dateTime = Object.values(filters).length
				? filters.date.split(' ')
				: null;
			const pathTo = dateTime
				? `/operation-history/${payment_system}/${id}/${dateTime[0]}/${dateTime[1]}`
				: `/operation-history/${payment_system}/${id}`;

			return (
				<Tooltip
					tip={`${currency_code} ${formattedAmount}`}
					preferredSide='bottom'
					key={i}
				>
					<div className={styles.accountsWrapper}>
						<Link
							data-test-id={dataTestId}
							to={pathTo}
							className={[styles.accountsBalance, amountClass].join(' ')}
							onClick={handleOnClick}
						>
							<span className={styles.accountsCurrencyCode}>
								{currency_code}
							</span>
							{formattedAmount}
						</Link>
					</div>
				</Tooltip>
			);
		});
	};

	return <>{renderList()}</>;
};

export default BalancesLinks;
