import { FC } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import { MenuGroupType } from '../../index';

import styles from './MenuGroup.module.scss';

type MenuGroupProps = {
	readonly group: ReadonlyArray<MenuGroupType>;
};

const MenuGroup: FC<MenuGroupProps> = ({ group }) => {
	const { pathname } = useLocation();

	return (
		<div className={styles.MenuGroup}>
			{group.map((elem: MenuGroupType, i) => {
				if (elem.elements.some((elem) => elem.isVisible)) {
					return (
						<div className={styles.group} key={i}>
							<div className={styles.title}>{elem.title}</div>
							{elem.elements.map((item, j) => {
								const isActive = item.link === pathname.substring(1);
								const isVisible = item.isVisible;

								if (!isVisible) {
									return null;
								}

								return (
									<div
										className={styles.subMenuItem}
										key={j}
										data-is-active={isActive}
									>
										<NavLink
											className={styles.link}
											to={item.link}
											data-test-id={item.link}
										>
											{item.title}
										</NavLink>
									</div>
								);
							})}
						</div>
					);
				}

				return null;
			})}
		</div>
	);
};

export default MenuGroup;
