export const convertIntoCurrency: ConvertIntoCurrencyType = ({
	amount,
	maximumFractionDigits = 2,
	minimumFractionDigits = 2,
	signDisplay = 'auto',
}) => {
	return Intl.NumberFormat('en', {
		signDisplay,
		maximumFractionDigits,
		minimumFractionDigits,
	})
		.format(amount)
		.trim();
};

type ConvertIntoCurrencyType = (params: {
	readonly amount: number;
	readonly maximumFractionDigits?: number;
	readonly minimumFractionDigits?: number;
	readonly signDisplay?: 'auto' | 'always' | 'exceptZero' | 'never';
}) => string;
