import { t } from '@paykassma/pay-kit';

export const validateNoTransactionsPeriod = (value: string) => {
	const parsedInt = parseInt(value, 10);

	if (!isNaN(parsedInt) && parsedInt >= 5) {
		if (parsedInt > 600) {
			return t('Value must be less than {{limit}}', { limit: 600 });
		}

		if (value !== parsedInt.toString()) {
			return t('Only integer values');
		}

		return undefined;
	}

	return t('Value must be more than {{limit}}', { limit: 5 });
};

export const validateImpressionCounter = (value: string) => {
	const parsedInt = parseInt(value, 10);

	if (!isNaN(parsedInt) && parsedInt >= 5) {
		if (parsedInt > 9999999999) {
			return t('Value must be less than {{limit}}', { limit: 9999999999 });
		}

		if (value !== parsedInt.toString()) {
			return t('Only integer values');
		}

		return undefined;
	}

	return t('Value must be more than {{limit}}', { limit: 5 });
};
