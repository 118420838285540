import api, { IGetWorktimeSuccessResponse } from 'api/walletTypesGroup';
import { useState } from 'react';
import { errorsMap } from 'utils/enums';

type useWorktimeSettingsHookType = () => {
	readonly load: (walletType: string) => void;
	readonly data?: IGetWorktimeSuccessResponse;
	readonly isLoading: boolean;
};

const useWorktimeSettingsHook: useWorktimeSettingsHookType = () => {
	const [data, setData] = useState<IGetWorktimeSuccessResponse | undefined>();
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const load = (walletType: string) => {
		setData(undefined);
		setIsLoading(true);

		api
			.getWorktime(walletType)
			.then((res: any) => {
				if (res?.status === 'ok') {
					setData(res);

					return res;
				} else {
					// throw new Error(`Getting Worktime Settings for ${walletType} error`);
					throw new Error(errorsMap.getWorkTime(walletType));
				}
			})
			.finally(() => setIsLoading(false))
			.catch((err) => {
				console.error(err);
			});
	};

	return {
		load,
		data,
		isLoading,
	};
};

export default useWorktimeSettingsHook;
