import API from 'api';
import { ICurrentTransaction } from 'api/transactionGroup';
import { WalletItem, WalletTypeType } from 'api/walletGroup';
import Broom from 'assets/icons/broom.svg';
import TransactionsIcon from 'components/Icons/TransactionsIcon';
import PaymentSystemLogo from 'components/PaymentSystemLogo';
import ActivatePayTM from 'components/SideModalContent/ActivatePayTM';
import EditableCommentContainer from 'components/SideModalContent/EditableComment';
import ProxiesView from 'components/SideModalContent/ProxiesView';
import TextWithCopyButton from 'components/TextWithCopyButton';
import AuthContext, { Roles } from 'contexts/AuthContext';
import { convertIntoCurrency } from 'helpers/convertIntoCurrency';
import { WalletState } from 'modules/Wallets/components/modals/ChangeWalletModal/utils/enums';
import { Tooltip, useTranslation } from 'pay-kit';
import { Button, Loader, ScrollBox } from 'pay-kit';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { WalletTypes, errorsMap } from 'utils/enums';

import styles from './sideModalContent.module.scss';

export const CURRENT_TAB_MAP = {
	working: `working`,
	disabled: `disabled`,
	archived: `archived`,
} as const;

const SideModalContent: React.FC<SideModalContentType> = ({
	walletHashId,
	id,
	showAllTransactionsForWallet,
	currentTab,
	onClick,
}) => {
	const { t } = useTranslation();
	const [walletData, setWalletData] = useState<WalletTypeType>(
		{} as WalletTypeType,
	);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const { hasRole } = useContext(AuthContext);

	const getWalletData = (hash_id: string) => {
		setIsLoading(true);

		API.wallet
			.getWallet(hash_id)
			.then((res) => {
				if (res?.status === 'success') {
					setWalletData(res.data);
					setIsLoading(false);

					return;
				} else {
					throw new Error('Unexpected response');
				}
			})
			.catch((err) => {
				console.log(err);
				setIsLoading(false);

				window.pushAlert({
					description: errorsMap.cantGetWalletData,
					type: 'error',
				});
			});
	};

	useEffect(() => {
		if (walletHashId) {
			getWalletData(walletHashId);
		}
	}, [walletHashId]);

	const updateComment = (comment: string | null) =>
		setWalletData((prevData) => ({ ...prevData, comment }));

	if (isLoading) {
		return (
			<div className={styles.loader}>
				<Loader color='#A2A9B0' />
			</div>
		);
	}

	if (!walletData || !walletHashId) {
		return (
			<div className={styles.noWalletData}>{t('No wallet information')}</div>
		);
	}

	const ROWS_MAP: readonly RowType[] = [
		{
			id: 'wallet_type',
			title: t('Wallet type'),
			render: ({ wallet_type }) => (
				<PaymentSystemLogo id={wallet_type} hideLabel={true} />
			),
		},
		{
			id: 'name',
			title: t('Name'),
			render: ({ wallet_type }) => (
				<PaymentSystemLogo id={wallet_type} hideLogo={true} />
			),
		},
		{
			id: 'hash_id',
			title: t(`Hash ID`),
			render: ({ hash_id }) => <TextWithCopyButton text={hash_id} />,
		},
		{
			id: 'identifier',
			title: t('Wallet number'),
			render: ({ identifier }) => <TextWithCopyButton text={identifier} />,
		},
		{
			id: 'balance',
			title: t('Balance'),
			render: ({ balance, currency }) => {
				if (currency) {
					return (
						<div
							className={styles.balance}
						>{`${convertIntoCurrency({ amount: balance })} ${currency}`}</div>
					);
				}

				return null;
			},
			hide: !hasRole(Roles.WALLET_BALANCE),
		},
		{
			id: 'activity_log',
			title: t('Update'),
			render: ({ activity_log }: WalletItem) => (
				<Tooltip
					preferredSide='bottom'
					tip={
						<>
							{activity_log?.last_ingoing && (
								<div>
									{t('Last money receiving')}: {activity_log?.last_ingoing}
								</div>
							)}
							{activity_log?.last_outgoing && (
								<div>
									{t('Last money withdrawal')}: {activity_log?.last_outgoing}
								</div>
							)}
							{activity_log?.last_parsing && (
								<div>
									{t('Last parsing')}: {activity_log?.last_parsing}
								</div>
							)}
							{/* {activity_log?.data?.updated_at && <div>Последнее обновление: {activity_log?.data?.updated_at}</div>} */}
							{activity_log?.data?.updated_at && (
								<div>
									{t('Last update')}: {activity_log?.data?.updated_at}
								</div>
							)}
						</>
					}
				>
					<div>{activity_log?.data?.updated_at}</div>
				</Tooltip>
			),
		},
		// https://confluence.fixmost.com/pages/viewpage.action?pageId=111613841
		// {
		// 	id: "proxies",
		// 	title: t("Proxy"),
		// 	render: ({ proxies }) => <ProxiesView proxies={proxies} />,
		// 	hide: !hasRole(Roles.PROXY_LIST),
		// },
		// {
		// 	id: "comment",
		// 	title: t("Comment"),
		// 	render: ({ comment, hash_id }) => (
		// 		<EditableCommentContainer hash_id={hash_id} comment={comment} onSuccess={updateComment} />
		// 	),
		// },
	];

	return (
		<div className={styles.wallet}>
			<ScrollBox>
				{ROWS_MAP.map((row) => {
					const value = walletData[row.id];

					return (
						<div key={`${row.id}_${row.title}`} className={styles.row}>
							<div className={styles.title}>{row.title}</div>
							<div className={styles.value}>
								{row.render ? row.render(walletData) : `${value}`}
							</div>
						</div>
					);
				})}
			</ScrollBox>

			<div className={styles.actions}>
				{hasRole(Roles.TRANSACTION_LIST) && (
					<Button
						data-test-id='I50B-DmZilNtFL91rMhNp'
						onClick={() => showAllTransactionsForWallet(walletHashId)}
						variant='primary'
						classname={styles.submitTransactions}
					>
						<TransactionsIcon className={styles.transactionsIcon} />{' '}
						{t('Transaction list')}
					</Button>
				)}
				{currentTab === CURRENT_TAB_MAP.disabled &&
					walletData.states?.includes(WalletState.UPDATE_COOKIES) && (
						<Button
							data-test-id='ikVB75DAPjJC2DssI7Vm_'
							variant='delete'
							classname={styles.resetCookies}
							onClick={() => {
								onClick && onClick();
							}}
						>
							<Broom />
							{t(`Reset cookies`)}
						</Button>
					)}
				{walletData.wallet_type === WalletTypes.PAY_TM && (
					<ActivatePayTM
						identifier={walletData.identifier}
						walletType={walletData.wallet_type}
						states={walletData.states}
						parserType={walletData.parser_type}
						smsSource={walletData.sms_source}
					/>
				)}
			</div>
		</div>
	);
};

export default SideModalContent;

interface SideModalContentType {
	readonly walletHashId?: ICurrentTransaction['wallet_hash_id'];
	readonly id?: ICurrentTransaction['id'];
	readonly showAllTransactionsForWallet: (
		wallet_hash_id: ICurrentTransaction['wallet_hash_id'],
	) => void;
	readonly currentTab?: `working` | `disabled` | `archived`;
	readonly onClick?: () => void;
}

type RowType = {
	readonly id: keyof WalletTypeType;
	readonly title: string;
	readonly render?: ({
		wallet_type,
	}: WalletTypeType) => ReactElement | string | null;
	readonly hide?: boolean;
};
