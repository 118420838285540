import { useTranslation } from 'pay-kit';
import { TransactionStatusEnum } from 'api/transactionGroup';
import { Tooltip } from 'pay-kit';
import React from 'react';

import styles from './TransactionStatus.module.scss';

interface ITransactionStatus {
	readonly status: TransactionStatusEnum;
	readonly dataTestId?: string;
}

const TransactionStatus: React.FC<ITransactionStatus> = ({
	status,
	dataTestId,
}) => {
	const { t } = useTranslation();

	const StatusMap = {
		[TransactionStatusEnum.NOT_ACTIVATED]: {
			// label: "Не активирована",
			label: t('Not activated'),
			className: styles.NOT_ACTIVATED,
		},
		[TransactionStatusEnum.ACTIVATED]: {
			// label: "Активирована",
			label: t('Activated'),
			className: styles.ACTIVATED,
		},
		[TransactionStatusEnum.PENDING]: {
			label: t('Pending'),
			className: styles.PENDING,
		},
		[TransactionStatusEnum.IN_PROCESSING]: {
			label: t('Processing'),
			className: styles.IN_PROCESSING,
		},
		[TransactionStatusEnum.CRASHED]: {
			// label: "Сбой",
			label: t('Crashed'),
			className: styles.CRASHED,
		},
		[TransactionStatusEnum.CANCELED]: {
			// label: "Отменена",
			label: t('Canceled'),
			className: styles.CANCELED,
		},
		[TransactionStatusEnum.REFUSED]: {
			// label: "Отклонена",
			label: t('Refused'),
			className: styles.REFUSED,
		},
		[TransactionStatusEnum.ERROR]: {
			label: t('Error'),
			className: styles.ERROR,
		},
		[TransactionStatusEnum.SUSPECT]: {
			label: t('Suspect'),
			className: styles.SUSPECT,
		},
	};

	// const statusObject = StatusMap[status] || { label: "Неопределенный статус", className: styles.notDefined };
	const statusObject = StatusMap[status] || {
		label: t('Not defined status'),
		className: styles.notDefined,
	};
	const classNames = [styles.TransactionStatus, statusObject.className];

	return (
		// <Tooltip tip="Статус транзакции" preferredSide="bottom">
		<Tooltip tip={t('Status transaction')} preferredSide='bottom'>
			<div className={classNames.join(' ')} data-test-id={dataTestId}>
				{statusObject.label}
			</div>
		</Tooltip>
	);
};

export default TransactionStatus;
