import API from 'api';
import { AvailableTimezonesType } from 'api/settingsGroup';
import { useState } from 'react';
import { errorsMap } from 'utils/enums';

const useGetTimezoneList = () => {
	const [error, setError] = useState<Error | null>(null);
	const [isLoading, setIsLoading] = useState(false);
	const [list, setList] = useState<
		AvailableTimezonesType['available_timezones'] | null
	>(null);

	const load = () => {
		setError(null);
		setIsLoading(true);
		setList(null);

		API.settings
			.getTimezoneList()
			.then((resp) => {
				if (resp.status === 'ok') {
					setList(resp.available_timezones);
				} else {
					throw new Error(errorsMap.anyResponse);
				}
			})
			.catch((err) => {
				console.log(err);
				setError(err);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	return {
		load,
		list,
		isLoading,
		error,
	};
};

export default useGetTimezoneList;
