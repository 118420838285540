import { useTranslation } from 'pay-kit';
import Filter from 'components/Filter';
import { WalletTypesContext } from 'contexts/WalletTypesContext';
import constructQueryStringParams from 'helpers/constructQueryStringParams';
import ReloadButton from 'modules/Postbacks/components/ReloadButton'; // TODO: Consider moving this component up to the components
import { getDateString } from 'modules/WalletsDebugging/helpers';
import { withdrawalsLogsListColumns } from 'modules/WalletsDebugging/WithdrawalsLogsList/component';
import useLoadWithdrawalsLogList from 'modules/WalletsDebugging/WithdrawalsLogsList/hooks/useLoadWithdrawalsLogList';
import { Table } from 'pay-kit';
import { useContext, useEffect, useState } from 'react';
import useURLState from 'utils/hooks/useURLState';
import { rejectSettlement } from 'utils/filterSettlement';
import { fromLocaleDate, notLaterThanToday } from 'utils/date';
import useURLFilters from 'utils/hooks/useURLFilters';

import styles from './withdrawalsLogsList.module.scss';

const WithdrawalsLogsList = () => {
	const { walletTypes, isLoading } = useContext(WalletTypesContext);
	const withdrawalsLog = useLoadWithdrawalsLogList();

	const { t } = useTranslation();

	const [page, setCurrentPage] = useURLState<number>(
		'page',
		withdrawalsLog.data?.current_page || 1,
	);
	const [limit, setLimit] = useURLState<number>(
		'limit',
		withdrawalsLog.data?.per_page || 10,
	);
	const [filters, setFilters] = useURLFilters({
		dateRangeFields: ['dateRange'],
	});

	const walletTypeOptions = rejectSettlement(walletTypes)?.map(
		({ name, code }) => ({ value: code, label: name }),
	);
	const OnLoadWithdrawalsLogList = () => {
		const { dateRange, ...rest } = filters;

		const rawParams = {
			...rest,
			page,
			limit,
		};

		if (dateRange) {
			rawParams.date_from = fromLocaleDate(dateRange?.from).split(' ')[0];
			rawParams.date_to = fromLocaleDate(dateRange?.to).split(' ')[0];
		}

		const params = constructQueryStringParams(rawParams);

		withdrawalsLog.load(params);
	};

	useEffect(OnLoadWithdrawalsLogList, [page, limit, filters]);

	return (
		<>
			<Filter
				classNames={{ wrapper: styles.logsFilters }}
				values={filters}
				fields={[
					{
						name: 'withdrawal_id',
						// placeholder: "ID вывода",
						placeholder: t('Withdrawal ID'),
						component: 'textInput',
						className: styles.selectField,
					},
					{
						name: 'wallet_type',
						placeholder: t('Wallet type'),
						component: 'select',
						className: styles.selectField,
						options: walletTypeOptions,
						isLoading: isLoading,
					},
					{
						name: 'dateRange',
						// toPlaceholder: "Дата до",
						toPlaceholder: t('Date to'),
						// fromPlaceholder: "Дата от",
						fromPlaceholder: t('Date from'),
						label: t('Date'),
						component: 'dateRange',
						dateFormat: 'DD.MM.YYYY',
						blockPredicate: notLaterThanToday,
					},
				]}
				onApply={setFilters}
			/>

			<div className={styles.withdrawalsLogsTableWrapper}>
				<Table
					className={styles.withdrawalsLogsTable}
					rowKey={'id'}
					data={withdrawalsLog.data?.data || []}
					columns={withdrawalsLogsListColumns}
					paginator={{
						currentPage: page,
						setCurrentPage,
						limit,
						setLimit,
						lastAvailablePage: withdrawalsLog.data?.last_available_page || 1,
						prefixElement: (
							<ReloadButton
								data-test-id='PPy7FPwz5A06ApnarXH8_'
								isLoading={withdrawalsLog.isLoading}
								onClick={OnLoadWithdrawalsLogList}
							/>
						),
						className: styles.paginator,
						bottomPaginatorProps: {
							className: styles.bottomPaginator,
						},
					}}
					isLoading={withdrawalsLog.isLoading}
				/>
			</div>
		</>
	);
};

export default WithdrawalsLogsList;

type FiltersType = {
	readonly wallet_type?: string;
	readonly withdrawal_id?: string;
	readonly dateRange?: { readonly from: string; readonly to: string };
};
