import { ChangeEvent, useState } from 'react';

type UseTextInput = readonly [
	string,
	(e: ChangeEvent<HTMLInputElement>) => void,
];

function useTextInput(initValue: string): UseTextInput {
	const [value, setValue] = useState<string>(initValue);

	const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
		setValue(e.target.value);
	};

	return [value, handleInput];
}

export default useTextInput;
