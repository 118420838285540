import API from 'api/walletGroup';
import AuthContext, { Roles } from 'contexts/AuthContext';
import React, { useContext, useEffect, useState } from 'react';
import { errorsMap } from 'utils/enums';

import { WalletType } from './types';

export const ActiveWalletsContext = React.createContext({
	list: [] as readonly WalletType[],
	isLoading: false,
});

type ActiveWalletsProviderPropsType = {
	readonly children: React.ReactNode;
};

const ActiveWalletsProvider = ({
	children,
}: ActiveWalletsProviderPropsType) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [list, setList] = useState<readonly WalletType[]>([]);

	const { hasRole } = useContext(AuthContext);
	const hasWalletsAccess = hasRole(Roles.WALLET_LIST);

	const load = (type?: string) => {
		setIsLoading(true);

		API.getList({ type, removed: 0, is_active: 1 })
			.then((res: any) => {
				if (res.status !== 'success' || !res?.data) {
					throw new Error(errorsMap.anyResponse);
				}

				setList(res.data);

				return res;
			})
			.finally(() => setIsLoading(false))
			.catch((err) => {
				console.error(err);
				window.pushAlert({ title: err, type: 'error' });
			});
	};

	useEffect(() => {
		if (hasWalletsAccess) {
			load();
		}
	}, [hasWalletsAccess]);

	return (
		<ActiveWalletsContext.Provider
			value={{
				isLoading,
				list,
			}}
		>
			{children}
		</ActiveWalletsContext.Provider>
	);
};

export default ActiveWalletsProvider;
