import { WithdrawPostbackStatusENUM } from 'api/withdrawalGroup';
import { useTranslation } from 'pay-kit';
import { Tooltip } from 'pay-kit';
import React from 'react';

import Status1SVG from './icons/status_1.svg';
import Status2SVG from './icons/status_2.svg';
import styles from './PostbackStatus.module.scss';

interface IPostbackStatusType {
	readonly status: WithdrawPostbackStatusENUM;
	readonly showCaption?: boolean;
	readonly dataTestId?: string;
}

const PostbackStatus: React.FC<IPostbackStatusType> = ({
	status,
	showCaption,
	dataTestId,
}) => {
	const { t } = useTranslation();

	if (status === WithdrawPostbackStatusENUM.SUCCESS) {
		return (
			// <Tooltip tip="Статус постбека: Отправлен" preferredSide="bottom">
			<Tooltip tip={t('Postaback status: Sent')} preferredSide='bottom'>
				<div className={styles.PostbackStatus} data-test-id={dataTestId}>
					<Status1SVG />
					{showCaption && <span>{t('Sent')}</span>}
				</div>
			</Tooltip>
		);
	}

	if (status === WithdrawPostbackStatusENUM.ERROR) {
		return (
			// <Tooltip tip="Статус постбека: Ошибка отправки" preferredSide="bottom">
			<Tooltip tip={t('Postback status: Error')} preferredSide='bottom'>
				<div className={styles.PostbackStatus} data-test-id={dataTestId}>
					<Status2SVG />
					{/* {showCaption && <span>Ошибка отправки</span>} */}
					{showCaption && <span>{t('Sent error')}</span>}
				</div>
			</Tooltip>
		);
	}

	return null;
};

export default PostbackStatus;
