import Api from 'api';
import { LoadWithdrawalsLogListType } from 'api/walletsDebuggingGroup';
import { useState } from 'react';
import { errorsMap } from 'utils/enums';

const useLoadWithdrawalsLogList = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState<Error | null>(null);
	const [data, setData] = useState<LoadWithdrawalsLogListType['data'] | null>(
		null,
	);

	const load = (params: string) => {
		setError(null);
		setIsLoading(true);

		Api.walletsDebugging
			.loadWithdrawalsLogList(params)
			.then((res) => {
				if (res.status === 'ok') {
					setData(res.data);
					setIsLoading(false);
				} else {
					// throw new Error("Unexpected response in loadWithdrawalsLogList!");
					throw new Error(errorsMap.anyResponse);
				}
			})
			.catch((err) => {
				console.log(err);
				setError(err);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	return {
		load,
		data,
		isLoading,
		error,
	};
};

export default useLoadWithdrawalsLogList;
