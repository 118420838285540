import API from 'api';
import { WalletListItem } from 'api/walletGroup';
import EditableComment from 'components/EditableComment';
import { useTranslation } from 'pay-kit';
import React, { useState } from 'react';

interface IUpdateCommentProps {
	readonly id: WalletListItem['id'];
	readonly comment: WalletListItem['comment'];
	readonly onSuccess: (comment: string | null) => void;
	readonly isEditable: boolean;
}

const EditableCommentContainer: React.FC<IUpdateCommentProps> = ({
	id,
	onSuccess,
	comment,
	isEditable = false,
}) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [value, setValue] = useState<string | null>(comment);
	const [editingModeOn, setEditingModeOn] = useState<boolean>(false);
	const { t } = useTranslation();

	const updateSettings = (comment: string | null) => {
		setIsLoading(true);

		API.withdrawal
			.setComment(id, comment !== null ? comment : '')
			.then((res) => {
				if (res?.status === 'ok') {
					setIsLoading(false);

					// window.pushAlert({ description: "Комментарий успешно изменен", type: "success" });
					window.pushAlert({
						description: t('Comment successfully changed'),
						type: 'success',
					});

					setEditingModeOn(false);
					onSuccess(comment);

					return res;
				} else {
					// throw new Error("Unexpected response");
					throw new Error(t('Unexpected response'));
				}
			})
			.catch((err) => {
				console.error(err);
				setIsLoading(false);

				// window.pushAlert({ description: "Не удалось изменить комментарий", type: "error" });
				window.pushAlert({
					description: t('Failed to change comment'),
					type: 'error',
				});
			});
	};

	if (!isEditable) {
		return <>{comment}</>;
	}

	return (
		<EditableComment
			data-test-id='useless-data-test-id'
			{...{
				isLoading,
				editingModeOn,
				setEditingModeOn,
				initialState: comment,
				value,
				onChange: setValue,
				onSubmit: updateSettings,
			}}
		/>
	);
};

export default EditableCommentContainer;
